import { CheckSolid } from '@motion/icons'
import { Button, Tag } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import {
  enterpriseBullets,
  enterpriseElevatorPitch,
  INDIVIDUAL_ANNUAL_PRICE_AMORTIZED_MONTHLY,
  individualBullets,
  individualElevatorPitch,
  makeTeamBullets,
  teamElevatorPitch,
} from '@motion/ui-logic/billing'

import { twMerge } from 'tailwind-merge'

import { useEnterpriseLink } from './hooks'

type BillingPlanCardProps = {
  subtitle: string
  sectionHeader?: string
  items: string[]
  onClick: () => void
  isTeam: boolean
  price?: number
  savingsDescription?: string
  buttonText?: string
  planType: 'Individual' | 'Team' | 'Enterprise'
  border?: boolean
  isSecondary?: boolean
  hidePrice?: boolean
}

const BillingPlanCard = ({
  subtitle,
  sectionHeader,
  items,
  onClick,
  isTeam,
  price,
  savingsDescription,
  buttonText,
  planType,
  border,
  isSecondary,
  hidePrice,
}: BillingPlanCardProps) => {
  return (
    <div
      className={twMerge(
        'flex flex-col flex-1 max-w-[500px] min-w-[300px] justify-top rounded bg-semantic-neutral-bg-default p-6 drop-shadow-md',
        border && 'border border-semantic-neutral-border-default'
      )}
    >
      <div className='pb-8'>
        <div className='flex flex-row w-full justify-between mb-2'>
          <p className='text-semantic-neutral-text-default text-lg font-bold'>
            {planType}
          </p>
          {isTeam && savingsDescription && (
            <Tag size='small' color='green' variant='subtle'>
              <strong>{savingsDescription}</strong>
            </Tag>
          )}
        </div>
        <p className='text-semantic-neutral-text-subtle lg:mb-4 text-sm font-medium'>
          {subtitle}
        </p>
        <p className='pb-6'>
          {hidePrice ? null : price ? (
            <>
              <span className='text-semantic-neutral-text-default text-xl font-bold'>
                ${price}
              </span>
              <span className='text-semantic-neutral-text-subtle text-sm font-normal'>
                {templateStr(' per month{{userStr}}', {
                  userStr: isTeam ? ' per user' : '',
                })}
              </span>
            </>
          ) : (
            <>
              <span className='text-semantic-neutral-text-default text-xl font-bold pr-1'>
                Contact us
              </span>
              <span className='text-semantic-neutral-text-subtle text-sm font-normal'>
                (25 seat minimum)
              </span>
            </>
          )}
        </p>
        <Button
          fullWidth
          onClick={onClick}
          sentiment={isSecondary ? 'neutral' : 'primary'}
          data-testid={`choose-plan-${planType}`}
        >
          {buttonText ? buttonText : `Continue as ${planType}`}
        </Button>
      </div>
      {sectionHeader && (
        <p className='text-semantic-neutral-text-subtle mb-2 text-sm font-normal'>
          {sectionHeader}
        </p>
      )}
      <div className='flex mb-6 flex-col gap-2'>
        {items.map((item) => (
          <div key={item} className='flex gap-2 place-content-start'>
            <CheckSolid className='text-semantic-primary-icon-default h-4 w-4 shrink-0 mt-[2px]' />
            <p className='text-semantic-neutral-text-subtle mb-0 text-sm font-normal'>
              {item}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

type IndividualBillingPlanCardProps = Pick<
  BillingPlanCardProps,
  'onClick' | 'buttonText' | 'hidePrice' | 'isSecondary'
>

export const IndividualBillingPlanCard = ({
  onClick,
  buttonText,
  hidePrice,
  isSecondary,
}: IndividualBillingPlanCardProps) => {
  return (
    <BillingPlanCard
      isTeam={false}
      subtitle={individualElevatorPitch}
      items={individualBullets}
      onClick={onClick}
      price={INDIVIDUAL_ANNUAL_PRICE_AMORTIZED_MONTHLY}
      buttonText={buttonText}
      planType='Individual'
      hidePrice={hidePrice}
      isSecondary={isSecondary}
    />
  )
}

type TeamBillingPlanCardProps = Pick<
  BillingPlanCardProps,
  'onClick' | 'buttonText' | 'hidePrice' | 'border' | 'price'
> & {
  savings?: number
}

export const TeamBillingPlanCard = ({
  onClick,
  buttonText,
  hidePrice,
  savings,
  price,
  border,
}: TeamBillingPlanCardProps) => {
  return (
    <BillingPlanCard
      isTeam
      subtitle={teamElevatorPitch}
      sectionHeader='Everything in Individual, plus:'
      items={makeTeamBullets(savings)}
      onClick={onClick}
      price={price}
      savingsDescription={
        savings ? `${savings}% cheaper than Individual` : undefined
      }
      planType='Team'
      border={border}
      buttonText={buttonText}
      hidePrice={hidePrice}
    />
  )
}

type EnterpriseBillingPlanCardProps = Pick<
  BillingPlanCardProps,
  'hidePrice' | 'border'
> & { location: string }

export const EnterpriseBillingPlanCard = ({
  hidePrice,
  border,
  location,
}: EnterpriseBillingPlanCardProps) => {
  const openEnterpriseLink = useEnterpriseLink()

  return (
    <BillingPlanCard
      isTeam
      subtitle={enterpriseElevatorPitch}
      sectionHeader='Everything in Team, plus:'
      items={enterpriseBullets}
      onClick={() => openEnterpriseLink(location)}
      savingsDescription='Dedicated expert ($10k value) for free'
      buttonText={hidePrice ? 'Book Demo (25+ seats)' : 'Book Demo'}
      planType='Enterprise'
      border={border}
      hidePrice={hidePrice}
    />
  )
}
