import EventEmitter from 'events'

import { type WsEventArg, type WsEventDef, type WsEventTypes } from './types'

import { logInDev } from '../logging'

export class WebsocketsEventSubscriber {
  ee: EventEmitter

  constructor() {
    this.ee = new EventEmitter()
  }

  subscribe<E extends WsEventDef, T extends string & WsEventTypes<E>>(
    event: `${T}`,
    callback: (args: WsEventArg<E, T>, event: T) => void
  ) {
    this.ee.addListener(event, callback)
    return this
  }

  unsubscribe<E extends WsEventDef, T extends string & WsEventTypes<E>>(
    event: `${T}`,
    callback: (args: WsEventArg<E, T>, event: T) => void
  ) {
    this.ee.removeListener(event, callback)
  }

  on<E extends WsEventDef, T extends string & WsEventTypes<E>>(
    event: `${T}`,
    callback: (args: WsEventArg<E, T>, event: T) => void
  ): () => void {
    this.ee.addListener(event, callback)
    return () => this.ee.removeListener(event, callback)
  }

  handle(event: string, args: any[]) {
    logInDev(`Received websockets message ${event}`, args)
    this.ee.listeners(event).forEach((cb) => cb(args, event))
  }
}

export const websocketsEventSubscriber = new WebsocketsEventSubscriber()
if (__IS_DEV__) {
  // @ts-expect-error only in dev
  window.ws = (...args) => websocketsEventSubscriber.handle(...args)
}
