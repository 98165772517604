import { Sentry } from '@motion/web-common/sentry'

import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'

export const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30_000,
      networkMode: 'always',
    },
    mutations: {
      networkMode: 'always',
    },
  },
  queryCache: new QueryCache({
    onError(error, query) {
      Sentry.captureException(error, {
        extra: {
          queryKey: query.queryKey,
          queryOptions: query.options,
        },
        tags: { position: 'queryCache' },
      })
    },
  }),
  mutationCache: new MutationCache({
    onError(error, variables) {
      Sentry.captureException(error, {
        extra: {
          variables,
        },
        tags: { position: 'mutationCache' },
      })
    },
  }),
})
