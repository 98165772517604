import { type RecurringTaskSchema } from '@motion/rpc-types'

import {
  type CustomFieldFieldArrayValue,
  mapCustomFieldToFieldArrayWithValue,
} from '../../../custom-fields'
import { getDefaultStatus } from '../../../statuses'
import {
  type RecurringTaskUpdateFields,
  type TaskFormChangedFieldOptions,
  type TaskUpdateFields,
  type UpdatableTaskSchema,
} from '../types'

type TaskFormUpdateFields = Omit<TaskUpdateFields, 'customFieldValues'> & {
  customFieldValuesFieldArray?: CustomFieldFieldArrayValue[]
}

export function getTaskWorkspaceChangedFields(
  task: UpdatableTaskSchema,
  options: Pick<
    TaskFormChangedFieldOptions,
    'currentUserId' | 'members' | 'statuses' | 'customFields'
  >
): TaskUpdateFields
export function getTaskWorkspaceChangedFields(
  task: RecurringTaskSchema,
  options: Pick<
    TaskFormChangedFieldOptions,
    'currentUserId' | 'members' | 'statuses' | 'customFields'
  >
): RecurringTaskUpdateFields
export function getTaskWorkspaceChangedFields(
  task: UpdatableTaskSchema | RecurringTaskSchema,
  options: Pick<
    TaskFormChangedFieldOptions,
    'currentUserId' | 'members' | 'statuses' | 'customFields'
  >
): TaskUpdateFields | RecurringTaskUpdateFields {
  const updates: TaskFormUpdateFields & RecurringTaskUpdateFields = {}

  const { currentUserId, members, statuses, customFields } = options

  updates.statusId = getDefaultStatus(statuses)?.id

  if (task.type !== 'CHUNK' && task.labelIds.length > 0) {
    updates.labelIds = []
  }

  // Assignee must exist in the new workspace
  if (
    task.assigneeUserId != null &&
    members.find((m) => m.userId === task.assigneeUserId) == null
  ) {
    updates.assigneeUserId = currentUserId
  }

  if (task.type === 'NORMAL' && customFields.length > 0) {
    updates.customFieldValuesFieldArray = customFields.map((field) =>
      mapCustomFieldToFieldArrayWithValue(field, undefined)
    )
  }

  return updates
}
