import { FilledChevronRightSolid } from '@motion/icons'
import { classed } from '@motion/theme'

import { type MouseEventHandler, useState } from 'react'

import { UnstyledCollapsableContainer } from './unstyled-collapsable-container'

import { Button } from '../button'

export type ActionsVisibility = 'always' | 'never' | 'on-hover'
export type ExpandMode = 'full-width' | 'icon-only'

export type CollapsablePanelProps = {
  title: React.ReactNode

  actions?: React.ReactNode
  actionsVisibility?: ActionsVisibility
  expandMode?: ExpandMode

  expanded: boolean
  onExpand(value: boolean): void
  onHeaderClick?: () => void

  className?: string

  children: React.ReactNode
}

const preventBubble: MouseEventHandler<HTMLElement> = (e: React.MouseEvent) => {
  e.preventDefault()
  e.stopPropagation()
}

export const CollapsablePanel = (props: CollapsablePanelProps) => {
  const { expandMode = 'full-width' } = props

  const toggle = () => {
    props.onExpand(!props.expanded)
  }

  return (
    <UnstyledCollapsableContainer
      expanded={props.expanded}
      toggle={toggle}
      renderHeader={() => (
        <Header
          className={props.className}
          actionsVisibility={props.actionsVisibility}
          onClick={() => {
            props.onHeaderClick?.()
            if (expandMode === 'full-width') {
              toggle()
            }
          }}
          columns={props.actions ? 3 : 2}
        >
          <Button
            size='xsmall'
            sentiment='neutral'
            variant='muted'
            iconOnly
            onClick={(e) => {
              preventBubble(e)
              toggle()
            }}
          >
            <ExpandIcon expanded={props.expanded} />
          </Button>
          <div>{props.title}</div>
          {props.actions && <div onClick={preventBubble}>{props.actions}</div>}
        </Header>
      )}
    >
      {props.children}
    </UnstyledCollapsableContainer>
  )
}

const ExpandIcon = classed(FilledChevronRightSolid, {
  base: 'transition-transform',
  variants: {
    expanded: {
      true: 'rotate-90',
    },
  },
})

const Header = classed('div', {
  base: `
    grid gap-1
    text-semantic-neutral-text-default text-sm font-medium font-sans
    p-1 items-center
    bg-sidebar-item-bg-default hover:bg-sidebar-item-bg-hover

    cursor-pointer
  `,
  variants: {
    columns: {
      2: 'grid-cols-[auto_1fr]',
      3: 'grid-cols-[auto_1fr_auto]',
    },
    actionsVisibility: {
      always: '',
      'on-hover': '[&:not(:hover)>:nth-child(3)]:hidden',
      never: '[&>:nth-child(3)]:hidden',
    },
  },
  defaultVariants: {
    columns: 2,
    actionsVisibility: 'always',
  },
})

export type ControlledCollapsablePanelProps = Omit<
  CollapsablePanelProps,
  'expanded' | 'onExpand'
>

export const ControlledCollapsablePanel = (
  props: ControlledCollapsablePanelProps
) => {
  const [expanded, setExpanded] = useState(true)
  return (
    <CollapsablePanel {...props} expanded={expanded} onExpand={setExpanded} />
  )
}
