import { defineMutation } from '../../core'
import { type RouteTypes } from '../types'

type ParseCancellation = RouteTypes<'AiController_parseCancellation'>
export const parseCancellation = defineMutation<
  ParseCancellation['request'],
  { reason: string }
>().using({
  method: 'POST',
  uri: '/ai/cancellation',
  body: (args) => args,
})
