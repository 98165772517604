// https://beta.reactjs.org/learn/separating-events-from-effects#declaring-an-effect-event

import { useCallback, useRef } from 'react'

/**
 * Return a stable function around the handler that will use the current closure
 * @param handler the function to wrap
 * @returns a stable function that will call handler
 */
export function useClosure<T extends (...args: any[]) => any>(handler: T): T {
  const handlerRef = useRef(handler)
  handlerRef.current = handler

  // @ts-expect-error - type is handled in the signature
  return useCallback((...args) => {
    return handlerRef.current(...args)
  }, [])
}
