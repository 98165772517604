import { createKey, defineApi, defineMutation, SKIP_UPDATE } from '../../core'
import { type PMTaskType, type RecurringTask, TaskType } from '../misc-types'
import {
  type GetArchivedTasks,
  queryKeys as personalKeys,
} from '../personal-tasks'
import { type RouteTypes } from '../types'

export const queryKeys = {
  root: createKey('tasks'),
  list: () => createKey(queryKeys.root, 'list'),
  byId: (id: string) => createKey(queryKeys.root, 'detail', id),
  byWorkspaceId: (id: string) => createKey(queryKeys.root, 'workspace', id),
  byProjectId: (workspaceId: string, projectId: string) =>
    createKey(queryKeys.root, 'workspace', workspaceId, 'project', projectId),
}

type GetTasksById = RouteTypes<'TeamTasksController_get'>
export const getTaskById = defineApi<
  /* GetTasksById['request'] & */ { id: string },
  /* GetTasksById['response']['teamTask'] */ PMTaskType
>().using({
  key: (args) => queryKeys.byId(args.id),
  uri: (args) => `/team_tasks/${args.id}`,
  transform: (data: GetTasksById['response']) =>
    data.teamTask as unknown as PMTaskType,
  initialData(args, ctx) {
    const listCache = ctx.client.getQueryState(queryKeys.list())
    if (!listCache) return

    const list = listCache.data as (PMTaskType | RecurringTask)[] | undefined

    const found = list?.find((x) => x.id === args.id)
    if (!found) return

    return { value: found, updatedAt: listCache.dataUpdatedAt }
  },
})

type GetTasksByProjectId =
  RouteTypes<'V1ProjectsController_getAllTasksForProject'>
export const getTasksByProjectId = defineApi<
  GetTasksByProjectId['request'] & { workspaceId: string },
  // Overriding this until the endpoint is fixed
  PMTaskType[]
>().using({
  key: (args) => queryKeys.byProjectId(args.workspaceId, args.projectId),
  uri: (args) => `/projects/${args.projectId}/tasks`,
  transform: (data: GetTasksByProjectId['response']) => {
    // TODO The response of this query is weird https://usemotion.slack.com/archives/CKP2K3JBS/p1699445109956149
    // The tasks are in the index 1
    // @ts-expect-error - for the reasons above
    const projectTasks = data?.[1] as PMTaskType[] | undefined

    if (projectTasks) {
      return projectTasks
        .filter((task) => {
          return !task.archivedTime && task.type !== TaskType.CHUNK
        })
        .map((task) => {
          return {
            ...task,
            // @ts-expect-error `blockingTaskIds` exists on this project task endpoint
            blockingTasks: task.blockingTaskIds.map((blockedId) => ({
              blockedId,
            })),
            // @ts-expect-error `blockedTaskIds` exists on this project task endpoint
            blockedTasks: task.blockedTaskIds.map((blockedId) => ({
              blockedId,
            })),
          }
        })
    }

    return []
  },
})

type CreateTask = RouteTypes<'TeamTasksController_create'>
/**
 * @deprecated use `createTask` from task v2 instead
 */
export const createTask = defineMutation<
  CreateTask['request'],
  CreateTask['response']
>().using({
  method: 'POST',
  uri: (args) => `/team_tasks`,
  invalidate: (args) => queryKeys.byWorkspaceId(args.workspaceId),
})

type UpdateTask = RouteTypes<'TeamTasksController_update'>
/**
 * @deprecated use `updateTask` from task v2 instead
 */
export const updateTask = defineMutation<
  UpdateTask['request'] & { workspaceId: string },
  UpdateTask['response']
>().using({
  method: 'PATCH',
  uri: (args) => `/team_tasks/${args.id}`,
  invalidate: (args) => [queryKeys.byWorkspaceId(args.workspaceId)],
  effects: [
    {
      on: 'mutate',
      action: 'update',
      key: (args) => queryKeys.byId(args.id),
      merge: (value, prev) => (prev ? { ...prev, ...value } : SKIP_UPDATE),
    },
    {
      on: 'success',
      action: 'update',
      key: (args) => queryKeys.byId(args.id),
      merge: (value) => value.teamTask,
    },
  ],
})

// type DeleteTask = RouteTypes<'TeamTasksController_delete'>
/**
 * @deprecated use v2 deleteTask instead
 */
export const deleteTask = defineMutation<
  /* DeleteTask['request'] &*/ { workspaceId: string; id: string },
  /* DeleteTask['response'] */ void
>().using({
  method: 'DELETE',
  uri: (args) => `/team_tasks/${args.id}`,
  invalidate: (args) => queryKeys.byWorkspaceId(args.workspaceId),
  effects: [
    {
      on: 'mutate',
      action: 'remove',
      key: (args) => queryKeys.byId(args.id),
    },
    {
      on: 'mutate',
      action: 'update',
      key: () => personalKeys.archive(),
      merge: (data, prev: GetArchivedTasks['response']) => {
        if (prev == null) return prev
        return {
          tasks: prev?.tasks.filter((t) => t.id !== data.id),
        }
      },
    },
  ],
})

type RestoreArchivedTask = RouteTypes<'TeamTasksController_restoreArchivedTask'>
export const restoreTask = defineMutation<
  RestoreArchivedTask['request'],
  /* RestoreArchivedTask['response'] */ void
>().using({
  uri: (args) => `/team_tasks/${args.taskId}/restore`,
  method: 'POST',
  effects: [
    {
      on: 'success',
      action: 'update',
      key: (args) => queryKeys.byId(args.taskId),
      merge: (value, prev) =>
        prev ? { ...prev, archivedTime: undefined } : SKIP_UPDATE,
    },
    {
      on: 'mutate',
      action: 'update',
      key: () => personalKeys.archive(),
      merge: (data, prev: GetArchivedTasks['response']) => {
        if (prev == null) return prev
        return {
          tasks: prev?.tasks.filter((t) => t.id !== data.taskId),
        }
      },
    },
  ],
})

type ArchiveTask = RouteTypes<'TeamTasksController_archiveTask'>
export const archiveTask = defineMutation<
  ArchiveTask['request'],
  ArchiveTask['response']
>().using({
  uri: (args) => `/team_tasks/${args.taskId}/archive`,
  method: 'POST',
  invalidate: personalKeys.archive(),
  effects: [
    {
      on: 'success',
      action: 'update',
      key: (args) => queryKeys.byId(args.taskId),
      merge: (value, prev) =>
        prev ? { ...prev, archivedTime: value } : SKIP_UPDATE,
    },
  ],
})

export const getArchivedTasksByWorkspaceId = defineApi<
  { workspaceId: string },
  PMTaskType[]
>().using({
  key: (args) => queryKeys.byWorkspaceId(args.workspaceId),
  uri: (args) => `/team_tasks/workspace/${args.workspaceId}/archived_tasks`,
})

type StartTask = RouteTypes<'TeamTasksController_startTask'>
export const startTask = defineMutation<
  StartTask['request'],
  StartTask['response']
>().using({
  method: 'POST',
  uri: (args) => `/team_tasks/${args.taskId}/start`,
  effects: [
    {
      on: 'success',
      action: 'update',
      key: (args) => queryKeys.byId(args.taskId),
      merge: (value) => value.teamTask,
    },
  ],
  body: (args) => ({ plannedStartDuration: args.plannedStartDuration }),
})

type StopTask = RouteTypes<'TeamTasksController_stopTask'>
export const stopTask = defineMutation<
  StopTask['request'],
  StopTask['response']
>().using({
  method: 'POST',
  uri: (args) => `/team_tasks/${args.taskId}/stop`,
  effects: [
    {
      on: 'success',
      action: 'update',
      key: (args) => queryKeys.byId(args.taskId),
      merge: (value) => value.teamTask,
    },
  ],
  body: (args) => ({
    additionalCompletedDuration: args.additionalCompletedDuration,
    remainingDuration: args.remainingDuration,
  }),
})

type SnoozeTask = RouteTypes<'TeamTasksController_snoozeTask'>
export const snoozeTask = defineMutation<
  SnoozeTask['request'],
  SnoozeTask['response']
>().using({
  method: 'POST',
  uri: (args) => `/team_tasks/${args.taskId}/snooze`,
  effects: [
    {
      on: 'success',
      action: 'update',
      key: (args) => queryKeys.byId(args.taskId),
      merge: (value) => value.teamTask,
    },
  ],
  body: (args) => ({ snoozeUntil: args.snoozeUntil }),
})
