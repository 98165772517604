import { uniqueId } from '@motion/utils/core'
import { type UserInfoSchema } from '@motion/zod/client'

/* c8 ignore start */
const NONE_ID_SUFFIX = '|<none>'
export const createNoneId = (workspaceId?: string) =>
  `${workspaceId || uniqueId()}${NONE_ID_SUFFIX}`
export const isNoneId = (id: string) => id.endsWith(NONE_ID_SUFFIX)
export const getWorkspaceIdfromNoneId = (id: string) =>
  id.replace(NONE_ID_SUFFIX, '')

export const isNoneUser = (user: UserInfoSchema) => isNoneId(user.id)
