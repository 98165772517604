import {
  MOTION_CLIENT_HEADER,
  MOTION_TIMEZONE_HEADER,
} from '@motion/shared/common'

import { DateTime } from 'luxon'

import { getMotionClient } from '../env'

export const STATIC_HEADERS = {
  'x-motion-web-version': __SENTRY_RELEASE__,
  [MOTION_CLIENT_HEADER]: getMotionClient(),
  [MOTION_TIMEZONE_HEADER]: DateTime.now().zoneName,
}
