/* eslint react-refresh/only-export-components: ["warn"] */
import { XSolid } from '@motion/icons'
import { classed, type VariantProps } from '@motion/theme'

import { type ReactNode } from 'react'

import { Button, type ButtonProps, IconButton } from '../button'
import { ButtonGroup } from '../button-group'

export const BannerSentimentValues: BannerSentiment[] = [
  'default',
  'warning',
  'error',
]

export const BannerVariantValues: BannerVariant[] = ['default', 'onPage']

export type BannerSentiment = NonNullable<
  VariantProps<typeof BannerContainer>['sentiment']
>
export type BannerVariant = NonNullable<
  VariantProps<typeof BannerContainer>['variant']
>
export type BannerSize = NonNullable<
  VariantProps<typeof BannerContainer>['size']
>

type Action = {
  label: ButtonProps['children']
  variant?: ButtonProps['variant']
  onAction?: ButtonProps['onClick']
  sentiment?: ButtonProps['sentiment']
  url?: ButtonProps['url']
  loading?: ButtonProps['loading']
}

export type BannerProps = {
  children?: ReactNode
  isDismissed?: boolean
  onDismiss?: () => void

  sentiment?: BannerSentiment
  variant?: BannerVariant
  size?: BannerSize
  actions?: Action[]
}

export const Banner = ({
  isDismissed = false,
  onDismiss,
  children,
  sentiment = 'default',
  variant = 'default',
  actions = [],
  size,
}: BannerProps) => {
  if (isDismissed) return null

  return (
    <BannerContainer sentiment={sentiment} variant={variant} size={size}>
      <Content variant={variant}>
        {children}
        {actions.length > 0 && (
          <ButtonGroup>
            {actions.map((action) => (
              <Button
                key={String(action.label)}
                size={size}
                onClick={action.onAction}
                variant={action.variant}
                sentiment={
                  action.sentiment ?? getButtonSentiment(sentiment, variant)
                }
                url={action.url}
                loading={action.loading}
              >
                {action.label}
              </Button>
            ))}
          </ButtonGroup>
        )}
      </Content>

      {onDismiss && (
        <IconButton
          icon={XSolid}
          size='small'
          variant='muted'
          onClick={onDismiss}
        />
      )}
    </BannerContainer>
  )
}

const BannerContainer = classed('div', {
  base: 'flex items-center px-4 py-2 gap-3 text-sm',
  variants: {
    sentiment: {
      default: `
        bg-banner-default-bg
        text-banner-default-text

        [&_[data-icon="x"]]:text-banner-default-icon
      `,
      warning: '',
      error: '',
    },
    variant: {
      default: 'electron-drag',
      onPage: 'rounded border electron-no-drag',
    },
    size: {
      small: 'text-xs leading-5',
      normal: '',
    },
  },
  compoundVariants: [
    {
      sentiment: 'default',
      variant: 'onPage',
      className: `
        border-banner-default-border
      `,
    },
    {
      sentiment: 'warning',
      variant: 'default',
      className: `
        bg-banner-warning-strong-bg
        text-banner-warning-strong-text

        [&_[data-icon="x"]]:text-banner-warning-strong-icon
      `,
    },
    {
      sentiment: 'warning',
      variant: 'onPage',
      className: `
        bg-banner-warning-subtle-bg
        border-banner-warning-subtle-border
        text-banner-warning-subtle-text

        [&_[data-icon="x"]]:text-banner-warning-subtle-icon
      `,
    },
    {
      sentiment: 'error',
      variant: 'default',
      className: `
        bg-banner-error-strong-bg
        text-banner-error-strong-text

        [&_[data-icon="x"]]:text-banner-error-strong-icon
      `,
    },
    {
      sentiment: 'error',
      variant: 'onPage',
      className: `
        bg-banner-error-subtle-bg
        border-banner-error-subtle-border
        text-banner-error-subtle-text

        [&_[data-icon="x"]]:text-banner-error-subtle-icon
      `,
    },
  ],
  defaultVariants: {
    sentiment: 'default',
    variant: 'default',
    size: 'normal',
  },
})

const Content = classed('div', {
  base: 'grow flex items-center gap-3',
  variants: {
    variant: {
      default: 'justify-center',
      onPage: 'justify-left',
    },
  },
})

function getButtonSentiment(
  sentiment: BannerSentiment,
  variant: BannerVariant
): ButtonProps['sentiment'] {
  if (sentiment === 'error') {
    if (variant === 'onPage') {
      return 'error'
    }

    return 'onDark'
  }
  if (sentiment === 'default') {
    return 'primary'
  }
  return sentiment
}
