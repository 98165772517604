import { classed, type VariantProps } from '@motion/theme'

import { type FunctionComponent, type ReactNode } from 'react'

const StyledButtonGroup = classed('div', {
  base: 'flex shrink-0',
  variants: {
    size: {
      medium: 'gap-3',
      normal: 'gap-2',
      small: 'gap-1',
    },
    segmented: {
      true: `
        gap-0 flex-nowrap

        [&>*:focus-visible]:z-[1]
        [&>*]:rounded-none
        [&>*:first-child]:rounded-l
        [&>*:last-child]:rounded-r
        [&>*:disabled]:border-[var(--b-border)]

        [&>*:not(:first-child):not(:hover):not([data-pressed="true"])]:border-l-0
        [&>*:not(:first-child):hover]:-ml-px
        [&>*:not(:first-child)[data-pressed="true"]]:-ml-px

      `,
    },
    nowrap: {
      true: 'flex-nowrap',
      false: 'flex-wrap',
    },
    stretch: {
      true: 'items-stretch',
      false: 'items-center',
    },
  },
  defaultVariants: {
    size: 'normal',
    segmented: false,
    nowrap: false,
    stretch: false,
  },
})

export const ButtonGroup = StyledButtonGroup as FunctionComponent<
  VariantProps<typeof StyledButtonGroup> & { children: ReactNode }
>

export type ButtonGroupProps = React.ComponentProps<typeof ButtonGroup>
