import { XSolid } from '@motion/icons'
import { classed, type VariantProps } from '@motion/theme'

import * as RadixToast from '@radix-ui/react-toast'
import React from 'react'

export type BaseToastProps = VariantProps<typeof Root> & {
  visible: boolean
  duration?: number
  children?: React.ReactNode
  onClose: () => void
}

export const BaseToast = ({
  visible,
  duration = 5_000,
  children,
  onClose,
}: BaseToastProps) => {
  return (
    <Root
      open={visible}
      onOpenChange={(open: boolean) => {
        if (!open) {
          // Fix a weird bug in radix toast which prevent subsequent toast from auto closing if a toast has been previously manually closed
          // https://github.com/radix-ui/primitives/issues/2233#issuecomment-1821222005
          document.getElementById('toast-viewport')?.blur()
          onClose()
        }
      }}
      duration={duration}
    >
      {children}
    </Root>
  )
}

const Root = classed(RadixToast.Root, {
  base: `
    w-80
    shadow-lg
    data-[state=open]:animate-slideInFromLeft
    data-[state=closed]:animate-hide
    data-[swipe=end]:animate-swipeOutToLeft
    data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)]
    data-[swipe=cancel]:translate-x-0
    data-[swipe=cancel]:transition-[transform_200ms_ease-out]
  `,
})

export const ToastClose = () => {
  return (
    <RadixToast.Action asChild altText='Close'>
      <CloseButton />
    </RadixToast.Action>
  )
}

const CloseButton = classed(XSolid, {
  base: 'w-4 h-4 text-toast-icon my-[2px] flex-shrink-0 cursor-pointer',
})
