import { type ColorId } from '@motion/ui-logic/calendar'

import { type ReactNode } from 'react'

import { GRAY_HUE, PaletteProvider } from '../../palette'

const colorIdToHueMap: Record<ColorId, number> = {
  '5': 0,
  '11': 30,
  '7': 45,
  '4': 75,
  '8': 105,
  '1': 150,
  '3': 180,
  '0': 225,
  '9': 255,
  '2': 285,
  '12': 315,
  '10': 330,
  // graphite - grey
  '6': GRAY_HUE,
}
export interface CalendarPalette {
  colorId?: ColorId
  colorHue?: number
}

interface CalendarPaletteProps extends CalendarPalette {
  children: ReactNode
}

export function CalendarPalette({
  children,
  colorId,
  colorHue,
}: CalendarPaletteProps) {
  if (colorId == null && colorHue == null) {
    throw new Error('CalendarEvent requires at least a colorId or a colorHue')
  }

  const hue = colorId ? colorIdToHueMap[colorId] : colorHue ?? 0
  return <PaletteProvider colorHue={hue}>{children}</PaletteProvider>
}
