import { BubbleMenu, type Editor } from '@tiptap/react'
import { type ReactNode } from 'react'

import { Button, ButtonGroup } from '../base'

export type RichTextImageBubbleMenuProps = {
  editor: Editor | null
}

// To use the RichTextImageBubbleMenu, ensure the editor is importing CustomImageExtension
// instead of just the Image extension from tiptap
export const RichTextImageBubbleMenu = ({
  editor,
}: RichTextImageBubbleMenuProps) => {
  if (!editor) return null

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{
        duration: 100,
        zIndex: 10,
        maxWidth: '500px',
      }}
      shouldShow={() => editor.isActive('custom-image')}
    >
      <div className='flex flex-row gap-4'>
        <ButtonGroup segmented size='small'>
          <ImagePropButton editor={editor} prop='width' propValue='25%'>
            Small
          </ImagePropButton>
          <ImagePropButton editor={editor} prop='width' propValue='50%'>
            Medium
          </ImagePropButton>
          <ImagePropButton editor={editor} prop='width' propValue='100%'>
            Large
          </ImagePropButton>
        </ButtonGroup>
        <ButtonGroup segmented size='small'>
          <ImagePropButton editor={editor} prop='float' propValue='left'>
            Left
          </ImagePropButton>
          <ImagePropButton editor={editor} prop='float' propValue='none'>
            Center
          </ImagePropButton>
          <ImagePropButton editor={editor} prop='float' propValue='right'>
            Right
          </ImagePropButton>
        </ButtonGroup>
      </div>
    </BubbleMenu>
  )
}

type ImagePropButtonProps = {
  editor: Editor
  prop: string
  propValue: string
  children: ReactNode
}

function ImagePropButton({
  editor,
  prop,
  propValue,
  ...rest
}: ImagePropButtonProps) {
  return (
    <Button
      size='small'
      sentiment={
        editor.isActive('custom-image', { [prop]: propValue })
          ? 'primary'
          : 'neutral'
      }
      onClick={() => {
        editor
          .chain()
          .focus()
          .setImage({ [prop]: propValue } as any)
          .run()
      }}
      {...rest}
    />
  )
}
