import { type Editor, Extension } from '@tiptap/core'

export type KeyboardShortcutsOptions = {
  shortcuts: Record<string, (args: { editor: Editor }) => boolean>
}

export const KeyboardShortcuts = Extension.create<KeyboardShortcutsOptions>({
  name: 'keyboard-shortcuts',
  addKeyboardShortcuts() {
    return this.options.shortcuts
  },
})
