import { classed } from '@motion/theme'

import * as Base from '@radix-ui/react-scroll-area'
import { type CSSProperties, type PropsWithChildren } from 'react'

type ScrollAreaProps = PropsWithChildren<
  Base.ScrollAreaProps & {
    scrollDirection?: 'horizontal' | 'vertical' | 'both'
    size?: number
  }
>

export const ScrollArea = ({
  children,
  scrollDirection = 'vertical',
  size = 8,
  ...props
}: ScrollAreaProps) => {
  const allowVertical =
    scrollDirection === 'vertical' || scrollDirection === 'both'
  const allowHorizontal =
    scrollDirection === 'horizontal' || scrollDirection === 'both'

  return (
    <Root
      style={
        {
          '--scrollbar-size': `${size}px`,
        } as CSSProperties
      }
      {...props}
    >
      <Viewport>{children}</Viewport>

      {allowHorizontal && (
        <Scrollbar orientation='horizontal'>
          <Thumb />
        </Scrollbar>
      )}

      {allowVertical && (
        <Scrollbar orientation='vertical'>
          <Thumb />
        </Scrollbar>
      )}
    </Root>
  )
}

const Root = classed(Base.Root, {
  base: 'overflow-hidden',
})

const Viewport = classed(Base.Viewport, {
  base: 'w-full h-full',
})

const Scrollbar = classed(Base.Scrollbar, {
  base: 'select-none touch-none p-1 box-content',
  variants: {
    orientation: {
      horizontal: 'h-[var(--scrollbar-size)] flex-col',
      vertical: 'w-[var(--scrollbar-size)]',
    },
  },
})

const Thumb = classed(Base.Thumb, {
  base: `
    relative flex-[1] 
    bg-semantic-neutral-border-strong rounded-[var(--scrollbar-size)]
  `,
})
