import { createKey } from '../../core'

export const queryKeys = {
  root: createKey('calendar-events'),
  search: (searchQuery: string) => createKey(queryKeys.root, searchQuery),
  teammates: (teammateIds: string[]) =>
    createKey(queryKeys.root, 'teammates', teammateIds.sort()),
  calendars: (providerIds: string[]) =>
    createKey(queryKeys.root, 'calendars', providerIds.sort()),
}
