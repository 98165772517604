import { type CustomFieldValidation } from './types'

export const checkbox = {
  name: {
    maxLength: 200,
  },
  value: {
    maxLength: 100,
  },
} as const satisfies CustomFieldValidation
