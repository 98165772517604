import {
  type InternalStateKey,
  type SharedStateSerializerApi,
  SharedStateSerializerContext,
  type StateKey,
} from '@motion/react-core/shared-state'
import { cloneDeep } from '@motion/utils/core'

import { type ReactNode, useMemo } from 'react'

type LocalStorageStateSerializerProps = {
  prefix: string
  children: ReactNode
}

export const LocalStorageStateSerializer = (
  props: LocalStorageStateSerializerProps
) => {
  const api = useMemo((): SharedStateSerializerApi => {
    return createLocalStorageSerializer(props.prefix)
  }, [props.prefix])

  return (
    <SharedStateSerializerContext.Provider value={api}>
      {props.children}
    </SharedStateSerializerContext.Provider>
  )
}

/* eslint react-refresh/only-export-components: ["warn"] */
export function createLocalStorageSerializer(prefix: string) {
  function getKey(key: StateKey<any>) {
    return `${prefix}:${key.name}`
  }

  return {
    save<T>(key: InternalStateKey<T>, value: T) {
      /* c8 ignore next */
      if (!key.serialize) return
      const t = key.serialize(value)
      try {
        window.localStorage.setItem(getKey(key), t)
        /* c8 ignore next 3 */
      } catch (ex) {
        // need to record to Sentry somehow
      }
    },
    load<T>(key: InternalStateKey<T>) {
      /* c8 ignore next */
      if (!key.deserialize) return undefined

      const text = window.localStorage.getItem(getKey(key))
      /* c8 ignore next */
      if (text == null) return undefined
      try {
        const value = key.deserialize?.(text)
        return value ?? cloneDeep(key.__defaultValue)
      } catch (ex) {
        return cloneDeep(key.__defaultValue)
      }
    },
  }
}
