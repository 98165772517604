import { createEvent } from '@motion/react-core/hooks'

import { type ExperimentUser } from '@amplitude/experiment-js-client'

import { experimentClient as client } from './client'
import {
  type AmplitudeExperimentName,
  type ExperimentValues,
  type TypedVariant,
} from './types'

import { getAnonymousId } from '../analytics'
import { bus } from '../event-bus'

declare module '../event-bus/global/events' {
  interface AppEvents {
    'flags:refresh': { flags: ExperimentValues }
  }
}

const POLL_DURATION = 300_000

class AmplitudeFeatureFlagService {
  private _email: string | null = null
  private _handle: number | null = null

  private _changed = createEvent<ExperimentValues>()

  private _overrides: Partial<ExperimentValues> = {}

  private _trackedExperiments: Set<AmplitudeExperimentName> = new Set()

  private _initialized = false

  enableTracking(...flags: AmplitudeExperimentName[]) {
    flags.forEach((flag) => this._trackedExperiments.add(flag))
  }

  isTracked(name: AmplitudeExperimentName) {
    return this._trackedExperiments.has(name)
  }

  async refresh() {
    await client.fetch()
    const flags = this.all()
    this.emitChanged(flags)
    return flags
  }

  get email() {
    return this._email
  }
  get changed() {
    return this._changed
  }

  async identify(value: string | null) {
    if (this._initialized && this._email === value) return

    this._email = value
    const user: ExperimentUser = {
      device_id: getAnonymousId(),
    }
    if (value) {
      user.user_id = value
    }

    this._initialized = true

    this.stop()
    client.setUser(user)
    const latest = await this.refresh()
    this.start()
    return latest
  }

  start() {
    if (this._handle) return
    this._handle = window.setInterval(() => {
      this.refresh()
    }, POLL_DURATION)
    return this.refresh()
  }

  stop() {
    if (!this._handle) return
    window.clearInterval(this._handle)
    this._handle = null
  }

  clear() {
    this.stop()
    client.clear()
    this._email = null
    this._initialized = false
  }

  all(): ExperimentValues {
    return this.applyOverrides(client.all() as unknown as ExperimentValues)
  }

  get<TName extends AmplitudeExperimentName>(name: TName): TypedVariant<TName> {
    if (this._overrides[name]) {
      return this._overrides[name] as TypedVariant<TName>
    }
    return client.variant(name) as TypedVariant<TName>
  }

  mark(name: AmplitudeExperimentName) {
    client.exposure(name)
  }

  hasOverride(name: AmplitudeExperimentName) {
    return name in this._overrides
  }

  clearOverrides() {
    this._overrides = {}
    this.emitChanged(this.all())
  }

  override(name: string, value: string | null | undefined) {
    if (!value) {
      delete this._overrides[name as AmplitudeExperimentName]
    } else {
      // @ts-ignore - types will be fine
      this._overrides[name as AmplitudeExperimentName] = {
        key: name as AmplitudeExperimentName,
        value,
      }
    }
    this.emitChanged(this.all())
  }

  private applyOverrides(flags: ExperimentValues): ExperimentValues {
    const withOverrides = { ...flags, ...this._overrides }
    return withOverrides
  }

  private emitChanged(flags: ExperimentValues) {
    this._changed.fire(flags)
    bus.emit('flags:refresh', { flags })
  }
}

export const instance = new AmplitudeFeatureFlagService()
