import { classed } from '@motion/theme'

import { type ReactNode } from 'react'

type DragOverlayContainerProps = {
  children: ReactNode
}
export const DragOverlayContainer = ({
  children,
}: DragOverlayContainerProps) => {
  return <Container>{children}</Container>
}

const Container = classed('div', {
  base: `
    rounded bg-semantic-neutral-bg-active-default  border border-semantic-neutral-border-active
    text-semantic-neutral-text-default text-sm font-medium
    inline-block px-2 py-0.5 truncate max-w-[180px]
    cursor-grabbing shadow
  `,
})
