import { classed } from '@motion/theme'

import { AvatarPill } from './avatar-pill'
import { UserAvatar, type UserAvatarProps } from './user-avatar'

type UserAvatarItemProps = Pick<UserAvatarProps, 'id' | 'name' | 'profileUrl'>
export type UserAvatarListProps = {
  maxNumberOfAvatars: number
  size: UserAvatarProps['size']
  users: UserAvatarItemProps[]
}

/**
 * @deprecated Use `PrettyAssignees` instead
 */
export const UserAvatarList = (props: UserAvatarListProps) => {
  const { maxNumberOfAvatars = 8, users = [], size = 16 } = props
  const displayedUsers =
    users.length > maxNumberOfAvatars
      ? users.slice(0, maxNumberOfAvatars - 1)
      : users.slice(0, maxNumberOfAvatars)
  const extraUsers = Math.max(users.length - displayedUsers.length, 0)
  return (
    <ReversedContainer
      style={{
        // @ts-expect-error css variable
        '--gap': `${-Math.round(size / 6)}px`,
      }}
    >
      {extraUsers > 0 && (
        <PillContainer>
          <AvatarPill
            style={{
              width: size,
              height: size,
              fontSize: size / 2,
            }}
            outlined
            className='bg-semantic-neutral-bg-strong-default'
          >
            +{extraUsers < 10 ? extraUsers : ''}
          </AvatarPill>
        </PillContainer>
      )}
      {displayedUsers
        .map((user) => (
          <PillContainer key={user.id}>
            <UserAvatar
              name={user.name?.toUpperCase()}
              size={size}
              id={user.id}
              outlined
              profileUrl={user.profileUrl}
            />
          </PillContainer>
        ))
        .reverse()}
    </ReversedContainer>
  )
}

const ReversedContainer = classed('div', {
  base: `
    flex flex-row-reverse -ml-[--gap]
  `,
  variants: {
    placement: {
      'bottom-right': '-right-[var(--xoffset)] -bottom-[var(--yoffset)]',
      'top-right': '-right-[var(--xoffset)] -top-[var(--yoffset)]',
    },
  },
})

const PillContainer = classed('div', { base: 'ml-[--gap]' })
