import { type DateTime } from 'luxon'

/**
 * Rounds the given time to the nearest intervalMinutes interval
 */
export function roundTime(startTime: DateTime, intervalMinutes: number) {
  if (startTime.second > 0) {
    startTime = startTime.plus({ minutes: 1 }).startOf('minute')
  }

  const remainder =
    (intervalMinutes - (startTime.minute % intervalMinutes)) % intervalMinutes
  return startTime.plus({ minutes: remainder })
}
