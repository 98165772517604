import { createContext, useContext } from 'react'

import { type StateKey } from '../types'

export type SharedStateSerializerApi = {
  save<T>(key: StateKey<T>, value: T): void
  load<T>(key: StateKey<T>): T | undefined
}

export const SharedStateSerializerContext =
  createContext<SharedStateSerializerApi | null>(null)

export const useStateSerializer = () => {
  return useContext(SharedStateSerializerContext)
}
