import { classed } from '@motion/theme'

export const Header = classed('header', {
  base: `flex p-3 pl-4 justify-between items-center self-stretch border-b border-modal-border`,
})

export const HeaderTitle = classed('div', {
  base: `text-semantic-neutral-text-default text-center font-semibold text-[16px] flex items-center gap-1`,
})

export const Body = classed('div', {
  base: `p-4 text-semantic-neutral-text-default`,
})

export const Footer = classed('footer', {
  base: `flex py-3 px-4 justify-end self-stretch border-t border-modal-border`,
})
