import {
  addBusinessDays,
  Bias,
  DateLike,
  diffBusinessDays,
  parseDate,
} from '@motion/utils/dates'

import { DateTime } from 'luxon'

export type AdjustmentResults = {
  stageDefinitionId: string
  dueDate: DateTime
  dueDateModified: boolean
  duration: number
  durationModified: boolean
}[]

type FloatBetweenZeroAndOne = number

/**
 * https://app.graphite.dev/github/pr/usemotion/motion/4180/fix-shared-Fix-stage-adjustment-rounding
 * There used to be an issue caused by rounding errors building up
 * as we calculated the best way to distribute added/removed days.
 *
 * An example is if we split 5 days over ten equal length stages,
 * each stage would get a ratio of 0.1, multiplied by the additional 4 (0.5),
 * and then rounded (1). This attempts to distribute 9 additional days,
 * and then on the last stage we try to add the remainder but realize that
 * it had somehow flipped into the negatives. This threw the "remainder switched signs" error.
 *
 * The new stage adjustment uses the accumulated ratio to compute a "target" allocation,
 * and then adjusts the stage by adding in the difference between what we have
 * currently allocated and what the target is.
 *
 * Using the same example (5 days over ten equal length stages) - the steps would be:
 * Stage 1 -> ratio = 1/10 = 0.1 -> round(0.1 x 5) -> +1 - (currently allocated +0) -> +1
 * Stage 2 -> ratio = 2/10 = 0.2 -> round(0.2 x 5) -> +1 - (currently allocated +1) -> +0
 * Stage 3 -> ratio = 3/10 = 0.3 -> round(0.3 x 5) -> +2 - (currently allocated +1) -> +1
 * Stage 4 -> ratio = 4/10 = 0.4 -> round(0.4 x 5) -> +2 - (currently allocated +2) -> +0
 * ...
 *
 * As you can see, using the accumulated ratio means every time we calculate
 * a target we discard previous rounding errors. By the last stage, our
 * accumulated ratio will be 1.0 and we're guaranteed to have the target
 * allocation as the total number of days added or removed.
 */
export class StageAdjuster {
  // For error reporting
  private newStart?: DateLike | null
  private newDue?: DateLike | null

  // If enabled, only count business days for shifting and scaling.
  private readonly onlyUseBusinessDays: boolean
  // If enabled, only scale stages including and after the active stage.
  private readonly onlyScaleActiveStages: boolean

  // Project fields
  private readonly projectStart: DateTime | null
  private readonly projectDue: DateTime | null
  private readonly duration: number
  private readonly originalStageDates: DateTime[] = []
  private readonly stages: {
    id: string
    dueDate: DateTime
    duration: number
    past: boolean
  }[] = []
  // Computed fields
  private readonly scalingRatios?: Map<string, number>

  // This loads our initial data for stage date adjustments. This includes
  // the project start date, each of the stages with due dates, and which
  // stage is active.
  // It also accepts two options:
  // - useBusinessDays: If true, only count business days when shifting and resizing stages.
  //    Defaults to true.
  // - onlyScaleActiveStages: If true, then when proportionally scaling stages, confine the
  //    scaling to only the stage after and including the active stage. Defaults to true.
  constructor(
    private readonly params: {
      start: DateLike | null
      due: DateLike | null
      stages: {
        stageDefinitionId: string
        dueDate: DateLike
        active?: boolean
      }[]
    },
    options?: {
      onlyUseBusinessDays?: boolean
      onlyScaleActiveStages?: boolean
    }
  ) {
    if (!params.stages.length) {
      throw this.error('At least one stage must be provided')
    }

    this.onlyUseBusinessDays = options?.onlyUseBusinessDays ?? true
    this.onlyScaleActiveStages = options?.onlyScaleActiveStages ?? true

    this.projectStart = this.parseAndValidateDateOnly(params.start)
    this.projectDue = this.parseAndValidateDateOnly(params.due)

    // The start date is inclusive, so any time we use start date add an extra day.
    this.duration =
      this.diffAndValidateDays(this.projectStart, this.projectDue) + 1

    /**
     * https://app.graphite.dev/github/pr/usemotion/motion/4180/fix-shared-Fix-stage-adjustment-rounding#comment-PRRC_kwDOL_0cvs5oP6nc
     * We added `Bias` to addDays to fix a very specific off by one case.
     *
     * Business day calculations are ambiguous when starting from a weekend (What is Sunday minus one business day? plus?).
     * You have to pick a "direction" to round the weekend to, and then start from there. Previously this "direction" was
     * inferred from the direction (+/-) you were applying in business days, but this argument makes it explicit.
     * For the start date, we want to "round" to the nearest business day after the weekend, and then
     * subtract one so all the math can treat it like a due date.
     *
     * Naive business days logic:
     * Sunday +1 = Monday
     * Sunday -1 = Friday
     *
     * but diffBusinessDays(Friday, Monday) should would be 1, when it seems we added 1
     * and subtracted 1 which would be 2, which makes the math really strange.
     *
     * Previous logic (rounding towards the direction we're adding):
     * Sunday +1 = Tuesday
     * Sunday -1 = Thursday
     *
     * This makes the diff line up going backwards match:
     * diffBusinessDays(Sunday, Tuesday) = 1
     * diffBusinessDays(Thursday, Sunday) = 1
     *
     * But now diffBusinessDays(Thursday, Tuesday) = 3, which is also strange.
     *
     * This Bias fixed this use case, but ideally we'll need to specify start or end of
     * day when doing business day math.
     */
    let cursor = this.addDays(this.projectStart, -1, Bias.AFTER)
    let active = false
    for (const [index, stage] of params.stages.entries()) {
      active = active || !!stage.active
      const dueDate = this.parseAndValidateDateOnly(stage.dueDate)
      const duration = this.diffAndValidateDays(cursor, dueDate)
      if (duration < 0) {
        throw this.error('Encountered stage with negative duration')
      }
      this.stages.push({
        id: stage.stageDefinitionId,
        dueDate:
          // Just in case the project due date and the last stage due date don't match,
          // override the last stage due date with the project due date if it's defined
          index < params.stages.length - 1
            ? dueDate
            : this.projectDue ?? dueDate,
        duration,
        past: !active,
      })
      // Save the original (possibly mis-aligned) stage due dates.
      // This way we can accurately compute dueDateModified
      this.originalStageDates.push(dueDate)
      cursor = dueDate
    }
    if (!active) {
      throw this.error('At least one active stage must be provided')
    }

    this.scalingRatios = this.computeScalingRatios()

    this.validateStageDates(this.projectStart, this.projectDue, this.stages)
  }

  /**
   * This is the main public function. Given new start and due dates, this will
   * return the new stage due dates shifted, scaled, and aligned to the new dates.
   * @param dates - the start and due dates to set the project to
   * @returns
   */
  public adjust({
    start,
    due,
  }: {
    start?: DateLike | null
    due?: DateLike | null
  }): AdjustmentResults {
    this.newStart = start
    this.newDue = due
    const { newStart, newDue, delta } = this.processNewStartAndDue(start, due)

    const adjustedDurations = this.computeAdjustedDurations(delta)

    const adjustedStages = this.computeAdjustedStages(
      newStart,
      newDue,
      adjustedDurations
    )

    const alignedStages = this.alignAdjustedStages(
      newStart,
      newDue,
      adjustedStages
    )

    this.validateStageDates(newStart, newDue, alignedStages)

    return this.computeAdjustmentResult(alignedStages)
  }

  // Private Helpers

  /**
   * Process new start and due dates, defaulting them to the existing
   * dates if not defined, and compute the difference in duration
   * @param start the new start date
   * @param due the new due date
   * @returns new start date, new due date, and project duration delta
   */
  private processNewStartAndDue(
    start?: DateLike | null,
    due?: DateLike | null
  ) {
    const newStart =
      start === undefined
        ? this.projectStart
        : this.parseAndValidateDateOnly(start)
    const newDue =
      due === undefined ? this.projectDue : this.parseAndValidateDateOnly(due)
    const duration = this.diffAndValidateDays(newStart, newDue) + 1

    const delta =
      isFinite(this.duration) && isFinite(duration)
        ? duration - this.duration
        : 0

    return { newStart, newDue, delta }
  }

  /**
   * Given a change in duration (delta), distribute that across the project stages
   * @param delta the change in project duration
   * @returns a list of adjusted stage durations for the project
   */
  private computeAdjustedDurations(delta: number) {
    /**
     * Apply adjustments to each stage duration if possible.
     * If we had no delta, or if scalingRatios couldn't be computed,
     * just use the original stage durations.
     * We allocate days using the total accumulated ratio so far to compute a target
     * amount of days we should have allocated.
     * If we've allocated less than that, assign the difference to the current stage.
     * Once we reach the end of the stages, we should have an accumulatedRatio of 1
     * which will set our allocation target equal to delta (and distribute all of delta)
     * Example:
     * Delta: 5
     * Ratios: 0.1, 0.7, 0.1, 0.1
     * Accumulated Ratios: 0.1, 0.8, 0.9, 1.0
     * Accumulated Ratio * Delta: 0.5, 4.0, 4.5, 5
     * Allocation Targets: 1, 4, 5, 5
     * Adjustments: 1, 3, 1, 0
     */
    let allocated = 0
    let accumulatedRatio = 0
    const adjustedDurations = this.stages.map((stage) => {
      if (!this.scalingRatios || !delta) return stage.duration
      const ratio = this.scalingRatios.get(stage.id) ?? 0
      accumulatedRatio += ratio
      const allocationTarget = Math.round(accumulatedRatio * delta)
      const adjustment = allocationTarget - allocated
      if (adjustment !== 0 && Math.sign(adjustment) !== Math.sign(delta)) {
        throw this.error('Adjustment switched signs')
      }
      allocated = allocationTarget
      return stage.duration + adjustment
    })
    // If some durations ended up being negative, that means we shrunk the project more
    // than the duration of the active stages. Return the original stage durations
    // and let later steps limit the stage due dates.
    return adjustedDurations.every((duration) => duration >= 0)
      ? adjustedDurations
      : this.stages.map(({ duration }) => duration)
  }

  /**
   * Compute new project stages by aligning to either the start or the due date, and then constraining
   * the stages based on new project start and due
   * @param newStart new project start date
   * @param newDue new due date
   * @param adjustedDurations list of adjusted durations
   * @returns new stages for the project
   */
  private computeAdjustedStages(
    newStart: DateTime | null,
    newDue: DateTime | null,
    adjustedDurations: number[]
  ) {
    // Align our stages with start or end
    if (newStart && isFinite(adjustedDurations[0])) {
      let cursor = this.addDays(newStart, -1, Bias.AFTER)
      return adjustedDurations.map((adjustedDuration, index) => {
        cursor = this.addDays(cursor, adjustedDuration)
        const stage = this.stages[index]
        return {
          id: stage.id,
          dueDate: cursor,
          duration: adjustedDuration,
        }
      })
    }
    if (newDue) {
      let cursor = newDue

      // When aligning with the end, we remove the first entry
      // and push a 0 in - so that when iterating from the due date
      // we get the duration between this stage and the prior stage.
      adjustedDurations.shift()
      adjustedDurations.push(0)
      adjustedDurations.reverse()
      return adjustedDurations
        .map((adjustedDuration, index) => {
          if (!isFinite(adjustedDuration)) {
            throw this.error(
              'Unexpected infinite duration when aligning to due date'
            )
          }
          cursor = this.addDays(cursor, -1 * adjustedDuration)
          const stage = this.stages[this.stages.length - 1 - index]
          return {
            id: stage.id,
            dueDate: cursor,
            duration: adjustedDurations[index - 1] ?? Infinity,
          }
        })
        .reverse()
    }
    return this.stages.map(({ past, ...stage }) => stage)
  }

  /**
   * Run a sanity check over the project and all stages to make sure
   * the stages are in order and the durations are correct.
   * @param newStart new project start date
   * @param newDue new project due date
   * @param adjustedStages adjusted stages for the project
   */
  private alignAdjustedStages(
    newStart: DateTime | null,
    newDue: DateTime | null,
    adjustedStages: { id: string; dueDate: DateTime; duration: number }[]
  ) {
    // Limit our stage due dates by start and due date
    const limitedStages = adjustedStages.map((stage) => ({
      ...stage,
      dueDate:
        newDue && stage.dueDate > newDue
          ? newDue
          : newStart && stage.dueDate < newStart
            ? newStart
            : stage.dueDate,
    }))

    if (newDue) {
      limitedStages[limitedStages.length - 1].dueDate = newDue
    }

    // Recalculate durations after aligning + limiting
    let cursor = this.addDays(newStart, -1, Bias.AFTER)
    return limitedStages.map(
      (stage) => {
        const result = {
          ...stage,
          duration: this.diffAndValidateDays(cursor, stage.dueDate),
        }
        cursor = stage.dueDate
        return result
      },
      [] as { id: string; dueDate: DateTime; duration: number }[]
    )
  }

  /**
   * Compute and return the result of running the adjust method - including
   * flags to indicate whether each stage due date or deadline has changed.
   * @param adjustedStages new project stages
   * @returns adjustment result
   */
  private computeAdjustmentResult(
    adjustedStages: { id: string; dueDate: DateTime; duration: number }[]
  ) {
    return adjustedStages.map((adjustedStage, index) => ({
      stageDefinitionId: adjustedStage.id,
      dueDate: adjustedStage.dueDate,
      dueDateModified: !adjustedStage.dueDate.equals(
        this.originalStageDates[index]
      ),
      duration: adjustedStage.duration,
      durationModified: adjustedStage.duration !== this.stages[index].duration,
    }))
  }

  /**
   * This computes how a difference in total project days should be distributed
   * amongst each of the stages. 0 means this stage is not affected at all,
   * and 1 means the stage will receive all of the difference in days.
   * If any of the stages we include in scaling is unbounded
   * (no start start date) and (first stage is active or we're trying to scale all stages)
   * then this is impossible to scale and we return undefined.
   * @returns the scaling ratios as a map from stage definition id to ratio
   */
  private computeScalingRatios():
    | Map<string, FloatBetweenZeroAndOne>
    | undefined {
    const totalScalableDays = this.stages
      .filter((stage) => !(this.onlyScaleActiveStages && stage.past))
      .reduce((acc, stage) => (acc += stage.duration), 0)
    if (!isFinite(totalScalableDays) || totalScalableDays === 0) return
    return new Map(
      this.stages.map((stage) => [
        stage.id,
        this.onlyScaleActiveStages && stage.past
          ? 0
          : stage.duration / totalScalableDays,
      ])
    )
  }

  // This parses dates and validates that they have no time component.
  private parseAndValidateDateOnly(d: DateLike): DateTime
  private parseAndValidateDateOnly(d: DateLike | null): DateTime | null
  private parseAndValidateDateOnly(d: DateLike | null): DateTime | null {
    if (d) {
      const date = parseDate(d)
      if (date.hour || date.minute || date.second || date.millisecond) {
        throw this.error('Attempted to parse date that was not date-only')
      }
      return date
    }
    return null
  }

  // This finds the difference in days (calendar or business days depending on supplied options)
  // and validates that the difference is an integer.
  private diffAndValidateDays(a: DateTime | null, b: DateTime | null): number {
    if (a == null || b == null) return Infinity
    const days = this.onlyUseBusinessDays
      ? diffBusinessDays(a, b)
      : a.diff(b, 'days').toObject().days
    if (!Number.isInteger(days) || days == null) {
      throw this.error('Non integer number of days')
    }
    return days
  }

  private addDays(date: DateTime, days: number, bias?: Bias): DateTime
  private addDays(
    date: DateTime | null,
    days: number,
    bias?: Bias
  ): DateTime | null
  private addDays(
    date: DateTime | null,
    days: number,
    bias?: Bias
  ): DateTime | null {
    if (!Number.isInteger(days)) {
      throw this.error('Attempted to add non-integer number of days')
    }
    if (!date) return date
    return this.onlyUseBusinessDays
      ? addBusinessDays(date, days, bias)
      : date.plus({ days })
  }

  private validateStageDates(
    start: DateTime | null,
    due: DateTime | null,
    stageDueDates: { dueDate: DateTime }[]
  ) {
    if (stageDueDates.length !== this.stages.length) {
      throw this.error('Adjustment results are invalid')
    }
    if (start && due && start > due) {
      throw this.error('Start date cannot be after due date')
    }
    let cursor = start
    for (const { dueDate } of stageDueDates) {
      if (cursor && dueDate < cursor) {
        throw this.error('Stage is out of order.')
      }
      if (due && dueDate > due) {
        throw this.error('Stage exceeds due date.')
      }
      cursor = dueDate
    }
    if (due && !due.equals(stageDueDates[stageDueDates.length - 1].dueDate)) {
      throw this.error('Last stage does not match due date.')
    }
  }

  private error(message: string) {
    return new Error(message, {
      cause: {
        params: JSON.stringify(
          { ...this.params, newStart: this.newStart, newDue: this.newDue },
          null,
          2
        ),
      },
    })
  }
}
