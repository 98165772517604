import { twMerge } from 'tailwind-merge'

import { UnstyledModal, type UnstyledModalProps } from './unstyled-modal'

export type ModalProps = Omit<
  UnstyledModalProps,
  'overlayClassName' | 'modalClassName'
>

export const Modal = (props: ModalProps) => {
  const modalClassName = twMerge(`bg-modal-bg
  rounded-[8px] border border-modal-border
  flex flex-col
  overflow-auto

  min-w-[450px]
  max-w-[clamp(450px,calc(100vw-192px),1000px)]
  max-h-[min(calc(100vh-10%),800px)]

  focus-visible:outline-0`)

  return (
    <UnstyledModal
      {...props}
      overlayClassName='bg-modal-overlay'
      modalClassName={modalClassName}
    />
  )
}
