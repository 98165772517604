import { useMemo } from 'react'

import { useModalStackContext } from './modal-stack'

export function useUIContext() {
  const { hasVisibleModals } = useModalStackContext()

  return useMemo(() => {
    return {
      hasVisibleModals,
    }
  }, [hasVisibleModals])
}
