import { type TeamWithRelationsSerializer } from '@motion/rpc-types'

import { createKey, defineApi, defineMutation } from '../../core'
import { type RouteTypes } from '../types'

type GetCurrentTeamResponse = {
  userId: string
  team: TeamWithRelationsSerializer
}

export const getCurrentTeam = defineApi<
  void,
  TeamWithRelationsSerializer
>().using({
  key: createKey('current-team'),
  uri: '/teams?isNew=true',
  transform: (data: GetCurrentTeamResponse) => data.team,
})

type RenameTeam = RouteTypes<'TeamsController_rename'>
export const renameTeam = defineMutation<
  RenameTeam['request'] & { id: string },
  void
>().using({
  uri: (args) => `teams/${args.id}/rename`,
  invalidate: createKey('current-team'),
  method: 'POST',
})

type InviteTeamMembers = RouteTypes<'TeamsController_inviteTeamMemberWithSeats'>
export const inviteTeamMembers = defineMutation<
  InviteTeamMembers['request'] & { id: string },
  void
>().using({
  uri: (args) => `teams/${args.id}/members/new`,
  invalidate: createKey('current-team'),
  method: 'POST',
})

type UpdateBucketSeats = RouteTypes<'TeamsController_updateBucketSeats'>
export const updateBucketSeats = defineMutation<
  UpdateBucketSeats['request'] & { id: string },
  void
>().using({
  uri: (args) => `teams/${args.id}/seats`,
  invalidate: createKey('current-team'),
  method: 'PATCH',
})

type ResubscribeTeam = RouteTypes<'TeamsController_resubscribe'>
export const resubscribe = defineMutation<
  ResubscribeTeam['request'] & { teamId: string },
  | {
      joinedTeam: TeamWithRelationsSerializer
    }
  | { error: string; clientSecret: string }
>().using({
  uri: (args) => `teams/${args.teamId}/resubscribe`,
  method: 'POST',
})
