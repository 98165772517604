import { isEqual } from '@motion/utils/core'

import { useCallback, useContext, useRef, useSyncExternalStore } from 'react'

import { SharedStateContext } from './context'
import type {
  ScopeOptions,
  SharedStateResult,
  SharedStateSetterOnly,
  SharedStateSetterValue,
  StateKey,
  StateOptions,
} from './types'

import { useClosure } from '../hooks'

/**
 * View the README for more information on how to properly use this hook
 */
export const useSharedState = <T>(
  key: StateKey<T>,
  opts?: ScopeOptions
): SharedStateResult<T> => {
  const api = useContext(SharedStateContext)

  const value = useSyncExternalStore(api.event.subscribe, () =>
    api.get(key, opts)
  )

  const setValue = useCallback(
    (
      arg: SharedStateSetterValue<T>,
      options: StateOptions = { notify: true }
    ): void => {
      // @ts-expect-error - assume the type of function is correct
      const value = typeof arg === 'function' ? arg(api.get(key)) : arg
      api.set(key, value, { scope: opts?.scope, ...options })
    },
    [api, key, opts?.scope]
  )

  return [value, setValue]
}

/**
 * View the README for more information on how to properly use this hook
 */
export const useSharedStateSendOnly = <T>(
  key: StateKey<T>,
  hookOptions?: ScopeOptions
): SharedStateSetterOnly<T> => {
  const api = useContext(SharedStateContext)

  const setValue = useCallback(
    (
      arg: SharedStateSetterValue<T>,
      options: StateOptions = { notify: true }
    ) => {
      // @ts-expect-error - assume the type of function is correct
      const value = typeof arg === 'function' ? arg(api.get(key)) : arg

      const effectiveOptions = Object.assign(
        {},
        { scope: hookOptions?.scope },
        options
      )
      api.set(key, value, effectiveOptions)
    },
    [api, hookOptions?.scope, key]
  )

  return setValue
}

export const useSharedStateContext = () => {
  return useContext(SharedStateContext)
}

const UNSET = Symbol('UNSET')
/**
 * Watches a portion of a state object
 * @param key the state key to watch
 * @param selector the value to select
 * @returns the selected value
 */
export function useSharedStateQuery<T, U>(
  key: StateKey<T>,
  selector: (data: T) => U,
  opts?: ScopeOptions
): U {
  const stableSelector = useClosure(selector)
  const valueRef = useRef<U | typeof UNSET>(UNSET)

  const api = useContext(SharedStateContext)
  const value = useSyncExternalStore(api.event.subscribe, () => {
    const newValue = stableSelector(api.get(key, opts))

    if (valueRef.current === UNSET || !isEqual(newValue, valueRef.current)) {
      valueRef.current = newValue
    }

    return valueRef.current
  })
  return value as U
}
