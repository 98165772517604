import { NoWifiSolid } from '@motion/icons'

import { Button } from '../../base'

export interface NoWifiProps {
  onClick?: () => void
}

export const NoWifi = ({ onClick }: NoWifiProps) => {
  return (
    <div className='dark:bg-dark-1100 bg-light-200 flex h-full w-full flex-col items-center justify-center gap-y-8'>
      <NoWifiSolid className='text-light-800 dark:text-dark-400 h-[70px] w-[70px]' />
      <div className='flex flex-col'>
        <p className='text-light-1200 text-center text-2xl font-semibold dark:text-white'>
          Oops! There&apos;s a problem connecting to Motion.
        </p>
        <p className='text-light-1200 text-center text-2xl font-semibold dark:text-white'>
          Please check your internet connection.
        </p>
      </div>
      <Button onClick={onClick}>Try again</Button>
    </div>
  )
}
