import { type MotionDesignTokenNames } from '@motion/theme/types'

import {
  createContext,
  type ReactNode,
  useContext,
  useMemo,
  useRef,
} from 'react'

type MotionTheme = 'dark' | 'light'

type ThemeContextApi = {
  theme: MotionTheme
  resolve(token: MotionDesignTokenNames): string
  resolveTailwindColor(token: TailwindColorToken): string
}

const ThemeContext = createContext<ThemeContextApi>({
  theme: 'light',
  resolve: () => {
    throw new Error('No context found')
  },
  resolveTailwindColor: () => {
    throw new Error('No context found')
  },
})

type TailwindColorToken =
  | `bg-${MotionDesignTokenNames}`
  | `border-${MotionDesignTokenNames}`
  | `text-${MotionDesignTokenNames}`

export type ThemeResolverProps = {
  children: ReactNode
  theme?: MotionTheme
}
export const ThemeResolver = ({
  children,
  theme = 'light',
}: ThemeResolverProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const api = useMemo(() => {
    function resolveToken(token: MotionDesignTokenNames) {
      const el = ref.current
      if (!el) return 'light'

      const value = getComputedStyle(el).getPropertyValue(`--motion-${token}`)
      return value
    }

    return {
      get theme() {
        return theme
      },
      resolve(token: MotionDesignTokenNames): string {
        return resolveToken(token)
      },
      resolveTailwindColor(token: TailwindColorToken): string {
        return resolveToken(extractDesignToken(token))
      },
    }
  }, [theme])

  return (
    <div className='contents' ref={ref} data-theme={theme}>
      <ThemeContext.Provider value={api}>{children}</ThemeContext.Provider>
    </div>
  )
}

const TOKEN_EXTRACT_NAME = /^(bg|text|border)-(.+)$/
function extractDesignToken(
  tailwind: TailwindColorToken
): MotionDesignTokenNames {
  const match = TOKEN_EXTRACT_NAME.exec(tailwind)
  if (!match) throw new Error('The token is not valid')
  return match[1] as MotionDesignTokenNames
}

/* eslint react-refresh/only-export-components: ["warn"] */
export const useTheme = () => {
  return useContext(ThemeContext)
}
