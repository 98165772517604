import { Experiment, type Exposure } from '@amplitude/experiment-js-client'

import { AMPLITUDE_API_KEY } from './constants'

import { recordAnalyticsEvent } from '../analytics'

export const experimentClient = Experiment.initializeWithAmplitudeAnalytics(
  AMPLITUDE_API_KEY,
  {
    serverUrl: 'https://amplitudelab.usemotion.com',
    // By default, amplitude will send an exposure event whenever the variant api is called.
    // However, we often fetch the variant in the background, so we don't want to send an exposure
    // until the user actually sees the feature
    automaticExposureTracking: false,
    exposureTrackingProvider: {
      track: (exposure: Exposure) => {
        recordAnalyticsEvent('$exposure', exposure)
      },
    },
    automaticFetchOnAmplitudeIdentityChange: true,
    pollOnStart: false,
    fetchOnStart: false,
  }
)
