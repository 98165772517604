import { createKey, defineMutation } from '../../core'
import { type RouteTypes } from '../types'

export const create = defineMutation<
  RouteTypes<'EmailAccountsController_createEmailAccount'>['request'],
  RouteTypes<'EmailAccountsController_createEmailAccount'>['response']
>().using({
  key: createKey('email_accounts'),
  uri: '/email_accounts',
  method: 'POST',
})

export const update = defineMutation<
  RouteTypes<'EmailAccountsController_updateEmailAccount'>['request'],
  RouteTypes<'EmailAccountsController_updateEmailAccount'>['response']
>().using({
  key: createKey('email_accounts'),
  uri: (args) => `/email_accounts/${args.id}`,
  method: 'PATCH',
})
