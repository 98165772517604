import { type CalendarProviderType } from './email-account'

export const CalendarStatus = {
  OK: 'OK',
  NOT_FOUND: 'NOT_FOUND',
  AUTH_ERROR: 'AUTH_ERROR',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
}

export type CalendarStatus =
  (typeof CalendarStatus)[keyof typeof CalendarStatus]

export const CalendarType = {
  DEFAULT: 'DEFAULT',
  FREQUENTLY_MET: 'FREQUENTLY_MET',
}

export type CalendarType = (typeof CalendarType)[keyof typeof CalendarType]

export const CalendarAccessRole = {
  EDITOR: 'EDITOR',
  OWNER: 'OWNER',
  VIEWER: 'VIEWER',
} as const

export type CalendarAccessRole =
  (typeof CalendarAccessRole)[keyof typeof CalendarAccessRole]

export type Calendar = {
  id: string
  userId: string
  emailAccountId: string
  type: CalendarType
  providerId: string
  accessRole: CalendarAccessRole
  allowedConferenceTypes: string[]
  colorId: string
  isEnabled: boolean
  isInFrequentlyMet: boolean
  isInMyCalendars: boolean
  isPrimary: boolean | null
  providerType: CalendarProviderType
  title: string
  status: CalendarStatus
  isDeleted: boolean
  createdTime: string
  updatedTime: string | null
  deletedTime: string | null
}

export type CalendarKillSwitch = 'HARD' | 'SOFT'

export type TemporaryCalendar = Calendar & { isTemporary?: boolean }

export type UpdateCalendarDto = Partial<Calendar> &
  Required<Pick<Calendar, 'emailAccountId' | 'id'>> & {
    // calendar was generated by the frontend - this should indicate to the
    // backend to create & persist the new calendar
    isNew?: boolean
  }

export type CreateCalendarDto = Partial<Calendar> &
  Required<Pick<Calendar, 'emailAccountId' | 'providerId'>>
