import { type DateLike, parseDate } from '@motion/utils/dates'

import { DateTime } from 'luxon'

/**
 * Returns a formatted date string representing a date
 * eg. Wed Feb 6
 *
 * @param {DateLike} date - The date like object to format.
 * @return {string} The formatted date string.
 */
export const formatToReadableWeekDayMonth = (value: DateLike) => {
  const date = parseDate(value)
  const format =
    date.year === DateTime.now().year ? 'ccc LLL d' : 'ccc LLL d, yyyy'
  return date.toFormat(format)
}

/**
 * Formats a date to month and day
 * ie. Feb 7
 *
 * If the year is not the current one, it includes the year as well
 * @param value the date to format
 * @returns
 */
export const formatMonthDay = (value: DateLike) => {
  const date = parseDate(value)
  const format = date.year === DateTime.now().year ? 'LLL d' : 'LLL d, yyyy'
  return date.toFormat(format)
}

/**
 * Returns a formatted date string representing a date range of `from - to`
 * eg. Dec 4, 2023 - Jan 2, 2024
 * eg. Dec 2 - Dec 5, 2023
 * eg. Jan 6 - Jan 12
 *
 * The year will be omitted when the `from` and `to` shares the same date and are the current year
 *
 * @param {DateLike} from - The date like object to format.
 * @param {DateLike} to - The date like object to format.
 * @return {string} The formatted date string.
 */
export const formatDateRange = (from: DateLike, to: DateLike) => {
  const parsedFrom = parseDate(from)
  const parsedTo = parseDate(to)
  const currentYear = DateTime.now().year

  const fromFormat =
    parsedFrom.year === parsedTo.year ? 'ccc LLL d' : 'ccc LLL d, yyyy'
  const toFormat =
    parsedTo.year === currentYear && parsedFrom.year === parsedTo.year
      ? 'ccc LLL d'
      : 'ccc LLL d, yyyy'

  const fromDate = parsedFrom.toFormat(fromFormat)
  const toDate = parsedTo.toFormat(toFormat)

  return `${fromDate} - ${toDate}`
}

export const formatToReadableMonthYear = (value: DateLike) => {
  const date = parseDate(value)
  return date.toFormat('MMMM yyyy')
}

export const formatToReadableQuarterYear = (value: DateLike) => {
  const date = parseDate(value)
  return date.toFormat('Qq yyyy')
}

export const formatToReadableHalfYear = (value: DateLike) => {
  const date = parseDate(value)
  const half = date.month > 6 ? '2' : '1'
  return `H${half} ${formatToReadableYear(date)}`
}

export const formatToReadableYear = (value: DateLike) => {
  const date = parseDate(value)
  return date.startOf('year').toFormat('yyyy')
}

export const DATE_FORMATTERS_BY_UNIT = {
  day: formatToReadableWeekDayMonth,
  week: (date: DateTime) =>
    formatDateRange(date.startOf('week'), date.endOf('week')),
  month: formatToReadableMonthYear,
  quarter: formatToReadableQuarterYear,
  half: formatToReadableHalfYear,
  year: formatToReadableYear,
} as const
