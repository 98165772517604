import { createKey, defineApi, defineMutation } from '../../core'
import { type RouteTypes } from '../types'

export const queryKeys = {
  root: createKey('calendars'),
}

export const updatePrimary = defineMutation<
  RouteTypes<'CalendarsV2Controller_setAsPrimary'>['request'],
  RouteTypes<'CalendarsV2Controller_setAsPrimary'>['response']
>().using({
  key: createKey('calendars_primary'),
  uri: (args) => `/v2/calendars/${args.id}/primary`,
  method: 'PATCH',
})

export const getCalendars = defineApi<
  RouteTypes<'CalendarsV2Controller_getAll'>['request'],
  RouteTypes<'CalendarsV2Controller_getAll'>['response']
>().using({
  uri: `/v2/calendars`,
  method: 'GET',
  key: queryKeys.root,
})
