import { type ColorId } from '@motion/ui-logic/calendar'

import { CalendarColorIcon } from '../calendar-color-icon'

export type CalendarColorPickerProps = {
  value: ColorId
  onSelect: (value: ColorId) => void
  options: readonly ColorId[]
}

export const CalendarColorPicker = (props: CalendarColorPickerProps) => {
  const { options, value, onSelect } = props
  return (
    <div className='grid grid-cols-6 gap-3'>
      {options.map((colorId) => (
        <CalendarColorIcon
          colorId={colorId}
          key={colorId}
          selected={value === colorId}
          onClick={() => onSelect(colorId)}
        />
      ))}
    </div>
  )
}
