import { type EventArgs, type SharedStateApi, type StateKey } from './types'

export const IGNORE = Symbol('ignore')

export type MergeFn = (
  key: EventArgs<unknown>,
  api: SharedStateApi
) => unknown | typeof IGNORE

export function isKey<T>(
  event: EventArgs<any>,
  target: StateKey<T>
): event is EventArgs<T> {
  return event.key === target
}

type KeyedReducer<T> = { key: StateKey<T>; reducer: MergeReducer<T> }
export type MergeReducer<T> = (
  value: T,
  api: SharedStateApi
) => T | typeof IGNORE

export function createMergeReducer<
  const R extends readonly KeyedReducer<any>[],
>(...reducers: R): MergeFn {
  const lookup = reducers.reduce((acc, cur) => {
    acc.set(cur.key, cur.reducer)
    return acc
  }, new Map<StateKey<any>, MergeReducer<any>>())

  return (ev, api) => {
    const reducer = lookup.get(ev.key)
    /* c8 ignore next */
    if (reducer == null) return IGNORE
    return reducer(ev.value, api)
  }
}

export function on<T>(key: StateKey<T>, reducer: MergeReducer<T>) {
  return { key, reducer }
}

export const DEFAULT_MERGE: MergeFn = (event) => IGNORE
