import { type ClientFirebaseSettingsDto } from '@motion/rpc-types'

export const settingsSyncPullKeys = [
  'reminderSettings',
  'zoomExpiration',
  'emailSignatures',
  'calendarTemplates',
  'calendarStartDay',
  'conferenceSettings',
  'onboardingProgress',
  'onboardingType',
  'zoomNeedsRefresh',
  'schedules',
  'publicTaskNames',
  'lastOnboardTimestamp',
  'isOnboardingComplete',
  'showCompletedTasks',
  'showAgendaEvents',
  'taskBreakSettings',
  'didSkipOnboarding',
  'cta',
  'desktop',
  'theme',
  'taskTimeBlockMode',
  'onboardingLatestScreen',
  'onboardingType',
  'onboardingVersion',
  'onboardingExtraData',
  'onboardingCompletedSurvey',
] as const satisfies readonly (keyof ClientFirebaseSettingsDto & string)[]

export const settingsSyncDontPushKeys = [
  'lastOnboardTimestamp',
  'onboardingProgress',
  'isOnboardingComplete',
  'didSkipOnboarding',
  'showCompletedTasks',
  'showAgendaEvents',
  'taskBreakSettings',
  'zoomAccessToken',
  'zoomExpiration',
  'zoomNeedsRefresh',
  'cta',
  'desktop',
  'theme',
  'taskTimeBlockMode',
  'onboardingLatestScreen',
  'onboardingType',
  'onboardingVersion',
  'onboardingExtraData',
  'onboardingCompletedSurvey',
] as const satisfies (
  | (keyof ClientFirebaseSettingsDto & string)
  | 'zoomAccessToken'
)[]

export const storageListenerDontPushKeys = [
  'googleAuth', // [kfs]
  'conferenceSettings',
  'calendarList', // [kfs]
  'cta',
  'desktop',
  'theme',
  'taskTimeBlockMode',
  'onboardingLatestScreen',
  'onboardingType',
  'onboardingVersion',
  'onboardingExtraData',
  'onboardingCompletedSurvey',
] as const satisfies (keyof ClientFirebaseSettingsDto & string)[]

export const KEYS_TO_PUSH_TO_FIREBASE: (keyof ClientFirebaseSettingsDto)[] =
  settingsSyncPullKeys.filter(
    (x) =>
      !settingsSyncDontPushKeys.includes(x as any) &&
      !storageListenerDontPushKeys.includes(x as any)
  )

export const firstSyncIgnoreKeys =
  [] as const satisfies readonly (keyof ClientFirebaseSettingsDto & string)[]
