import { DateTime } from 'luxon'

declare const __TESTING__: boolean

/**
 * Shifts a date tiem to the specified zone while keeping the string representation the same.
 *
 * E.g. "2022-11-06T01:23:45.000Z" will be converted into PST
 * "2022-11-06T01:23:45.000-07:00". Passing the input ISO into Luxon directly
 * would produce "2022-11-05T18:23:45.000-07:00"
 * @param iso the iso string to shift
 * @param zone the zone to shift to @default system
 * @returns the DateTime instance with only the date in the new zone
 */
export const shiftToZone = (iso: string, zone: string = 'system') => {
  if (typeof __TESTING__ !== 'undefined' && __TESTING__) {
    // Since we hardcode 'TZ' we use 'default' for testing
    if (zone === 'system' || zone === 'local') {
      zone = 'default'
    }
  }

  if (!iso.includes('T')) {
    DateTime.fromFormat(iso, 'yyyy-LL-dd', { zone: zone })
  }

  return DateTime.fromISO(iso, { setZone: true }).setZone(zone, {
    keepLocalTime: true,
  })
}

/**
 * Shifts a date to the specified zone while keeping the string representation the same.
 *
 * E.g. "2022-11-06T00:00:00.000Z" will be converted into PST
 * "2022-11-06T00:00:00.000-07:00". Passing the input ISO into Luxon directly
 * would produce "2022-11-05T17:00:00.000-07:00"
 * @param iso the iso string to shift
 * @param zone the zone to shift to @default system
 * @returns the DateTime instance with only the date in the new zone
 */
export const shiftDateToZone = (iso: string, zone: string = 'system') => {
  return shiftToZone(iso, zone).startOf('day')
}

/**
 * Take the date and ignore timezone info.
 */
export function extractDateIgnoringTimezone(iso: string): string
// prettier-ignore
export function extractDateIgnoringTimezone(iso: string | null | undefined): string | null
export function extractDateIgnoringTimezone(iso: string | null | undefined) {
  if (!iso) return null
  return shiftToZone(iso, 'utc').toISODate()
}
