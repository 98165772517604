import { useClosure } from '@motion/react-core/hooks'

import { useEffect } from 'react'

import { type WsEventDef, type WsEventTypes } from './types'
import { websocketsEventSubscriber } from './websocketsEventSubscriber'

export const useOnWebsocketEvent = <
  E extends WsEventDef,
  T extends string & WsEventTypes<E>,
>(
  name: `${T}`,
  handler: (data: WsEventDef) => void
) => {
  const stable = useClosure(handler)

  useEffect(() => {
    return websocketsEventSubscriber.on(name, (data) => {
      stable(data)
    })
  }, [name, stable])
}
