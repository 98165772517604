import { cloneDeep } from '@motion/utils/core'

import {
  type QueryFilters,
  type QueryKey,
  useQueryClient,
  type WithRequired,
} from '@tanstack/react-query'

import { createFetch } from '../../core/create-fetch'
import {
  type ApiUseMutationOptions,
  type MutationApiDefinition,
  SKIP_UPDATE,
} from '../../core/types'
import { useRpcContext } from '../context/rpc-provider'

export type UseMutationOptionsWithFn<TApi extends MutationApiDefinition> =
  WithRequired<ApiUseMutationOptions<TApi>, 'mutationFn'>

const OptimisticContextKey = Symbol('optimistic')
type OptimisticState = { key: QueryKey; original: unknown }
type WrappedContext = {
  [OptimisticContextKey]: OptimisticState[]
  base: unknown
}

export type MutationOptionsFactory<TApi extends MutationApiDefinition> = (
  opts?: ApiUseMutationOptions<TApi> | undefined
) => UseMutationOptionsWithFn<TApi>

export function useMutationOptionsFactory<TApi extends MutationApiDefinition>(
  api: TApi
): MutationOptionsFactory<TApi> {
  type Options = ApiUseMutationOptions<TApi>

  const rpcContext = useRpcContext()
  const client = useQueryClient()
  const staticOptions = api.mutationOptions

  const call = createFetch(api, {
    token: rpcContext.token,
    baseUri: rpcContext.baseUri,
    headers: rpcContext.headers,
    executor: rpcContext.executor,
  })

  const mutationFn: Options['mutationFn'] = (args) => {
    return call(args).then((data) => {
      const queriesToInvalidate = normalizeInvalidation(api, args)

      queriesToInvalidate.forEach((key) => void client.invalidateQueries(key))

      return data
    })
  }

  const effects = {
    mutate: (api.effects ?? []).filter((x) => x.on === 'mutate'),
    success: (api.effects ?? []).filter((x) => x.on === 'success'),
  }

  return (opts?: Options): UseMutationOptionsWithFn<TApi> => {
    const normalizedOpts = { ...staticOptions, ...opts }
    return {
      ...normalizedOpts,
      mutationFn,
      async onMutate(args) {
        const optimisticContext: OptimisticState[] = []
        for (const op of effects.mutate) {
          const key = op.key(args)
          // eslint-disable-next-line no-await-in-loop
          await client.cancelQueries({ queryKey: key })
          const original = client.getQueryData(key)
          optimisticContext.push({ key, original })

          if (op.action === 'remove') {
            client.removeQueries({ queryKey: key, exact: true })
          } else if (op.action === 'update') {
            const newValue = op.merge(args, cloneDeep(original))
            if (newValue !== SKIP_UPDATE) {
              client.setQueryData(key, newValue)
            }
          }
        }

        const ctx = { [OptimisticContextKey]: optimisticContext }
        if (normalizedOpts.onMutate) {
          // @ts-expect-error - dynamic keys
          ctx.base = await normalizedOpts.onMutate(args)
        }

        return ctx
      },
      async onError(ex, args, ctx) {
        const { base, [OptimisticContextKey]: optimisticContext } =
          ctx as WrappedContext

        for (const state of optimisticContext) {
          // eslint-disable-next-line no-await-in-loop
          await client.setQueryData(state.key, state.original)
        }

        normalizedOpts.onError?.(ex, args, base)
      },
      onSuccess(data, args, ctx) {
        const { base } = ctx as WrappedContext

        effects.success.forEach((op) => {
          const key = op.key(args)

          if (op.action === 'invalidate') {
            void client.invalidateQueries({ queryKey: key })
          } else if (op.action === 'remove') {
            client.removeQueries({ queryKey: key, exact: true })
          } else if (op.action === 'update') {
            const previousValue = client.getQueryData(key)
            const newValue = op.merge(data, cloneDeep(previousValue), args)

            if (newValue !== SKIP_UPDATE && newValue !== previousValue) {
              client.setQueryData(key, newValue)
            }
          }
        })

        normalizedOpts.onSuccess?.(data, args, base)
      },
    }
  }
}

function normalizeInvalidation<TArgs, TApi extends MutationApiDefinition>(
  api: TApi,
  args: TArgs
): QueryFilters[] {
  if (api.invalidate == null) return []
  const value =
    typeof api.invalidate === 'function' ? api.invalidate(args) : api.invalidate

  const normalized = isMultipleResults(value) ? value : [value]
  return normalized
    .filter((x) => {
      if (x == null) return false
      if (Array.isArray(x) && x.length === 0) return false
      return true
    })
    .map((x) => (isQueryKey(x) ? { queryKey: x } : x))
}

function isMultipleResults(
  value: QueryKey | QueryFilters | (QueryKey | QueryFilters)[]
): value is (QueryKey | QueryFilters)[] {
  // single QueryFilters
  if (!Array.isArray(value)) return false
  if (value.length === 0) return true
  const first = value[0]
  // function of array of QueryKeys
  if (typeof first === 'function' && Array.isArray(first())) return true
  // array of QueryKeys
  if (Array.isArray(first)) return true

  return false
}

function isQueryKey(value: QueryKey | QueryFilters): value is QueryKey {
  return Array.isArray(value)
}
