import {
  type ProjectSchema,
  type RecurringTaskSchema,
  type StatusSchema,
  type TaskSchema,
  type WorkspaceSchema,
} from '@motion/rpc-types'

import { DateTime } from 'luxon'

import { type TaskFormFields } from './form-fields'
import { type TaskUrlParams, type TaskUrlSearchParams } from './task-url-params'
import { getTaskDefaultDueDate, getTaskInitialStartDate } from './value-helpers'

import {
  type AllAvailableCustomFieldSchema,
  mapCustomFieldToFieldArrayWithValue,
} from '../../custom-fields'
import { DEFAULT_DURATION, NO_CHUNK_DURATION } from '../../duration'
import { isValidPriority } from '../../priorities'
import { isValidStageDefinitionId } from '../../project/flows/utils'
import { getTaskStaticURL } from '../../static-url'
import {
  getDefaultTaskStatusForWorkspace,
  isCompletedStatus,
} from '../../statuses'
import { DEFAULT_SCHEDULE_ID } from '../fields'

type TaskModalLocationState = {
  chunkId?: TaskSchema['id']
}

type GetInitialFormDataOptions = {
  searchParams: TaskUrlSearchParams
  state?: TaskModalLocationState
  urlParams: TaskUrlParams
  task?: TaskSchema | RecurringTaskSchema
  parentTask?: TaskSchema | RecurringTaskSchema
  isLoading: boolean
  hasError: boolean
  currentUserId: string
  project?: ProjectSchema | null
  defaultWorkspace?: WorkspaceSchema
  workspace?: WorkspaceSchema | null
  workspaceStatuses: StatusSchema[]
  workspaceCustomFields: AllAvailableCustomFieldSchema[]
  scheduledStart?: string | null
  scheduledEnd?: string | null
}

export function getInitialFormData({
  task,
  parentTask,
  project,
  workspace,
  workspaceCustomFields,
  workspaceStatuses,
  isLoading: isLoadingTask,
  hasError,
  currentUserId,
  searchParams,
  urlParams,
  defaultWorkspace,
  scheduledStart,
  scheduledEnd,
}: GetInitialFormDataOptions): TaskFormFields {
  const {
    forTaskId: forTaskIdParam,
    forAssignee: forAssigneeParam,
    forPriority: forPriorityParam,
    forStartDate: forStartDateParam,
    forDueDate: forDueDateParam,
    forStage: forStageParam = null,
    forStatus: forStatusParam,
    forCustomField: forCustomFieldParam,
    forLabel: forLabelParam,
  } = searchParams

  if (task?.type === 'CHUNK') {
    throw new Error('Cannot open a chunk in the task modal')
  }

  const taskId = resolveFormTaskId({ searchParams })

  const { workspaceId, projectId } = resolveFormWorkspaceAndProjectId({
    searchParams,
    urlParams,
    task,
    defaultWorkspace,
  })

  const usingTaskData = taskId != null && task != null

  const assigneeUserId = usingTaskData
    ? task.assigneeUserId
    : forAssigneeParam === 'unassigned'
      ? null
      : forAssigneeParam ?? currentUserId

  if (workspaceId == null) {
    throw new Error('Workspace id not defined')
  }

  if (forPriorityParam != null && !isValidPriority(forPriorityParam)) {
    throw new Error('Priority unknown', {
      cause: {
        forPriorityParam,
      },
    })
  }

  const dueDate =
    usingTaskData && 'dueDate' in task
      ? task.dueDate
      : forDueDateParam ?? getTaskDefaultDueDate()

  const startDate =
    forStartDateParam != null
      ? forStartDateParam
      : getTaskInitialStartDate(task, dueDate)

  const isValidStageParam =
    project != null ? isValidStageDefinitionId(forStageParam, project) : false
  const stageDefinitionId =
    usingTaskData && 'stageDefinitionId' in task
      ? task.stageDefinitionId
      : isValidStageParam
        ? forStageParam
        : project?.activeStageDefinitionId ?? null

  const scheduledStartDate =
    scheduledStart != null ? DateTime.fromISO(scheduledStart) : null
  const scheduledEndDate =
    scheduledEnd != null ? DateTime.fromISO(scheduledEnd) : null

  const isFixedTimeTask = scheduledStart != null && scheduledEnd != null
  const fixedTimeTaskDuration =
    scheduledStartDate && scheduledEndDate
      ? scheduledEndDate.diff(scheduledStartDate, 'minutes')
      : null

  const statusId = usingTaskData ? task.statusId : forStatusParam
  let status =
    workspaceStatuses.find((s) => s.id === statusId) ??
    getDefaultTaskStatusForWorkspace(workspaceStatuses)
  let isAutoScheduled = usingTaskData
    ? task.isAutoScheduled
    : isFixedTimeTask ||
      (status ? status.autoScheduleEnabled && !status.isResolvedStatus : false)

  let completedTime =
    usingTaskData && 'completedTime' in task ? task.completedTime : null
  let completedDuration =
    usingTaskData && 'completedDuration' in task ? task.completedDuration : 0

  const isDuplicatingTask = forTaskIdParam != null && task != null
  if (isDuplicatingTask && isCompletedStatus(status)) {
    status = getDefaultTaskStatusForWorkspace(workspaceStatuses)
    isAutoScheduled = status?.autoScheduleEnabled ?? false

    // the duplicated task has not been started or completed
    completedTime = null
    completedDuration = 0
  }

  const isInstance = task?.type === 'RECURRING_INSTANCE'

  const customFieldValuesFieldArray = workspaceCustomFields.map((field) =>
    mapCustomFieldToFieldArrayWithValue(field, {
      ...forCustomFieldParam,
      ...(usingTaskData && 'customFieldValues' in task
        ? task.customFieldValues
        : {}),
    })
  )

  let description =
    (isInstance ? parentTask?.description : task?.description) ?? ''
  if (isDuplicatingTask) {
    const duplicatedFromTaskUrl = getTaskStaticURL({
      workspaceId,
      taskId: forTaskIdParam,
    })
    const duplicateStr = `<p>Duplicated from: <a href="${duplicatedFromTaskUrl}" target="_blank">${task?.name}</a></p>`
    description = `${description}${duplicateStr}`
  }

  let name = task?.name ?? ''
  if (isDuplicatingTask) {
    name = `${name} (duplicate)`
  }

  // Workspace can be null if the user is within a modal with a workspace that has been deleted or is simply invalid
  // Let's show an error message instead of throwing
  const hasErrorState =
    hasError ||
    (taskId != null && task == null) ||
    workspace == null ||
    status == null

  return {
    isLoading: isLoadingTask,
    hasError: hasErrorState,
    id: forTaskIdParam ? undefined : task?.id,
    parentId: usingTaskData ? parentTask?.id : undefined,
    type: usingTaskData ? task.type : 'NORMAL',
    workspaceId,
    projectId,
    stageDefinitionId,
    statusId: status?.id ?? '', // fallback on empty string is ok because we check the validity of status for the error state
    assigneeUserId,
    isAutoScheduled,
    labelIds: usingTaskData
      ? 'labelIds' in task
        ? task.labelIds
        : []
      : forLabelParam != null
        ? [forLabelParam]
        : [],
    blockingTaskIds:
      usingTaskData && 'blockingTaskIds' in task ? task.blockingTaskIds : [],
    blockedByTaskIds:
      usingTaskData && 'blockedByTaskIds' in task ? task.blockedByTaskIds : [],
    priorityLevel: usingTaskData
      ? task.priorityLevel
      : forPriorityParam ?? 'MEDIUM',
    name,
    description,
    duration: usingTaskData
      ? task.duration
      : fixedTimeTaskDuration?.minutes ?? DEFAULT_DURATION,
    completedDuration,
    minimumDuration:
      usingTaskData && 'minimumDuration' in task
        ? task.minimumDuration
        : NO_CHUNK_DURATION,
    startDate,
    dueDate,
    deadlineType: usingTaskData ? task.deadlineType : 'SOFT',
    scheduleId: usingTaskData ? task.scheduleId : DEFAULT_SCHEDULE_ID,
    ignoreWarnOnPastDue:
      usingTaskData && 'ignoreWarnOnPastDue' in task
        ? task.ignoreWarnOnPastDue
        : false,
    customFieldValuesFieldArray,
    archivedTime:
      usingTaskData && 'archivedTime' in task ? task.archivedTime : null,
    completedTime,
    days:
      usingTaskData && 'days' in task
        ? task.days
        : ['MO', 'TU', 'WE', 'TH', 'FR'],
    frequency: usingTaskData && 'frequency' in task ? task.frequency : 'DAILY',
    recurrenceMeta:
      usingTaskData && 'recurrenceMeta' in task ? task.recurrenceMeta : '',
    idealTime: usingTaskData && 'idealTime' in task ? task.idealTime : null,
    timeStart: usingTaskData
      ? parentTask != null && 'timeStart' in parentTask
        ? parentTask.timeStart
        : 'timeStart' in task
          ? task.timeStart
          : '8:00 am'
      : '8:00 am',
    timeEnd: usingTaskData
      ? parentTask != null && 'timeEnd' in parentTask
        ? parentTask.timeEnd
        : 'timeEnd' in task
          ? task.timeEnd
          : '5:00 pm'
      : '5:00 pm',
    isFixedTimeTask,
    scheduledStart: scheduledStart ?? null,
    scheduledEnd: scheduledEnd ?? null,
    isUnvisitedStage:
      usingTaskData && 'isUnvisitedStage' in task
        ? task.isUnvisitedStage
        : false,
  }
}

export function resolveFormTaskId({
  searchParams,
  state,
}: Pick<GetInitialFormDataOptions, 'searchParams' | 'state'>) {
  if (state?.chunkId != null) {
    return state.chunkId
  }

  const { forTaskId: forTaskIdParam, task: taskParam } = searchParams

  const taskParamId =
    forTaskIdParam ?? (taskParam === 'new' ? undefined : taskParam)

  return taskParamId
}

export function resolveFormWorkspaceAndProjectId({
  searchParams,
  urlParams,
  task,
  defaultWorkspace,
}: Pick<
  GetInitialFormDataOptions,
  'searchParams' | 'task' | 'urlParams' | 'defaultWorkspace'
>) {
  const taskParamId = resolveFormTaskId({ searchParams })
  const usingTaskData = taskParamId != null && task != null
  const { workspaceId: workspaceIdParam, projectId: projectIdParam } = urlParams

  const workspaceId = usingTaskData
    ? task.workspaceId
    : searchParams.forWorkspace ?? workspaceIdParam ?? defaultWorkspace?.id

  const projectId = usingTaskData
    ? 'projectId' in task
      ? task.projectId
      : null
    : searchParams.forProject ?? projectIdParam ?? null

  return {
    taskId: taskParamId,
    workspaceId,
    projectId,
  }
}
