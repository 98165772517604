import { ChevronDownSolid } from '@motion/icons'
import { classed, type ComponentProps } from '@motion/theme'

import { type ComponentRef, forwardRef, type ReactNode } from 'react'

export type PopoverButtonProps = ComponentProps<typeof StyledButton> & {
  icon?: ReactNode
  placeholder?: ReactNode
}

export const PopoverButton = forwardRef<
  ComponentRef<typeof StyledButton>,
  PopoverButtonProps
>((props, ref) => {
  const { children, muted, placeholder, icon, ...rest } = props

  const child =
    typeof children === 'string' ? <Value>{children}</Value> : children

  return (
    <StyledButton ref={ref} type='button' muted={muted} {...rest}>
      {icon && <PrefixIcon>{icon}</PrefixIcon>}

      {/* supports falsy child value like `false`, `null` and `undefined` */}
      {child ? child : <Placeholder>{placeholder}</Placeholder>}

      {!muted && <ChevronDownIcon aria-hidden='true' />}
    </StyledButton>
  )
})
PopoverButton.displayName = 'PopoverButton'

const StyledButton = classed('button', {
  base: `
    w-full h-8
    flex items-center justify-between
    gap-2 py-1.5 px-2
    border border-solid border-field-border-default rounded
    bg-field-bg-default
    text-field-text-default
    text-sm leading-5

    hover:border-field-border-hover

    focus-visible:outline-0
    focus-visible:transition-shadow
    focus-visible:ring-2 focus-visible:ring-offset-1
    focus-visible:ring-offset-field-bg-default
    focus-visible:ring-field-border-focus

    active:border-field-border-active

    disabled:bg-field-bg-disabled
    disabled:border-field-border-disabled
    [&>*]:disabled:opacity-50
    disabled:cursor-not-allowed
    `,
  variants: {
    muted: {
      true: `
        bg-transparent
        border-transparent

        hover:border-field-border-hover
        active:border-field-border-active
    `,
    },
    sentiment: {
      error: 'border-field-border-error',
    },
    size: {
      small: `
        setvar-[b-gap=6px]
        text-xs px-[7px] py-[3px]
        [&_[data-icon]]:w-4
        [&_[data-icon]]:h-4
      `,
    },
  },
})

const PrefixIcon = classed('span', {
  base: 'shrink-0 text-field-icon-default',
})

const ChevronDownIcon = classed(ChevronDownSolid, {
  base: 'shrink-0 text-field-icon-selectChevron w-[18px] h-[18px]',
})

const Value = classed('span', {
  base: 'truncate grow text-left',
})

const Placeholder = classed(Value, {
  base: 'text-field-text-placeholder',
})
