import { extractUrlComponents } from '../../validation'

export function addSchemeIfNeeded(text: string): string
export function addSchemeIfNeeded(text: string | null): string | null
export function addSchemeIfNeeded(text: string | null): string | null {
  if (text == null || text.length === 0) {
    return text
  }
  const components = extractUrlComponents(text)

  // check if scheme defined
  if (components?.scheme) {
    return text
  }

  return `https://${text}`
}
