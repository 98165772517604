import { classed } from '@motion/theme'
import { filterAndRankMatches } from '@motion/ui-logic'

import {
  type MutableRefObject,
  type ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'

import { GrowOnlyDiv } from './grow-only-div'

import {
  CollapsablePanel,
  type ControlledCollapsablePanelProps,
} from '../../collapsable-panel'
import { type SearchableListSectionedSelectProps, type Section } from '../types'
import { MAX_HEIGHT } from '../utils'

const EmptyRender = () => <div>No Items</div>

export type SectionedListProps<T> = Pick<
  SearchableListSectionedSelectProps<T>,
  | 'computeKey'
  | 'filter'
  | 'computeSearchValue'
  | 'computeSelected'
  | 'hideEmptySections'
> & {
  sections: Section<T>[]

  renderEmpty?(): ReactNode
  renderItem(props: { item: T }): ReactNode

  search: string

  actionsVisibility?: ControlledCollapsablePanelProps['actionsVisibility']
  actions?(section: Section<T>): ReactNode
  expandMode?: ControlledCollapsablePanelProps['expandMode']
  onHeaderClick?: (section: Section<T>) => void

  containerRef?: MutableRefObject<HTMLDivElement | null>
}

export const SectionedList = <T,>(props: SectionedListProps<T>) => {
  const {
    sections,
    hideEmptySections = false,
    renderEmpty = EmptyRender,
    computeKey,
    renderItem,
    filter,
    search,
    computeSearchValue = computeKey,
    computeSelected,
    actions,
    actionsVisibility,
    expandMode,
    onHeaderClick,
    containerRef,
  } = props

  const [expandedSections, setExpandedSections] = useState(() =>
    sections.reduce<Record<string, boolean>>((acc, section) => {
      // force a section with selected items to be expanded by default if they are not
      const expanded = !section.initialExpanded
        ? section.items.some((item) => computeSelected(item))
        : true

      acc[section.key] = expanded
      return acc
    }, {})
  )

  const filterFn = useCallback(
    (section: Section<T>) => {
      return typeof filter === 'function'
        ? filter(search, section)
        : filterAndRankMatches(search, section.items, computeSearchValue)
    },
    [computeSearchValue, filter, search]
  )

  const filteredSections = useMemo(() => {
    return sections.map((section) => {
      return {
        ...section,
        items: filterFn(section),
      }
    })
  }, [filterFn, sections])

  return (
    <GrowOnlyDiv
      ref={containerRef}
      calculateWhenChange={search}
      style={{
        maxHeight: MAX_HEIGHT,
        transitionProperty: 'height',
        transitionDuration: '75ms',
      }}
      className='scrollbar-none w-full scroll-py-1 max-w-sm overflow-y-auto overflow-x-hidden'
    >
      {filteredSections.map((section) => {
        if (hideEmptySections && section.items.length === 0) return null

        const expanded = expandedSections[section.key] ?? true
        // Forces expand the section if not expanded and there's a search query
        const forceExpanded = !expanded
          ? search.length > 0 && section.items.length > 0
          : true

        return (
          <CollapsablePanel
            key={section.key}
            title={section.label}
            className='text-semantic-neutral-text-subtle bg-semantic-neutral-surface-overlay-bg-subtle hover:bg-semantic-neutral-surface-overlay-bg-subtle-hover'
            actions={actions?.(section)}
            actionsVisibility={actionsVisibility}
            expandMode={expandMode}
            onHeaderClick={
              onHeaderClick != null
                ? () => {
                    onHeaderClick(section)
                  }
                : undefined
            }
            expanded={forceExpanded}
            onExpand={(expand) => {
              setExpandedSections((prev) => ({
                ...prev,
                [section.key]: expand,
              }))
            }}
          >
            {section.items.length === 0 ? (
              <EmptyContainer>{renderEmpty()}</EmptyContainer>
            ) : (
              <div className='p-1'>
                {section.items.map((item) => {
                  return renderItem({
                    item,
                  })
                })}
              </div>
            )}
          </CollapsablePanel>
        )
      })}
    </GrowOnlyDiv>
  )
}

const EmptyContainer = classed('div', {
  base: `text-semantic-neutral-text-subtle text-xs px-2 py-0.5 leading-5`,
})
