import { classed } from '@motion/theme'

import { type PropsWithChildren, useMemo } from 'react'

import { type CalendarEventProps } from './calendar-event'

import { CalendarPalette } from '../palette'

export interface EventColorBarsGridProps {
  otherCalendars?: CalendarEventProps['otherCalendars']
  roundFirst?: boolean
  size?: 'regular' | 'small'
}

export function EventColorBarsGrid(
  props: PropsWithChildren<EventColorBarsGridProps>
) {
  const {
    otherCalendars = [],
    children,
    roundFirst = false,
    size = 'regular',
  } = props
  const first2 = useMemo(() => otherCalendars.slice(0, 2), [otherCalendars])
  const hasHiddenCalendars = first2.length !== otherCalendars.length

  return (
    <EventGridWrapper
      cols={getCols(first2.length + 1)}
      padLeft={hasHiddenCalendars || !roundFirst}
      size={size}
    >
      {first2.map((m, i) => (
        <CalendarPalette key={i} {...m}>
          <MultiCalBar
            withShadows={hasHiddenCalendars}
            roundFirst={roundFirst}
          />
        </CalendarPalette>
      ))}
      {children}
    </EventGridWrapper>
  )
}

type AllowedColNumber = '1' | '2' | '3' | '4'
function getCols(len: number): AllowedColNumber {
  if (len < 1) return '1'
  if (len > 3) return '4'
  return len.toString() as AllowedColNumber
}

const EventGridWrapper = classed('div', 'grid gap-0.5 h-full w-full', {
  variants: {
    cols: {
      1: '',
      2: '',
      3: '',
      4: '',
    },
    size: {
      regular: '',
      small: '',
    },
    padLeft: {
      true: 'ml-0.5',
    },
  },
  compoundVariants: [
    {
      cols: '1',
      size: 'regular',
      className: 'grid-cols-1',
    },
    {
      cols: '2',
      size: 'regular',
      className: 'grid-cols-[3px_1fr]',
    },
    {
      cols: '3',
      size: 'regular',
      className: 'grid-cols-[3px_3px_1fr]',
    },
    {
      cols: '4',
      size: 'regular',
      className: 'grid-cols-[3px_3px_3px_1fr]',
    },
    {
      cols: '1',
      size: 'small',
      className: 'grid-cols-1',
    },
    {
      cols: '2',
      size: 'small',
      className: 'grid-cols-[2px_1fr]',
    },
    {
      cols: '3',
      size: 'small',
      className: 'grid-cols-[2px_2px_1fr]',
    },
    {
      cols: '4',
      size: 'small',
      className: 'grid-cols-[2px_2px_2px_1fr]',
    },
  ],
})

const MultiCalBar = classed('div', 'bg-palette-highlight-subtle', {
  variants: {
    withShadows: {
      true: '[.motion-palette:first-child_>_&]:shadow-calendar-event',
    },
    roundFirst: {
      true: '[.motion-palette:first-child_>_&]:rounded-l',
    },
  },
})
