import { type FocusEvent, forwardRef, useEffect, useRef } from 'react'

import { FieldLabel, type FieldLabelProps } from './field-label'
import { Textarea, type TextareaProps } from './textarea'
import { TextInput, type TextInputProps } from './textinput'

type BaseProps = {
  label?: FieldLabelProps['label'] // TODO: Make it required
  labelHidden?: FieldLabelProps['labelHidden']
  multiline?: boolean
  selectTextOnFocus?: boolean
  fullWidth?: FieldLabelProps['fullWidth']
}

export type SingleLineProps = TextInputProps & {
  multiline?: false
}

export type MultiLineProps = TextareaProps & {
  multiline: true
}

export type TextFieldProps = BaseProps & {
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
} & (MultiLineProps | SingleLineProps)

export const TextField = forwardRef<HTMLLabelElement, TextFieldProps>(
  (props, ref) => {
    const {
      label,
      labelHidden,
      multiline = false,
      selectTextOnFocus = false,
      onFocus,
      fullWidth,
      ...rest
    } = props

    const inputRef = useRef<HTMLInputElement>(null)
    const textareaRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
      const input = multiline ? textareaRef.current : inputRef.current
      if (!input || !rest.autoFocus || selectTextOnFocus) return

      if (input.type === 'email') return

      try {
        const end = input.value.length
        input.setSelectionRange(end, end)
      } catch (ex) {
        // suppress errors while attempting to select text
      }
    }, [multiline, rest.autoFocus, selectTextOnFocus])

    const handleOnFocus = (
      event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      if (selectTextOnFocus) {
        event.currentTarget.select()
      }
      onFocus?.(event)
    }

    return (
      <FieldLabel
        ref={ref}
        label={label}
        labelHidden={labelHidden}
        fullWidth={fullWidth}
      >
        {multiline ? (
          <Textarea
            ref={textareaRef}
            onFocus={handleOnFocus}
            {...(rest as TextareaProps)}
          />
        ) : (
          <TextInput
            ref={inputRef}
            onFocus={handleOnFocus}
            {...(rest as TextInputProps)}
          />
        )}
      </FieldLabel>
    )
  }
)
TextField.displayName = 'TextField'
