import { CheckSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { type ColorId } from '@motion/ui-logic/calendar'

import { CalendarPalette } from '../palette'

export type CalendarColorIconProps = {
  colorId: ColorId
  size?: 'normal' | 'small' | 'xsmall'
  selected?: boolean
  onClick?: () => void
}

export const CalendarColorIcon = (props: CalendarColorIconProps) => {
  const { colorId, size = 'normal', selected = false, onClick } = props

  return (
    <CalendarPalette colorId={colorId}>
      <ColorIcon
        size={size}
        selected={selected}
        as={onClick ? 'button' : 'div'}
        onClick={onClick}
      >
        {selected && (
          <CheckSolid className='w-4/5 h-4/5 text-semantic-neutral-icon-onDark' />
        )}
      </ColorIcon>
    </CalendarPalette>
  )
}

const ColorIcon = classed('div', {
  base: `
    bg-calendar-palette-highlight-default grid place-items-center
    enabled:hover:outline enabled:outline-1 enabled:outline-offset-2 enabled:outline-calendar-palette-highlight-default
  `,
  variants: {
    size: {
      normal: 'w-4 h-4 rounded',
      small: 'w-3 h-3 rounded-[3px]',
      xsmall: 'w-2.5 h-2.5 rounded-sm',
    },
    selected: {
      true: 'enabled:outline',
    },
  },
})
