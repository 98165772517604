import { useSettings } from '@motion/web-common/settings'

import { type OnboardingProgress, shouldOnboard } from './onboarding'

import { log } from '../log'

export const useRequiresOnboarding = () => {
  const data = useSettings([
    'onboardingProgress',
    'didSkipOnboarding',
    'isOnboardingComplete',
  ])

  log('requires-onboarding', { ...data })
  if (data == null) return null

  if (data.isOnboardingComplete) return false

  return shouldOnboard(
    data.onboardingProgress as Partial<OnboardingProgress>,
    false,
    data.didSkipOnboarding
  )
}
