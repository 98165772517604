/**
 * Return an object with all the undefined property values removed
 */
export function stripUndefined<T extends object>(obj: T): T
export function stripUndefined<T extends object>(
  obj: T | undefined
): T | undefined
export function stripUndefined<T extends object>(obj: T): T {
  if (!obj) return obj
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined)
  ) as T
}

export type OrNullable<T extends object> = {
  [K in keyof T]: T[K] | null
}

export type NullToUndefined<T extends object> = {
  [K in keyof T]: T[K] extends null ? NonNullable<T[K]> | undefined : T[K]
}

export function stripNull<T extends object>(obj: T): NullToUndefined<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value != null)
  ) as NullToUndefined<T>
}
