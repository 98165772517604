import { ClientValidationError } from '@motion/rpc'

const NON_ACTIONABLE_ERRORS = [
  'Failed to fetch',
  'Failed to connect',
  'Load failed',
  'Not Authorized',
  'No auth token was found',
  'AbortError',
  'The transaction was aborted',
  'The request timed out',
  'ThrottlerException',
  'ID token expired',
  'Connection to Indexed Database server lost',
  'Internal error opening backing store for indexedopen',
  'Encountered full disk while opening backing store for indexedopen',
] as const

export function shouldBeIgnored(exception: unknown) {
  if (exception instanceof ClientValidationError) {
    return true
  }

  if (
    NON_ACTIONABLE_ERRORS.some((err) =>
      (exception as { message?: string })?.message?.includes(err)
    )
  ) {
    return true
  }

  if (exception instanceof Error) {
    const cause = exception.cause
    if (cause && cause instanceof Error) {
      if (NON_ACTIONABLE_ERRORS.some((err) => cause.message.includes(err))) {
        return true
      }
    }
  }

  return false
}
