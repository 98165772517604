import { type COLOR } from '@motion/shared/common'

import { type ReactNode } from 'react'

import { colorOptionToHueMap, PaletteProvider } from '../../palette'

type ProjectPaletteProps = {
  colorOption?: COLOR
  colorHue?: number
  children: ReactNode
}

export const ProjectPalette = ({
  children,
  colorOption,
  colorHue,
}: ProjectPaletteProps) => {
  if (colorOption == null && colorHue == null) {
    throw new Error(
      'ProjectPalette requires at least a colorOption or a colorHue'
    )
  }

  const hue = colorOption ? colorOptionToHueMap[colorOption] : colorHue ?? 0
  return <PaletteProvider colorHue={hue}>{children}</PaletteProvider>
}
