import { v4 as uuidv4 } from 'uuid'

import {
  HttpConnectionError,
  HttpError,
  HttpValidationError,
  NotAuthorizedError,
} from './errors'
import { buildFetchRequest, type FetchContext } from './fetch'
import {
  type ApiTypes,
  type CommonApiDefinition,
  type CommonApiResponseDefinition,
} from './types'
import { parseBody } from './utils'

// TODO: Clean up this type
type ApiArg<TApi> =
  TApi extends CommonApiResponseDefinition<infer TArgs> ? TArgs : never
type ApiReturn<TApi> =
  TApi extends CommonApiResponseDefinition<any, infer TReturn> ? TReturn : never

export type CreateFetchFn<TApi extends CommonApiResponseDefinition> =
  ApiArg<TApi> extends object
    ? (args: ApiArg<TApi>, opts?: RequestInit) => Promise<ApiReturn<TApi>>
    : (args?: undefined, opts?: RequestInit) => Promise<ApiReturn<TApi>>

export function createFetch<TApi extends CommonApiResponseDefinition>(
  api: TApi,
  ctx: FetchContext<TApi>
): CreateFetchFn<TApi>
export function createFetch<TApi extends CommonApiResponseDefinition>(
  api: TApi,
  ctx: FetchContext<CommonApiDefinition>
): CreateFetchFn<TApi>
export function createFetch<TApi extends CommonApiResponseDefinition>(
  api: TApi,
  ctx: FetchContext<TApi>
): CreateFetchFn<TApi> {
  type t = ApiTypes<TApi>

  return async (args?: ApiArg<TApi>, opts?: RequestInit) => {
    const reqId = uuidv4()

    const [uri, request] = await buildFetchRequest(
      api as CommonApiDefinition<ApiArg<TApi>>,
      args!,
      {
        baseUri: ctx.baseUri,
        token: ctx.token,
        headers: ctx.headers,
        reqId,
      },
      opts?.signal
    )

    const responseHandler = async (res: Response) => {
      if (res.status === 204) return null as unknown as t['queryFnData']

      const rawBody = await parseBody<t['queryFnData']>(res)

      if (res.status === 401) {
        throw new NotAuthorizedError(reqId)
      }

      if (!res.ok) {
        if ((rawBody as any)?.type === 'validation') {
          throw new HttpValidationError({
            status: res.status,
            body: rawBody as any,
            reqId,
          })
        }

        throw new HttpError({ status: res.status, body: rawBody, reqId })
      }

      if (api.transform) {
        return api.transform(rawBody, args)
      }

      return rawBody
    }

    const response = ctx.executor
      ? ctx
          .executor((uri, req) => wrappedFetch(uri, req), {
            api,
            args,
            uri,
            req: request,
            responseHandler,
          })
          .then((res) => (isResponseObject(res) ? responseHandler(res) : res))
      : wrappedFetch(uri, request).then(responseHandler)

    return response
  }
}

const wrappedFetch = (uri: string, req: RequestInit) =>
  fetch(uri, { ...req, credentials: 'include' }).catch((ex) => {
    const requestId =
      (req.headers as Record<string, string>)?.['x-request-id'] ?? 'not found'

    throw new HttpConnectionError({
      uri,
      method: req.method,
      cause: ex,
      reqId: requestId,
    })
  })

function isResponseObject(obj: unknown): obj is Response {
  if (!obj) return false
  if (!(typeof obj === 'object')) return false
  return (
    'status' in obj &&
    'statusText' in obj &&
    'json' in obj &&
    typeof obj.json === 'function'
  )
}
