import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { Modal, type ModalProps } from './modal'

import { type DataTestIdAttribute } from '../../types'
import { Button } from '../button'
import { ButtonGroup } from '../button-group'

type Action = {
  onAction(): void
  label: ReactNode
  disabled?: boolean
}

type BlockingConfirmationModalProps = {
  blocking: true
  onClose?: ModalProps['onClose']
}

type NonBlockingConfirmationModalProps = {
  blocking?: boolean
  onClose: ModalProps['onClose']
}

export type BaseConfirmationModalProps = Pick<ModalProps, 'visible'> &
  DataTestIdAttribute & {
    title: ReactNode
    description?: ReactNode
    closeLabel?: ReactNode
    action: Action
    loading?: boolean
    destructive?: boolean
    blocking?: boolean
    onClose?: ModalProps['onClose']
  }

export type ConfirmationModalProps = BaseConfirmationModalProps &
  (BlockingConfirmationModalProps | NonBlockingConfirmationModalProps)

export function ConfirmationModal(props: ConfirmationModalProps) {
  const {
    visible,
    onClose = () => {},
    title,
    description,
    destructive,
    action,
    loading = false,
    closeLabel = 'Close',
    blocking = false,
  } = props

  return (
    <Modal
      data-testid={props['data-testid']}
      visible={visible}
      disableOverlayClick
      disableEscapeKey={blocking}
      onClose={onClose}
    >
      <div className='p-6 flex flex-col gap-3 w-[450px] overflow-x-hidden'>
        <div className='flex items-center gap-1 text-semantic-neutral-text-default font-semibold text-base'>
          {title}
        </div>
        {description && (
          <div className='text-semantic-neutral-text-subtle text-sm leading-5'>
            {description}
          </div>
        )}

        <div
          className={twMerge(
            'flex justify-end',
            description ? 'mt-1' : 'mt-3.5'
          )}
        >
          <ButtonGroup>
            {!blocking && (
              <Button
                data-testid='action-close'
                variant='muted'
                sentiment='neutral'
                onClick={() => onClose()}
                shortcut='esc'
                loading={loading}
              >
                {closeLabel}
              </Button>
            )}
            <Button
              data-testid='action-accept'
              disabled={action.disabled}
              onClick={action.onAction}
              sentiment={destructive ? 'error' : 'primary'}
              loading={loading}
            >
              {action.label}
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </Modal>
  )
}
