export const CalendarProviderType = {
  APPLE: 'APPLE',
  GOOGLE: 'GOOGLE',
  MICROSOFT: 'MICROSOFT',
} as const

export type CalendarProviderType =
  (typeof CalendarProviderType)[keyof typeof CalendarProviderType]

export const EmailAccountStatus = {
  OK: 'OK',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  INVALID_GRANT: 'INVALID_GRANT',
  DISABLED: 'DISABLED',
  NO_CALENDAR_ACCESS: 'NO_CALENDAR_ACCESS',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
}

export type EmailAccountStatus =
  (typeof EmailAccountStatus)[keyof typeof EmailAccountStatus]

export type EmailAccount = {
  id: string
  userId: string
  email: string
  name: string | null
  providerType: CalendarProviderType
  profilePictureUrl?: string
  scope: string[]
  status: EmailAccountStatus
  createdTime: string
  updatedTime: string | null
}
