import { sleep } from '@motion/utils/promise'

// eslint-disable-next-line @motion/import-path
import { type FirebaseApp } from 'firebase/app'
import {
  doc,
  type DocumentData,
  type DocumentSnapshot,
  type Firestore,
  type FirestoreError,
  getDoc,
  getFirestore,
  initializeFirestore,
  onSnapshot,
  type PartialWithFieldValue,
  setDoc,
  type SetOptions,
  updateDoc,
  // eslint-disable-next-line @motion/import-path
} from 'firebase/firestore'

import { makeLog } from '../logging'
import { Sentry } from '../sentry'

type BuilderState = {
  collection: string | null
  documentId: string | null
}

export type FirestoreBuilder<TData = unknown> = {
  collection<TCol>(name: string): FirestoreBuilder<TCol>
  doc(id: string): FirestoreBuilder<TData>
  get(): Promise<DocumentSnapshot<TData>>
  set(data: PartialWithFieldValue<TData>, options?: SetOptions): Promise<void>
  update(data: PartialWithFieldValue<TData>): Promise<void>
  onSnapshot(
    observer: (snapshot: DocumentSnapshot<TData>) => void,
    onError?: (error: FirestoreError) => void
  ): () => void
}

const log = makeLog('firebase.store.sentry')

export function initialize(app: FirebaseApp) {
  log('initialize')
  Sentry.addBreadcrumb({ message: `Initialize Firestore` })
  initializeFirestore(app, {
    ignoreUndefinedProperties: true,
    experimentalAutoDetectLongPolling: true,
    // There is no more 'merge' setting
    //   merge: true,
  })

  return {
    get store() {
      return getFirestore()
    },
    builder() {
      return createFirestoreBuilder(getFirestore())
    },
  }
}

const TIMEOUT_SYMBOL = Symbol('timeout')
function createFirestoreBuilder(store: Firestore): FirestoreBuilder {
  const state: BuilderState = {
    collection: null,
    documentId: null,
  }

  function getDocument() {
    if (state.collection == null) {
      throw new Error('collection is not set')
    }
    if (state.documentId == null) {
      throw new Error('documentId is not set')
    }
    return doc(store, state.collection, state.documentId)
  }

  return {
    collection<TData>(name: string): FirestoreBuilder<TData> {
      state.collection = name
      return this as FirestoreBuilder<TData>
    },
    doc(id: string) {
      state.documentId = id
      return this
    },

    get() {
      const fetchDocument = () => getDoc(getDocument())
      return new Promise((resolve, reject) => {
        Promise.race([sleep(5_000, TIMEOUT_SYMBOL), fetchDocument()])
          .then((value) => {
            if (value !== TIMEOUT_SYMBOL) {
              return resolve(value)
            }
            Sentry.addBreadcrumb({ message: `Firebase.getDocument timed out` })
            // eslint-disable-next-line promise/no-nesting
            fetchDocument().then(resolve).catch(reject)
            return
          })
          .catch(reject)
      })
    },

    set(data: PartialWithFieldValue<DocumentData>, options?: SetOptions) {
      const ref = getDocument()
      if (options) {
        return setDoc(ref, data, options)
      }
      return setDoc(ref, data)
    },

    update(data: PartialWithFieldValue<DocumentData>) {
      const ref = getDocument()
      return updateDoc(ref, data)
    },

    onSnapshot(
      observer: (snapshot: DocumentSnapshot) => void,
      onError?: (error: FirestoreError) => void
    ) {
      const ref = getDocument()
      let unsub: (() => void) | undefined
      unsub = onSnapshot(ref, observer, (error) => {
        if (error.code === 'cancelled') {
          Sentry.addBreadcrumb({ message: 'Firestore.onSnapshot cancelled' })
        }
        Sentry.captureException(error, {
          extra: { message: 'Failed to register listener' },
          tags: { position: 'onSnapshot' },
        })
        unsub?.()

        unsub = onSnapshot(getDocument(), observer, (error) => {
          if (error.code === 'cancelled') {
            Sentry.addBreadcrumb({
              message: 'Firestore.onSnapshot cancelled [retried]',
            })
          } else {
            Sentry.captureException(error, {
              extra: { message: 'Failed to register listener [retried]' },
              tags: { position: 'onSnapshot' },
            })
            onError?.(error)
          }
        })
      })

      return () => unsub?.()
    },
  }
}
