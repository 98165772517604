import { FilledChevronRightSolid, type SvgIcon } from '@motion/icons'
import { classed } from '@motion/theme'

import { type UniqueIdentifier } from '@dnd-kit/core'
import { type MouseEventHandler, type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type Item = {
  id: string
  renderActions?: (hovering: boolean) => React.ReactNode
  disabled?: boolean
  isContainer?: boolean
}

export type SortableTreeviewItemProps = {
  item: Item
  activeId?: UniqueIdentifier | null
  expanded?: boolean
  onClick?: () => void
  icon?: SvgIcon | null
  hovering?: boolean
  children: ReactNode
  selected?: boolean
  withSpacer?: boolean
}

export const SortableTreeviewItem = ({
  item,
  activeId,
  expanded,
  onClick,
  hovering = false,
  children,
  icon,
  selected = false,
  withSpacer = false,
}: SortableTreeviewItemProps) => {
  const actionsRendered = item.renderActions?.(hovering)
  const Icon = icon
  return (
    <SortableTreeviewItemShell
      placeholder={activeId === item.id}
      expandable={item.isContainer}
      selected={selected}
    >
      {withSpacer && <div />}

      {(Icon || item.isContainer) && (
        <button
          onClick={(e) => {
            preventBubble(e)
            onClick?.()
          }}
          className='relative z-[1] w-6 cursor-pointer h-full flex items-center justify-center text-semantic-neutral-icon-subtle transition-colors [&_[data-icon]]:w-4 [&_[data-icon]]:h-4 [&_[data-icon]]:transition-[transform,opacity] [&_[data-icon]]:absolute'
        >
          {Icon && (
            <Icon
              className={twMerge(
                'translate-x-0 opacity-1',
                item.isContainer &&
                  'group-hover/sortable-item:opacity-0 group-hover/sortable-item:-translate-x-1'
              )}
            />
          )}
          {item.isContainer && (
            <FilledChevronRightSolid
              className={twMerge(
                'hover:text-semantic-neutral-icon-default translate-x-1 opacity-0 rotate-0',
                item.isContainer &&
                  'group-hover/sortable-item:opacity-100 group-hover/sortable-item:translate-x-0',
                item.isContainer && expanded && 'rotate-90'
              )}
            />
          )}
        </button>
      )}
      {children}
      {!activeId && actionsRendered && (
        <div onClick={preventBubble} className='pr-1'>
          {actionsRendered}
        </div>
      )}
    </SortableTreeviewItemShell>
  )
}

const SortableTreeviewItemShell = classed('div', {
  base: `
    grid rounded items-center w-full h-full group/sortable-item
    border grid-cols-[min-content_auto_min-content] relative
  `,
  variants: {
    placeholder: {
      true: 'border-dashed border-semantic-neutral-border-strong',
      false: 'border-transparent',
    },
    expandable: {
      true: 'grid-cols-[min-content_auto_min-content]',
    },
    selected: {
      true: '[&_[data-icon]]:text-semantic-neutral-icon-default',
    },
  },
})

const preventBubble: MouseEventHandler<HTMLElement> = (e: React.MouseEvent) => {
  e.preventDefault()
  e.stopPropagation()
}
