export const SHOW_TIME_ABOVE_DURATION_MINUTES = 30
/**
 * Computes duration in minutes for a given start and end date.
 * @returns
 */
export function getDurationfromDates(start: Date | null, end: Date | null) {
  return end && start
    ? Math.floor((end.getTime() - start.getTime()) / 1000 / 60)
    : 0
}
