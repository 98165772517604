import { type ProjectSchema, type StageSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'

export function isValidStageDeadline(
  date: DateTime,
  projectStageId: StageSchema['id'],
  project: Pick<ProjectSchema, 'startDate' | 'dueDate'> & {
    stages: Pick<ProjectSchema['stages'][number], 'dueDate' | 'id'>[]
  }
) {
  const projectStartDate = project.startDate
    ? DateTime.fromISO(project.startDate)
    : null
  const projectDueDate = project.dueDate
    ? DateTime.fromISO(project.dueDate)
    : null

  // Date is before or after the project start/end
  if (projectStartDate != null && date < projectStartDate) return false
  if (projectDueDate != null && date > projectDueDate) return false

  // Otherwise we check based on the previous/next stage
  const stageIndex = project.stages.findIndex((s) => s.id === projectStageId)
  if (stageIndex === -1) return false

  const lowerBound =
    stageIndex === 0
      ? projectStartDate
      : DateTime.fromISO(project.stages[stageIndex - 1].dueDate)

  const upperBound =
    stageIndex === project.stages.length - 1
      ? projectDueDate
      : DateTime.fromISO(project.stages[stageIndex + 1].dueDate)

  if (lowerBound != null && date < lowerBound) return false
  if (upperBound != null && date > upperBound) return false

  return true
}
