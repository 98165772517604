import { DateTime } from 'luxon'

import { type SubscriptionResponse, type SubscriptionState } from './types'

const validStatus = ['trialing', 'active', 'past_due']

// there's a copy of this function in utils
export const calculateIsValid = (response: SubscriptionResponse) => {
  const stripeSubscription = response.combined?.subscription

  if (!stripeSubscription) return false
  if (!stripeSubscription.status) return false
  if (!validStatus.includes(stripeSubscription.status)) return false
  if (!stripeSubscription.current_period_end) return false

  const expiryDate = DateTime.fromMillis(
    stripeSubscription.current_period_end * 1_000
  ).plus({
    days: 1,
  })

  return expiryDate > DateTime.now()
}

export const buildSubscriptionState = (
  sub: SubscriptionResponse | null | undefined
): SubscriptionState => {
  if (sub === undefined) return { state: 'unset' }
  if (sub === null) return { state: 'none', value: null }
  const isActive = calculateIsValid(sub)

  return {
    state: isActive ? 'active' : 'inactive',
    value: sub,
  }
}
