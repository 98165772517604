import { type CustomFieldValidation } from './types'

export const url = {
  name: {
    maxLength: 200,
  },
  value: {
    maxLength: 255,
  },
} as const satisfies CustomFieldValidation
