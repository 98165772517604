import { type StateKey } from './types'

export type FilterFn = (key: StateKey<any>) => boolean

export const DEFAULT_FILTER: FilterFn = (key) => true

export type FilterArgs = {
  include?: StateKey<any>[]
  exclude?: StateKey<any>[]
}

export function createStateKeyFilter(args: FilterArgs): FilterFn {
  return (key: StateKey<any>) => {
    if (args.exclude && args.exclude.includes(key)) return false
    return args.include ? args.include.includes(key) : true
  }
}
