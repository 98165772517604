import { isEqual } from '@motion/utils/core'

import { useCallback, useRef } from 'react'

// Copied from ui-logic since we can not depend on that package
export function useMemoDeep<T>(data: T): T {
  const ref = useRef<T>(data)
  if (!isEqual(data, ref.current)) {
    ref.current = data
  }
  return ref.current
}

export function useClosure<T extends (...args: any[]) => any>(handler: T): T {
  const handlerRef = useRef(handler)
  handlerRef.current = handler

  // @ts-expect-error - type is handled in the signature
  return useCallback((...args) => {
    return handlerRef.current(...args)
  }, [])
}
