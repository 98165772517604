import { isMobileExperience } from '../../env'
import { warnInDev } from '../../logging'
import { Sentry } from '../../sentry'
import { createDb } from '../indexeddb/create-db'
import { createSimpleStore } from '../local-storage/local-storage-store'
import { type MotionDB } from '../types'

export async function initialize(): Promise<MotionDB> {
  if (!window.indexedDB || isMobileExperience()) {
    return createLocalStorageDb()
  }

  const idb = createDb('motion')
  return idb
    .open()
    .then(() => idb)
    .catch((ex) => {
      Sentry.addBreadcrumb({
        message: 'Failed to open IndexedDB. Falling back to localStorage',
      })
      Sentry.captureException(ex, {
        tags: {
          category: 'indexeddb',
        },
        extra: {
          action: 'indexeddb.open',
        },
      })
      return createLocalStorageDb()
    })
}

function createLocalStorageDb(): MotionDB {
  return {
    provider: 'localStorage',
    async open() {},
    async close() {},
    initialized: true,
    async clearAll() {
      try {
        window.localStorage.clear()
      } catch (ex) {
        warnInDev('Unable to clear local storage', ex)
      }
    },

    state: createSimpleStore(),
  }
}
