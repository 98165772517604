import { SearchableListCheckboxItem } from './checkbox-item'
import { SectionedList, type SectionedListProps } from './sectioned-list'

import { type SearchableListSectionedCheckboxProps } from '../types'

export type SectionedCheckboxContainerProps<T> = Pick<
  SearchableListSectionedCheckboxProps<T>,
  | 'renderItem'
  | 'computeKey'
  | 'computeSelected'
  | 'computeDisabled'
  | 'onSelect'
  | 'filter'
  | 'computeSearchValue'
> &
  Omit<SectionedListProps<T>, 'renderItem'> & {
    activeValue: string | null
    setActiveValue(value: string | null): void

    clearSearch(): void
  }

export const SectionedCheckboxList = <T,>(
  props: SectionedCheckboxContainerProps<T>
) => {
  const {
    sections,
    computeKey,
    computeSelected,
    computeDisabled,
    activeValue,
    setActiveValue,
    renderItem,
    onSelect,
    clearSearch,
    computeSearchValue = computeKey,
    ...otherSectionedListProps
  } = props

  const allItems = sections.flatMap((section) => section.items)

  return (
    <SectionedList
      computeKey={computeKey}
      computeSearchValue={computeSearchValue}
      computeSelected={computeSelected}
      sections={sections}
      {...otherSectionedListProps}
      renderItem={({ item }) => {
        const key = computeKey(item)
        const selected = computeSelected(item)

        return (
          <SearchableListCheckboxItem
            key={key}
            // value needs to be unique, ideally we could read the key prop, but we can't so we need to "pass it" twice.
            value={key}
            active={activeValue === key}
            setActiveValue={setActiveValue}
            selected={selected}
            disabled={computeDisabled?.(item) ?? false}
            onSelect={() => {
              const selectedItems = allItems.filter((allItem) => {
                const itemKey = computeKey(allItem)
                if (selected && itemKey === key) {
                  return false
                }
                return computeSelected(allItem) || itemKey === key
              })

              return onSelect(selectedItems, clearSearch)
            }}
          >
            {renderItem(item)}
          </SearchableListCheckboxItem>
        )
      }}
    />
  )
}
