import { useRef } from 'react'

const UNSET = Symbol('unset')

/**
 * Creates a static instance of an object that will persist between renders
 * @param fn initializer function. Will only be called on the first render
 * @returns the stable instance
 */
export const useConst = <T>(fn: () => T): T => {
  const ref = useRef<T | typeof UNSET>(UNSET)
  if (ref.current === UNSET) {
    ref.current = fn()
  }
  return ref.current
}
