import { checkbox } from './checkbox'
import { date } from './date'
import { email } from './email'
import { multiPerson } from './multiPerson'
import { multiSelect } from './multiSelect'
import { number } from './number'
import { person } from './person'
import { phone } from './phone'
import { relatedTo } from './relatedTo'
import { select } from './select'
import { text } from './text'
import { type CompleteConfig } from './types'
import { url } from './url'

export const CUSTOM_FIELDS_VALIDATIONS = {
  categories: {
    text,
    date,
    multiSelect,
    select,
    number,
    url,
    person,
    multiPerson,
    email,
    phone,
    checkbox,
    relatedTo,
  },

  workspace: {
    maxFields: 25,
  },
  name: {
    maxLength: 200,
  },
} as const satisfies CompleteConfig
