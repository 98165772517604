import * as RadixToast from '@radix-ui/react-toast'
import { type ReactNode, useEffect, useState } from 'react'

import { Toast } from './toast'
import { type SubscriberToast, ToasterState } from './toaster-state'

import { Portal } from '../portal'

interface ToasterProps {
  children: ReactNode
}
export function Toaster({ children }: ToasterProps) {
  const [currentToast, setCurrentToast] = useState<SubscriberToast>()

  useEffect(() => {
    return ToasterState.subscribe((toast) => {
      if (toast.removed) {
        setCurrentToast(undefined)
      } else {
        setCurrentToast(toast)
      }
    })
  }, [])

  const visible = currentToast != null && !currentToast.dismissed
  const close = () => {
    if (currentToast != null) {
      ToasterState.dismiss(currentToast)
    }
  }

  const toastChild =
    currentToast == null ? null : currentToast.type === 'custom' ? (
      <span key={currentToast.id}>
        {currentToast.renderFn({ visible, close })}
      </span>
    ) : (
      <Toast
        key={currentToast.id}
        visible={visible}
        title={currentToast.message}
        type={currentToast.type}
        duration={currentToast.opts.duration}
        onClose={close}
      />
    )

  return (
    <RadixToast.Provider swipeDirection='left' label='Notifications'>
      {children}
      {toastChild}
      <Portal>
        <RadixToast.Viewport
          id='toast-viewport'
          className='fixed bottom-0 left-0 p-6'
          style={{ zIndex: 50 }}
        />
      </Portal>
    </RadixToast.Provider>
  )
}
