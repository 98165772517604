import { isEqual } from '@motion/utils/core'

import type { EqualityCheck, InternalStateKey, StateKey } from './types'

export const propertyCompare =
  <T>(key: keyof NonNullable<T>) =>
  (l: T | null | undefined, r: T | null | undefined) =>
    l?.[key] === r?.[key]

export type StateKeyOptions<T> = {
  defaultValue?: T
  serialize?(value: T): string
  deserialize?(raw: string): T | undefined
  equals?: EqualityCheck<T>
}

/**
 * createStateKey
 * @param name name of state
 * @param options the options
 * @returns the state key
 */
export function createStateKey<T>(
  name: string,
  options?: StateKeyOptions<T>
): StateKey<T> {
  const {
    defaultValue,
    equals: objectEquals = isEqual,
    serialize,
    deserialize,
  } = options ?? {}

  const key = {
    name,
    key: Symbol.for(`shared-state:${name}`),
    __defaultValue: defaultValue,
    equals: objectEquals,
    serialize,
    deserialize,
  } as InternalStateKey<any>

  return key
}
