export type Deferred<T> = {
  promise: Promise<T>
  resolve(value: T): void
  reject(ex: Error): void
}

export const createDeferred = <T>(): Deferred<T> => {
  let _resolve!: (value: T) => void
  let _reject!: (ex: Error) => void

  const promise = new Promise<T>((resolve, reject) => {
    _resolve = resolve
    _reject = reject
  })
  return { promise, resolve: _resolve, reject: _reject }
}
