import { type Editor, EditorContent } from '@tiptap/react'
import { twMerge } from 'tailwind-merge'

export type RichTextContentProps = {
  editor: Editor | null
  disabled?: boolean
  className?: string
}

export const RichTextContent = ({
  editor,
  disabled = false,
  className,
}: RichTextContentProps) => {
  return (
    <EditorContent
      editor={editor}
      onFocus={() => {
        if (disabled) {
          editor?.commands.blur()
        }
      }}
      className={twMerge(
        `
      overflow-x-hidden overflow-y-scroll
      cursor-text
      [&>[contenteditable='false']]:text-field-text-disabled
    `,
        RTEStyles,
        className
      )}
    />
  )
}

export const RTEStyles = `
text-field-text-default text-sm
placeholder:text-field-text-placeholder

[&>.ProseMirror]:p-0

[&>*+*]:mt-[0.5em] [&>.ProseMirror>*+*]:mt-[0.5em]
[&_li_p]:my-0 [&_li]:my-[0.2em] [&_li:has(input)]:my-[0.6em]

[&_code]:text-xs [&_pre]:text-xs
[&_code]:py-0.5 [&_code]:px-1 [&_code]:rounded [&_code]:box-decoration-clone
[&_pre]:my-[0.5em] [&_pre]:py-0.5 [&_pre]:px-1 [&_pre]:rounded [&_pre]:box-decoration-clone
[&_code]:text-semantic-neutral-text-default [&_pre]:text-semantic-neutral-text-default
[&_code]:bg-semantic-neutral-bg-active-hover [&_pre]:bg-semantic-neutral-bg-active-hover
[&_blockquote]:border-l-4
[&_blockquote]:border-semantic-neutral-border-default
[&_blockquote]:pl-2
[&_blockquote]:text-semantic-neutral-text-subtle
`
