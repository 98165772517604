type NormalizerOption = {
  normalize: (key: string) => string
}
type DefaultKeyOption<T> = {
  defaultKey: T
}
type Options<T> = DefaultKeyOption<T> & NormalizerOption

// prettier-ignore
export type LookupFn<TMap extends Record<string, unknown>> =
  & ((key: string) => TMap[keyof TMap])
  & (<TKey extends keyof TMap>(key: TKey) => TMap[TKey])

type RecordWithDefault = Record<string, unknown> & { default: unknown }

export function createLookup<
  TMap extends RecordWithDefault = RecordWithDefault,
>(obj: TMap, opts?: NormalizerOption): LookupFn<TMap>

export function createLookup<
  TMap extends Record<string, unknown> = Record<string, unknown>,
>(
  obj: TMap,
  opts: DefaultKeyOption<keyof TMap> & Partial<NormalizerOption>
): LookupFn<TMap>

export function createLookup<
  TMap extends Record<string, unknown> = Record<string, unknown>,
>(obj: TMap, opts?: Partial<Options<keyof TMap>>) {
  // Merge provided options with defaults
  const { defaultKey, normalize } = Object.assign(
    {},
    { defaultKey: 'default', normalize: (key: string) => key },
    opts
  )

  return (key: string): TMap[keyof TMap] => {
    const normalizedKey = normalize(key) as keyof TMap
    const value = obj[normalizedKey] ?? obj[defaultKey]
    return value
  }
}
