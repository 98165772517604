import { type SaveSettingsDto } from '@motion/rpc-types/dto'

import { createKey, defineApi, defineMutation, SKIP_UPDATE } from '../../core'
import { type RouteTypes } from '../types'

export const queryKeys = {
  root: createKey('user-settings'),
}

type UpdateUserSettings = RouteTypes<'SettingsController_saveFirebaseSettings'>

export const get = defineApi<void, SaveSettingsDto>().using({
  key: queryKeys.root,
  uri: '/settings',
})

export const update = defineMutation<
  UpdateUserSettings['request'],
  UpdateUserSettings['response']
>().using({
  method: 'PATCH',
  uri: '/settings',
  effects: [
    {
      on: 'mutate',
      action: 'update',
      key: () => queryKeys.root,
      merge: (value, prev) => (prev ? { ...prev, ...value } : SKIP_UPDATE),
    },
    {
      on: 'success',
      action: 'update',
      key: () => queryKeys.root,
      merge: (value) => value,
    },
  ],
})

type GetFirestoreSettings =
  RouteTypes<'SettingsController_getFirestoreSettings'>
export const getFirestoreSettings = defineApi<
  void,
  GetFirestoreSettings['response']
>().using({
  key: createKey('settings', 'firestore'),
  uri: '/settings/firestore',
})
