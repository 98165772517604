import { type VirtualizedSharedProps } from './types'
import { VirtualizedBase } from './virtualized-base'

type VirtualizedRowsProps<T> = VirtualizedSharedProps<T> & {
  estimateHeight: (item: T) => number
}

export const VirtualizedRows = <T,>(props: VirtualizedRowsProps<T>) => {
  const { estimateHeight, ...rest } = props
  return <VirtualizedBase estimateSize={estimateHeight} {...rest} />
}
