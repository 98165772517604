import { type PMTaskType, type RecurringTask } from '@motion/rpc/types'
import { shiftDateToZone } from '@motion/utils/dates'
import { type RecurringTaskSchema, type TaskSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'

import { isRecurringTaskParent } from '../../task-utils'
import { isReminderTask } from '../helpers'

export function getTaskInitialStartDate(): string
export function getTaskInitialStartDate(
  task?: PMTaskType | RecurringTask | TaskSchema | RecurringTaskSchema,
  knownDueDate?: string | null
): string | null
export function getTaskInitialStartDate(
  task?: PMTaskType | RecurringTask | TaskSchema | RecurringTaskSchema,
  knownDueDate?: string | null
): string | null {
  const today = DateTime.now().startOf('day')

  if (!task) {
    const dueDate = knownDueDate != null ? DateTime.fromISO(knownDueDate) : null

    if (dueDate != null && dueDate < today) {
      return dueDate.minus({ days: 1 }).startOf('day').toISODate()
    }

    return today.toISODate()
  }

  const isRecurring = isRecurringTaskParent(task)

  if (isRecurring) {
    return task.startingOn
      ? shiftDateToZone(task.startingOn).toISODate()
      : today.toISODate()
  }

  if (
    task.completedTime &&
    task.startDate &&
    task.completedTime < task.startDate
  ) {
    return shiftDateToZone(task.completedTime).toISODate()
  }

  if (task.startDate) {
    return shiftDateToZone(task.startDate).toISODate()
  }

  if (task.isAutoScheduled) {
    return today.toISODate()
  }

  return null
}

export function getTaskDefaultDueDate(): string {
  return DateTime.now().plus({ days: 1 }).endOf('day').toISO()
}

export const getScheduledDate = (task?: TaskSchema | RecurringTaskSchema) => {
  if (!task) return null

  if (
    isReminderTask(task) &&
    task.type !== 'RECURRING_TASK' &&
    task.dueDate != null
  ) {
    return DateTime.fromISO(task.dueDate)
  }

  if (
    'estimatedCompletionTime' in task &&
    task.estimatedCompletionTime != null
  ) {
    return DateTime.fromISO(task.estimatedCompletionTime)
  }

  return null
}
