export const priorityLevels: ['ASAP', 'HIGH', 'MEDIUM', 'LOW'] = [
  'ASAP',
  'HIGH',
  'MEDIUM',
  'LOW',
]
export const recurringTaskPriorityLevels: ['HIGH', 'MEDIUM'] = [
  'HIGH',
  'MEDIUM',
]

export type PriorityLevelType = (typeof priorityLevels)[number]

// eslint-disable-next-line @typescript-eslint/sort-type-constituents
export type Frequency =
  | 'daily'
  | 'weekly'
  | 'biweekly'
  | 'monthly'
  | 'quarterly'

export enum SomedayChoiceText {
  ONE_WEEK = '1 week',
  TWO_TO_THREE_WEEKS = '2-3 weeks',
  ONE_TO_TWO_MONTHS = '1-2 months',
  THREE_TO_SIX_MONTHS = '3-6 months',
}

export type SomedayChoice = {
  text: SomedayChoiceText
  deadlineIncrement: number
  relevanceRange: number
}
