import { byProperty, byValue, ordered } from '@motion/utils/array'

import { type ReactNode, useMemo, useState } from 'react'

import { GroupedCheckboxItem } from './grouped-checkbox-item'
import {
  type Group,
  type RenderGroupContext,
  type RenderItemContext,
} from './types'

export type GroupedCheckboxListProps<T> = {
  groups: Group<T>[]

  selected: T[]
  onSelected(items: T[]): void

  renderHeader(ctx: RenderGroupContext<T>): NonNullable<ReactNode>
  renderItem(ctx: RenderItemContext<T>): NonNullable<ReactNode>
}

export const GroupedCheckboxList = <T,>(props: GroupedCheckboxListProps<T>) => {
  const { groups: unsortedGroups, renderHeader, renderItem, selected } = props

  function setSelected(group: Group<T>, items: T[]) {
    const newItems = selected.filter((x) => !group.items.includes(x))
    newItems.push(...items)
    props.onSelected(newItems)
  }

  // Keep the initial sort on mount, so checking/unchecking items while it's render doesn't affect the order
  const [initialSortedGroupKeys] = useState(() =>
    [...unsortedGroups]
      .sort(
        byValue(
          (group) => group.items.some((item) => selected.includes(item)),
          ordered([true, false])
        )
      )
      .map((g) => g.key)
  )

  const groups = useMemo(
    () =>
      [...unsortedGroups].sort(
        byProperty('key', ordered(initialSortedGroupKeys))
      ),
    [unsortedGroups, initialSortedGroupKeys]
  )

  return (
    <div className='py-1.5 flex flex-col'>
      {groups.map((group) => {
        return (
          <GroupedCheckboxItem
            key={group.key}
            group={group}
            renderHeader={renderHeader}
            renderItem={renderItem}
            selected={selected}
            onSelected={(items) => setSelected(group, items)}
          />
        )
      })}
    </div>
  )
}
