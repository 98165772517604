/**
 * Auto Generated
 */
import { type ClientZod } from '../../utils/client-transform'
import type * as Server from '../../server/common/priority-level'

// Generated Types

export type PriorityLevelSchema = ClientZod<typeof Server.PriorityLevelSchema>
export const PriorityLevelSchema = ['ASAP', 'HIGH', 'MEDIUM', 'LOW'] as const
