import { type AnalyticEvents } from '@motion/web-common/analytics/events'

import { useEffect, useRef } from 'react'

import { logEvent } from './amplitude'

type VoidKeys<T extends object> = keyof {
  [K in keyof T as T[K] extends void ? K : never]: K
}

type NonVoidKeys<T extends object> = keyof {
  [K in keyof T as T[K] extends void ? never : K]: K
}

export function recordAnalyticsEvent<TName extends VoidKeys<AnalyticEvents>>(
  eventName: TName
): void
export function recordAnalyticsEvent<TName extends NonVoidKeys<AnalyticEvents>>(
  eventName: TName,
  properties: AnalyticEvents[TName]
): void
export function recordAnalyticsEvent<TName extends keyof AnalyticEvents>(
  eventName: TName,
  properties?: AnalyticEvents[TName]
) {
  logEvent(eventName, properties)
}

type Options<TName extends keyof AnalyticEvents> =
  TName extends VoidKeys<AnalyticEvents>
    ? { enabled?: boolean; properties?: never }
    : { enabled?: boolean; properties: AnalyticEvents[TName] }

export function useOnMountAnalyticsEvent<T extends keyof AnalyticEvents>(
  eventName: T,
  opts?: Options<T>
) {
  const { enabled = true, properties } = opts || {}

  const propsRef = useRef(properties)
  propsRef.current = properties

  // Only record the event once, even if enabled goes from true to false to true.
  const recorded = useRef(false)
  useEffect(
    function analytics() {
      if (recorded.current || !enabled) return
      recorded.current = true
      // externally typed
      recordAnalyticsEvent(eventName as any, propsRef.current as any)
    },
    [eventName, enabled]
  )
}

type SegmentAnalytics = {
  identify: (event: string, properties?: object) => void
  page: () => void
  track: (event: string, properties?: object, traits?: object) => void
  user: () => SegmentUser
}

type SegmentUser = {
  id: () => string | null
  anonymousId: () => string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getSegmentAnalytics = (): SegmentAnalytics | undefined =>
  (window as any).analytics
