import { parseDate } from '@motion/utils/dates'

import { DateTime } from 'luxon'

import { type SetupProjectFormFields } from '../../form-fields'
import { calculateProjectStageDueDates, setLastStageDueDate } from '../utils'

export function getSetupProjectDueDateChangedFields(
  fields: SetupProjectFormFields
): Partial<SetupProjectFormFields> {
  const updates: Partial<SetupProjectFormFields> = {}

  const deadlineDate = fields.dueDate
  const startDate = fields.startDate

  if (startDate && deadlineDate) {
    const start = parseDate(startDate)
    const deadline = parseDate(deadlineDate)

    // If the start date is greater than the deadline, we need to move start date to the day before the deadline
    if (start > deadline) {
      const today = DateTime.now().startOf('day')
      const deadlineDateMinus1 = deadline.startOf('day').minus({ days: 1 })
      const newStart = deadlineDateMinus1 < today ? today : deadlineDateMinus1

      updates.startDate = newStart.toISODate()
    }

    if (fields.stageDueDates.length > 0) {
      const prevProjectDeadline =
        fields.stageDueDates[fields.stageDueDates.length - 1].dueDate
      const prevProjectDeadlineDateTime = parseDate(prevProjectDeadline)

      if (prevProjectDeadlineDateTime > deadline) {
        // Shrink the stage due dates
        updates.stageDueDates = calculateProjectStageDueDates(
          fields.projectDefinition?.stages ?? [],
          start,
          deadline
        )
      } else {
        const adjustedStageDueDates = fields.stageDueDates
        setLastStageDueDate(adjustedStageDueDates, deadline)
        updates.stageDueDates = adjustedStageDueDates
      }
    }
  }

  return updates
}
