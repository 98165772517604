import { useClosure } from '@motion/react-core/hooks'
import { useSharedState } from '@motion/react-core/shared-state'

import { useEffect } from 'react'

import { AuthStateKey, AuthTokenKey, CookieAuthFlagKey } from './state'
import { type AuthStatus, type User } from './types'

export const useAuth = () => useSharedState(AuthStateKey)[0]

export const useAuthStateChanged = (fn: (state: AuthStatus) => void) => {
  const stable = useClosure(fn)

  const { auth } = useAuth()
  useEffect(() => {
    stable(auth)
  }, [auth, stable])
}

export const useCurrentUserOrNull = () => {
  const { auth } = useAuth()
  return auth.state === 'authenticated' ? auth.user : null
}

export const useAuthenticatedUser = (): User => {
  const user = useCurrentUserOrNull()
  if (!user) throw new Error('User is not authenticated')
  return user
}

export const useAuthToken = () => useSharedState(AuthTokenKey)[0]

export const useHasCookieAuth = () => useSharedState(CookieAuthFlagKey)[0]

export const useOnLogout = (fn: (auth: AuthStatus) => Promise<void>) => {
  const [state] = useSharedState(AuthStateKey)

  const stable = useClosure(fn)

  useEffect(() => {
    return state.event.subscribe((x) => {
      if (x.state !== 'signing-out') return
      return stable(x)
    })
  }, [stable, state.event])
}
