export const INDIVIDUAL_ANNUAL_PLAN_SAVINGS_PERCENT = 44
export const INDIVIDUAL_MONTHLY_PRICE = 34
export const INDIVIDUAL_ANNUAL_PRICE = 228
export const INDIVIDUAL_ANNUAL_PRICE_AMORTIZED_MONTHLY =
  INDIVIDUAL_ANNUAL_PRICE / 12
export const OVERDUE_SUBSCRIPTION_STATES = ['unpaid', 'past_due']
export const TEAM_BILLING_BUCKETS = [3, 5, 10, 15, 20, 25, 30, 40, 50]

export type BillingPrices = {
  annualPrice: number
  monthlyPrice: number
  annualPricePerMonth: number
  annualSavingsPercent: number
  annualSavingsPercentInteger: number
}

export const INDIVIDUAL_PRICES: BillingPrices = {
  annualPrice: INDIVIDUAL_ANNUAL_PRICE,
  monthlyPrice: INDIVIDUAL_MONTHLY_PRICE,
  annualPricePerMonth: INDIVIDUAL_ANNUAL_PRICE_AMORTIZED_MONTHLY,
  annualSavingsPercent: INDIVIDUAL_ANNUAL_PLAN_SAVINGS_PERCENT,
  annualSavingsPercentInteger: Math.round(
    INDIVIDUAL_ANNUAL_PLAN_SAVINGS_PERCENT
  ),
}
