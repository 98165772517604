import { type DateTime } from 'luxon'

import { stripEmojis } from '../string'

export interface CompareFn<T> {
  (left: T, right: T): number
}

/**
 * Creates a comparator based on a property of an object
 * @param accessor a function to get the value to sort by
 * @returns a comparator
 */
export function byProperty<T, TKey extends keyof T>(
  prop: TKey,
  fn: CompareFn<T[TKey]>
): CompareFn<T> {
  return (l: T, r: T) => {
    const leftValue = l[prop]
    const rightValue = r[prop]
    return fn(leftValue, rightValue)
  }
}

/**
 * Cascades multiple comparators.
 * Executes the comparators in order and returns the first non-0 result
 * @param comparators the comparators to iterate
 * @returns -1, 0, 1
 */
export function cascade<T>(...comparators: CompareFn<T>[]): CompareFn<T> {
  return (l: T, r: T) => {
    for (const cmp of comparators) {
      const result = cmp(l, r)
      if (result !== 0) return result
    }
    return 0
  }
}

export function byValue<T, TValue>(
  accessor: (item: T) => TValue,
  fn: CompareFn<TValue>
): CompareFn<T> {
  return (l: T, r: T) => {
    const leftValue = accessor(l)
    const rightValue = accessor(r)
    return fn(leftValue, rightValue)
  }
}

export function ordered<T>(
  ordering: readonly T[],
  fallback?: CompareFn<T>
): CompareFn<T> {
  return (l: T, r: T) => {
    const leftIndex = ordering.indexOf(l)
    const rightIndex = ordering.indexOf(r)
    if (leftIndex === rightIndex) {
      return fallback?.(l, r) ?? 0
    }

    if (leftIndex === -1) {
      return 1
    }

    if (rightIndex === -1) {
      return -1
    }
    return leftIndex < rightIndex ? -1 : 1
  }
}

export function orderedAtEnd<T>(
  ordering: readonly T[],
  fallback?: CompareFn<T>
): CompareFn<T> {
  return (l: T, r: T) => {
    const leftIndex = ordering.indexOf(l)
    const rightIndex = ordering.indexOf(r)
    if (leftIndex === rightIndex) {
      return fallback?.(l, r) ?? 0
    }

    if (leftIndex === -1) {
      return -1
    }

    if (rightIndex === -1) {
      return 1
    }
    return leftIndex < rightIndex ? -1 : 1
  }
}

export interface CompareFnWithDesc<T> extends CompareFn<T> {
  desc: CompareFn<T>
}

function createWithDescending<T>(fn: CompareFn<T>): CompareFnWithDesc<T> {
  return Object.defineProperty(fn, 'desc', {
    value: (l: T, r: T) => -fn(l, r),
  }) as CompareFnWithDesc<T>
}

const caseInsensitiveCollator = new Intl.Collator(undefined, {
  sensitivity: 'base',
})

const caseInsensitiveWithNumericCollator = new Intl.Collator(undefined, {
  sensitivity: 'base',
  numeric: true,
})

const caseSensitiveCollator = new Intl.Collator(undefined, {
  sensitivity: 'case',
})

const caseSensitiveWithNumericCollator = new Intl.Collator(undefined, {
  sensitivity: 'case',
  numeric: true,
})

function createWithOptions<TData, TOptions>(
  factory: (opts: TOptions) => CompareFn<TData>,
  defaultOptions: TOptions
) {
  const defaultSort = createWithDescending(factory(defaultOptions))

  return Object.assign(defaultSort, {
    with(opt: TOptions) {
      return createWithDescending(factory(opt))
    },
  })
}

export type OrderOption = 'at-start' | 'at-end'
export type StringSortOptions = {
  null: OrderOption
  empty: OrderOption
  emoji: 'include' | 'exclude'
  trim: boolean
  caseSensitive: boolean
  numeric: boolean
}
const DEFAULT_STRING_SORT_OPTIONS: StringSortOptions = {
  null: 'at-start',
  empty: 'at-start',
  emoji: 'include',
  trim: false,
  caseSensitive: false,
  numeric: false,
}

function createStringComparator(opts: Partial<StringSortOptions>) {
  const config = Object.assign(
    {},
    DEFAULT_STRING_SORT_OPTIONS,
    opts
  ) as StringSortOptions

  return (l: string | null | undefined, r: string | null | undefined) => {
    if (l == null && r == null) return 0
    if (l == null) return config.empty === 'at-start' ? -1 : 1
    if (r == null) return config.empty === 'at-start' ? 1 : -1

    let left = l
    let right = r

    if (config.trim) {
      left = left.trim()
      right = right.trim()
    }

    if (config.emoji === 'exclude') {
      left = stripEmojis(left)
      right = stripEmojis(right)
    }

    const collator = getCollator(config)
    return collator.compare(left, right)
  }
}

function getCollator(opts: StringSortOptions) {
  if (opts.caseSensitive) {
    return opts.numeric
      ? caseSensitiveWithNumericCollator
      : caseSensitiveCollator
  }
  if (!opts.caseSensitive) {
    return opts.numeric
      ? caseInsensitiveWithNumericCollator
      : caseInsensitiveCollator
  }
  return caseInsensitiveCollator
}

type NumericSortOptions = {
  null: OrderOption
}
const DEFAULT_NUMERIC_SORT_OPTIONS: NumericSortOptions = {
  null: 'at-start',
}

function createNumericComparator(opts: Partial<NumericSortOptions>) {
  const config = Object.assign({}, DEFAULT_NUMERIC_SORT_OPTIONS, opts)

  return (l: number | null | undefined, r: number | null | undefined) => {
    l =
      l ??
      (config.null === 'at-start'
        ? Number.MIN_SAFE_INTEGER
        : Number.MAX_SAFE_INTEGER)
    r =
      r ??
      (config.null === 'at-start'
        ? Number.MIN_SAFE_INTEGER
        : Number.MAX_SAFE_INTEGER)
    return l === r ? 0 : l < r ? -1 : 1
  }
}

type DateSortOptions = {
  null: OrderOption
}
const DEFAULT_DATE_SORT_OPTIONS: DateSortOptions = {
  null: 'at-start',
}

function createDateComparator(opts: Partial<DateSortOptions>) {
  const config = Object.assign({}, DEFAULT_DATE_SORT_OPTIONS, opts)

  const numeric = createNumericComparator(config)
  return (
    l: Date | DateTime | null | undefined,
    r: Date | DateTime | null | undefined
  ) => {
    return numeric(l?.valueOf(), r?.valueOf())
  }
}

export function descending<T>(fn: CompareFn<T>): CompareFn<T> {
  return (l, r) => -fn(l, r)
}

export function ascending<T>(fn: CompareFn<T>): CompareFn<T> {
  return fn
}

export const Compare = {
  exists: createWithDescending((l, r) => (l ? (r ? 0 : -1) : r ? 1 : 0)),

  numeric: createWithOptions(
    createNumericComparator,
    DEFAULT_NUMERIC_SORT_OPTIONS
  ),
  string: createWithOptions(createStringComparator, {}),

  /**
   * @deprecated use `Compare.string.with({caseSensitive: true})` instead
   */
  caseSensitive: createWithDescending(
    createStringComparator({ caseSensitive: true })
  ),

  /**
   * @deprecated use `Compare.string` instead
   */
  caseInsensitive: createWithDescending(
    createStringComparator({ caseSensitive: false })
  ),

  dateTime: createWithOptions(createDateComparator, DEFAULT_DATE_SORT_OPTIONS),
  date: createWithOptions(createDateComparator, DEFAULT_DATE_SORT_OPTIONS),
}
