import { useEffect, useState } from 'react'

export const useIsOnline = () => {
  const [isOnline, setIsOnline] = useState<boolean>(true)

  useEffect(
    function checkIfOnline() {
      const onlineHandler = () => setIsOnline(true)
      const offlineHandler = () => setIsOnline(false)

      window.addEventListener('online', onlineHandler)
      window.addEventListener('offline', offlineHandler)
      return () => {
        window.removeEventListener('online', onlineHandler)
        window.removeEventListener('offline', offlineHandler)
      }
    },
    [setIsOnline]
  )

  return isOnline
}
