import { Button } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'

type EmailPasswordPreSignupProps = {
  email: string
  setEmail: (email: string) => void
  disabled: boolean
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const EmailPasswordPreSignup = ({
  email,
  setEmail,
  disabled,
  onSubmit,
}: EmailPasswordPreSignupProps) => {
  return (
    <div className='flex flex-col mt-7 w-full gap-y-4'>
      <div className='flex text-semantic-neutral-text-subtle w-full items-center'>
        <div className='flex-1 h-[1px] bg-semantic-neutral-border-default' />
        <p className='flex-2 px-4'>Or</p>
        <div className='flex-1 h-[1px] bg-semantic-neutral-border-default' />
      </div>
      <form className='w-full' onSubmit={onSubmit}>
        <div className='w-full flex flex-col gap-y-3'>
          <TextField
            placeholder='name@company.com'
            onChange={setEmail}
            value={email}
          />
          <Button disabled={disabled} type='submit'>
            Continue
          </Button>
        </div>
      </form>
    </div>
  )
}
