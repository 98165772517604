import { classed, type ComponentProps, type VariantProps } from '@motion/theme'

import { type ReactNode } from 'react'

export type ToggleProps = Pick<
  ComponentProps<typeof StyledInput>,
  'checked' | 'onChange'
> & {
  children?: ReactNode
  disabled?: boolean
  size?: VariantProps<typeof StyledLabel>['size']
  side?: 'left' | 'right'
}

export const Toggle = (props: ToggleProps) => {
  const { children, size, side = 'left', ...rest } = props

  const render = () => {
    if (side === 'left') {
      return (
        <>
          <Control>
            <Pill />
          </Control>
          {children}
        </>
      )
    }
    return (
      <>
        {children}
        <Control>
          <Pill />
        </Control>
      </>
    )
  }

  return (
    <StyledLabel disabled={rest.disabled} size={size}>
      <StyledInput
        type='checkbox'
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
          }
        }}
        {...rest}
      />
      {render()}
    </StyledLabel>
  )
}

export const StyledLabel = classed('label', {
  base: `
  group/label relative
  text-toggle-text-default
  inline-flex items-center
  gap-2
  cursor-pointer
  transition-colors
  `,
  variants: {
    disabled: {
      true: 'cursor-default',
    },
    size: {
      normal: 'text-sm',
      small: 'text-xs',
      xsmall: 'text-xs',
    },
    variant: {
      autoSchedule: '',
      subtle: 'opacity-50',
      default: '',
    },
  },
  compoundVariants: [
    {
      disabled: true,
      variant: 'autoSchedule',
      className: 'opacity-50',
    },
    {
      disabled: true,
      variant: 'default',
      className: 'text-toggle-text-disabled',
    },
  ],
  defaultVariants: {
    size: 'normal',
    variant: 'default',
  },
  dataAttributes: ['size'],
})

export const Control = classed('span', {
  base: `
    group
    flex items-center shrink-0
    h-[16px] w-[26px] rounded-full
    transition-colors

    bg-toggle-bg-default
    group-hover/label:bg-toggle-bg-hover
    peer-disabled:bg-toggle-bg-disabled

    peer-focus-visible:transition-shadow
    peer-focus-visible:ring-2
    peer-focus-visible:ring-offset-1
    peer-focus-visible:ring-offset-field-bg-default
    peer-focus-visible:ring-field-border-focus

    peer-checked:bg-toggle-bg-active-default
    group-hover/label:peer-checked:bg-toggle-bg-active-hover
    peer-checked:peer-disabled:bg-toggle-bg-active-disabled
    group-hover/label:peer-checked:peer-disabled:bg-toggle-bg-active-disabled

    [[data-size='small']_&]:h-[12px]
    [[data-size='small']_&]:w-[20px]

    [[data-size='xsmall']_&]:h-[12px]
    [[data-size='xsmall']_&]:w-[16px]
  `,
})

export const StyledInput = classed(`input`, {
  base: 'peer sr-only',
})

export const Pill = classed('span', {
  base: `
    inline-flex items-center justify-center
    rounded-full
    w-3 h-3 ml-0.5
    transition-all

    bg-toggle-circle-off-default
    peer-disabled:group-[]:bg-toggle-circle-off-disabled

    peer-checked:group-[]:translate-x-2.5
    peer-checked:group-[]:bg-toggle-circle-on-default
    peer-checked:peer-disabled:group-[]:bg-toggle-circle-on-disabled

    [[data-size='small']_&]:h-[10px]
    [[data-size='small']_&]:w-[10px]
    [[data-size='small']_&]:ml-px
    [[data-size='small']_&]:peer-checked:group-[]:translate-x-2

    [[data-size='xsmall']_&]:h-[10px]
    [[data-size='xsmall']_&]:w-[10px]
    [[data-size='xsmall']_&]:ml-px
    [[data-size='xsmall']_&]:peer-checked:group-[]:translate-x-1
  `,
})
