export function range(max: number): number[]
export function range(min: number, max: number): number[]
export function range(min: number, max?: number): number[] {
  if (max == null) {
    max = min
    min = 0
  }

  return Array.from(new Array(max - min), (_, i) => i + min)
}
