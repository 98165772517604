import { type Ref, type RefCallback, useMemo } from 'react'

export function mergeRefs<T>(
  ...refs: (Ref<T> | null | undefined)[]
): RefCallback<T> | null {
  if (refs.every((ref) => ref == null)) {
    return null
  }

  return (value: T) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref != null) {
        const refObj = ref as React.MutableRefObject<T | null>
        refObj.current = value
      }
    })
  }
}

export function useMergedRefs<T>(...refs: (Ref<T> | null | undefined)[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => mergeRefs(...refs), refs)
}
