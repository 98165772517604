import { type CustomFieldValidation } from './types'

export const relatedTo = {
  name: {
    maxLength: 200,
  },
  options: {
    maxOptions: 10,
  },
} as const satisfies CustomFieldValidation
