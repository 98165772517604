import { type UniqueIdentifier } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { type Transform } from '@dnd-kit/utilities'
import { type CSSProperties, memo, type ReactNode, useState } from 'react'

import { DragHandle } from './drag-handle'

type SortableTreeviewContainerProps = {
  id: UniqueIdentifier
  style: CSSProperties
  disabled?: boolean
  isContainer?: boolean
  children?: React.ReactNode
  renderHeader?: (hovering: boolean) => React.ReactNode
  placeholder?: ReactNode
  renderItem?: (hovering: boolean) => React.ReactNode
}

export const SortableTreeviewItemContainer = memo(
  function SortableTreeviewItemContainer(
    props: SortableTreeviewContainerProps
  ) {
    const [hovering, setHovering] = useState(false)
    const {
      isDragging,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
    } = useSortable({
      id: props.id,
      disabled: props.disabled,
    })
    return (
      <div
        ref={setNodeRef}
        className='flex absolute'
        style={{
          ...props.style,
          ...parseTransform(transform, transition),
          zIndex: isDragging ? 1 : 0,
        }}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <DragHandle
          listeners={listeners}
          setActivatorNodeRef={setActivatorNodeRef}
          disabled={props.disabled || isDragging || !hovering}
        />
        {props.renderItem?.(hovering)}
      </div>
    )
  }
)

export const SortableTreeviewGroupContainer = memo(
  function SortableTreeviewGroupContainer(
    props: SortableTreeviewContainerProps
  ) {
    const [hovering, setHovering] = useState(false)
    const {
      isDragging,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
    } = useSortable({
      id: props.id,
      disabled: props.disabled,
    })
    return (
      <div
        ref={setNodeRef}
        className='mr-3 flex flex-col'
        style={{
          ...props.style,
          ...parseTransform(transform, transition),
          zIndex: isDragging ? 1 : 0,
        }}
      >
        <div
          className='items-center grid grid-cols-[min-content_1fr]'
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
        >
          <DragHandle
            listeners={listeners}
            setActivatorNodeRef={setActivatorNodeRef}
            disabled={props.disabled || !hovering}
          />
          {props.renderHeader?.(hovering)}
        </div>
        {props.placeholder ? props.placeholder : props.children}
      </div>
    )
  }
)

function parseTransform(
  transform: Transform | null,
  transition?: string | null
) {
  return transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        transition: transition
          ? 'transform 140ms cubic-bezier(0.2, 0, 0, 1)'
          : undefined,
      }
    : undefined
}
