import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

type UploadFunction = (inputFile: File) => Promise<string | undefined> | null

// Allow drag and drop + paste images into Editor
// Import this extension in your editor's configuration
// Adapted from this discussion: https://gist.github.com/slava-vishnyakov/16076dff1a77ddaca93c4bccd4ec4521
export const CustomEventHandlers = (onUpload: UploadFunction) =>
  Extension.create({
    addProseMirrorPlugins() {
      return [
        new Plugin({
          key: new PluginKey('customEventHandlers'),
          props: {
            handlePaste: (_view, event) => {
              if (!onUpload) return
              const items = Array.from(event.clipboardData?.items || [])

              if (items.length) {
                for (const item of items) {
                  const file = item.getAsFile()
                  if (file && file.type.indexOf('image') === 0) {
                    event.preventDefault()
                    // Pasted item is an image
                    onUpload(file)?.then((src) => {
                      if (src) {
                        this.editor.chain().focus().setImage({ src }).run()
                      }
                      return
                    })
                  }
                }
              }

              return false
            },
            handleDrop: (_view, event) => {
              const files = event.dataTransfer?.files

              if (files && files.length) {
                event.preventDefault()

                onUpload(files[0])?.then((src) => {
                  if (src) {
                    this.editor.chain().focus().setImage({ src }).run()
                  }
                  return
                })
              }

              return false
            },
          },
        }),
      ]
    },

    name: 'CustomEventHandlers',
  })
