import { CheckOutline, MinusSolid } from '@motion/icons'
import { classed } from '@motion/theme'

import {
  type ComponentRef,
  forwardRef,
  type MouseEventHandler,
  type ReactNode,
  useEffect,
  useRef,
} from 'react'

/**
 * This is an internal only Checkbox meant to be used by Motion UI to build custom checkbox
 * (eg. Calendar checkboxes with different color, and Form Checkbox with default values)
 */

export type InternalCheckboxProps = {
  label?: NonNullable<ReactNode>
  children?: ReactNode
  labelHidden?: boolean
  checked: boolean | 'indeterminate'
  onChange: (checked: boolean) => void
  onClick?: MouseEventHandler<HTMLInputElement>
  disabled?: boolean
  className?: string
  size?: 'normal' | 'small'
}

/**
 * This component requires these themevar to be set to render properly:
 * c-border
 * c-border-hover
 * c-bg
 * c-border-checked
 * c-bg-checked
 * c-bg-checked-hover
 *
 * (eg. themevar-[c-bg=form-bg-default])
 */
export const InternalCheckbox = forwardRef<
  ComponentRef<typeof CheckboxWrapper>,
  InternalCheckboxProps
>(function InternalCheckbox(props, ref) {
  const {
    disabled = false,
    checked,
    onChange,
    onClick,
    label,
    labelHidden = false,
  } = props

  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputRef.current == null) return
    inputRef.current.indeterminate = checked === 'indeterminate'
  }, [checked])

  return (
    <CheckboxWrapper
      ref={ref}
      size={props.size}
      disabled={disabled}
      className={props.className}
    >
      <CheckboxInput
        ref={inputRef}
        type='checkbox'
        disabled={disabled}
        checked={checked === 'indeterminate' ? false : checked}
        onChange={(e) => {
          onChange(e.currentTarget.checked)
        }}
        onClick={onClick}
      />
      <CheckboxContainer size={props.size}>
        {checked === 'indeterminate' ? <MinusSolid /> : <CheckOutline />}
      </CheckboxContainer>
      <LabelText labelHidden={labelHidden}>{label ?? props.children}</LabelText>
    </CheckboxWrapper>
  )
})

export const CheckboxWrapper = classed('label', {
  base: `group inline-flex items-center gap-2 relative`,
  variants: {
    disabled: {
      true: `text-form-text-disabled cursor-not-allowed`,
      false: `text-form-text-default cursor-pointer`,
    },
    size: {
      normal: '',
      small: 'text-xs',
    },
  },
  defaultVariants: {
    disabled: false,
    size: 'normal',
  },
})

const CheckboxInput = classed('input', {
  base: `sr-only peer`,
})

const CheckboxContainer = classed('div', {
  base: `
    inline-flex items-center justify-center
    border rounded
    text-transparent peer-focus-visible:outline-none
    [&>[data-icon]]:w-3.5 [&>[data-icon]]:h-3.5

    border-[var(--c-border)]
    group-hover:peer-enabled:border-[var(--c-border-hover)]
    bg-[var(--c-bg)]

    peer-checked:text-form-icon-active
    peer-checked:bg-[var(--c-bg-checked)]
    peer-checked:border-[var(--c-border-checked)]

    peer-indeterminate:text-form-icon-active
    peer-indeterminate:bg-[var(--c-bg-checked)]
    peer-indeterminate:border-[var(--c-border-checked)]

    group-hover:peer-enabled:peer-checked:border-[var(--c-bg-checked-hover)]
    group-hover:peer-enabled:peer-checked:bg-[var(--c-bg-checked-hover)]

    peer-focus-visible:ring-[2px]
    peer-focus-visible:ring-form-border-focus
    peer-focus-visible:ring-offset-1

    peer-disabled:peer-checked:text-form-icon-disabled
    peer-disabled:peer-checked:border-form-bg-disabled
    peer-disabled:bg-form-bg-disabled
  `,
  variants: {
    size: {
      normal: 'w-4 h-4',
      small: 'w-2.5 h-2.5 rounded-sm',
    },
  },
  defaultVariants: {
    size: 'normal',
  },
})

const LabelText = classed('span', {
  variants: {
    labelHidden: { true: 'sr-only' },
  },
})
