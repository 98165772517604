import { isEqual } from '@motion/utils/core'

import { useEffect, useRef } from 'react'

import { useClosure } from './use-closure'

interface Options {
  /**
   * Define if the `onChange` handler should be called on the first render
   */
  triggerOnFirstRender: boolean
}

/**
 * Track the `value` and calls the `onChange` handler when the `value` changes.
 * The `value` is tracked with a deep comparison
 *
 * @param value - value to track
 * @param onChange - handler to invoke
 * @param options - Options to customize the behavior
 */
export function useOnValueChange<T>(
  value: T,
  onChange: (value: T, oldValue: T) => void,
  options: Partial<Options> = {}
): void {
  const { triggerOnFirstRender = false } = options

  const valueRef = useRef(value)
  const onChangeClosure = useClosure(onChange)

  useEffect(() => {
    if (triggerOnFirstRender) {
      onChangeClosure(value, value)
    }
    // Disabling as we want this only on first render!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const oldValue = valueRef.current

    if (isEqual(value, oldValue)) return

    valueRef.current = value
    onChangeClosure(value, oldValue)
  }, [value, onChangeClosure])
}
