const basePath = '/web/pm/workspaces/' as const
type GetTaskStaticURLProps = {
  workspaceId: string
  taskId: string
  origin?: string
}
export const getTaskStaticURL = ({
  workspaceId,
  taskId,
  origin = window.location.origin,
}: GetTaskStaticURLProps) => {
  const searchParams = new URLSearchParams({ task: taskId })
  return new URL(`${basePath}${workspaceId}?${searchParams}`, origin).toString()
}

type GetProjectStaticURLProps = {
  workspaceId: string
  projectId: string
}
export const getProjectStaticURL = ({
  workspaceId,
  projectId,
}: GetProjectStaticURLProps) => {
  const searchParams = new URLSearchParams({ project: projectId })
  return new URL(
    `${basePath}${workspaceId}?${searchParams}`,
    window.location.origin
  ).toString()
}
