import {
  type ApiTypes,
  type CommonApiDefinition,
  type CommonApiResponseDefinition,
} from './types'
import { bodyAllowed, getBaseHeaders, getUri } from './utils'

type TokenAccessor =
  | string
  | (() => Promise<string | null | undefined>)
  | null
  | undefined
export type FetchRequestContext = {
  token: TokenAccessor
  baseUri?: string
  headers?: Record<string, string>
  reqId?: string
}

export type FetchContext<TApi extends CommonApiResponseDefinition> =
  FetchRequestContext & {
    executor?: (
      fn: (uri: string, args: RequestInit) => Promise<Response>,
      ctx: ExecutorContext<TApi>
    ) => Promise<any>
  }

export type ExecutorContext<TApi extends CommonApiResponseDefinition> = {
  api: TApi
  args: ApiTypes<TApi>['args']
  uri: string
  req: RequestInit
  responseHandler: (res: Response) => Promise<any>
}

export async function dereferenceToken(accessor: TokenAccessor) {
  if (typeof accessor === 'function') return await accessor()
  return accessor
}

export async function buildFetchRequest<TArgs>(
  api: CommonApiDefinition<TArgs>,
  args: TArgs,
  ctx: FetchRequestContext,
  signal?: AbortSignal | null
): Promise<[string, RequestInit]> {
  const body = bodyAllowed(api.method)
    ? api.body?.(args) ?? JSON.stringify(args)
    : undefined

  const token = await dereferenceToken(ctx.token)

  const headers = {
    ...getBaseHeaders({ token, contentType: 'json', reqId: ctx.reqId }),
    ...(ctx.headers ?? {}),
  }

  const defaultOptions: RequestInit = {
    signal: signal,
    method: api.method,
    headers: headers,
  }
  const opts = api.fetchOptions?.(defaultOptions) ?? defaultOptions
  const uri = getUri(ctx.baseUri, api.uri, args)

  return [uri, { body, ...opts }]
}
