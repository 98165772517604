import { classed } from '@motion/theme'

import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { Button, type ButtonProps } from '../button'

type TabItem = {
  content: ReactNode
  value: string

  url?: string
  onAction?: () => void
  disabled?: boolean
}

export type ButtonTabsProps = {
  activeValue: TabItem['value']
  items: (TabItem | false)[]
  size?: ButtonProps['size']
  fullWidth?: ButtonProps['fullWidth']
}

export const ButtonTabs = (props: ButtonTabsProps) => {
  const { activeValue, items, size, fullWidth } = props

  return (
    <Wrapper fullWidth={fullWidth}>
      {items.map((x) => {
        if (!x) return null

        return (
          <Button
            // @ts-expect-error - need to override the hover/active style on the selected item
            className={twMerge(
              activeValue === x.value &&
                'hover:bg-[var(--b-bg)] hover:border-[var(--b-border)] active:bg-[var(--b-bg)] active:border-[var(--b-border)]'
            )}
            key={x.value}
            variant={activeValue === x.value ? 'outlined' : 'muted'}
            sentiment='neutral'
            url={x.url}
            disabled={x.disabled}
            onClick={() => x.onAction?.()}
            size={size}
            fullWidth={fullWidth}
          >
            {x.content}
          </Button>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = classed('div', {
  base: `
    rounded
    bg-semantic-neutral-bg-subtle
  `,
  variants: {
    fullWidth: { true: 'flex', false: 'inline-flex' },
  },
  defaultVariants: {
    fullWidth: 'false',
  },
})
