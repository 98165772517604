/* eslint react-refresh/only-export-components: ["warn"] */
import { FloatingTree, useFloatingNodeId } from '@floating-ui/react'
import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react'

type ModalIdsType = {
  modalId: string
  nodeId: string
}

type ModalStackContextValue = {
  pushModal: (type: ModalType, ids: ModalIdsType) => () => void
  getParentId: (modalId: string) => string | undefined
  getLastVisibleModalNodeId: () => string | null
  hasVisibleModals: boolean
  isPageModalStacked: (nodeId: string) => boolean
}

const defaultValue: ModalStackContextValue = {
  pushModal: () => () => undefined,
  getParentId: () => undefined,
  getLastVisibleModalNodeId: () => null,
  hasVisibleModals: false,
  isPageModalStacked: () => false,
}

const ModalStack = createContext(defaultValue)

type ModalStackProviderProps = {
  children: ReactNode
}

export type ModalType = 'page' | 'inline'
type Modal = { type: ModalType; modalId: string; nodeId: string }

/**
 * Because a lot of our modals are siblings rather than parent -> child relationship,
 * we need to manually tell Floating who is the parent of the child.
 * This is done automatically in here with this context keeping a stack of our modals
 * and giving the right parent id to any given modal via `usePushModalStack`
 */
export function ModalStackProvider({ children }: ModalStackProviderProps) {
  const [modalStack, setModalStack] = useState<Modal[]>([])
  const modalIdToParentId = useRef<Map<string, string | undefined>>(new Map())

  const getParentModalNodeId = useCallback(
    (modalId: string, modalStack: Modal[]) => {
      const hasKnownParent = modalIdToParentId.current.has(modalId)

      if (hasKnownParent) {
        return modalIdToParentId.current.get(modalId)
      }

      if (modalStack.find((s) => s.modalId === modalId)) return

      const lastNodeId =
        modalStack.length > 0
          ? modalStack[modalStack.length - 1].nodeId
          : undefined

      return lastNodeId
    },
    []
  )

  const pushModal = useCallback<ModalStackContextValue['pushModal']>(
    (type, { modalId, nodeId }) => {
      setModalStack((prev) => {
        const parentId = getParentModalNodeId(modalId, prev)
        modalIdToParentId.current.set(modalId, parentId)

        return [...prev, { type, modalId, nodeId }]
      })

      return () => {
        modalIdToParentId.current.delete(modalId)
        setModalStack((prev) => {
          const val = [...prev]
          val.pop()
          return val
        })
      }
    },
    [getParentModalNodeId]
  )

  const isPageModalStacked = useCallback(
    (nodeId: string) => {
      const pageStack = modalStack.filter((m) => m.type === 'page')

      if (pageStack.length < 2) return false

      const modalIndex = pageStack.findIndex((m) => m.nodeId === nodeId)
      return modalIndex < pageStack.length - 1
    },
    [modalStack]
  )

  const uiValue = useMemo<ModalStackContextValue>(() => {
    return {
      pushModal,
      getParentId: (modalId) => getParentModalNodeId(modalId, modalStack),
      getLastVisibleModalNodeId: () =>
        modalStack[modalStack.length - 1]?.nodeId ?? null,
      hasVisibleModals: modalStack.length > 0,
      isPageModalStacked,
    }
  }, [pushModal, modalStack, isPageModalStacked, getParentModalNodeId])

  return (
    <ModalStack.Provider value={uiValue}>
      <FloatingTree>{children}</FloatingTree>
    </ModalStack.Provider>
  )
}

export function useModalStackContext() {
  const { hasVisibleModals, getLastVisibleModalNodeId } = useContext(ModalStack)

  return {
    hasVisibleModals,
    getLastVisibleModalNodeId,
  }
}

export function usePushModalStack(type: ModalType, visible: boolean) {
  const { pushModal, getParentId, isPageModalStacked } = useContext(ModalStack)

  const modalId = useId()
  const parentId = getParentId(modalId)
  const nodeId = useFloatingNodeId(parentId)

  useEffect(() => {
    if (!visible) return

    return pushModal(type, { modalId, nodeId })
  }, [modalId, nodeId, pushModal, type, visible])

  return {
    nodeId,
    isPageModalStacked: isPageModalStacked(nodeId),
  }
}
