import { classed } from '@motion/theme'

export const NowIndicator = classed(
  'div',
  'relative border border-semantic-neutral-text-default',
  `
  before:block
  before:absolute
  before:rounded-full
  before:-left-[5px]
  before:-top-[4px]
  before:w-2
  before:h-2
  before:bg-semantic-neutral-text-default
  `
)
