/**
 * Return a new object containing the list of properties
 * @param obj object to pick the properties from
 * @param keys array of string
 * @returns a new object only containing the list of `keys`
 */
export function pick<T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
  const entries = keys.map((k) => [k, obj[k]])
  return Object.fromEntries(entries)
}
