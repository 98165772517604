import { parseDate } from '@motion/utils/dates'

/*
 * Given a list of stages, and a project start and due date, return the stages with the correct stage start and end dates
 */
export function getStagesWithDates<T extends { id: string; dueDate: string }>(
  stages: T[],
  { start, due }: { start: string | null; due: string | null }
): { start: string; due: string; stage: T }[] {
  // If there is a start date provided, use that as the start date for the first stage, otherwise use the stage due date
  const getStageStart = (index: number) => {
    if (index === 0) {
      return start ?? stages[index].dueDate
    }

    return stages[index - 1].dueDate
  }

  // If there is a due date provided, use that as the due date for the last stage
  const getStageEnd = (index: number) => {
    if (index === stages.length - 1 && due) {
      return due
    }

    return stages[index].dueDate
  }

  return stages.map((stage, index) => {
    const stageStart = getStageStart(index)
    const stageEnd = getStageEnd(index)

    return {
      stage,
      start: parseDate(stageStart).toISODate(),
      due: parseDate(stageEnd).toISODate(),
    }
  })
}
