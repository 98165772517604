import { type CustomFieldValidation } from './types'

export const select = {
  name: {
    maxLength: 200,
  },
  options: {
    maxLength: 100,
    maxOptions: 500,
    maxSelected: 1,
  },
} as const satisfies CustomFieldValidation
