import { DragSolid } from '@motion/icons'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { twMerge } from 'tailwind-merge'

type SortableRowProps = {
  id: string
  children: React.ReactNode
  size: 'regular' | 'small'
  rowClassName?: string
  renderDraggableRowSection: () => React.ReactNode
}

export const SortableRow = ({
  id,
  children,
  size,
  rowClassName,
  renderDraggableRowSection,
}: SortableRowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      className='flex items-center justify-between w-full'
      style={style}
    >
      <button
        ref={setActivatorNodeRef}
        {...listeners}
        className='shrink-0 cursor-grab flex items-center'
      >
        <DragSolid className='w-4 h-4 text-semantic-neutral-icon-subtle' />
        {renderDraggableRowSection()}
      </button>
      <div
        className={twMerge(
          'flex items-center',
          size === 'regular' && 'gap-3 pl-1 pr-4 py-3',
          size === 'small' && 'gap-1 pl-1 pr-3 py-1.5',
          rowClassName
        )}
      >
        {children}
      </div>
    </div>
  )
}
