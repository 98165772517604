import { useEffect, useRef } from 'react'

import { SHORTCUT_DELIMITER } from './constants'
import { useShortcutScope } from './use-shortcut-scope'

const IGNORED_TAGS = ['INPUT', 'TEXTAREA']

const defaultOptions: Options = {
  enabled: true,
  ignoreInput: false,
}

type Handler = (evt: KeyboardEvent) => void
type Options = {
  enabled: boolean
  ignoreInput: boolean
}

export function useShortcut(
  key: string,
  handler: Handler,
  options: Partial<Options> = {}
) {
  const { enabled, ignoreInput }: Options = { ...defaultOptions, ...options }

  const { isInScope } = useShortcutScope()
  const currentlyPressedRef = useRef(new Set<string>())
  const trackedRef = useRef({ handler, key })

  useEffect(() => {
    trackedRef.current = { handler, key }
  }, [handler, key])

  useEffect(() => {
    if (!enabled) return

    function addToPressedKey(key: string) {
      // wtf Mac We cannot trust you
      // We don't get any keyup events when meta is down, so we can't trust the data we have. Let's clear everything that is not a meta key
      // Only modifier keys are getting their key up events, that's why we keep them
      // can be played with this https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key#result
      if (currentlyPressedRef.current.has('meta')) {
        currentlyPressedRef.current.forEach((k) => {
          if (!isHotKeyModifier(k)) {
            currentlyPressedRef.current.delete(k)
          }
        })
      }
      currentlyPressedRef.current.add(key)
    }
    function removeFromPressedKey(key: string) {
      // wtf Mac https://stackoverflow.com/questions/11818637/why-does-javascript-drop-keyup-events-when-the-metakey-is-pressed-on-mac-browser
      // Can't trust you. Better to clear everything
      if (key === 'meta') {
        currentlyPressedRef.current.clear()
      } else {
        currentlyPressedRef.current.delete(key)
      }
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      // Doesn't trigger shortcuts for things that aren't in scope
      if (!isInScope()) return

      // Discard repeating keydown (basically keeping the key being pressed for a long time)
      if (event.repeat) return

      // Discard if focusing input and should ignore
      if (ignoreInput && isFocusedInput()) return

      const eventKey = convertEventKey(event)
      if (eventKey == null) {
        return
      }

      // Safe check to clear out the meta key from being pressed in case we don't have the right info
      // Rocket app doesn't send a meta key up event - https://dev-app.usemotion.com/web/pm/workspaces/u7_D-Iy8jdmn3IAvEkdfR?task=uf1_CEH3b5RK5IjdfcLwo
      if (currentlyPressedRef.current.has('meta') && !event.metaKey) {
        currentlyPressedRef.current.delete('meta')
      }

      // When the meta key is down, we don't get keyup events for other keys.
      // So we need to assume, when receiving a keypress, that all keys have been released.
      if (event.metaKey) {
        currentlyPressedRef.current.clear()
        addToPressedKey('meta')

        if (event.shiftKey) addToPressedKey('shift')
        if (event.altKey) addToPressedKey('alt')
      }

      addToPressedKey(eventKey)

      const { key, handler } = trackedRef.current
      const parsedKeys = key.split(SHORTCUT_DELIMITER).map(convertShortcutKey)
      const valid =
        parsedKeys.length === currentlyPressedRef.current.size &&
        parsedKeys.every((parsedKey) => {
          return currentlyPressedRef.current.has(parsedKey)
        })

      if (!valid) return

      event.stopPropagation()
      event.preventDefault()
      event.stopImmediatePropagation()

      handler(event)
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      const eventKey = convertEventKey(event)
      if (eventKey == null) {
        return
      }
      removeFromPressedKey(eventKey)
    }

    const handleWindowBlur = () => {
      currentlyPressedRef.current.clear()
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)
    window?.addEventListener('blur', handleWindowBlur)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
      window?.removeEventListener('blur', handleWindowBlur)

      // safe clear in case we clean before receiving the keyUp event
      // eslint-disable-next-line react-hooks/exhaustive-deps
      currentlyPressedRef.current.clear()
    }
  }, [enabled, ignoreInput, isInScope])
}

function isHotKeyModifier(key: string) {
  return ['meta', 'control', 'shift', 'alt'].includes(key)
}

function convertShortcutKey(key: string) {
  const isMac = window.navigator.userAgent.includes('Mac')

  const mapping: Record<string, string> = {
    cmd: 'meta',
    ctrl: 'control',
    mod: isMac ? 'meta' : 'control',
  }

  const lcKey = key.toLowerCase()
  return mapping[lcKey] ?? lcKey
}

function convertEventKey(kbEvent: KeyboardEvent): string | null {
  // Can't do anything with this
  if (kbEvent.key == null && kbEvent.code == null) {
    return null
  }

  const parsedKbKey = kbEvent.key
    .trim()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase()

  if (parsedKbKey === '') {
    return kbEvent.code.toLowerCase()
  }

  return parsedKbKey
}

function isFocusedInput() {
  const target = document.activeElement

  if (target == null || target.tagName == null) {
    return false
  }

  return (
    IGNORED_TAGS.includes(target.tagName) ||
    target.hasAttribute('contenteditable')
  )
}
