// Note: This module is shared across both BG and CS, so do not import any
// chromeApi modules

export type OnboardingType =
  | 'individual'
  | 'individual_no_card'
  | 'team'
  | 'team_invitee'
  | 'team_with_card'

export const newOnboardingPrePurchasePages = [
  'individual_or_team_prompt',
  'billing',
] as const

export const newOnboardingPostPurchasePages = [
  'work_email_prompt',
  '1_min_video',
  'connect_calendar',
  'choose_work_hours',
  'pm_tm_video',
  'create_team',
] as const

export const newOnboardingPages = [
  ...newOnboardingPrePurchasePages,
  ...newOnboardingPostPurchasePages,
] as const

export type NewOnboardingPage = (typeof newOnboardingPages)[number]

export type OnboardingPage =
  | 'invited-pm-member'
  | 'personalize'
  | 'calendar-setup'
  | 'team-setup'
  | 'task'
  | 'referral'
  | 'scheduler'
  | 'desktop-app'
  | 'shortcuts'
  | 'flexible-events'
  | 'task-tutorial'
  | 'scheduler-tutorial'
  | 'flexible-tutorial'

export type OnboardingProgressKey =
  | 'calendar'
  | 'calendarTasks'
  | 'tasks'
  | 'scheduler'
  // newOnboarding fields
  | 'billing'
  | 'individualOrTeam'
  | 'oneMinVideo'
  | 'connectCalendar'
  | 'chooseWorkHours'
  | 'pmTmvideo'
  | 'createTeam'
  | 'alreadyInTeam'
  | 'isPmOnboardingInProgress'

export type OnboardingProgress = Record<OnboardingProgressKey, boolean>

export const shouldOnboard = (
  onboardingProgress: Partial<OnboardingProgress>,
  hasTasks: boolean,
  didSkipOnboarding: boolean
) => {
  if (didSkipOnboarding) {
    return false
  }

  if (!onboardingProgress) {
    return true
  }

  // Force onboarding if user hasn't completed calendar onboarding
  if (
    Object.keys(onboardingProgress).length === 0 ||
    !onboardingProgress.calendar
  ) {
    return true
  }

  if (!onboardingProgress.tasks && !hasTasks) {
    return true
  }

  return false
}

export const resolveOnboardingPage = async (
  onboardingProgress: OnboardingProgress
): Promise<OnboardingPage> => {
  if (!onboardingProgress || Object.keys(onboardingProgress).length === 0) {
    return 'personalize'
  }

  switch (true) {
    case !onboardingProgress.calendar:
      return 'calendar-setup'
    case !onboardingProgress.tasks:
      return 'task-tutorial'
    default:
      return 'personalize'
  }
}
