import { type CustomFieldValidation } from './types'

export const multiSelect = {
  name: {
    maxLength: 200,
  },
  options: {
    maxLength: 100,
    maxOptions: 500,
    maxSelected: 10,
  },
} as const satisfies CustomFieldValidation
