export const USER_COLORS = [
  '#0B9C82',
  '#CF4519',
  '#CD1583',
  '#7957FD',
  '#1EA2CC',
  '#6A943F',
  '#3369BB',
  '#8F33BB',
  '#8C2828',
  '#AB7A30',
]

/**
 * Returns a predefined user color for a given identifier
 * @param identifier - user identifier to generate the color from
 * @returns
 */
// Ref: pmSliceUtils.ts -> pickTeamMemeberColor
export function getUserColor(identifier: string) {
  let hash = 0
  if (!identifier || identifier.length === 0) return USER_COLORS[hash]
  for (let i = 0; i < identifier.length; i++) {
    const chr = identifier.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }

  const colorIndex = Math.abs(hash) % USER_COLORS.length
  return USER_COLORS[colorIndex]
}
