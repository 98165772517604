import { useClosure } from '@motion/react-core/hooks'

import { useEffect, useState } from 'react'

import { Sentry } from '../../sentry'

declare global {
  interface WindowEventMap {
    'local-storage': CustomEvent
  }
}

function readValue<T>(key: string, initialValue: T) {
  if (key == null) return null
  try {
    const text = window.localStorage.getItem(key)
    return (text ? JSON.parse(text) : initialValue) ?? null
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`Error reading localStorage key "${key}":`, error)
    return initialValue ?? null
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LocalStorageTypes {}

export const useLocalStorage = <
  K extends keyof LocalStorageTypes,
  T extends LocalStorageTypes[K],
>(
  key: K,
  initialValue?: LocalStorageTypes[K]
) => {
  const [storedValue, setStoredValue] = useState<T | null>(() =>
    readValue(key, initialValue)
  )

  const setValue = useClosure(
    (value: T | ((val: T | null) => T | null) | null) => {
      const newValue =
        typeof value === 'function'
          ? (value as (val: T | null) => T | null)(storedValue)
          : value
      try {
        window.localStorage.setItem(key, JSON.stringify(newValue))
        setStoredValue(newValue)
        window.dispatchEvent(new CustomEvent('local-storage', { detail: key }))
      } catch (error) {
        Sentry.captureException(error, {
          tags: { position: 'useLocalStorage' },
        })
      }
    }
  )

  useEffect(() => {
    const handleStorageChange = (event: CustomEvent | StorageEvent) => {
      const eventKey = event instanceof StorageEvent ? event.key : event.detail
      if (eventKey !== key) {
        return
      }
      setStoredValue(readValue(key, initialValue))
    }
    // this only works for other documents, not the current one
    window.addEventListener('storage', handleStorageChange)
    window.addEventListener('local-storage', handleStorageChange)
    return () => {
      window.removeEventListener('storage', handleStorageChange)
      window.removeEventListener('local-storage', handleStorageChange)
    }
  }, [key, initialValue])

  return [storedValue, setValue] as const
}
