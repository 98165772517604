import { type EmailAccount } from '@motion/rpc/types'

export function sortEmailAccounts(
  emailAccounts: EmailAccount[],
  mainEmailAccountId?: string
) {
  return emailAccounts.sort((a, b) => {
    // Main calendar email should be at the top
    if (a.id === mainEmailAccountId) return -1
    if (b.id === mainEmailAccountId) return 1
    return a.email.localeCompare(b.email)
  })
}

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isEmailValid = (email: string) => emailRegex.test(email)

export const isPasswordValid = (password: string) => password.length >= 8
