export const isAsciiPhrase = (text: string): boolean => {
  return /^[0-9a-zA-Z\s.]+$/.test(text)
}

/**
 * Convert a multi-word string into one that is URL-friendly and easily readable
 */
export const toUrlReadableFormat = (text: string): string => {
  return text
    .trim()
    .replace(/[^0-9a-zA-Z\s.]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

/**
 * Validate that a url can be parsed.
 * This util acts as a ponyfill for URL.canParse
 *
 *
 * https://caniuse.com/mdn-api_url_canparse_static
 * Inspired by https://github.com/zloirock/core-js/blob/master/packages/core-js/modules/web.url.can-parse.js
 *
 * @param url
 * @param base
 * @returns boolean
 */
export const canParseUrl = (url: string, base?: string) => {
  if (url.length < 1) return false

  try {
    return !!new URL(url, base)
  } catch (error) {
    return false
  }
}
