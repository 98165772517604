import { type CustomFieldValidation } from './types'

export const number = {
  name: {
    maxLength: 200,
  },
  value: {
    maxLength: 15, // one less digit than Number.MAX_VALUE
  },
} as const satisfies CustomFieldValidation
