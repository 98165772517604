import { SearchableListItem } from './common'
import { type SelectableListItemProps } from './types'

export const SimpleItem = ({
  selected,
  children,
  ...etc
}: SelectableListItemProps) => {
  return (
    <SearchableListItem {...etc} role='menuitem' selected={selected}>
      {children}
    </SearchableListItem>
  )
}
