import { parseDate } from '@motion/utils/dates'

import { DateTime } from 'luxon'

import { type ProjectUpdateFields, type UpdatableProjectSchema } from '../types'

export function getProjectDueDateDateChangedFields(
  project: UpdatableProjectSchema
): Partial<ProjectUpdateFields> {
  const deadlineDate = project.dueDate
  const startDate = project.startDate

  const updates: Partial<ProjectUpdateFields> = {}

  if (startDate && deadlineDate) {
    const start = parseDate(startDate)
    const deadline = parseDate(deadlineDate)

    if (start > deadline) {
      const today = DateTime.now().startOf('day')
      const deadlineDateMinus1 = deadline.startOf('day').minus({ days: 1 })
      const newStart = deadlineDateMinus1 < today ? today : deadlineDateMinus1

      updates.startDate = newStart.toISODate()
    }
  }

  return updates
}
