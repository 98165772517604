import { classed } from '@motion/theme'

import {
  FloatingFocusManager,
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { type ReactNode } from 'react'

import { usePushModalStack } from '../../providers'

export type BaseModalProps = {
  visible: boolean
  disableOverlayClick?: boolean
  disableEscapeKey?: boolean
  expanded?: boolean
  fullScreen?: boolean
  children: ReactNode
  onClose: () => void
}

/**
 * @deprecated use `Modal` instead
 */
export const BaseModal = (props: BaseModalProps) => {
  const {
    visible,
    disableOverlayClick = false,
    disableEscapeKey = false,
    expanded, // explicitly keep undefined as default
    fullScreen = false,
    onClose,
    children,
    // @ts-expect-error - hidden property
    className,
  } = props

  const { nodeId } = usePushModalStack('inline', visible)

  const { context, refs } = useFloating({
    open: visible,
    onOpenChange: (open) => !open && onClose(),
    nodeId,
  })
  const dismiss = useDismiss(context, {
    enabled: visible,
    escapeKey: !disableEscapeKey,
    outsidePress: (event) => {
      const otherElements = [
        // Radix toasts use this attribute
        '[data-radix-collection-item]',
        // Motion desktop deeplink popup
        '[data-motion-desktop-deeplink-popup]',
        // Custom dialogs (schedule modal)
        '#motion-task-input-iframe',
        // Grammarly browser extension
        '[data-grammarly-part]',
      ]

      return (
        !disableOverlayClick &&
        !otherElements.some((name) =>
          (event.target as Element | undefined)?.closest(name)
        )
      )
    },
    bubbles: false,
  })
  const role = useRole(context)
  const { getFloatingProps } = useInteractions([dismiss, role])

  if (!visible) {
    return null
  }

  return (
    <FloatingNode id={nodeId}>
      <FloatingPortal>
        <FloatingOverlay
          className='bg-modal-overlay grid place-items-center w-screen h-screen z-[1]'
          lockScroll
        >
          <FloatingFocusManager context={context}>
            <Shell
              className={className}
              ref={refs.setFloating}
              expanded={expanded}
              fullScreen={fullScreen}
              {...getFloatingProps()}
            >
              {children}
            </Shell>
          </FloatingFocusManager>
        </FloatingOverlay>
      </FloatingPortal>
    </FloatingNode>
  )
}

const Shell = classed('div', {
  base: `
    bg-modal-bg
    rounded border border-modal-border
    flex flex-col
    transition-motion

    overflow-auto
    max-w-[min(1064px,calc(100vw-64px))]
    max-h-[min(768px,calc(100vh-64px))]

    focus-visible:outline-0
  `,
  variants: {
    expanded: {
      true: 'w-screen h-screen',
      false: 'max-w-[550px] max-h-[600px]',
    },
    fullScreen: {
      true: 'max-w-none max-h-none',
      false: '',
    },
  },
})
