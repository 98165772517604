import MobileDetect from 'mobile-detect'

export type RuntimeHost = 'browser' | 'desktop' | 'mobile' | 'tablet'
export type OperatingSystem =
  | 'linux'
  | 'macos'
  | 'windows'
  | 'iOS'
  | 'Android'
  | 'unknown'
export type Experience = 'browser' | 'mobile'

export type OperatingEnvironment = {
  host: RuntimeHost
  os: OperatingSystem
  experience: Experience
}

const mobileInfo = new MobileDetect(window.navigator.userAgent)

export const systemData: OperatingEnvironment = {
  host: getRuntimeHost(),
  os: getOperatingSystem(),
  experience: getExperience(),
}

export const getWindowData = () => {
  return {
    isElectron: systemData.host === 'desktop',
    isWindows: systemData.os === 'windows',
    isMac: systemData.os === 'macos',
    isMobile: systemData.host === 'mobile',
    isTablet: systemData.host === 'tablet',
  }
}

export function getMotionClient() {
  return getWindowData().isElectron ? 'desktop' : 'webapp'
}

export function isMobileExperience() {
  return systemData.experience === 'mobile'
}

function getOperatingSystem(): OperatingSystem {
  const platform = navigator.platform ?? 'unknown'
  if (platform.includes('Mac')) return 'macos'
  if (platform.includes('Win')) return 'windows'
  if (platform.includes('linux')) return 'linux'

  const mobileOS = mobileInfo.os()?.toLowerCase()
  if (!mobileOS) return 'unknown'

  if (mobileOS.includes('ios')) return 'iOS'
  if (mobileOS.includes('android')) return 'Android'

  return 'unknown'
}

function getRuntimeHost(): RuntimeHost {
  if (isElectron()) return 'desktop'
  if (isTablet()) return 'tablet'
  if (isMobileBrowser()) return 'mobile'
  return 'browser'
}

function getExperience(): Experience {
  if (isMobileBrowser()) return 'mobile'
  return 'browser'
}

function isElectron() {
  const agent = (navigator.userAgent ?? '').toLowerCase()
  return agent.includes(' electron/')
}

function isTablet() {
  return mobileInfo.tablet() != null
}

function isMobileBrowser() {
  return mobileInfo.mobile() != null && !isTablet()
}
