import { classed } from '@motion/theme'

import { forwardRef, type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export type FieldLabelProps = {
  label?: ReactNode
  labelHidden?: boolean
  children: ReactNode
  fullWidth?: boolean
  column?: boolean
}

export const FieldLabel = forwardRef<HTMLLabelElement, FieldLabelProps>(
  (props, ref) => {
    const {
      label,
      labelHidden = false,
      fullWidth = false,
      children,
      column,
    } = props
    const handleMouseDown = (e: React.MouseEvent<HTMLLabelElement>) => {
      if (
        e.target instanceof Element &&
        !(e.target instanceof HTMLInputElement) &&
        !(e.target instanceof HTMLTextAreaElement) &&
        e.target.contains(document.activeElement)
      ) {
        e.preventDefault()
      }
    }
    return (
      <label
        ref={ref}
        onMouseDown={handleMouseDown}
        className={twMerge(fullWidth && 'w-full', column && 'flex flex-col')}
      >
        <LabelText labelHidden={labelHidden}>{label}</LabelText>
        {children}
      </label>
    )
  }
)
FieldLabel.displayName = 'FieldLabel'

const LabelText = classed('span', {
  base: `
    text-semantic-neutral-text-subtle text-xs
    mb-1.5
  `,
  variants: {
    labelHidden: { true: 'sr-only' },
  },
})
