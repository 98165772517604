import { type SvgIcon } from '@motion/icons'
import { classed } from '@motion/theme'

import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type TreeviewItemProps = {
  label: ReactNode
  icon?: SvgIcon | null
  selected?: boolean
  highlighted?: boolean
  isButton?: boolean
}

export const TreeviewItem = ({
  label,
  icon,
  selected,
  highlighted,
  isButton,
}: TreeviewItemProps) => {
  const Icon = icon
  return (
    <TreeviewItemShell
      active={selected}
      highlighted={highlighted}
      isButton={isButton}
    >
      {Icon && (
        <div className='pl-1 pr-1.5 relative'>
          <Icon className='w-4' />
        </div>
      )}
      <div
        className={twMerge(
          'truncate w-full inline relative',
          isButton && 'h-full flex items-stretch'
        )}
      >
        {label}
      </div>
    </TreeviewItemShell>
  )
}

export const TreeviewItemShell = classed('div', {
  base: `
    flex items-center text-sm select-none cursor-pointer
    px-0.5 h-full
    text-sidebar-item-text-default
    before:absolute before:inset-0 before:content-[""] before:rounded hover:before:bg-sidebar-item-bg-hover
    group-hover:before:bg-sidebar-item-bg-hover
    [&_[data-icon]]:text-semantic-neutral-icon-subtle
  `,
  variants: {
    active: {
      true: 'text-sidebar-item-text-selected before:bg-sidebar-item-bg-selected  [&_[data-icon]]:text-semantic-neutral-icon-default',
      false: '',
    },
    isButton: {
      true: 'font-semibold',
      false: 'hover:text-sidebar-item-text-selected',
    },
    highlighted: {
      true: 'before:bg-sidebar-item-bg-hover',
      false: '',
    },
  },
  defaultVariants: {
    active: false,
    isButton: false,
    highlighted: false,
  },
})
