import { DateTime } from 'luxon'
import { RRule, rrulestr, type Weekday } from 'rrule'

export function recurrenceRuleToText(recurrence: string) {
  if (!recurrence) {
    return ''
  }

  if (recurrence === 'Custom') {
    return recurrence
  }

  return normalizeRRule(recurrence)
    .replace('every day', 'daily')
    .replace('every week ', 'weekly ')
    .replace('every month', 'monthly')
    .replace(/^[a-z]/, (char) => char.toUpperCase())
}
/**
 * Recurrence rules might not be identical strings but have the same recurrence behaviour
 * For example when a 2 recurrence rules for every weekday that have their days listed in a different order:
 * RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR should still match RRULE:FREQ=WEEKLY;BYDAY=FR,MO,TH,TU,WE
 * So we normalize the recurrence rule via the `toText()` method to compare them
 */
export function normalizeRRule(rruleString: string) {
  const rule = rrulestr(rruleString)
  return rule.toText()
}

const weekdayMap: Record<number, Weekday> = {
  1: RRule.MO,
  2: RRule.TU,
  3: RRule.WE,
  4: RRule.TH,
  5: RRule.FR,
  6: RRule.SA,
  7: RRule.SU,
}

const dailyRule = new RRule({ freq: RRule.DAILY })
const weekdaysRule = new RRule({
  freq: RRule.WEEKLY,
  byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
})

export function getRecurrenceRulesForDate(isoDateString: string) {
  const recurringRules: RRule[] = [dailyRule]

  const date = DateTime.fromISO(isoDateString)

  const weekday = weekdayMap[date.weekday]

  const weeklyRule = new RRule({ freq: RRule.WEEKLY, byweekday: weekday })
  recurringRules.push(weeklyRule)

  const weekOfDate = Math.ceil(date.day / 7)

  if (weekOfDate < 5) {
    const nthWeekdayRule = new RRule({
      freq: RRule.MONTHLY,
      byweekday: weekday.nth(weekOfDate),
    })
    recurringRules.push(nthWeekdayRule)
  }

  const isLastInstanceOfWeekday = date.plus({ days: 7 }).month !== date.month

  if (isLastInstanceOfWeekday) {
    const lastWeekdayRule = new RRule({
      freq: RRule.MONTHLY,
      byweekday: weekday.nth(-1),
    })
    recurringRules.push(lastWeekdayRule)
  }

  if (weekday !== RRule.SA && weekday !== RRule.SU) {
    recurringRules.push(weekdaysRule)
  }

  return recurringRules
}
