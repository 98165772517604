import { useEffect, useMemo, useRef, useState } from 'react'

export type Batch<T> = {
  append(item: T): void
}

export function useBatch<T>(cb: (items: T[]) => void, batchTime = 1): Batch<T> {
  const ref = useRef(cb)
  ref.current = cb

  const items = useRef<T[]>([])
  const timer = useRef<number | null>(null)
  const [signal, setSignal] = useState<object>(() => Object.create(null))

  useEffect(() => {
    if (items.current.length === 0) return

    const local = items.current
    items.current = []

    ref.current(local)
  }, [signal])

  return useMemo(() => {
    return {
      append(item: T) {
        items.current = [...items.current, item]
        if (timer.current) return
        timer.current = setTimeout(() => {
          timer.current = null
          setSignal(Object.create(null))
        }, batchTime) as unknown as number // fixes incompatibilities between node and web
      },
    }
  }, [batchTime])
}
