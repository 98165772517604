import { isEqual } from '@motion/utils/core'

import { useRef } from 'react'

type Comparator<T> = (left: T, right: T) => boolean

export const useMemoDeep = <T>(
  value: T,
  comparator: Comparator<T> = isEqual
) => {
  const ref = useRef(value)
  if (!comparator(value, ref.current)) {
    ref.current = value
  }
  return ref.current
}
