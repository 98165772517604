import { classed, type VariantProps } from '@motion/theme'

import { SHORTCUT_DELIMITER } from './constants'
import { useShortcut } from './use-shortcut'

const isMac = window.navigator.userAgent.includes('Mac')

const keyRenameMapping: Record<string, string> = {
  mod: isMac ? '⌘' : 'Ctrl',
  escape: 'esc',
  enter: '⮐',
  arrowup: '↑',
  arrowdown: '↓',
  alt: isMac ? 'Opt' : 'Alt',
  option: isMac ? 'Opt' : 'Alt',
  space: 'Space',
}

export type ShortcutProps = {
  shortcut: string
  size?: VariantProps<typeof KeyboardKey>['size']
}

export const Shortcut = ({ shortcut, size }: ShortcutProps) => {
  const keys = shortcut.split(SHORTCUT_DELIMITER)

  return (
    <span className='inline-flex gap-1'>
      {keys.map((k) => (
        <KeyboardKey key={k} size={size}>
          {keyRenameMapping[k.toLowerCase()] ?? k}
        </KeyboardKey>
      ))}
    </span>
  )
}

const KeyboardKey = classed('span', {
  base: `
    rounded-sm
    px-[3px]

    capitalize
    font-semibold

    bg-keyboardShortcut-default-bg
  `,
  variants: {
    size: {
      default: 'text-[10px]',
      small: 'text-[8px]',
    },
  },
  defaultVariants: {
    size: 'default',
  },
})

export type ShortcutListener = {
  shortcut: Parameters<typeof useShortcut>['0']
  handler: Parameters<typeof useShortcut>['1']
  options?: Parameters<typeof useShortcut>['2']
}
export const ShortcutListener = ({
  shortcut,
  handler,
  options,
}: ShortcutListener) => {
  useShortcut(shortcut, handler, options)

  return null
}
