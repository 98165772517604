import { filterAndRankMatches } from '@motion/ui-logic'

import { type MutableRefObject, useMemo } from 'react'

import { GrowOnlyDiv } from './grow-only-div'
import { SelectableListItem } from './selectable-item'

import { type SearchableListSelectProps } from '../types'
import { ITEM_HEIGHT, MAX_HEIGHT } from '../utils'

type SimpleListProps<T> = Pick<
  SearchableListSelectProps<T>,
  | 'renderItem'
  | 'computeKey'
  | 'computeSelected'
  | 'computeDisabled'
  | 'onSelect'
  | 'filter'
  | 'computeSearchValue'
> & {
  items: T[]

  activeValue: string | null
  setActiveValue(value: string | null): void

  search: string
  clearSearch(): void

  containerRef?: MutableRefObject<HTMLDivElement | null>
}
export const SimpleList = <T,>(props: SimpleListProps<T>) => {
  const {
    items,
    computeKey,
    computeSelected,
    computeDisabled,
    activeValue,
    setActiveValue,
    renderItem,
    onSelect,
    clearSearch,
    filter,
    search,
    computeSearchValue = computeKey,
    containerRef,
  } = props

  const filteredItems = useMemo(() => {
    // Return original items if there's no search term
    if (!search) return items
    return filter
      ? filter(search, items)
      : filterAndRankMatches(search, items, computeSearchValue)
  }, [search, items, filter, computeSearchValue])

  const resultCount = filteredItems.length
  const hasResults = resultCount !== 0

  return (
    <GrowOnlyDiv
      ref={containerRef}
      calculateWhenChange={search}
      style={{
        height: Math.min(
          Math.max(0, ITEM_HEIGHT * resultCount + (hasResults ? 9 : 0)),
          MAX_HEIGHT
        ),
        transitionProperty: 'height',
        transitionDuration: '75ms',
      }}
      className={`scrollbar-none w-full scroll-py-1 max-w-sm overflow-y-auto overflow-x-hidden ${
        hasResults ? 'py-1' : ''
      }`}
    >
      {filteredItems.map((item) => {
        const key = computeKey(item)
        const selected = computeSelected(item)

        return (
          <SelectableListItem
            key={key}
            // value needs to be unique, ideally we could read the key prop, but we can't so we need to "pass it" twice.
            value={key}
            active={activeValue === key}
            setActiveValue={setActiveValue}
            selected={selected}
            disabled={computeDisabled?.(item) ?? false}
            onSelect={() => {
              return onSelect(item, clearSearch)
            }}
          >
            {renderItem(item)}
          </SelectableListItem>
        )
      })}
    </GrowOnlyDiv>
  )
}
