import { type ReactNode, useEffect, useMemo, useRef, useState } from 'react'

import { SharedStateContext } from './context'
import { DEFAULT_FILTER, type FilterFn } from './filter'
import { DEFAULT_MERGE, IGNORE, type MergeFn } from './merge'
import { useStateSerializer } from './serializers'
import {
  type EventArgs,
  type InternalStateKey,
  type MapState,
  type ScopeOptions,
  type StateKey,
  type StateOptions,
} from './types'
import { useSharedStateContext } from './use-shared-state'

import { useBatch, useClosure, useEvent, useEventHandler } from '../hooks'

export type ScopedStateProviderProps = {
  children: ReactNode
  merge?: MergeFn
  filter?: FilterFn
  initialValues?: MapState

  name?: string
}

let _nextId = 1

export function SharedStateProvider(props: ScopedStateProviderProps) {
  const stableMerge = useClosure(props.merge ?? DEFAULT_MERGE)
  const stableFilter = useClosure(props.filter ?? DEFAULT_FILTER)
  const parentContext = useSharedStateContext()

  const serializer = useStateSerializer()
  const idRef = useRef(props.name ?? '')
  if (idRef.current === '') {
    idRef.current = `provider-${_nextId++}`
  }

  const changedEvent = useEvent<EventArgs>()
  const batch = useBatch<EventArgs<unknown>>((events) => {
    // Batch the events by merging items with the same key
    const batchedEvents = events.reduce((acc, event) => {
      acc.set(event.key, event)
      return acc
    }, new Map<StateKey<unknown>, EventArgs<unknown>>())
    batchedEvents.forEach((event) => changedEvent.fire(event))
  })

  const [state] = useState(
    () => new Map<StateKey<unknown>, unknown>(props.initialValues)
  )

  const api = useMemo(() => {
    const toObject: () => { [symbol: symbol]: unknown } = () =>
      Array.from(state.entries()).reduce((obj, [key, value]) => {
        obj[key.key] = value
        return obj
      }, parentContext.all())

    const api = {
      id: idRef.current,
      all() {
        return toObject()
      },
      keys() {
        const set = new Set(parentContext.keys())
        for (const key of state.keys()) {
          set.add(key)
        }
        return Array.from(set)
      },
      has(key: StateKey<any>) {
        return state.has(key) || parentContext.has(key)
      },
      hasOwn(key: StateKey<any>) {
        return state.has(key)
      },
      get<T>(key: InternalStateKey<T>, opts: ScopeOptions = {}): T {
        if (opts.scope && opts.scope !== idRef.current) {
          return parentContext.get(key, opts)
        }

        const shouldFilter = stableFilter(key)
        if (shouldFilter === false) {
          return parentContext.get(key, opts)
        }

        if (state.has(key as StateKey<unknown>)) {
          return state.get(key as StateKey<unknown>) as T
        }

        if (serializer && key.deserialize) {
          const loaded = serializer.load(key)
          if (loaded !== undefined) {
            this.set(key, loaded, { notify: false })
            return loaded
          }
        }

        return parentContext.get(key)
      },
      set<T>(key: InternalStateKey<T>, value: T, options?: StateOptions) {
        const { notify = true, scope } = options ?? {}

        if (scope && scope !== idRef.current) {
          return parentContext.set(key, value, options)
        }

        const shouldFilter = stableFilter(key)
        if (shouldFilter === false && parentContext != null) {
          return parentContext.set(key, value, options)
        }

        const previousValue = state.get(key as StateKey<unknown>) as T
        state.set(key as StateKey<unknown>, value)

        if (serializer && key.serialize) {
          serializer.save(key, value)
        }

        if (notify && !key.equals(previousValue, value)) {
          const effectiveValue =
            value === undefined ? parentContext.get(key) : value
          batch.append({ key: key as StateKey<unknown>, value: effectiveValue })
        }
      },
      clear<T>(key: StateKey<T>, options?: StateOptions) {
        const { notify = true } = options ?? {}
        state.delete(key as StateKey<unknown>)
        if (notify) {
          batch.append({ key: key as StateKey<unknown>, value: undefined })
        }
      },

      refresh(options?: StateOptions) {
        parentContext.keys().forEach((key) => {
          if (stableFilter(key) === false) return
          const loadedValue = serializer?.load(key)
          const value = stableMerge(
            { key, value: loadedValue ?? parentContext.get(key) },
            api
          )
          if (value === IGNORE) return
          api.set(key, value, options)
        })
      },

      event: changedEvent,
    }

    api.refresh({ notify: false })

    return api
    // We are not putting in 'props.initialValues' in the dependency list
    // because we only want it to take effect for the first render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedEvent])

  useEventHandler(
    parentContext.event,
    (event) => {
      // If the current scope shouldn't have the key, then forward the change event
      if (stableFilter(event.key) === false) {
        return batch.append(event)
      }

      // merge data if needed
      const mergedValue = stableMerge(event, api)

      // if we are ignoring the value, then forward the event
      if (mergedValue === IGNORE) {
        return batch.append(event)
      }

      // Otherwise set the value
      api.set(event.key, mergedValue)
    },
    [parentContext.event]
  )

  useEffect(() => {
    api.refresh({ notify: true })
  }, [api, props.merge])

  return (
    <SharedStateContext.Provider value={api}>
      {props.children}
    </SharedStateContext.Provider>
  )
}
