import { ExclamationTriangleSolid, RefreshSolid } from '@motion/icons'
import { classed, type VariantProps } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'
import {
  formatTimeRange,
  SHOW_TIME_ABOVE_DURATION_MINUTES,
} from '@motion/ui-logic'

import { DateTime } from 'luxon'
import {
  type CSSProperties,
  forwardRef,
  type MouseEventHandler,
  useMemo,
} from 'react'

import { EventColorBarsGrid } from './event-color-bars-grid'
import {
  getDurationEvent,
  getNameLineCount,
  SHORT_DURATION_THRESHOLD,
} from './utils'

import { CalendarPalette } from '../palette'

export interface CalendarEventProps
  extends Omit<VariantProps<typeof Event>, 'multiCalendar'>,
    CalendarPalette {
  startTime: Date
  endTime: Date
  allDay?: boolean
  past?: boolean
  name?: string
  attendeesDeclined?: boolean
  otherCalendars?: CalendarPalette[]
  eventLocation?: string
  isRecurring: boolean
  onContextMenu?: MouseEventHandler<HTMLDivElement>
}

export const CalendarEvent = forwardRef<HTMLDivElement, CalendarEventProps>(
  function CalendarEvent(props, ref) {
    const {
      attendeesDeclined,
      colorId,
      colorHue,
      startTime,
      endTime,
      allDay = false,
      past = false,
      name = '',
      otherCalendars = [],
      eventLocation,
      isRecurring,
      ...rest
    } = props

    const duration = useMemo(
      () => getDurationEvent(startTime, endTime),
      [startTime, endTime]
    )

    const hasOtherCalendars = otherCalendars.length > 0
    const startTimeISO = startTime.toISOString()
    const endTimeISO = endTime.toISOString()

    const showName = name.length > 0
    const showTime =
      !allDay && (!showName || duration >= SHOW_TIME_ABOVE_DURATION_MINUTES)
    const height = allDay ? '20px' : '100%'

    const NameLabel = (
      <Name
        style={
          {
            '--name-lines': getNameLineCount(duration),
          } as CSSProperties
        }
      >
        {name}
      </Name>
    )

    const renderEvent = ({ past }: { past: CalendarEventProps['past'] }) => (
      <Event {...rest} multiCalendar={hasOtherCalendars} style={{ height }}>
        <EventOverlays past={past} multiCalendar={hasOtherCalendars} />
        <TextContainer short={duration < SHORT_DURATION_THRESHOLD}>
          {showName && (
            <NameAndIconContainer>
              <Tooltip
                asChild
                renderContent={() => {
                  return (
                    <div className='flex flex-col gap-2 items-start text-left'>
                      {attendeesDeclined && <p>All attendees declined</p>}
                      <p>{name}</p>
                      <p className='text-xs'>
                        {formatTimeRange(
                          DateTime.fromISO(startTimeISO),
                          DateTime.fromISO(endTimeISO)
                        )}
                      </p>
                    </div>
                  )
                }}
              >
                {attendeesDeclined ? (
                  <DeclinedLabel showTime={showTime}>
                    <ExclamationTriangleSolid className='h-3 w-3 flex-shrink-0 mt-0.5' />
                    {NameLabel}
                  </DeclinedLabel>
                ) : (
                  NameLabel
                )}
              </Tooltip>
              {isRecurring && (
                <Tooltip asChild content='Recurring event'>
                  <RefreshSolid className='shrink-0 self-start mt-0.5 w-2.5 h-2.5' />
                </Tooltip>
              )}
            </NameAndIconContainer>
          )}
          {showTime && (
            <TimeAndLocationWrapper
              duration={duration}
              endTimeISO={endTimeISO}
              startTimeISO={startTimeISO}
              eventLocation={eventLocation}
            />
          )}
        </TextContainer>
      </Event>
    )

    return (
      <CalendarPalette colorId={colorId} colorHue={colorHue}>
        {hasOtherCalendars ? (
          <EventColorBarsGrid otherCalendars={otherCalendars} roundFirst>
            {renderEvent({ past })}
          </EventColorBarsGrid>
        ) : (
          renderEvent({ past })
        )}
      </CalendarPalette>
    )
  }
)

interface TimeAndLocationProps {
  duration: number
  startTimeISO: string
  endTimeISO: string
  eventLocation?: string
}

function TimeAndLocationWrapper({
  duration,
  startTimeISO,
  endTimeISO,
  eventLocation,
}: TimeAndLocationProps) {
  /**
   * Doesn't have location or under 30 mins
   */
  if (!eventLocation || duration < 30) {
    return (
      <TimeAndLocation startTimeISO={startTimeISO} endTimeISO={endTimeISO} />
    )
  }

  /**
   * Has location and between 30 and 60 mins
   */
  if (duration >= 30 && duration < 60) {
    return (
      <TimeAndLocation
        startTimeISO={startTimeISO}
        endTimeISO={endTimeISO}
        eventLocation={eventLocation}
        inline
      />
    )
  }

  /**
   * Has location and >60 mins
   */
  return (
    <TimeAndLocation
      startTimeISO={startTimeISO}
      endTimeISO={endTimeISO}
      eventLocation={eventLocation}
    />
  )
}

const Event = classed('div', {
  base: `
  group/event-item
  min-w-full
  rounded
  relative
  cursor-pointer
  border
  border-transparent

  before:block
  before:absolute
  before:rounded-l
  before:-left-px
  before:-top-px
  before:-bottom-px
  before:w-[4px]
  before:bg-palette-highlight-subtle

  `,
  variants: {
    multiCalendar: {
      true: 'rounded-l-none before:rounded-l-none',
    },
    availability: {
      default: 'bg-calendar-palette-bg-default',
      free: `
        bg-calendar-bg-default
        border-calendar-palette-border-default
        border
      `,
    },
    selected: {
      true: `
        bg-calendar-palette-highlight-default
        border-calendar-palette-highlight-default
        before:w-0
      `,
    },
    rsvp: {
      default: `
        border
        border-dashed
        border-calendar-palette-border-default
      `,
      maybe: `
        border
        border-dashed
        border-calendar-palette-border-default
      `,
      no: `
        border
        border-dashed
        border-calendar-palette-border-default
      `,
    },
  },
  compoundVariants: [
    {
      selected: false,
      rsvp: 'default',
      className:
        'bg-event-stripes from-calendar-bg-default to-calendar-palette-stripe-default',
    },
    {
      selected: false,
      rsvp: 'maybe',
      className:
        'bg-event-stripes from-calendar-bg-default to-calendar-palette-stripe-default',
    },
    {
      selected: false,
      rsvp: 'no',
      className: 'bg-calendar-bg-default',
    },
  ],
  defaultVariants: {
    selected: false,
    availability: 'default',
  },
  dataAttributes: ['rsvp', 'selected'],
})

const EventOverlays = classed(
  'div',
  'relative absolute -inset-px',
  'pointer-events-none rounded-sm',
  '[[data-selected]_&]:invisible [[data-selected]:hover_&]:invisible',
  {
    variants: {
      past: {
        true: `
          opacity-50 bg-calendar-bg-default
          group-hover/event-item:opacity-15 group-hover/event-item:bg-calendar-palette-highlight-default
        `,
        false:
          'invisible group-hover/event-item:visible opacity-15 bg-calendar-palette-highlight-default',
      },
      multiCalendar: {
        true: 'rounded-l-none',
      },
    },
  }
)

const TextContainer = classed(
  'div',
  'flex h-full pl-[7px] pr-1 text-[11px] text-calendar-palette-text-default font-medium',
  '[[data-selected]_&]:text-calendar-palette-text-selected',
  {
    variants: {
      short: {
        true: 'items-center leading-none',
        false: 'flex-col gap-0.5 leading-4',
      },
    },
  }
)

const DeclinedLabel = classed('div', {
  base: 'flex items-center gap-1 min-w-0',
  variants: {
    showTime: {
      true: 'items-start',
    },
  },
  defaultVariants: {
    showTime: false,
  },
})

const Name = classed(
  'div',
  'font-regular line-clamp-[--name-lines] pr-1',
  `[[data-rsvp='no']_&]:line-through`
)

const NameAndIconContainer = classed(
  'div',
  'flex items-center w-full justify-between'
)

const TimeLocationContainer = classed(
  'div',
  `text-[9px] overflow-hidden gap-0.5 flex flex-col font-thin`
)
const Time = classed('span', {
  base: 'pr-1 leading-3',
  variants: {
    truncate: { true: `truncate`, false: `whitespace-nowrap` },
  },
  defaultVariants: { truncate: false },
})
const Location = classed('span', `whitespace-nowrap leading-3`)

function TimeAndLocation({
  startTimeISO,
  endTimeISO,
  eventLocation,
  inline,
}: Omit<TimeAndLocationProps, 'duration'> & { inline?: true }) {
  return (
    <TimeLocationContainer>
      <Time>
        {formatTimeRange(
          DateTime.fromISO(startTimeISO),
          DateTime.fromISO(endTimeISO)
        )}
        {eventLocation != null && inline != null && (
          <Location>{`, ${eventLocation}`}</Location>
        )}
      </Time>
      {eventLocation != null && inline == null && (
        <Location>{eventLocation}</Location>
      )}
    </TimeLocationContainer>
  )
}
