import {
  getDurationfromDates,
  SHOW_TIME_ABOVE_DURATION_MINUTES,
} from '@motion/ui-logic/calendar'

const MIN_DURATION_HEIGHT = 15
const NAME_LINE_HEIGHT = 16

export const SHORT_DURATION_THRESHOLD = 20

export function getDurationEvent(startTime: Date, endTime: Date) {
  return getDurationHeight(getDurationfromDates(startTime, endTime))
}

export function getDurationHeight(duration: number) {
  return Math.max(MIN_DURATION_HEIGHT, duration)
}

export function getNameLineCount(duration: number) {
  if (duration <= SHOW_TIME_ABOVE_DURATION_MINUTES) {
    return 1
  }

  const remaining = duration - SHOW_TIME_ABOVE_DURATION_MINUTES
  return 1 + Math.floor(remaining / NAME_LINE_HEIGHT)
}
