import { Link } from '@tiptap/extension-link'
import { Paragraph } from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import { Text } from '@tiptap/extension-text'
import { Underline } from '@tiptap/extension-underline'
import { type Extensions, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useEffect } from 'react'

import { CustomImageExtension, SingleLineDocument } from '../extensions'

type Options = {
  className?: string
  value: string
  onChange?(value: string): void
  onBlur?(value: string, event: FocusEvent): void
  extensions?: Extensions
  disableImageUpload?: boolean
}

function useBaseRichTextEditor({
  className = '',
  value,
  onChange,
  onBlur,
  extensions = [],
  disableImageUpload = false,
}: Options) {
  const editor = useEditor({
    content: value,
    onBlur: ({ editor, event }) => {
      if (onBlur != null) {
        const html = editor.getHTML()
        onBlur(html, event)
      }
    },
    onUpdate: ({ editor }) => {
      if (onChange != null) {
        const html = editor.getHTML()
        onChange(html)
      }
    },
    editorProps: {
      attributes: {
        class: `focus:outline-none ${className}`,
      },
      handleDOMEvents: {
        ...(disableImageUpload
          ? {
              drop: (view, e) => {
                e.preventDefault()
              },
            }
          : {}),
      },
    },
    extensions,
  })

  useEffect(() => {
    if (editor && editor.getHTML() !== value) {
      // Context: https://github.com/usemotion/motion/pull/3701
      // Save cursor position to restore it after setContent
      const { from, to } = editor.state.selection
      const newFrom = Math.min(from, editor.state.doc.content.size)
      const newTo = Math.min(to, editor.state.doc.content.size)

      // Set new content also reset the cursor position to last.
      editor.commands.setContent(value)

      editor.commands.setTextSelection({ from: newFrom, to: newTo })
    }
  }, [editor, value])

  return editor
}

export function useSingleLineRichTextEditor({
  extensions = [],
  placeholder,
  ...rest
}: Options & { placeholder: string }) {
  return useBaseRichTextEditor({
    ...rest,
    extensions: [
      SingleLineDocument,
      Text,
      Placeholder.configure({
        placeholder,
      }),
      extensions.some((e) => e.name === 'paragraph') ? null : Paragraph,
      ...extensions,
    ].filter(Boolean) as Extensions,
  })
}

export function useRichTextEditor({
  extensions = [],
  placeholder,
  ...rest
}: Options & { placeholder: string }) {
  return useBaseRichTextEditor({
    ...rest,
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2],
        },
      }),
      Placeholder.configure({
        placeholder,
      }),
      Underline,
      Link.configure({
        // Autolink conflicts with copy/paste links with `text/html` type
        autolink: false,
      }),
      CustomImageExtension,
      TaskList,
      TaskItem,
      ...extensions,
    ],
  })
}
