import { isEqual } from '@motion/utils/core'

import { useRef } from 'react'

import { useClosure } from './use-closure'
import { useForceUpdate } from './use-force-update'

const UNSET = Symbol()
export function useDependantState<T>(
  fn: (prev: NoInfer<T | undefined>) => T,
  deps: unknown[],
  { freezeDependencyUpdates }: { freezeDependencyUpdates?: boolean } = {}
) {
  const prevDependencies = useRef(deps)

  // @ts-expect-error - initial value
  const value = useRef<T>(UNSET)
  if (
    value.current === UNSET ||
    (dependenciesChanged(prevDependencies.current, deps) &&
      !freezeDependencyUpdates)
  ) {
    const newValue = fn(value.current === UNSET ? undefined : value.current)
    value.current = newValue
    prevDependencies.current = deps
  }

  const update = useForceUpdate()

  const setValue = useClosure((setter: T | ((prev: T) => T)) => {
    const newValue =
      typeof setter === 'function'
        ? // @ts-expect-error - will be a function
          setter(value.current)
        : setter
    if (!isEqual(newValue, value.current)) {
      value.current = newValue
      update()
    }
  })

  return [value.current, setValue] as const
}

function dependenciesChanged(prev: unknown[], current: unknown[]) {
  if (prev.length !== current.length) return true
  return !prev.every((d, i) => d === current[i])
}
