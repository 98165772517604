import { initialize } from './initialize'

// eslint-disable-next-line @motion/import-path
export { getRedirectResult, type User } from 'firebase/auth'
// eslint-disable-next-line @motion/import-path
export { type DocumentSnapshot } from 'firebase/firestore'
// eslint-disable-next-line @motion/import-path
export { FirebaseError } from 'firebase/app'

export const firebase = initialize()
