import {
  type DateFilterSchema,
  type IdFilterSchema,
  type Inclusion,
  type ViewDateFilterSchema,
} from '@motion/zod/client'

import { type DataFilters } from './types'

import { type EntityFilterState } from '../state'

export function intersection(...values: string[][]): string[] {
  const nonEmpty = values.filter((s) => s.length > 0)
  const unique = Array.from(new Set(nonEmpty.flat()))
  return unique.reduce((acc, cur) => {
    const count = values.filter((v) => v.some((v1) => v1 === cur)).length

    if (count === values.length) {
      acc.push(cur)
    }

    return acc
  }, [] as string[])
}

export function toDataFilter(state: EntityFilterState): DataFilters {
  return {
    tasks: state.tasks.filters,
    projects: state.projects.filters,
    workspaces: state.workspaces.filters,
  }
}

export function normalizeToDataFilter(
  stateOrFilter: EntityFilterState | DataFilters
): DataFilters {
  return isDataFilter(stateOrFilter)
    ? stateOrFilter
    : toDataFilter(stateOrFilter)
}

function isDataFilter(
  stateOrFilter: EntityFilterState | DataFilters
): stateOrFilter is DataFilters {
  return !('ordered' in stateOrFilter.tasks)
}

type CompletedFilterRequires = {
  statusIds?: IdFilterSchema | null | undefined
  completedTime?: DateFilterSchema | ViewDateFilterSchema | null | undefined
  completed?: Inclusion | null | undefined
}

export function getCompletedFilter(filter: CompletedFilterRequires): Inclusion {
  if (filter.statusIds != null) return 'include'
  if ('completedTime' in filter && filter.completedTime != null)
    return 'include'
  return filter.completed ?? 'exclude'
}
