import { useEffect, useRef } from 'react'

import { useClosure } from './use-closure'

/**
 * useOnceWhen will execute the provided `callback` the first time that the `condition` evaluates to true.
 * Its main usage is when you need to execute something only once, but you require some state to be setup first
 * @param condition the condition to check if the callback should be called
 * @param callback the function to execute
 */
export const useOnceWhen = (condition: boolean, callback: () => void) => {
  const hasRun = useRef(false)

  const stable = useClosure(callback)

  useEffect(() => {
    if (hasRun.current) return
    if (!condition) return
    hasRun.current = true
    stable()
  }, [condition, stable])
}
