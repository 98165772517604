import { canParseUrl } from '@motion/utils/string'

import { v4 as uuidv4 } from 'uuid'

import { type HttpMethod, type UriFn } from './types'

export function normalizeUri<TArgs>(
  uriAccessor: UriFn<TArgs> | string,
  args: TArgs
) {
  const raw = typeof uriAccessor === 'string' ? uriAccessor : uriAccessor(args)

  if (typeof raw === 'string') {
    return raw
  }

  // Remove keys that are undefined
  const withStringValues =
    typeof raw.search === 'string'
      ? raw.search
      : (Object.fromEntries(
          Object.entries(raw.search)
            .map(([key, value]) =>
              value === undefined ? [] : [key, String(value)]
            )
            .filter(([key, value]) => key && value !== undefined)
        ) as Record<string, string>)

  const search = new URLSearchParams(withStringValues)
  if (Array.from(search.keys()).length === 0) {
    return raw.pathname
  }
  return raw.pathname + '?' + search.toString()
}

export function getUri<TArgs>(
  baseUri: string | null | undefined,
  uriAccessor: UriFn<TArgs> | string,
  args: TArgs
) {
  const relativeUri = normalizeUri(uriAccessor, args)

  if (baseUri == null || canParseUrl(relativeUri)) {
    return relativeUri
  }

  return new URL(relativeUri, baseUri).toString()
}

// TODO: DELETE requests should not have a body
// however some endpoints are relying on this
export function bodyAllowed(method: HttpMethod) {
  return method !== 'GET'
}

export function parseBody<T>(res: Response): Promise<T> {
  const contentType = res.headers.get('content-type') || 'text/plain'

  if (
    contentType.includes('application/json') ||
    contentType.includes('+json')
  ) {
    return res.json() as Promise<T>
  }

  return res.text() as Promise<T>
}

type BaseHeaders = {
  token?: string | null | undefined
  body?: string | undefined
  contentType?: 'json'
  reqId?: string
}

export const getBaseHeaders = ({
  token,
  contentType,
  reqId = uuidv4(),
}: BaseHeaders) => ({
  accept: 'application/json; charset=utf-8',
  'x-request-id': reqId,
  ...(contentType === 'json'
    ? {
        'content-type': 'application/json; charset=utf-8',
      }
    : {}),
  ...(token ? { authorization: `Bearer ${token}` } : {}),
})
