// Copied from https://observablehq.com/@mbostock/localized-number-parsing

type ParseOptions = {
  mode?: 'loose' | 'strict'
}

export const NUMBER_RE = /([-+]?((?:\d+(?:\.\d*)?)|(?:\.\d*)))/i

class NumberParserCore {
  private _group: RegExp
  private _decimal: RegExp
  private _numeral: RegExp
  private _index: (c: string) => string

  constructor(locale: string = window.navigator.language) {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6)
    const numerals = [
      ...new Intl.NumberFormat(locale, { useGrouping: false }).format(
        9876543210
      ),
    ].reverse()
    const index = new Map(numerals.map((d, i) => [d, i]))
    this._group = new RegExp(
      `[${parts.find((d) => d.type === 'group')?.value}]`,
      'g'
    )
    this._decimal = new RegExp(
      `[${parts.find((d) => d.type === 'decimal')?.value}]`
    )
    this._numeral = new RegExp(`[${numerals.join('')}]`, 'g')
    this._index = (d) => String(index.get(d) ?? d)
  }
  parse(text: string, opts: ParseOptions = { mode: 'loose' }) {
    const textValue = this.cleanLocalizedString(text)

    const match = NUMBER_RE.exec(textValue)
    const extracted = match ? match[1] : textValue

    return opts.mode === 'loose' ? parseFloat(extracted) : +extracted
  }

  stripInvalidChars(text: string) {
    const startsWithDash = text.startsWith('-')

    const cleaned = this.cleanLocalizedString(text)
      // Remove all invalid characters
      .replace(/[^0-9.]+/g, '')
    return startsWithDash ? '-' + cleaned : cleaned
  }

  private cleanLocalizedString(text: string) {
    return text
      .trim()
      .replace(this._group, '')
      .replace(this._decimal, '.')
      .replace(this._numeral, this._index)
  }
}

const parsers = new Map<string, NumberParserCore>()
const getParser = (locale: string) => {
  const found = parsers.get(locale)
  if (found != null) return found
  const newParser = new NumberParserCore(locale)
  parsers.set(locale, newParser)
  return newParser
}

export const NumberParser = {
  parse(value: string, locale = window.navigator.language) {
    const parser = getParser(locale)
    return parser.parse(value)
  },
  stripInvalidChars(value: string, locale = window.navigator.language) {
    const parser = getParser(locale)
    return parser.stripInvalidChars(value)
  },
}
