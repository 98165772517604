import { type ReactNode } from 'react'

import { LinkContext, type LinkLikeComponent } from './link-context'
import { ModalStackProvider } from './modal-stack'
import { ThemeResolver, type ThemeResolverProps } from './theme-resolver'

type MotionUIProviderProps = {
  linkComponent?: LinkLikeComponent
  theme?: ThemeResolverProps['theme']
  children: ReactNode
}

export function MotionUIProvider({
  linkComponent,
  theme,
  children,
}: MotionUIProviderProps) {
  return (
    <ThemeResolver theme={theme}>
      <LinkContext.Provider value={linkComponent}>
        <ModalStackProvider>{children}</ModalStackProvider>
      </LinkContext.Provider>
    </ThemeResolver>
  )
}
