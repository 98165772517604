export type IterableKeys<T extends object> = {
  [K in keyof T]: T[K] extends string | number | undefined | null ? K : never
}[keyof T]

export function createLookupBy<T, R = T>(
  arr: readonly T[],
  keyAccessor: (item: T) => string | undefined,
  mapping?: (item: T) => R
) {
  return arr.reduce<Record<string, R>>((acc, item) => {
    const keyValue = keyAccessor(item)
    if (keyValue != null) {
      acc[keyValue] = mapping ? mapping(item) : (item as unknown as R)
    }
    return acc
  }, {})
}

export function createLookupById<T extends { id?: string }>(
  items: T[]
): Record<string, T> {
  return createLookupBy(items, (item) => item.id)
}

export function createLookupByKey<
  T extends Record<string, any>,
  K extends IterableKeys<T>,
  R = T,
>(arr: T[], key: K, mapping?: (value: T) => R): { [key: string]: R } {
  return createLookupBy(arr, (item) => item[key], mapping)
}
