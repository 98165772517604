import { createKey, defineApi } from '../../core'
import { type RouteTypes } from '../types'

export const queryKeys = {
  root: createKey('personal'),
  archive: () => createKey(queryKeys.root, 'archive'),
}

export type GetArchivedTasks =
  RouteTypes<'PersonalTasksController_getPersonalArchivedTasks'>
export const getArchivedTasks = defineApi<
  void,
  GetArchivedTasks['response']
>().using({
  key: queryKeys.archive(),
  uri: '/personal_tasks/archived_tasks',
})
