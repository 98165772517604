import { useCallback, useState } from 'react'

export interface ForceUpdate {
  // An opaque token that changes when the hook is re-executed.  Used to track changes
  readonly token: unknown
  // The function that causes the re-render
  (): void
}

/**
 * A hook that allows the user to force a re-render of the calling component.
 * @returns a function to case the component to re-render
 */
export function useForceUpdate(): ForceUpdate {
  const [token, setValue] = useState(Object.create(null))
  const fn = useCallback(() => setValue(Object.create(null)), [])
  // @ts-expect-error - adding the token
  fn.token = token
  return fn as ForceUpdate
}
