// eslint-disable-next-line @motion/import-path
import { type FirebaseApp } from 'firebase/app'
// eslint-disable-next-line @motion/import-path
import { getAuth, type User, type UserCredential } from 'firebase/auth'

import { makeLog } from '../logging'

const log = makeLog('firebase.auth')

declare module 'firebase/auth' {
  interface User {
    email: string
  }
}

export type { User, UserCredential }

let unsub: undefined | (() => void)
export function initialize(app: FirebaseApp) {
  log('initialize')
  const firebaseAuth = getAuth(app)

  unsub?.()

  unsub = firebaseAuth.onAuthStateChanged((user) =>
    log('user-changed', { hasUser: user != null })
  )

  return firebaseAuth
}
