import { type ComponentRef, forwardRef, type MouseEventHandler } from 'react'

import { InternalCheckbox, type InternalCheckboxProps } from '../internal'

export type CheckboxProps = Omit<InternalCheckboxProps, 'className'>

const preventBubble: MouseEventHandler<HTMLElement> = (e: React.MouseEvent) => {
  e.stopPropagation()
}

export const Checkbox = forwardRef<
  ComponentRef<typeof InternalCheckbox>,
  CheckboxProps
>(function Checkbox(props, ref) {
  const classes = `
  contents
  themevar-[c-border=form-border-default]
  themevar-[c-border-hover=form-border-hover]
  themevar-[c-bg=form-bg-default]

  themevar-[c-border-checked=form-bg-active-default]
  themevar-[c-bg-checked=form-bg-active-default]
  themevar-[c-bg-checked-hover=form-bg-active-hover]
  `

  return (
    <div className={classes} onClick={preventBubble}>
      <InternalCheckbox ref={ref} {...props} />
    </div>
  )
})
