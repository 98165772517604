import { type PMTaskType } from '@motion/rpc/types'
import {
  type NormalTaskSchema,
  type RecurringTaskSchema,
  type TaskSchema,
} from '@motion/zod/client'

import { NO_DURATION, SHORT_TASK_DURATION } from '../duration'

export function isReminderTask(
  task: Pick<TaskSchema, 'duration'> | Pick<PMTaskType, 'duration'>
) {
  return task.duration === SHORT_TASK_DURATION
}

export function isTaskUnfit(
  task:
    | Pick<TaskSchema, 'isUnfit' | 'scheduledEnd' | 'scheduledStart'>
    | Pick<PMTaskType, 'isUnfit' | 'scheduledEnd' | 'scheduledStart'>
) {
  return task.isUnfit || !task.scheduledEnd || !task.scheduledStart
}

export function isTaskFutureSchedulable(
  task:
    | Pick<PMTaskType, 'scheduledStatus'>
    | Pick<NormalTaskSchema, 'scheduledStatus'>
) {
  return task.scheduledStatus === 'UNFIT_SCHEDULABLE'
}

export function isChunkable(
  task:
    | Pick<NormalTaskSchema, 'minimumDuration'>
    | Pick<PMTaskType, 'minimumDuration'>
): boolean {
  return task.minimumDuration !== NO_DURATION
}

export function getTaskParentId(
  task: TaskSchema | RecurringTaskSchema | null | undefined
) {
  if (task == null) return null

  if (task.type === 'CHUNK') {
    return task.parentChunkTaskId
  }

  if (task.type === 'RECURRING_INSTANCE') {
    return task.parentRecurringTaskId
  }

  return null
}
