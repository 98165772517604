export type ProgressBarProps = {
  value: number
}

export const ProgressBar = ({ value }: ProgressBarProps) => {
  return (
    <div className='relative h-4 p-0.5 rounded-sm bg-tutorial-progress-bar bg-opacity-10'>
      <div
        className='rounded-sm box-content h-full bg-tutorial-progress-bar bg-opacity-100'
        style={{
          width: `${value}%`,
        }}
      />
    </div>
  )
}
