import { createKey, defineApi, defineMutation } from '../../core'
import { type RouteTypes } from '../types'
import { type TemplateProjectType, type TemplateTaskType } from '../types-index'

const queryKeys = {
  root: createKey('templates'),
  byWorkspaceId: (id: string) => createKey(queryKeys.root, 'workspaces', id),
  byId: (args: { workspaceId: string; templateId: string }) =>
    createKey(
      queryKeys.byWorkspaceId(args.workspaceId),
      'templates',
      args.templateId
    ),
}

type GetAll = RouteTypes<'TemplatesController_getAll'>

type getAllResponse = {
  templateProjects: TemplateProjectType[]
  templateTasks: TemplateTaskType[]
}
export const getTemplatesByWorkspaceId = defineApi<
  GetAll['request'],
  getAllResponse
>().using({
  key: (args) => queryKeys.byWorkspaceId(args.workspaceId),
  uri: (args) => `/workspaces/${args.workspaceId}/templates`,
})

type CreateTemplateTask = RouteTypes<'TemplatesController_createTemplateTask'>
export const createTemplateTask = defineMutation<
  CreateTemplateTask['request'],
  CreateTemplateTask['response'] & { id: string }
>().using({
  key: (args) => queryKeys.byWorkspaceId(args.workspaceId),
  method: 'POST',
  uri: '/templates/tasks',
  body: (args) => args,
  invalidate: (args) => queryKeys.byWorkspaceId(args.workspaceId),
})

type UpdateTemplateTask = RouteTypes<'TemplatesController_updateTemplateTask'>
export const updateTemplateTask = defineMutation<
  Omit<UpdateTemplateTask['request'], 'templateId'>,
  UpdateTemplateTask['response'] & { id: string }
>().using({
  key: (args) =>
    queryKeys.byId({ workspaceId: args.workspaceId, templateId: args.id }),
  uri: (args) => `/templates/tasks/${args.id}`,
  method: 'PATCH',
  body: (args) => args,
  invalidate: (args) => queryKeys.byWorkspaceId(args.workspaceId),
})

// type DeleteTemplateTask = RouteTypes<'TemplatesController_deleteTemplateTask'>
export const deleteTemplateTask = defineMutation<
  /* DeleteTemplateTask['request'] & */ {
    workspaceId: string
    id: string
  },
  void
>().using({
  key: (args) =>
    queryKeys.byId({ workspaceId: args.workspaceId, templateId: args.id }),
  uri: (args) => `/templates/tasks/${args.id}`,
  method: 'DELETE',
  invalidate: (args) => queryKeys.byWorkspaceId(args.workspaceId),
})

type CreateTemplateProject =
  RouteTypes<'TemplatesController_createTemplateProject'>
export const createTemplateProject = defineMutation<
  CreateTemplateProject['request'],
  CreateTemplateProject['response'] & { id: string }
>().using({
  key: (args) => queryKeys.byWorkspaceId(args.workspaceId),
  method: 'POST',
  uri: '/templates/projects',
  body: (args) => args,
  invalidate: (args) => queryKeys.byWorkspaceId(args.workspaceId),
})

type UpdateTemplateProject =
  RouteTypes<'TemplatesController_updateTemplateProject'>
export const updateTemplateProject = defineMutation<
  UpdateTemplateProject['request'],
  UpdateTemplateProject['response'] & { id: string }
>().using({
  key: (args) =>
    queryKeys.byId({ workspaceId: args.workspaceId, templateId: args.id }),
  uri: (args) => `/templates/projects/${args.id}`,
  method: 'PATCH',
  body: (args) => args,
  invalidate: (args) => queryKeys.byWorkspaceId(args.workspaceId),
})

type DeleteTemplateProjectArgs = {
  workspaceId: string
  id: string
}
export const deleteTemplateProject = defineMutation<
  DeleteTemplateProjectArgs,
  void
>().using({
  key: (args) =>
    queryKeys.byId({ workspaceId: args.workspaceId, templateId: args.id }),
  uri: (args) => `/templates/projects/${args.id}`,
  method: 'DELETE',
  invalidate: (args) => queryKeys.byWorkspaceId(args.workspaceId),
})

type CreateTemplateProjectTask =
  RouteTypes<'TemplatesController_createTemplateProjectTask'>
export const createTemplateProjectTask = defineMutation<
  /* CreateTemplateProjectTask['request'] & */ {
    templateProjectId: string
    workspaceId: string
  },
  CreateTemplateProjectTask['response'] & { id: string }
>().using({
  key: (args) => queryKeys.byWorkspaceId(args.workspaceId),
  method: 'POST',
  uri: (args) => `/templates/projects/${args.templateProjectId}/tasks`,
  body: ({ templateProjectId, ...args }) => args,
  invalidate: (args) => queryKeys.byWorkspaceId(args.workspaceId),
})

type UpdateTemplateProjectTask =
  RouteTypes<'TemplatesController_updateTemplateProjectTask'>
export const updateTemplateProjectTask = defineMutation<
  /* UpdateTemplateProjectTask['request'] & */ {
    templateProjectId: string
    workspaceId: string
    task: { id: string; workspaceId: string }
  },
  UpdateTemplateProjectTask['response'] & { id: string }
>().using({
  key: (args) => queryKeys.byWorkspaceId(args.workspaceId),
  method: 'PATCH',
  uri: (args) =>
    `/templates/projects/${args.templateProjectId}/tasks/${args.task.id}`,
  body: ({ task }) => task,
  invalidate: (args) => queryKeys.byWorkspaceId(args.workspaceId),
})

type DeleteTemplateProjectTask =
  RouteTypes<'TemplatesController_deleteTemplateProjectTask'>
export const deleteTemplateProjectTask = defineMutation<
  DeleteTemplateProjectTask['request'] & {
    workspaceId: string
  },
  void
>().using({
  key: (args) => queryKeys.byWorkspaceId(args.workspaceId),
  method: 'DELETE',
  uri: (args) => `/templates/projects/tasks/${args.taskId}`,
  invalidate: (args) => queryKeys.byWorkspaceId(args.workspaceId),
})
