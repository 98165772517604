import { isString } from '@motion/utils/guards'

import { cloneElement, isValidElement, type ReactNode } from 'react'

const TEMPLATE_RE = /{{\s*(\w+)\s*}}/g
type ReplacementDict = Record<string, number | string>
type ComplexReplacementDict = Record<string, ReactNode | number | string>
type FnReplacement = (word: string) => string
type FnComplexReplacement = (word: string) => ReactNode
type AllReplacements =
  | ReplacementDict
  | ComplexReplacementDict
  | FnReplacement
  | FnComplexReplacement

interface TemplateStrFunction {
  (value: string, replacements: ReplacementDict): string
  (value: string, replacements: ComplexReplacementDict): ReactNode[]
  (value: string, replacements: (word: string, index: number) => string): string
  (
    value: string,
    replacements: (word: string, index: number) => ReactNode
  ): ReactNode[]
  (value: string, replacements: AllReplacements): string | ReactNode[]
}

export const makeTemplateString = (re: RegExp): TemplateStrFunction => {
  // @ts-expect-error I know the type is right
  return (
    value: string | undefined,
    replacements: AllReplacements
  ): string | ReactNode[] => {
    if (value == null) return ''

    const parts: (ReactNode | string)[] = []
    let matchIndex = 0
    let lastOffset = 0

    value.replace(re, (match, replacementKey: string, offset: number) => {
      matchIndex += 1

      // push the source text before the first match if it exists
      const previousString = value.substring(lastOffset, offset)
      if (previousString) parts.push(previousString)

      lastOffset = offset + match.length

      const replacementValue =
        replacements instanceof Function
          ? replacements(replacementKey)
          : replacements[replacementKey] ?? ''

      // push the replacement
      if (isValidElement(replacementValue)) {
        parts.push(cloneElement(replacementValue, { key: matchIndex }))
      } else {
        parts.push(String(replacementValue))
      }

      return ''
    })

    // push the last part of the source text
    const lastPart = value.substring(lastOffset)
    if (lastPart) parts.push(lastPart)

    return parts.every(isString) ? parts.join('') : parts
  }
}

/**
 * Replace any `{{ key }}` in the value string with support of React nodes
 * @param value - string
 * @param replacements - Record<string, string | number | ReactNode> | (word: string) => string | ReactNode
 */
export const templateStr = makeTemplateString(TEMPLATE_RE)
