import { LoadingOutline } from '@motion/icons'

import { twMerge } from 'tailwind-merge'

import { useSyncAnimation } from '../../animation'

export type LoadingSpinnerProps = {
  size?: number
  color?: string
  className?: string
}

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const ref = useSyncAnimation<SVGSVGElement>('static-loading-spin')
  const { size = 30 } = props

  return (
    <LoadingOutline
      width={size}
      height={size}
      ref={ref}
      className={twMerge('spin', props.className)}
      style={{
        // @ts-expect-error - overriding color
        '--loading-fg-title':
          props.color ?? 'var(--motion-semantic-neutral-icon-default)',
      }}
    />
  )
}
