import {
  BanSolid,
  NonVisitedStatusSolid,
  StatusCompletedSolid,
  StatusSolid,
} from '@motion/icons'
import { getStatusColorClass, isCompletedStatus } from '@motion/ui-logic'
import { type StatusSchema } from '@motion/zod/client'

import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

export interface StatusIconProps {
  item: Pick<
    StatusSchema,
    'name' | 'color' | 'isResolvedStatus' | 'isDefaultStatus'
  >
  iconVariant?: 'default' | 'blocker' | 'isUnvisitedStage'
  width?: number
  height?: number
}

const getStatusIconSvg = (
  item: StatusIconProps['item'],
  iconVariant: StatusIconProps['iconVariant']
) => {
  if (isCompletedStatus(item)) {
    return StatusCompletedSolid
  }

  if (iconVariant === 'blocker') {
    return BanSolid
  }

  if (iconVariant === 'isUnvisitedStage') {
    return NonVisitedStatusSolid
  }

  return StatusSolid
}

export const StatusIcon = forwardRef<SVGSVGElement, StatusIconProps>(
  function StatusIcon(
    { item, width = 16, height = 16, iconVariant = 'default' },
    ref
  ) {
    const colorClass = getStatusColorClass(item)
    const legacyColor = colorClass ? undefined : item?.color
    const Icon = getStatusIconSvg(item, iconVariant)

    return (
      <Icon
        ref={ref}
        className={twMerge('inline-flex shrink-0', colorClass)}
        style={{ color: legacyColor }}
        width={width}
        height={height}
      />
    )
  }
)
