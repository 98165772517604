import { createStateKey } from '@motion/react-core/shared-state'

import { type SubscriptionState } from './types'

export const SubscriptionStateKey = createStateKey<SubscriptionState>(
  'subscription',
  {
    defaultValue: { state: 'unset' },
  }
)
