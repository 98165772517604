import { type RecurringTaskSchema } from '@motion/rpc-types'

import { isValidStageDefinitionId } from '../../../project'
import {
  type RecurringTaskUpdateFields,
  type TaskFormChangedFieldOptions,
  type TaskUpdateFields,
  type UpdatableTaskSchema,
} from '../types'

export function getTaskProjectChangedFields(
  task: UpdatableTaskSchema,
  options: Pick<TaskFormChangedFieldOptions, 'projects'>
): TaskUpdateFields
export function getTaskProjectChangedFields(
  task: RecurringTaskSchema,
  options: Pick<TaskFormChangedFieldOptions, 'projects'>
): RecurringTaskUpdateFields
export function getTaskProjectChangedFields(
  task: UpdatableTaskSchema | RecurringTaskSchema,
  options: Pick<TaskFormChangedFieldOptions, 'projects'>
): TaskUpdateFields | RecurringTaskUpdateFields {
  if (task.type !== 'NORMAL') return {}

  const { projects } = options

  const updates: TaskUpdateFields = {}

  const project = projects.find((p) => p.id === task.projectId)
  // Is a flow project
  if (
    project != null &&
    project.projectDefinitionId != null &&
    project.activeStageDefinitionId != null
  ) {
    if (!isValidStageDefinitionId(task.stageDefinitionId, project)) {
      updates.stageDefinitionId = project.activeStageDefinitionId
    }
    // Not a flow project
  } else if (task.stageDefinitionId != null) {
    updates.stageDefinitionId = null
  }

  return updates
}
