import {
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query'

import { useQueryOptionsFactory } from './use-query-options-factory'

import { type ApiTypes, type LooseApiDefinition } from '../../core/types'

export function createUseQuery<TApi extends LooseApiDefinition, TSelect>(
  api: TApi,
  localOptions: Omit<Partial<ApiTypes<TApi>['UseQueryOptions']>, 'select'> & {
    select(data: ApiTypes<TApi>['data']): TSelect
  }
): (
  args: ApiTypes<TApi>['args'],
  opts?: Omit<UseQueryOptions<TSelect>, 'queryKey'>
) => UseQueryResult<TSelect>
export function createUseQuery<TApi extends LooseApiDefinition>(
  api: TApi,
  localOptions?: Partial<ApiTypes<TApi>['UseQueryOptions']>
): (
  args: ApiTypes<TApi>['args'],
  opts?: Omit<UseQueryOptions<ApiTypes<TApi>['data']>, 'queryKey'>
) => UseQueryResult<ApiTypes<TApi>['data']>
export function createUseQuery<TApi extends LooseApiDefinition>(
  api: TApi,
  localOptions?: any // Omit<UseQueryOptions<any>, 'queryKey'>
) {
  type t = ApiTypes<TApi>

  return (args: t['args'], opts?: any): t['UseQueryResult'] => {
    const queryOptionsOf = useQueryOptionsFactory(api)
    return useQuery(queryOptionsOf(args, { ...localOptions, ...opts }))
  }
}

export type UseQuery<TApi extends LooseApiDefinition> = ReturnType<
  typeof createUseQuery<TApi>
>
