import { useContext, useEffect } from 'react'

import { HtmlContext } from './context'

export function useTitle(title: string | undefined) {
  const { pushTitle } = useContext(HtmlContext)

  useEffect(() => {
    if (!title) return

    return pushTitle(title)
  }, [pushTitle, title])
}

export function useModalTitle(title: string | undefined) {
  const { pushModalTitle } = useContext(HtmlContext)

  useEffect(() => {
    if (!title) return

    return pushModalTitle(title)
  }, [pushModalTitle, title])
}
