import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  type Placement,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react'
import {
  cloneElement,
  createContext,
  forwardRef,
  type HTMLProps,
  isValidElement,
  type ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react'

export interface TooltipOptions {
  placement?: Placement
}

export type UnstyledTooltipProps = TooltipOptions & {
  children: ReactNode
  renderContent: (() => ReactNode) | null | undefined
  asChild?: boolean
  interactive?: boolean
}

export const UnstyledTooltip = forwardRef<HTMLElement, UnstyledTooltipProps>(
  function UnstyledTooltip(
    {
      placement = 'top',
      asChild = false,
      children,
      renderContent,
      interactive,
      ...rest
    },
    ref
  ) {
    const tooltip = useTooltip({ placement, visible: !!renderContent })

    return (
      <TooltipContext.Provider value={tooltip}>
        <TooltipTrigger ref={ref} asChild={asChild} {...rest}>
          {children}
        </TooltipTrigger>
        <TooltipContent interactive={interactive}>
          {renderContent?.()}
        </TooltipContent>
      </TooltipContext.Provider>
    )
  }
)

const TooltipTrigger = forwardRef<
  HTMLElement,
  HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
  const context = useTooltipContext()
  const childrenRef = (children as any).ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

  if (asChild && isValidElement(children)) {
    return cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      })
    )
  }

  return (
    <span
      ref={ref}
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </span>
  )
})

const TooltipContent = forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement> & { interactive?: boolean }
>(function TooltipContent({ style, interactive = false, ...props }, propRef) {
  const context = useTooltipContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])

  const { styles } = useTransitionStyles(context.context, {
    duration: 140,
  })

  if (!context.open) return null

  return (
    <FloatingPortal>
      <div
        ref={ref}
        style={{
          ...context.floatingStyles,
          ...styles,
          ...style,
          pointerEvents: interactive ? 'auto' : 'none',
          zIndex: 50,
        }}
        {...context.getFloatingProps(props)}
      />
    </FloatingPortal>
  )
})

type ContextType = ReturnType<typeof useTooltip> | null
const TooltipContext = createContext<ContextType>(null)
const useTooltipContext = () => {
  const context = useContext(TooltipContext)
  if (context == null) {
    throw new Error('Tooltip context is not defined')
  }
  return context
}

function useTooltip({
  placement,
  visible,
}: TooltipOptions & { visible: boolean }) {
  const [open, setOpen] = useState(false)

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        crossAxis: false,
        fallbackAxisSideDirection: 'end',
      }),
      shift({ padding: 5 }),
    ],
  })
  const { context } = data

  const hover = useHover(context, {
    move: false,
    delay: 90,
    enabled: visible,
  })
  const focus = useFocus(context, { enabled: visible })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  const interactions = useInteractions([hover, focus, dismiss, role])

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data]
  )
}
