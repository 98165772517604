import { type ReactNode, useEffect } from 'react'

import { useFirestoreSettings } from './hooks'

export const EnsureFirestoreSettingsFetched = ({
  children,
}: {
  children: ReactNode
}) => {
  const fs = useFirestoreSettings()
  useEffect(() => {
    if (!fs.isFetching && !fs.isFetched) {
      fs.refetch()
    }
  }, [fs, fs.isFetching, fs.isFetched])

  if (fs.status === 'success' && fs.isFetched) {
    return children
  }

  return null
}
