import Emitter from 'events'

export type Unsubscribe = () => void

class Frame<const TEvents extends object> {
  #disposables: Unsubscribe[] = []

  constructor(private readonly bus: EventBus<TEvents>) {}

  on<TKey extends keyof TEvents & string>(
    name: TKey,
    handler: (data: TEvents[TKey]) => void
  ): this {
    this.#disposables.push(this.bus.on(name, handler))

    return this
  }

  dispose() {
    const handlers = [...this.#disposables]
    this.#disposables = []
    handlers.forEach((unsub) => unsub())
  }

  [Symbol.dispose]() {
    this.dispose()
  }
}

export class EventBus<const TEvents extends object> {
  private emitter = new Emitter()

  frame() {
    return new Frame(this)
  }

  on<TKey extends keyof TEvents & string>(
    name: TKey,
    handler: (data: TEvents[TKey]) => void
  ): Unsubscribe {
    this.emitter.on(name, handler)
    return () => this.emitter.off(name, handler)
  }

  emit<T extends keyof TEvents & string>(name: T, args: TEvents[T]) {
    this.emitter.emit(name, args)
  }

  unsubscribeAll() {
    this.emitter.removeAllListeners()
  }
}
