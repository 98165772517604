import { type CSSProperties, type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

// Any hue not in the range 0-360 is considered gray
// Intentionally large to avoid confusion
export const GRAY_HUE = 1000

export type PaletteProviderProps = {
  children: ReactNode
  colorHue: number
}

export function PaletteProvider({ children, colorHue }: PaletteProviderProps) {
  if (colorHue == null) {
    throw new Error('PaletteProvider requires a colorHue')
  }

  const isGray = colorHue < 0 || colorHue > 360
  const style = !isGray
    ? ({
        '--motion-palette-hue': colorHue,
      } as CSSProperties)
    : undefined

  return (
    <div
      className={twMerge('contents motion-palette', isGray && 'gray')}
      style={style}
    >
      {children}
    </div>
  )
}
