import { type ProjectSchema } from '@motion/rpc-types'

import { getFlowTaskDueDate } from './get-flow-task-due-date'
import { getFlowTaskStartDate } from './get-flow-task-start-date'

import {
  getTaskDefaultDueDate,
  getTaskInitialStartDate,
} from '../../task/form/value-helpers'

/**
 * Return start and due date for a task in a flow project
 */
export function getFlowTaskDefaultDates({
  project,
  stageDefinitionId,
}: {
  project: ProjectSchema | null
  stageDefinitionId: string | null | undefined
}) {
  let startDate = getTaskInitialStartDate() // today
  let dueDate = getTaskDefaultDueDate()

  if (project == null || stageDefinitionId == null) {
    return { startDate, dueDate }
  }

  const selectedStageIndex = project.stages.findIndex(
    (s) => s.stageDefinitionId === stageDefinitionId
  )
  const selectedStage = project.stages[selectedStageIndex]

  if (selectedStage != null) {
    // Don't change the start date from today if the stage is visited
    if (!selectedStage.visited) {
      startDate = getFlowTaskStartDate(selectedStageIndex, project)
    }
    dueDate = getFlowTaskDueDate(selectedStage.dueDate)
  }

  return { startDate, dueDate }
}
