import { Compare } from '@motion/utils/array'

import {
  defaultRangeExtractor,
  type Range,
  useVirtualizer,
} from '@tanstack/react-virtual'
import { useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { MemoizedChild } from './memoized-child'
import { type VirtualizedSharedProps } from './types'

type VirtualizedBaseProps<T> = VirtualizedSharedProps<T> & {
  estimateSize: (item: T) => number
  horizontal?: boolean
  stableScrollbar?: boolean
}

export const VirtualizedBase = <T,>(props: VirtualizedBaseProps<T>) => {
  const { overscan = 2, horizontal = false } = props
  const scrollerRef = useRef<HTMLDivElement | null>(null)
  const virtualizer = useVirtualizer({
    count: props.items.length,
    horizontal,
    getScrollElement: () => scrollerRef.current,
    estimateSize: (index) => props.estimateSize(props.items[index]),
    overscan,
    getItemKey: (index) => {
      return props.computeItemKey?.(props.items[index]) ?? index
    },
    rangeExtractor: (range: Range) => {
      const next =
        props.stickyIndexes != null
          ? [
              ...new Set([
                ...props.stickyIndexes,
                ...defaultRangeExtractor(range),
              ]),
            ].sort(Compare.numeric)
          : defaultRangeExtractor(range)
      return next
    },
  })

  const virtualItems = virtualizer.getVirtualItems()

  return (
    <div
      ref={scrollerRef}
      className={twMerge(
        'w-full h-full will-change-transform',
        horizontal
          ? 'overflow-x-auto overflow-y-hidden'
          : 'overflow-y-auto overflow-x-hidden',
        props.containerClassName
      )}
      style={{
        scrollbarGutter: props.stableGutter ? 'stable' : 'auto',
      }}
    >
      <div
        className='relative'
        style={{
          width: horizontal ? `${virtualizer.getTotalSize()}px` : '100%',
          height: horizontal ? '100%' : `${virtualizer.getTotalSize()}px`,
        }}
      >
        {virtualItems.map((virtualItem) => (
          <div
            key={virtualItem.key}
            ref={virtualizer.measureElement}
            data-index={virtualItem.index}
            className={twMerge(horizontal ? 'h-full' : 'w-full')}
            style={{
              position: 'absolute',
              transform: horizontal
                ? `translateX(${virtualItem.start ?? 0}px)`
                : `translateY(${virtualItem.start ?? 0}px)`,
            }}
          >
            <MemoizedChild
              item={props.items[virtualItem.index]}
              index={virtualItem.index}
              renderChildren={props.renderItem}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
