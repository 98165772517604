import { type UserSettingsSchema } from '@motion/rpc-types'

import { DateTime } from 'luxon'

import { areTimezonesOffsetsEqual, formatTimezoneToString } from '../utils'

export function getTimezoneSettings({
  settings,
  useDefaults,
}: {
  settings?: UserSettingsSchema | null
  useDefaults?: boolean
}) {
  const localTimezone = DateTime.local().zone
  const localTimezoneName = localTimezone.name
  const formattedLocalTimezoneString = formatTimezoneToString(localTimezone)

  const timezone = settings?.timezones?.defaultTimezone
  const defaultTimezone = useDefaults
    ? timezone ?? localTimezone.name
    : timezone
  return {
    defaultTimezone,
    secondaryTimezone: settings?.timezones?.secondaryTimezone,
    detectedTimezone: settings?.timezones?.latestClientTimezoneDetected,
    localTimezoneName: localTimezoneName,
    formattedLocalTimezoneString: formattedLocalTimezoneString,
  }
}

export function shouldPromptForTimezoneChange({
  localTimezoneName,
  defaultTimezone,
  detectedTimezone,
}: {
  localTimezoneName: string
  defaultTimezone?: string
  detectedTimezone?: string | null
}) {
  const isInDifferentTimezone = !areTimezonesOffsetsEqual(
    localTimezoneName,
    defaultTimezone
  )

  const isLastCheckedTimezoneDifferent = detectedTimezone !== localTimezoneName

  return (
    defaultTimezone && isInDifferentTimezone && isLastCheckedTimezoneDifferent
  )
}
