import { isEqual } from '@motion/utils/core'

import { useSyncExternalStore } from 'react'

const cache: Map<string, unknown> = new Map()
export function useMemoSyncExternalStore<Snapshot>(
  key: string,
  subscribe: (onStoreChange: () => void) => () => void,
  getSnapshot: () => Snapshot
): Snapshot {
  return useSyncExternalStore<Snapshot>(subscribe, () => {
    const currentValue = getSnapshot()
    const lastValue = cache.get(key) as Snapshot
    if (isEqual(lastValue, currentValue)) return lastValue
    cache.set(key, currentValue)
    return currentValue
  })
}
