import { defineApi, defineMutation } from '../../core'
import { type PMTaskType } from '../misc-types'
import { type RouteTypes } from '../types'

type GetTeamScheduleTasks =
  RouteTypes<'TeamScheduleController_getTeamScheduleTasks'>

export const getTasks = defineApi<
  GetTeamScheduleTasks['request'],
  { tasks: PMTaskType[] }
>().using({
  key: (args) => ['team-schedule', 'tasks', args.filter],
  uri: '/team-schedule/tasks',
  method: 'POST',
  queryOptions: {
    keepPreviousData: true,
  },
})

type GetTeamScheduleContext = RouteTypes<'TeamScheduleController_getContext'>
export const getContext = defineApi<
  GetTeamScheduleContext['request'],
  GetTeamScheduleContext['response']
>().using({
  key: ['team-schedule', 'context'],
  uri: '/team-schedule/context',
  method: 'GET',
})

type CreateView = RouteTypes<'TeamScheduleController_createView'>
export const createView = defineMutation<
  CreateView['request'],
  CreateView['response']
>().using({
  uri: '/team-schedule/views',
  method: 'POST',
})
export type CreateViewRequest = CreateView['request']

type UpdateView = RouteTypes<'TeamScheduleController_updateView'>
export const updateView = defineMutation<
  UpdateView['request'],
  UpdateView['response']
>().using({
  uri: (args) => `/team-schedule/views/${args.viewId}`,
  method: 'PATCH',
  body: (args) => {
    const { viewId, ...body } = args
    return body
  },
})

type DeleteView = RouteTypes<'TeamScheduleController_deleteView'>
export const deleteView = defineMutation<DeleteView['request'], void>().using({
  uri: (args) => `/team-schedule/views/${args.viewId}`,
  method: 'DELETE',
})

type GetViews = RouteTypes<'TeamScheduleController_getViews'>
export const getViews = defineApi<
  GetViews['request'],
  GetViews['response']
>().using({
  uri: '/team-schedule/views',
  key: ['team-schedule', 'views'],
})
