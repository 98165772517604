export const GOOGLE_URL_PREFIX = 'https://www.googleapis.com'
export const MICROSOFT_URL_PREFIX = 'https://graph.microsoft.com/v1.0/me'

export const ANDROID_APP_LINK =
  'https://play.google.com/store/apps/details?id=com.usemotion.motion'
export const IOS_APP_LINK =
  'https://apps.apple.com/us/app/motion-tasks-and-scheduling/id1580440623'

export enum SYSTEM_THEMES {
  LIGHT = 'light',
  DARK = 'dark',
  SYSTEM_SETTINGS = 'systemSettings',
}
