import { filterAndRankMatches } from '@motion/ui-logic'

import { type ReactNode, useMemo, useState } from 'react'

import {
  type Group,
  GroupedCheckboxList,
  type RenderGroupContext,
  type RenderItemContext,
} from './components/grouped-checkbox-list'
import { SearchableListContainer } from './components/internal'

export type { Group, RenderGroupContext, RenderItemContext }

export type SearchableGroupedCheckboxListProps<T> = {
  groups: Group<T>[]
  selected: T[]
  onSelected(items: T[]): void

  renderHeader(ctx: RenderGroupContext<T>): NonNullable<ReactNode>
  renderItem(ctx: RenderItemContext<T>): NonNullable<ReactNode>

  placeholder: string
}

export const SearchableGroupedCheckboxList = <T,>(
  props: SearchableGroupedCheckboxListProps<T>
) => {
  const [search, setSearch] = useState('')

  const filteredGroups = useMemo(() => {
    if (!search) return props.groups
    return filterAndRankMatches(search, props.groups, (g) => g.key)
  }, [props.groups, search])

  return (
    <SearchableListContainer
      placeholder={props.placeholder}
      search={search}
      setSearch={setSearch}
    >
      <GroupedCheckboxList
        groups={filteredGroups}
        selected={props.selected}
        onSelected={props.onSelected}
        renderHeader={props.renderHeader}
        renderItem={props.renderItem}
      />
    </SearchableListContainer>
  )
}
