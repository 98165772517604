import { type UniqueIdentifier } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { memo, type ReactNode, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import { type RenderItem, type SortableItem } from './sortable-treeview.types'
import {
  SortableTreeviewGroupContainer,
  SortableTreeviewItemContainer,
} from './sortable-treeview-container'
import { useScrollBoundsObserver } from './use-scroll-bounds-observer'

type VirtualizedGroupedListProps = {
  hasRevampedSidebars: boolean
  items: SortableItem[]
  itemHeight: number
  renderItem: RenderItem<SortableItem>
  activeId: UniqueIdentifier | null
  setExpanded: (id: string) => void
  renderPlaceholder?: (item: SortableItem) => ReactNode
  disableDrag?: boolean
}

export const VirtualizedGroupedList = memo(function VirtualizedGroupedList(
  props: VirtualizedGroupedListProps
) {
  const { scrollRef, scrollTop, scrollBottom } = useScrollBoundsObserver(
    props.itemHeight
  )
  const topIndex = Math.floor(scrollTop / props.itemHeight) - 5
  const bottomIndex = Math.floor(scrollBottom / props.itemHeight) + 5

  const containers = useMemo(
    function measureActiveItems() {
      let currentIndex = 0
      // TODO: Virtualize containers too
      return props.items.map((container) => {
        const startIndex = currentIndex
        let childCount = 1
        if (props.activeId !== container.id && container.expanded) {
          childCount += Math.max(getLength(container.children), 1)
        }
        currentIndex += childCount
        // TODO: clean up
        const childIsActive = container.children.some(
          (c) => c.id === props.activeId
        )
        const renderDroppedChild = childIsActive && !container.expanded
        return {
          id: container.id,
          item: container,
          startIndex,
          height: renderDroppedChild
            ? 2 * props.itemHeight
            : childCount * props.itemHeight,
          expanded: container.expanded,
          children: container.children,
          childIds: container.children.map((child) => child.id),
        }
      })
    },
    [props.items, props.activeId, props.itemHeight]
  )

  const rows = useMemo(() => {
    return containers.map((container) => {
      return container.children.map((child, index) => {
        const relativeIndex = container.startIndex + (index + 1)
        const top = props.itemHeight * (index + 1)
        return {
          id: child.id,
          item: child,
          relativeIndex,
          style: {
            top: container.expanded ? top : props.itemHeight,
            left: 14,
            width: 'calc(100% - 14px)',
            height: props.itemHeight,
          },
        }
      })
    })
  }, [containers, props.itemHeight])

  return (
    <div
      ref={scrollRef}
      className={twMerge(
        'h-full w-full',
        !props.hasRevampedSidebars && 'overflow-auto'
      )}
    >
      <div className='overflow-hidden'>
        {containers.map((container, containerIndex) => {
          return (
            <SortableTreeviewGroupContainer
              key={container.id}
              id={container.id}
              disabled={props.disableDrag}
              style={{
                height: container.height,
                position: 'relative',
                overflow: 'hidden',
                // TODO: animate height
                // transition: 'height 0.1s cubic-bezier(0.2, 0, 0, 1)',
              }}
              isContainer
              placeholder={
                container.expanded &&
                container.children.length < 1 &&
                props.renderPlaceholder ? (
                  <div
                    className='relative h-full'
                    style={{ left: 26, width: 'calc(100% - 26px)' }}
                  >
                    {props.renderPlaceholder(container.item)}
                  </div>
                ) : null
              }
              renderHeader={(hovering: boolean) => (
                <div
                  className='w-full'
                  style={{
                    height: props.itemHeight,
                  }}
                >
                  {props.renderItem(
                    container.item,
                    props.activeId,
                    () => props.setExpanded(container.id),
                    hovering
                  )}
                </div>
              )}
            >
              <SortableContext
                items={container.childIds}
                strategy={verticalListSortingStrategy}
              >
                {rows[containerIndex].map((child) => {
                  const isInView =
                    !!container.expanded &&
                    child.relativeIndex > topIndex &&
                    child.relativeIndex < bottomIndex
                  if (
                    !isInView &&
                    !props.hasRevampedSidebars && // temporary fix, will rework this for folders anyway
                    props.activeId !== child.id
                  )
                    return null
                  return (
                    <SortableTreeviewItemContainer
                      key={child.id}
                      id={child.id}
                      style={child.style}
                      disabled={props.disableDrag}
                      renderItem={(hovering: boolean) =>
                        props.renderItem(
                          child.item,
                          props.activeId,
                          () => null,
                          hovering
                        )
                      }
                    />
                  )
                })}
              </SortableContext>
            </SortableTreeviewGroupContainer>
          )
        })}
        <div className='h-10' />
      </div>
    </div>
  )
})

const getLength = (arr: unknown[] | undefined) =>
  Array.isArray(arr) ? arr.length : 0
