import { classed } from '@motion/theme'

const BubbleText = classed(
  'span',
  'text-[10px] leading-[10px] font-semibold text-semantic-neutral-text-subtle'
)

export interface ChunkBubbleTextProps {
  chunkNumber: number
  chunkTotal: number
}

export const ChunkBubbleText = ({
  chunkNumber,
  chunkTotal,
}: ChunkBubbleTextProps) => {
  return (
    <BubbleText>
      <sup>{chunkNumber}</sup>
      &frasl;
      <sub>{chunkTotal}</sub>
    </BubbleText>
  )
}
