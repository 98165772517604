const UNSET = Symbol('lazy-unset')

export type Lazy<T> = {
  get(): Promise<T>
  reset(): void

  get resolved(): boolean
  get value(): T
}

export function lazy<T>(fn: () => Promise<T>): Lazy<T> {
  let valuePromise: Promise<T> | typeof UNSET = UNSET
  let value: T | typeof UNSET = UNSET

  return {
    get resolved() {
      return value !== UNSET
    },
    get value() {
      if (value === UNSET) {
        throw new Error('Lazy value has not resolved.')
      }
      return value
    },
    get() {
      if (valuePromise !== UNSET) return valuePromise
      valuePromise = fn()
        .then((x) => {
          value = x
          return x
        })
        .catch((ex) => {
          valuePromise = UNSET
          throw ex
        })
      return valuePromise
    },
    reset() {
      valuePromise = UNSET
      value = UNSET
    },
  }
}
