import { type COLOR } from '@motion/shared/common'

import { GRAY_HUE } from './palette-provider'

export const colorOptionToHueMap: Record<COLOR, number> = {
  red: 0,
  orange: 15,
  tangerine: 30,
  yellow: 45,
  'highlighter-yellow': 60,
  lime: 75,
  mint: 90,
  green: 105,
  sage: 135,
  emerald: 150,
  cyan: 165,
  teal: 180,
  sky: 195,
  blue: 210,
  cobalt: 225,
  lavender: 240,
  purple: 255,
  violet: 270,
  grape: 285,
  pink: 315,
  raspberry: 330,
  rose: 345,
  gray: GRAY_HUE,
}
