type HttpErrorArgs<T> = {
  status: number
  body: T
  reqId: string
  ex?: Error
}

export class HttpError<T = any> extends Error {
  public readonly reqId: string
  public readonly status: number
  public readonly body: T

  constructor({ status, body, reqId, ex }: HttpErrorArgs<T>) {
    super((body as any)?.message ?? `HTTP Error ${status}`, {
      cause: ex,
    })
    this.reqId = reqId
    this.status = status
    this.body = body
    Error.captureStackTrace?.(this, this.constructor)
  }
}

type HttpValidationErrorBody = {
  message: string
  errors: {
    code: string
    message: string
    path: (string | number)[]
  }[]
}
export class HttpValidationError extends HttpError<HttpValidationErrorBody> {
  constructor(args: HttpErrorArgs<HttpValidationErrorBody>) {
    super(args)

    this.message = this.body.errors[0]?.message ?? this.body.message
  }
}

export class NotAuthorizedError extends HttpError {
  constructor(public readonly reqId: string) {
    super({ status: 401, body: { message: 'Not Authorized' }, reqId })
  }
}

type HttpConnectionErrorOptions = {
  uri: string
  method: string | undefined
  cause: unknown
  reqId: string
}

export class HttpConnectionError extends Error {
  public readonly reqId: string

  constructor(opts: HttpConnectionErrorOptions) {
    super(
      `Failed to connect to '${opts.method ? `${opts.method} ` : ''}${
        opts.uri
      }'`,
      {
        cause: opts.cause,
      }
    )
    this.reqId = opts.reqId
    Error.captureStackTrace?.(this, this.constructor)
  }
}

/**
 * Defined for user-facing errors that should not be reported.
 */
export class ClientValidationError extends Error {}
