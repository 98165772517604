export function safeParseNumber(value: string | null | undefined): number | null
export function safeParseNumber(
  value: string | null | undefined,
  defaultValue: number
): number
export function safeParseNumber(
  value: string | null | undefined,
  defaultValue: null
): number | null
export function safeParseNumber(
  value: string | null | undefined,
  defaultValue: string
): number | string
export function safeParseNumber(
  value: string | null | undefined,
  defaultValue: number | string | null = null
): number | string | null {
  if (!value) return defaultValue
  const asNumber = parseInt(value)
  if (isNaN(asNumber)) {
    return defaultValue
  }
  return asNumber
}
