export type CalendarEvent = {
  id: string
  providerId: string
  title: string
  description: string | null
  type: string
  iCalUid: string
  start: string
  end: string
  isAllDay: boolean
  status: CalendarEventStatus
  location?: string
  isCancelled: boolean
  attendees?: EventAttendee[]
  conferenceLink: string | null
  teamTaskId: string | null
  visibility: string
  recurrence: string | null
  recurringEventId: string
  areAttendeesHidden: boolean | null
  canAttendeesInvite: boolean | null
  canAttendeesModify: boolean | null
  url: string
  colorId: string | null
  bookingLinkId: string | null
  bookingPriority: string | null
  isDeleted: boolean
  sourceEventId: string | null
  sourceCalendarId: string | null
  travelTimeId: string | null
  travelTimeBefore: string | null
  travelTimeAfter: string | null
  calendarId: string
  email: string
  organizer?: {
    displayName?: string
    email?: string
  }
  recurringParentId: string | null
}

export type EventAttendeeStatus =
  | 'accepted'
  | 'declined'
  | 'needsAction'
  | 'tentative'

export type EventAttendee = {
  displayName?: string
  email: string
  isOptional: boolean
  isOrganizer: boolean
  status: EventAttendeeStatus
}

export type DeprecatedCalendar = {
  id: string
  email: string
  accessRole?: string
  active?: boolean
  mine?: boolean
  primary?: boolean
  owner?: string
  title?: string
  provider?: 'google' | 'microsoft'
  modified?: boolean
  frequent?: boolean
  colorClass?: string
  editable?: boolean
  visible?: boolean
}

export type CalendarList = Record<string, DeprecatedCalendar[]>

export const CalendarEventStatuses = ['FREE', 'BUSY'] as const
export type CalendarEventStatus = (typeof CalendarEventStatuses)[number]

export const MobileConferenceTypes = [
  'zoom',
  'hangoutsMeet',
  'teamsForBusiness',
] as const

export type MobileConferenceType = (typeof MobileConferenceTypes)[number]

export const ConferenceTypeEnum = {
  NONE: 'NONE',
  ZOOM: 'ZOOM',
  GOOGLE_MEET: 'GOOGLE_MEET',
  MICROSOFT_TEAMS: 'MICROSOFT_TEAMS',
  PHONE: 'PHONE',
  CUSTOM_LOCATION: 'CUSTOM_LOCATION',
} as const
export type ConferenceTypeUpper =
  (typeof ConferenceTypeEnum)[keyof typeof ConferenceTypeEnum]

export const ConferenceTypes = [
  'none',
  'meet',
  ...MobileConferenceTypes,
  'phone',
  'customLocation',
] as const

export const ConferenceTypesDict = {
  none: 'none',
  zoom: 'zoom',
  hangoutsMeet: 'hangoutsMeet',
  meet: 'meet',
  teamsForBusiness: 'teamsForBusiness',
  phone: 'phone',
  customLocation: 'customLocation',
  eventHangout: 'eventHangout',
  eventNamedHangout: 'eventNamedHangout',
  unknown: 'unknown',
  skypeForBusiness: 'skypeForBusiness',
  skypeForConsumer: 'skypeForConsumer',
} as const

export type ConferenceType =
  (typeof ConferenceTypesDict)[keyof typeof ConferenceTypesDict]

export const ConferenceTypesDictReadable = {
  none: 'None',
  zoom: 'Zoom',
  hangoutsMeet: 'Google Meet',
  meet: 'Meet',
  teamsForBusiness: 'Teams Meeting',
  phone: 'Phone Call',
  customLocation: 'Custom Location',
  eventHangout: 'Event Hangout',
  // eventNamedHangout: 'eventNamedHangout', ??
  unknown: 'Unknown',
  skypeForBusiness: 'Skype',
  skypeForConsumer: 'Skype',
} as const

export type ConferenceTypeHumanReadable =
  (typeof ConferenceTypesDictReadable)[keyof typeof ConferenceTypesDictReadable]
