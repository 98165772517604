import { Button, Tooltip } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'

export const LoginErrors = [
  'auth/invalid-email',
  'auth/user-disabled',
  'auth/wrong-password',
  'auth/wrong-provider-google',
  'auth/wrong-provider-microsoft',
  'auth/wrong-provider-apple',
  'auth/wrong-provider-unknown',
  'auth/wrong-password-or-wrong-provider',
  'unknown',
] as const

export type LoginErrors = (typeof LoginErrors)[number]

const EmailPasswordSignInError = ({ error }: { error: LoginErrors | null }) => {
  if (error == null) {
    return null
  }

  if (error === 'auth/invalid-email') {
    return (
      <p className='text-semantic-error-text-default text-sm'>
        Invalid email address. Please try another email.
      </p>
    )
  }

  if (error === 'auth/user-disabled') {
    return (
      <p className='text-semantic-error-text-default text-sm'>
        Your account has been disabled. Please contact support@usemotion.com
      </p>
    )
  }

  if (error === 'auth/wrong-password') {
    return (
      <p className='text-semantic-error-text-default text-sm'>
        Incorrect password. Please try again or reset your password.
      </p>
    )
  }

  if (error === 'auth/wrong-provider-google') {
    return (
      <p className='text-semantic-error-text-default text-sm'>
        Please log in with Google.
      </p>
    )
  }

  if (error === 'auth/wrong-provider-microsoft') {
    return (
      <p className='text-semantic-error-text-default text-sm'>
        Please log in with Microsoft.
      </p>
    )
  }

  if (error === 'auth/wrong-provider-apple') {
    return (
      <p className='text-semantic-error-text-default text-sm'>
        Please log in with Apple.
      </p>
    )
  }

  if (error === 'auth/wrong-provider-unknown') {
    return (
      <p className='text-semantic-error-text-default text-sm'>
        Please log in with Google, Microsoft, or Apple.
      </p>
    )
  }

  if (error === 'auth/wrong-password-or-wrong-provider') {
    return (
      <div className='text-semantic-error-text-default text-sm'>
        <p>Login failed due to one of the following reasons:</p>
        <ol>
          <li>Incorrect email & password combination,</li>
          <li>
            this email is associated with a different &quot;login&quot; method
            such as &quot;login with Google/Microsoft/Apple&quot;
          </li>
        </ol>
      </div>
    )
  }

  return (
    <p className='text-semantic-error-text-default text-sm'>
      We were not able to process this request. Please contact
      support@usemotion.com
    </p>
  )
}

type EmailPasswordSigninProps = {
  email: string
  password: string
  setEmail: (email: string) => void
  setPassword: (password: string) => void
  disabled: boolean
  error: LoginErrors | null
  loading?: boolean
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const EmailPasswordSignin = ({
  email,
  password,
  setEmail,
  setPassword,
  disabled,
  loading = false,
  error,
  onSubmit,
}: EmailPasswordSigninProps) => {
  return (
    <div className='flex flex-col mt-5 w-full gap-y-4'>
      <div className='flex text-semantic-neutral-text-subtle w-full items-center'>
        <div className='flex-1 h-px bg-semantic-neutral-border-default' />
        <p className='flex-2 px-4'>Or</p>
        <div className='flex-1 h-px bg-semantic-neutral-border-default' />
      </div>
      <form className='w-full' onSubmit={onSubmit}>
        <div className='w-full flex flex-col gap-y-3'>
          <TextField
            label={
              <p className='mb-2 text-sm font-semibold text-semantic-neutral-text-subtle'>
                Email
              </p>
            }
            onChange={setEmail}
            value={email}
            placeholder='name@company.com'
            type='email'
          />
          <div className='flex w-full justify-between items-center'>
            <p className='font-semibold text-sm text-semantic-neutral-text-subtle'>
              Password
            </p>
            <a
              className='text-sm text-semantic-primary-text-default'
              href='/forgot-password/reset'
            >
              Forgot your password?
            </a>
          </div>
          <TextField
            value={password}
            onChange={setPassword}
            placeholder='Enter your password'
            type='password'
          />
          <EmailPasswordSignInError error={error} />
          <Tooltip
            asChild
            content={
              disabled && 'Enter an email and password of at least 8 characters'
            }
          >
            <Button disabled={disabled} loading={loading} type='submit'>
              Sign in
            </Button>
          </Tooltip>
        </div>
      </form>
    </div>
  )
}
