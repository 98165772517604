import { classed } from '@motion/theme'

import { type ReactNode, useState } from 'react'

type ControlledUnstyledCollapsableContainerProps = {
  initialExpanded?: boolean
  renderHeader: ({
    expanded,
    toggle,
  }: {
    expanded: boolean
    toggle: () => void
  }) => ReactNode
  children: ReactNode
  className?: string
  onToggle?: (expanded: boolean) => void
}
export const ControlledUnstyledCollapsableContainer = ({
  initialExpanded = true,
  renderHeader,
  children,
  className,
  onToggle,
}: ControlledUnstyledCollapsableContainerProps) => {
  const [expanded, setExpanded] = useState(initialExpanded)
  const toggle = () => {
    setExpanded((expanded) => !expanded)
    onToggle?.(!expanded)
  }

  return (
    <UnstyledCollapsableContainer
      expanded={expanded}
      className={className}
      renderHeader={renderHeader}
      toggle={toggle}
    >
      {children}
    </UnstyledCollapsableContainer>
  )
}

export function UnstyledCollapsableContainer({
  expanded,
  toggle,
  renderHeader,
  renderHeaderInline = false,
  children,
  className,
}: Omit<ControlledUnstyledCollapsableContainerProps, 'initialExpanded'> & {
  renderHeaderInline?: boolean
  expanded: boolean
  toggle: () => void
}) {
  return (
    <>
      {renderHeaderInline && renderHeader({ expanded, toggle })}
      <Container
        expanded={expanded}
        aria-expanded={expanded}
        className={className}
      >
        {/* Empty node to keep transition working */}
        {renderHeaderInline ? <div /> : renderHeader({ expanded, toggle })}
        <Content visible={expanded} aria-hidden={expanded ? undefined : true}>
          {children}
        </Content>
      </Container>
    </>
  )
}

const Container = classed('div', {
  base: `
    motion-safe:transition-[grid-template-rows]
    motion-safe:duration-[var(--animate-time,200ms)]
    grid
  `,
  variants: {
    expanded: {
      true: '[grid-template-rows:min-content_1fr]',
      false: '[grid-template-rows:min-content_0fr]',
    },
  },
})

const Content = classed('div', {
  base: 'overflow-hidden',
  variants: {
    visible: {
      true: 'motion-safe:animate-fadeIn',
      false: 'motion-safe:animate-fadeOut',
    },
  },
})
