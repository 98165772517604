import { type BaseToast, type CustomToast } from './types'

interface ToastOptions {
  duration?: number
}

type ToastType = (BaseToast | CustomToast) & {
  opts: Required<ToastOptions>
}
export type SubscriberToast = ToastType & {
  id: number
  dismissed: boolean
  removed: boolean
}
type ToastSubscriber = (toast: SubscriberToast) => void

class ToasterStateClass {
  subscribers: ToastSubscriber[]
  timeoutId: number | null
  toastCountId: number
  lastToast: SubscriberToast | null

  constructor() {
    this.subscribers = []
    this.timeoutId = null
    this.toastCountId = 0
    this.lastToast = null
  }

  subscribe(subscriber: ToastSubscriber) {
    this.subscribers.push(subscriber)

    if (this.lastToast != null) {
      this.publish(this.lastToast)
    }

    return () => {
      const index = this.subscribers.indexOf(subscriber)
      this.subscribers.splice(index, 1)
    }
  }

  publish(data: SubscriberToast) {
    this.subscribers.forEach((subscriber) => subscriber(data))
  }

  private maybeClearTimeout() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId)
      this.timeoutId = null
    }
  }

  addToast(data: ToastType) {
    this.lastToast = {
      ...data,
      id: this.toastCountId++,
      dismissed: false,
      removed: false,
    }

    this.publish(this.lastToast)
    this.maybeClearTimeout()
  }

  dismiss(data: SubscriberToast) {
    if (this.lastToast?.id !== data.id) return

    this.publish({ ...data, dismissed: true })
    this.maybeClearTimeout()
    this.timeoutId = window.setTimeout(() => {
      this.publish({ ...data, removed: true })
      // Toast disappear with `animate-hide` which lasts 100ms
    }, 200)
  }
}

export const ToasterState = new ToasterStateClass()

const defaultOptions: Required<ToastOptions> = {
  duration: 5_000,
}

export function showToast(
  type: 'custom',
  renderFn: CustomToast['renderFn'],
  options?: ToastOptions
): void
export function showToast(
  type: BaseToast['type'],
  message: BaseToast['message'],
  options?: ToastOptions
): void
export function showToast<T extends ToastType['type']>(
  type: T,
  messageOrRender: BaseToast['message'] | CustomToast['renderFn'],
  options: ToastOptions = {}
): void {
  const opts = { ...defaultOptions, ...options }

  if (type === 'custom') {
    ToasterState.addToast({
      type,
      renderFn: messageOrRender as CustomToast['renderFn'],
      opts,
    })
  } else {
    ToasterState.addToast({
      type,
      message: messageOrRender as BaseToast['message'],
      opts,
    })
  }
}
