import { SearchableListItem } from './common'
import { type SelectableListItemProps } from './types'

import { Checkbox } from '../../../forms'

export const SearchableListCheckboxItem = ({
  selected,
  children,
  ...props
}: SelectableListItemProps) => {
  return (
    <SearchableListItem
      {...props}
      role='menuitemcheckbox'
      aria-checked={selected || undefined}
    >
      <div className='relative flex gap-2 items-center grow truncate'>
        <Checkbox
          checked={selected}
          onChange={() => props.onSelect()}
          label='Select'
          labelHidden
        />
        {children}
      </div>
    </SearchableListItem>
  )
}
