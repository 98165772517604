import { entries } from './iterate'

export function definedValueCount<T extends object>(
  values: T | null | undefined
) {
  if (values == null) return 0
  return entries(values).filter(([, value]) => {
    if (value == null) return false
    if (typeof value === 'object') return Object.keys(value).length > 0
    if (Array.isArray(value)) return value.length > 0
    return true
  }).length
}
