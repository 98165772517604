export type NetworkStatus = 'online' | 'offline'
type NetworkStatusHandler = (status: NetworkStatus) => void

let networkStatus: NetworkStatus = 'online'
let listeners: NetworkStatusHandler[] = []

export const isOnline = () => networkStatus === 'online'
export const getNetworkStatus = () => networkStatus

export function onNetworkStatusChanged(
  handler: (status: NetworkStatus) => void
) {
  listeners.push(handler)
  handler(networkStatus)

  return () => (listeners = listeners.filter((x) => x !== handler))
}

const setNetworkStatus = (status: NetworkStatus) => {
  networkStatus = status
  listeners.forEach((handler) => handler(networkStatus))
}

if (typeof window !== 'undefined') {
  window.addEventListener('online', () => setNetworkStatus('online'))
  window.addEventListener('offline', () => setNetworkStatus('offline'))
}
