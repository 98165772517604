import { parseDate } from '@motion/utils/dates'
import { type TaskSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'

export function isValidTaskDeadlineDateOption(
  task: Pick<TaskSchema, 'type' | 'startDate'>,
  date: DateTime
) {
  if (task.type === 'RECURRING_INSTANCE') {
    return task.startDate != null && date >= parseDate(task.startDate)
  }

  return date >= DateTime.now().startOf('day')
}
