import { useQuery as useReactQuery } from '@tanstack/react-query'

import { useQueryOptionsFactory } from './use-query-options-factory'

import {
  type ApiArgs,
  type ApiUseQueryOptions,
  type ApiUseQueryResult,
  type LooseApiDefinition,
} from '../../core/types'

export function useQuery<TApi extends LooseApiDefinition>(
  api: TApi,
  args: ApiArgs<TApi>,
  opts?: ApiUseQueryOptions<typeof api>
): ApiUseQueryResult<typeof api> {
  const queryOptionsOf = useQueryOptionsFactory(api)
  return useReactQuery(queryOptionsOf(args, opts))
}
