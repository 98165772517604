import { useEffect } from 'react'

import { useClosure } from './use-closure'

type IntervalCallback = () => void
type IntervalDelay = number | null

export function useInterval(
  callback: IntervalCallback,
  delayMs: IntervalDelay
) {
  const handler = useClosure(callback)

  useEffect(() => {
    if (delayMs == null) return

    const id = setInterval(handler, delayMs)
    return () => clearInterval(id)
  }, [delayMs, handler])
}
