export function safeJsonParse<T>(
  value: string | null | undefined
): T | undefined
export function safeJsonParse<T>(
  value: string | null | undefined,
  defaultValue: T
): T
export function safeJsonParse(
  value: string | null | undefined,
  defaultValue?: any
): any {
  if (!value) return defaultValue
  try {
    return JSON.parse(value)
  } catch (ex) {
    return defaultValue
  }
}
