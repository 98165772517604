import { DateTime } from 'luxon'

/**
 * Determines whether a newly selected date in the "Date Range" mode of our
 * datepicker should start a new range
 * @param dateRangeValues
 * @param day
 */
export const isNewDatePartOfExistingDateRange = (
  dateRangeValues: string[],
  day: DateTime
): boolean => {
  const dates = dateRangeValues.map((item) => DateTime.fromISO(item))

  return !!(
    dates.length === 2 &&
    dates[0].hasSame(dates[1], 'day') &&
    dates[0] < day
  )
}

/*
 *
 * Returns a list of all the dates between the start date & end date
 * including the start date and the end date
 *
 * @param startDate: DateTime
 * @param: endDate: DateTime
 * */
export function getDatesBetween(
  startDate: DateTime,
  endDate: DateTime
): DateTime[] {
  const { days: daysBetween } = endDate.diff(startDate, ['days'])
  return Array.from({ length: daysBetween + 1 }, (_, days) => {
    return startDate.plus({ days })
  })
}

/*
 * Returns the date as a key string for use in maps
 *
 * @param date: DateTime
 * */
export function dateToDateStringKey(date: DateTime): string {
  return date.startOf('day').toISODate()
}
