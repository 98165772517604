import { useCallback, useRef } from 'react'

export type UseScrollContainerProps = {
  scrollAmount: number
}

type Direction = 'left' | 'right' | 'up' | 'down'

export const useScrollContainer = <T extends HTMLElement>(
  { scrollAmount }: UseScrollContainerProps = {
    scrollAmount: 20,
  }
) => {
  const scrollContainerRef = useRef<T>(null)
  const scrollAnimationFrame = useRef<number>(0)

  const handleScrollOnMouseDown = useCallback(
    (direction: Direction) => {
      const isHorizontal = direction === 'left' || direction === 'right'
      const amount =
        direction === 'left' || direction === 'up'
          ? -scrollAmount
          : scrollAmount

      const scroll = () => {
        scrollContainerRef.current?.scrollBy({
          left: isHorizontal ? amount : 0,
          top: !isHorizontal ? amount : 0,
          behavior: 'auto',
        })
        scrollAnimationFrame.current = requestAnimationFrame(scroll)
      }

      scroll()

      const mouseUpHandler = () => {
        if (scrollAnimationFrame) {
          cancelAnimationFrame(scrollAnimationFrame.current)
          scrollAnimationFrame.current = 0
        }
        document.removeEventListener('mouseup', mouseUpHandler)
      }

      document.addEventListener('mouseup', mouseUpHandler)
    },
    [scrollAmount]
  )

  const handleScrollOnClick = useCallback(
    (direction: Direction) => {
      const isHorizontal = direction === 'left' || direction === 'right'
      const amount =
        direction === 'left' || direction === 'up'
          ? -scrollAmount
          : scrollAmount

      return scrollContainerRef.current?.scrollBy({
        left: isHorizontal ? amount : 0,
        top: !isHorizontal ? amount : 0,
        behavior: 'smooth',
      })
    },
    [scrollAmount]
  )

  return {
    scrollContainerRef,
    getListeners: (direction: Direction) => ({
      onClick: () => handleScrollOnClick(direction),
      onMouseDown: () => handleScrollOnMouseDown(direction),
    }),
  }
}
