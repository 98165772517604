function formatTime(start: number, end: number) {
  return (end - start).toFixed(2).padStart(8)
}
function isPromise<T>(value: Promise<T> | T): value is Promise<T> {
  return typeof value === 'object' && 'then' in (value as any)
}

export type TimeFn = {
  <T>(label: string, fn: () => T): T
}

// eslint-disable-next-line no-console
export const time = createTimer(console.debug.bind(console))

export function createTimer(logger: (msg: string) => void): TimeFn {
  // @ts-expect-error - type is fine
  return function time<T>(
    label: string,
    fn: () => Promise<T> | T
  ): Promise<T> | T {
    const start = performance.now()
    const result = fn()
    if (isPromise(result)) {
      return result.then((ret) => {
        const end = performance.now()
        // eslint-disable-next-line no-console
        logger(`TIME: [${formatTime(start, end)}] ${label}`)
        return ret
      })
    }
    const end = performance.now()
    // eslint-disable-next-line no-console
    logger(`TIME: [${formatTime(start, end)}] ${label}`)
    return result
  }
}
