import { ChevronLeftSolid, ChevronRightSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { type CalendarStartDay } from '@motion/ui-logic/calendar'

import { DateTime } from 'luxon'
import {
  Fragment,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react'

import { type BarredDateRangeData, type DateRangeColorData } from './types'
import { Day, useDatePicker } from './use-date-picker'
import { dateToDateStringKey, isNewDatePartOfExistingDateRange } from './utils'

import { ProjectPalette } from '../../project'
import { Button } from '../button'
import { Tooltip } from '../tooltip'

type DatePickerMode = 'multiple' | 'range' | 'single'
type Variant = 'default' | 'highlight'
type Size = 'small' | 'normal'
export interface DatePickerProps {
  value: string[] | string | null
  mode?: DatePickerMode
  weekStartDay?: CalendarStartDay
  onChange: (value: string[] | string | null) => void
  onNextMonth?: () => void
  onPreviousMonth?: () => void
  disabledDate?: (date: DateTime) => boolean
  disabledDateTooltipContent?: ReactNode
  variant?: Variant
  size?: Size
  highlightSelectedWeek?: boolean
  dateRangeColors?: DateRangeColorData[]
  barredRange?: BarredDateRangeData
}
export const DatePicker = ({
  value,
  onChange,
  onNextMonth,
  onPreviousMonth,
  mode = 'single',
  weekStartDay = 'sunday',
  disabledDate = () => false,
  disabledDateTooltipContent,
  variant = 'default',
  size = 'normal',
  highlightSelectedWeek = false,
  dateRangeColors,
  barredRange,
}: DatePickerProps) => {
  const {
    calendar,
    select,
    isSelected,
    deselect,
    viewPreviousMonth,
    viewNextMonth,
    viewing,
    setViewing,
    clearSelected,
    dateStringToColor,
  } = useDatePicker({
    weekStartsOn: weekStartDay === 'sunday' ? Day.SUNDAY : Day.MONDAY,
    viewing: value
      ? DateTime.fromISO(Array.isArray(value) ? value[0] : value)
      : DateTime.now(),
    selected: value
      ? Array.isArray(value)
        ? value.map((val) => DateTime.fromISO(val))
        : [DateTime.fromISO(value)]
      : [],
    dateRangeColors,
  })

  const weekDays = useMemo(() => {
    const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
    if (weekStartDay === 'monday') {
      const first = days.shift()
      if (first) days.push(first)
      return days
    }

    return days
  }, [weekStartDay])

  useEffect(() => {
    clearSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  useEffect(() => {
    if (!value) {
      clearSelected()
      setViewing(DateTime.now())
      return
    }

    if (!Array.isArray(value) || mode === 'single') {
      const date = DateTime.fromISO(Array.isArray(value) ? value[0] : value)
      select(date, true)
      setViewing(date)
    } else if (Array.isArray(value) && mode === 'range') {
      clearSelected()

      let startDate, endDate
      if (value[0]) {
        startDate = DateTime.fromISO(value[0])
        select(startDate, false)
      }

      if (value[1]) {
        endDate = DateTime.fromISO(value[1])
        select(endDate, false)
      }

      if (endDate) {
        setViewing(endDate)
      } else if (startDate) {
        setViewing(startDate)
      }
    } else if (Array.isArray(value) && mode === 'multiple') {
      clearSelected()

      const dates = value.map((val) => DateTime.fromISO(val))
      dates.forEach((date) => select(date, false))
      if (dates.filter((date) => !!date).length > 0) {
        setViewing(dates[dates.length - 1])
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleDaySelect = (day: DateTime) => {
    if (!day) return
    if (mode === 'single') {
      select(day, true)
      onChange(day.toISO())
    } else if (mode === 'range') {
      // If the start and end date are the same, the newly selected date becomes
      // the end date of the range
      if (
        value &&
        Array.isArray(value) &&
        isNewDatePartOfExistingDateRange(value, day)
      ) {
        select(day, false)
        onChange([value[0], day.toISO()])
        return
      }

      select(day, true)
      onChange([day.toISO(), day.toISO()])
    } else if (mode === 'multiple') {
      if (isSelected(day)) {
        deselect(day)
        if (Array.isArray(value)) {
          onChange(value.filter((val) => val !== day.toISO()))
        } else {
          onChange([])
        }
      } else {
        select(day, false)
        onChange([...(value || []), day.toISO()])
      }
    }
  }

  const getColorDataForDay = useCallback(
    (day: DateTime) => {
      if (day && Array.isArray(dateRangeColors) && dateRangeColors.length > 0) {
        const dateStringKey = dateToDateStringKey(day)
        const color = dateStringToColor.get(dateStringKey)
        const isEndDate = dateRangeColors.some(
          (range) => dateToDateStringKey(range.endDate) === dateStringKey
        )
        return { color, isEndDate }
      }

      return { color: undefined, isEndDate: false }
    },
    [dateRangeColors, dateStringToColor]
  )

  // Given a date and mode is range,
  const isDayInRange = (day: DateTime) => {
    if (
      mode === 'range' &&
      day &&
      Array.isArray(value) &&
      value.filter((date) => !!date).length === 2
    ) {
      const value0StartDay = DateTime.fromISO(value[0]).startOf('day')
      const value1StartDay = DateTime.fromISO(value[1]).startOf('day')

      if (
        value0StartDay <= day &&
        value1StartDay >= day &&
        !value0StartDay.equals(value1StartDay)
      ) {
        return {
          isInRange: true,
          isFirst: day.equals(value0StartDay),
          isLast: day.equals(value1StartDay),
        }
      }
    }

    return {
      isInRange: false,
      isFirst: false,
      isLast: false,
    }
  }

  return (
    <div className='w-fit'>
      <div className='flex items-center justify-between'>
        <Button
          iconOnly
          sentiment='neutral'
          variant='muted'
          size='small'
          onClick={() => {
            viewPreviousMonth()
            onPreviousMonth?.()
          }}
        >
          <ChevronLeftSolid />
        </Button>
        <Title size={size}>{viewing.toFormat('LLLL yyyy')}</Title>
        <Button
          iconOnly
          sentiment='neutral'
          variant='muted'
          size='small'
          onClick={() => {
            viewNextMonth()
            onNextMonth?.()
          }}
        >
          <ChevronRightSolid />
        </Button>
      </div>
      <WeekDayRow size={size}>
        {weekDays.map((weekday) => (
          <WeekDay key={weekday} size={size}>
            {weekday}
          </WeekDay>
        ))}
      </WeekDayRow>

      {calendar.map((month) => (
        <Fragment key={month[0][0].toString()}>
          {month.map((week) => {
            const isDayWithinWeek = highlightSelectedWeek
              ? week.some(isSelected)
              : false

            return (
              <WeekRow
                key={`${month[0][0].toString()}-${week[0]}`}
                selected={isDayWithinWeek}
              >
                {week.map((day) => {
                  const dayIsSelected = isSelected(day)
                  const isDisabled = disabledDate(day)
                  const {
                    isInRange,
                    isFirst: isFirstDayInRange,
                    isLast: isLastDayInRange,
                  } = isDayInRange(day)

                  const hasLeftBar =
                    barredRange?.startDate.startOf('day').equals(day) ?? false
                  const hasRightBar =
                    barredRange?.endDate.startOf('day').equals(day) ?? false

                  const { color, isEndDate } = getColorDataForDay(day)

                  const dayContent = (
                    <DayNumberWrapper
                      key={day.toString()}
                      inRange={isInRange}
                      isFirstDayInRange={isFirstDayInRange}
                      isLastDayInRange={isLastDayInRange}
                      disabled={isDisabled}
                      isEndDate={isEndDate && !hasRightBar}
                      isColored={color != null}
                      hasLeftBar={hasLeftBar}
                      hasRightBar={hasRightBar}
                    >
                      <Tooltip
                        asChild
                        renderContent={
                          isDisabled && disabledDateTooltipContent
                            ? () => disabledDateTooltipContent
                            : undefined
                        }
                      >
                        <DayNumberButton
                          disabled={isDisabled}
                          variant={variant}
                          size={size}
                          selected={
                            dayIsSelected ||
                            isFirstDayInRange ||
                            isLastDayInRange
                          }
                          isSameMonth={day.month === viewing.month}
                          isToday={
                            DateTime.now().hasSame(day, 'day') && !isInRange
                          }
                          isEndDate={isEndDate}
                          onClick={() => {
                            if (!isDisabled) {
                              handleDaySelect(day)
                            }
                          }}
                        >
                          {day.toFormat('d')}
                        </DayNumberButton>
                      </Tooltip>
                    </DayNumberWrapper>
                  )

                  return color != null ? (
                    <ProjectPalette colorOption={color} key={day.toString()}>
                      {dayContent}
                    </ProjectPalette>
                  ) : (
                    dayContent
                  )
                })}
              </WeekRow>
            )
          })}
        </Fragment>
      ))}
    </div>
  )
}

const Title = classed('span', {
  base: `text-semantic-neutral-text-default font-semibold`,
  variants: {
    size: {
      small: 'text-xs',
      normal: 'text-sm',
    },
  },
})
const WeekDayRow = classed('div', {
  base: `
    text-xs
    text-semantic-neutral-text-disabled
    text-center
    flex
  `,
  variants: { size: { small: ' py-1.5', normal: ' py-2' } },
})

const WeekRow = classed('div', {
  base: 'my-0.5 flex',
  variants: {
    selected: {
      true: 'rounded bg-semantic-neutral-bg-subtle',
    },
  },
})

const WeekDay = classed('div', {
  base: 'font-semibold',
  variants: {
    size: {
      small: 'w-7 text-[11px]',
      normal: 'w-9 text-sm',
    },
  },
})

const DayNumberWrapper = classed('div', {
  base: 'flex',
  variants: {
    inRange: {
      true: '',
    },
    isFirstDayInRange: {
      true: 'bg-gradient-to-r from-transparent to-datepicker-item-bg-range',
    },
    isLastDayInRange: {
      true: 'bg-gradient-to-l from-transparent to-datepicker-item-bg-range',
    },
    variant: {
      default: '',
      highlight: '',
    },
    disabled: {
      true: '',
      false: '',
    },
    isEndDate: {
      true: 'rounded-r-full',
    },
    isColored: {
      true: 'bg-palette-bg-default',
    },
    hasLeftBar: {
      true: 'border-l border-black',
    },
    hasRightBar: {
      true: 'border-r border-black',
    },
  },
  compoundVariants: [
    {
      inRange: true,
      isFirstDayInRange: false,
      isLastDayInRange: false,
      class: 'bg-datepicker-item-bg-range',
    },
  ],
})

const DayNumberButton = classed('button', {
  base: `
    mx-0.5
    inline-block
    font-medium
    rounded
    border border-transparent

    hover:border-datepicker-item-border-hover

    focus-visible:outline-0
    focus-visible:transition-shadow
    focus-visible:ring-2
    focus-visible:ring-offset-datepicker-item-border-focus
    focus-visible:ring-datepicker-item-border-focus
  `,
  variants: {
    disabled: {
      true: 'cursor-not-allowed opacity-50',
      false: 'group cursor-pointer',
    },
    selected: {
      true: '',
      false: 'text-semantic-primary-text-strong',
    },
    isSameMonth: { true: '' },
    isToday: {
      true: 'bg-datepicker-item-bg-today text-datepicker-item-text-today',
    },
    variant: { highlight: '', default: '' },
    size: {
      small: 'w-6 h-6 text-xs',
      normal: 'w-8 h-8 text-sm',
    },
    isEndDate: {
      true: 'rounded-full bg-palette-bg-hover',
    },
  },
  compoundVariants: [
    {
      selected: true,
      isToday: false,
      class:
        'bg-datepicker-item-bg-selected text-datepicker-item-text-selected',
    },
    {
      disabled: true,
      selected: false,
      isToday: false,
      class: 'text-datepicker-item-text-muted',
    },
    {
      isSameMonth: false,
      selected: false,
      isToday: false,
      class: 'text-datepicker-item-text-muted',
    },
    {
      variant: 'highlight',
      disabled: false,
      selected: false,
      class: 'bg-datepicker-item-bg-range',
    },
  ],
})
