import {
  type DateFilterSchema,
  type DefinedRelativeDateFilterSchema,
  type LogicalDateFilterSchema,
  type RangeDateFilterSchema,
  type RelativeDateFilterSchema,
  type ViewDateFilterSchema,
} from '@motion/zod/client'

import { DateTime, type DateTimeUnit, Duration } from 'luxon'

import { type CalendarStartDay } from '../../../../../calendar'

const LOGICAL_OPS = [
  'gt',
  'gte',
  'lt',
  'lte',
  'equals',
] as LogicalDateFilterSchema['operator'][]

export function isLogicalFilter(
  obj: ViewDateFilterSchema
): obj is LogicalDateFilterSchema {
  return LOGICAL_OPS.includes(obj.operator)
}

export function buildDateFilterQuery(
  filter: ViewDateFilterSchema | null | undefined,
  startDay: CalendarStartDay = 'sunday'
): DateFilterSchema | undefined {
  if (filter == null) return undefined
  if (filter.operator === 'defined') return filter
  if (filter.operator === 'empty') return filter

  if (filter.operator === 'relative') {
    return buildRelativeDateQuery(filter, startDay)
  }

  if (filter.operator === 'defined-relative') {
    return buildRelativeRangeDateQuery(filter, startDay)
  }

  if (filter.operator === 'range') return normalizeRangeFilter(filter)

  return normalizeLogicalFilters(filter)
}

export function buildRelativeDateQuery(
  filter: RelativeDateFilterSchema,
  startDay: CalendarStartDay = 'sunday'
): RangeDateFilterSchema {
  const now = DateTime.now().setZone('default')

  const duration = Duration.fromISO(filter.duration)
  const farEnd = now.plus(duration)
  const [from, to] = [now, farEnd].sort()

  return {
    operator: 'range',
    inverse: filter.inverse,
    value: {
      from: from.startOf('day').toISO(),
      to: to.endOf('day').toISO(),
    },
  }
}

export function buildRelativeRangeDateQuery(
  filter: DefinedRelativeDateFilterSchema,
  startDay: CalendarStartDay = 'sunday'
): DateFilterSchema {
  const base = buildRelativeRangeDateQueryBase(filter, startDay)
  return {
    ...base,
    inverse: filter.inverse,
  }
}

function buildRelativeRangeDateQueryBase(
  filter: DefinedRelativeDateFilterSchema,
  startDay: CalendarStartDay = 'sunday'
): DateFilterSchema {
  const now = DateTime.now()

  switch (filter.name) {
    case 'today':
      return createDateUnitRangeFilter(now, 'day', 0)
    case 'yesterday':
      return createDateUnitRangeFilter(now, 'day', -1)
    case 'tomorrow':
      return createDateUnitRangeFilter(now, 'day', 1)

    // These are 1 less than 'expected' since it includes the current day
    case 'next-7-days':
      return createRelativeUnitFilter(now, 'day', 6)
    case 'next-14-days':
      return createRelativeUnitFilter(now, 'day', 13)
    case 'last-7-days':
      return createRelativeUnitFilter(now, 'day', -6)
    case 'last-14-days':
      return createRelativeUnitFilter(now, 'day', -13)

    case 'this-week':
      return createDateUnitRangeFilter(now, 'week', 0)
    case 'last-week':
      return createDateUnitRangeFilter(now, 'week', -1)
    case 'next-week':
      return createDateUnitRangeFilter(now, 'week', 1)

    case 'this-month':
      return createDateUnitRangeFilter(now, 'month', 0)
    case 'last-month':
      return createDateUnitRangeFilter(now, 'month', -1)
    case 'next-month':
      return createDateUnitRangeFilter(now, 'month', 1)
  }
}

function createRelativeUnitFilter(
  base: DateTime,
  unit: DateTimeUnit,
  count: number
): DateFilterSchema {
  const farSide = base.plus({ [unit]: count })

  const [left, right] = [base, farSide].sort()
  return {
    operator: 'range',
    value: {
      from: left.startOf(unit).toISO(),
      to: right.endOf(unit).toISO(),
    },
  }
}

const createDateUnitRangeFilter = (
  base: DateTime,
  unit: DateTimeUnit,
  count: number
): DateFilterSchema => {
  const offset = base.plus({ [unit]: count })
  return {
    operator: 'range',
    value: {
      from: offset.startOf(unit).toISO(),
      to: offset.endOf(unit).toISO(),
    },
  }
}

function normalizeRangeFilter(filter: RangeDateFilterSchema): DateFilterSchema {
  return {
    ...filter,
    value: {
      from: DateTime.fromISO(filter.value.from).startOf('day').toISO(),
      to: DateTime.fromISO(filter.value.to).endOf('day').toISO(),
    },
  }
}

function normalizeLogicalFilters(
  filter: LogicalDateFilterSchema
): DateFilterSchema {
  const value = DateTime.fromISO(filter.value)
  if (filter.operator === 'equals') {
    return {
      inverse: filter.inverse,
      operator: 'range',
      value: {
        from: value.startOf('day').toISO(),
        to: value.endOf('day').toISO(),
      },
    }
  }
  if (filter.operator === 'gt') {
    return { ...filter, value: value.endOf('day').toISO() }
  }
  if (filter.operator === 'lt') {
    return { ...filter, value: value.startOf('day').toISO() }
  }
  if (filter.operator === 'gte') {
    return { ...filter, value: value.startOf('day').toISO() }
  }

  if (filter.operator === 'lte') {
    return { ...filter, value: value.endOf('day').toISO() }
    /* c8 ignore start */
  }

  // This shouldn't ever be hit.
  // But just in case, return the filter
  return filter
}
