import { useEffect, useRef } from 'react'

import { useClosure } from './use-closure'

export function useClickOutside<T extends HTMLElement>(
  callback: (element: HTMLElement) => void
) {
  const ref = useRef<T>(null)
  const closure = useClosure(callback)

  useEffect(() => {
    const listener = (evt: MouseEvent) => {
      const target = evt.target as HTMLElement
      if (ref.current && !ref.current.contains(target)) {
        closure(target)
      }
    }

    document.addEventListener('mousedown', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, closure])

  return ref
}
