import { createKey, defineApi, defineMutation, SKIP_UPDATE } from '../../core'
import { type RouteTypes } from '../types'

export const queryKeys = {
  root: createKey(['v2', 'users']),
  mySettings: () => ['v2', 'users', 'me', 'settings'] as const,
}

type GetMySettings = RouteTypes<'UsersController_getMySettings'>

type UpdateCtaSettings =
  RouteTypes<'UsersController_updateMySettingsCallToActions'>

type UpdateTimezoneSettings = RouteTypes<'UsersController_updateMyTimezones'>

type UpdateAutoScheduleSettings =
  RouteTypes<'UsersController_updateAutoScheduleSettings'>

export const getMySettings = defineApi<
  GetMySettings['request'],
  GetMySettings['response']
>().using({
  key: () => queryKeys.mySettings(),
  uri: `/v2/users/me/settings`,
  method: 'GET',
})

export const updateCtaSettings = defineMutation<
  UpdateCtaSettings['request'],
  UpdateCtaSettings['response']
>().using({
  key: () => queryKeys.mySettings(),
  uri: `/v2/users/me/settings/call-to-actions`,
  method: 'PATCH',
  effects: [
    {
      on: 'mutate',
      action: 'update',
      key: (args) => queryKeys.mySettings(),
      merge: (value, prev) => (prev ? { ...prev, ...value } : SKIP_UPDATE),
    },
    {
      on: 'success',
      action: 'update',
      key: (args) => queryKeys.mySettings(),
      merge: (value) => value,
    },
  ],
})

export const updateTimezoneSettings = defineMutation<
  UpdateTimezoneSettings['request'],
  UpdateTimezoneSettings['response']
>().using({
  key: () => queryKeys.mySettings(),
  uri: `/v2/users/me/settings/timezones`,
  method: 'PATCH',
  effects: [
    {
      on: 'mutate',
      action: 'update',
      key: (args) => queryKeys.mySettings(),
      merge: (value, prev) => (prev ? { ...prev, ...value } : SKIP_UPDATE),
    },
    {
      on: 'success',
      action: 'update',
      key: (args) => queryKeys.mySettings(),
      merge: (value) => value,
    },
  ],
})

export const updateAutoScheduleSettings = defineMutation<
  UpdateAutoScheduleSettings['request'],
  UpdateAutoScheduleSettings['response']
>().using({
  key: () => queryKeys.mySettings(),
  uri: `/v2/users/me/settings/auto-schedule`,
  method: 'PATCH',
  effects: [
    {
      on: 'mutate',
      action: 'update',
      key: (args) => queryKeys.mySettings(),
      merge: (value, prev) => (prev ? { ...prev, ...value } : SKIP_UPDATE),
    },
    {
      on: 'success',
      action: 'update',
      key: (args) => queryKeys.mySettings(),
      merge: (value) => value,
    },
  ],
})
