import { XSolid } from '@motion/icons'
import { classed, type VariantProps } from '@motion/theme'

import { forwardRef, type MutableRefObject, type ReactNode } from 'react'

import { IconButton } from './icon-button'

import { Tooltip, type TooltipProps } from '../tooltip'

const ButtonWrapper = classed('div', {
  base: `
    group relative inline-flex items-center
  `,
  variants: {
    fullWidth: {
      true: 'w-full min-w-0',
    },
  },
})

const StyledButton = classed('button', {
  base: `
    relative inline-flex items-center
    text-left

    font-normal

    rounded
    box-border
    border border-solid
    border-transparent


    text-button-neutral-outlined-text-default
    [&_[data-icon]]:flex
    [&_[data-icon]]:items-center
    [&_[data-icon]]:shrink-0
    [&_[data-icon]]:text-[7px]
    [&_[data-icon]]:text-field-icon-default

    bg-button-neutral-outlined-bg-default

    disabled:cursor-not-allowed
    disabled:text-button-neutral-outlined-text-disabled
    [&:disabled_[data-icon]]:text-button-neutral-outlined-icon-disabled


    focus-visible:outline
    focus-visible:outline-1
    focus-visible:-outline-offset-1
    focus-visible:outline-button-primary-solid-border-focus

  `,
  variants: {
    variant: {
      outlined: `
        bg-button-neutral-outlined-bg-default
        border-button-neutral-outlined-border-default
        disabled:border-button-neutral-outlined-border-disabled
      `,
      muted: `
        bg-transparent
        enabled:group-hover:bg-button-neutral-outlined-bg-default
        focus-visible:bg-button-neutral-outlined-bg-default
      `,
      minimal: `
        bg-transparent
        border-transparent
        enabled:group-hover:border-transparent
      `,
    },
    sentiment: {
      neutral: `
        enabled:group-hover:border-button-neutral-outlined-border-hover
      `,
      ai: `
        text-semantic-purple-text-default
        enabled:group-hover:border-semantic-purple-border-hover
        disabled:text-semantic-purple-text-disabled
        [&_[data-icon]]:text-semantic-purple-icon-default
        [&:disabled_[data-icon]]:text-semantic-purple-icon-disabled
      `,
      error: `
        enabled:border-field-border-error
        [&_[data-icon]]:text-button-error-outlined-icon-default
      `,
    },
    size: {
      normal: `
        gap-1.5
        text-sm px-[7px] py-[5px]
        [&_[data-icon]]:w-4
        [&_[data-icon]]:h-4
        [&_[data-icon]]:m-0.5
      `,
      small: `
        gap-1.5
        text-xs px-[7px] py-[3px]
        [&_[data-icon]]:w-4
        [&_[data-icon]]:h-4
      `,
      xsmall: `
        gap-1
        text-[10px] leading-4 px-[5px] py-px
        [&_[data-icon]]:w-3
        [&_[data-icon]]:h-3
      `,
    },
    fullWidth: {
      true: 'w-full min-w-0',
    },
    clearable: {
      true: 'enabled:group-hover:pr-6 enabled:group-focus-within:pr-6',
    },
    iconOnly: { true: '' },
    wrap: { true: 'flex-wrap' },
  },
  compoundVariants: [
    {
      variant: 'outlined',
      sentiment: 'ai',
      className: `
        enabled:border-semantic-purple-border-default
      `,
    },
    {
      iconOnly: true,
      size: 'xsmall',
      className: 'gap-0 px-[1px]',
    },
    {
      iconOnly: true,
      size: 'small',
      className: 'gap-0 px-[3px]',
    },
    {
      iconOnly: true,
      size: 'normal',
      className: 'gap-0 px-[5px]',
    },
  ],
  defaultVariants: {
    variant: 'outlined',
    sentiment: 'neutral',
    size: 'normal',
    fullWidth: false,
  },
})

const ReadOnlyDiv = classed('div', StyledButton)

const ClearButtonWrapper = classed(
  'div',
  'absolute top-1/2 -translate-y-1/2 right-[7px]',
  'hidden group-hover:block group-focus-within:block'
)

export type FieldButtonProps = VariantProps<typeof StyledButton> & {
  children: ReactNode
  disabled?: boolean
  onClear?: () => void
  clearTooltipContent?: TooltipProps['content']
  readOnly?: boolean
  onClick?: () => void
}

export const FieldButton = forwardRef<HTMLButtonElement, FieldButtonProps>(
  (props, ref) => {
    const {
      clearTooltipContent,
      onClear,
      readOnly,
      onClick,
      fullWidth = false,
      ...rest
    } = props
    if (readOnly) {
      return (
        <ReadOnlyDiv
          {...rest}
          fullWidth={fullWidth}
          ref={ref as unknown as MutableRefObject<HTMLDivElement | null>}
        />
      )
    }
    return (
      <ButtonWrapper fullWidth={fullWidth}>
        <StyledButton
          fullWidth={fullWidth}
          ref={ref}
          type='button'
          onClick={onClick}
          {...rest}
          clearable={onClear != null}
          data-id='field-button'
        />
        {onClear != null && !rest.disabled && (
          <ClearButtonWrapper>
            <Tooltip asChild content={clearTooltipContent}>
              <IconButton
                variant='muted'
                sentiment='neutral'
                icon={XSolid}
                onClick={() => onClear()}
                size='xsmall'
              />
            </Tooltip>
          </ClearButtonWrapper>
        )}
      </ButtonWrapper>
    )
  }
)

FieldButton.displayName = 'FieldButton'
