import { classed, type ComponentProps, type VariantProps } from '@motion/theme'

import {
  forwardRef,
  type ReactNode,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react'

import { StyledField, type StyledFieldProps } from './styled-field'

type AutoSize = {
  minRows?: number
  maxRows?: number
}

export type TextareaProps = Pick<
  ComponentProps<typeof StyledTextarea>,
  | 'autoFocus'
  | 'disabled'
  | 'onFocus'
  | 'onKeyDown'
  | 'placeholder'
  | 'readOnly'
  | 'rows'
  | 'value'
  | 'required'
  | 'onBlur'
> &
  StyledFieldProps &
  VariantProps<typeof StyledTextarea> & {
    onChange?: (value: string) => void
    icon?: ReactNode
    autoSize?: AutoSize
  }

const defaultAutoSize: AutoSize = { minRows: 2, maxRows: undefined }

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  function Textarea(props, ref) {
    const {
      sentiment,
      variant,
      disabled,
      icon,
      autoSize = {},
      rows,
      onChange,
      size,
      ...rest
    } = props

    const realAutoSize = { ...defaultAutoSize, ...autoSize }

    const inputRef = useRef<HTMLTextAreaElement>(null)
    const lineHeightRef = useRef(0)

    useImperativeHandle(ref, () => inputRef.current as any)

    const resizeTextarea = useCallback(() => {
      const computeRowValue = (row?: number) => {
        return row != null ? lineHeightRef.current * row : undefined
      }

      const computeRealLineHeight = () => {
        if (inputRef.current && lineHeightRef.current === 0) {
          lineHeightRef.current = parseInt(
            window
              .getComputedStyle(inputRef.current)
              .getPropertyValue('line-height'),
            10
          )
        }
      }

      if (autoSize != null && inputRef.current != null) {
        computeRealLineHeight()

        // reset its height first to get the real scrollHeight
        // This is required otherwise the `scrollHeight` never decrease
        inputRef.current.style.height = '0'

        const minHeight = computeRowValue(realAutoSize.minRows)
        const maxHeight = computeRowValue(realAutoSize.maxRows)

        const { scrollHeight, style: inputStyle } = inputRef.current

        if (minHeight != null && scrollHeight < minHeight) {
          inputStyle.height = `${minHeight}px`
        } else if (maxHeight != null && scrollHeight > maxHeight) {
          inputStyle.height = `${maxHeight}px`
        } else {
          inputStyle.height = `${scrollHeight}px`
        }
      }
    }, [autoSize, realAutoSize.maxRows, realAutoSize.minRows])

    useLayoutEffect(() => {
      resizeTextarea()
    }, [resizeTextarea, rest.value])

    const rowsValue =
      rows != null &&
      realAutoSize.minRows != null &&
      rows < realAutoSize.minRows
        ? realAutoSize.minRows
        : rows

    return (
      <StyledField
        className='items-start [&>[data-icon]]:mt-px'
        variant={variant}
        disabled={disabled}
        sentiment={sentiment}
        size={size}
      >
        {icon}
        <StyledTextarea
          ref={inputRef}
          rows={rowsValue}
          disabled={disabled}
          onChange={(e) => {
            onChange?.(e.currentTarget.value)
          }}
          {...rest}
        />
      </StyledField>
    )
  }
)

const StyledTextarea = classed('textarea', {
  base: `
    outline-none
    bg-transparent
    min-h-[20px] w-full

    text-field-text-default

    placeholder:text-field-text-placeholder
    disabled:text-field-text-disabled
  `,
  variants: {
    noResize: {
      true: `
        resize-none
    `,
    },
  },
})
