import { useDependantState } from '@motion/react-core/hooks'

import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import { SortableRow } from './sortable-row'

export type SortableListProps<T> = {
  items: T[]
  onChange: (items: T[]) => void
  renderDraggableRowSection?: (item: T) => React.ReactNode
  renderRow: (item: T) => React.ReactNode
  size?: 'regular' | 'small'
  rowClassName?: string
}
export function SortableList<T extends { id: string }>({
  items: itemsProp,
  onChange,
  renderDraggableRowSection,
  renderRow,
  size = 'regular',
  rowClassName,
}: SortableListProps<T>) {
  const sensors = useSensors(useSensor(PointerSensor))

  const [items, setItems] = useDependantState(() => itemsProp, [itemsProp])

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (over == null) return
    if (active.id === over.id) return

    const oldIndex = items.findIndex((x) => x.id === active.id)
    const newIndex = items.findIndex((x) => x.id === over.id)
    const newItems = arrayMove(items, oldIndex, newIndex)

    setItems(newItems)
    onChange(newItems)
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item) => (
          <SortableRow
            key={item.id}
            id={item.id}
            size={size}
            rowClassName={rowClassName}
            renderDraggableRowSection={() => renderDraggableRowSection?.(item)}
          >
            {renderRow(item)}
          </SortableRow>
        ))}
      </SortableContext>
    </DndContext>
  )
}
