// this is defined this way so that we can utilize the typed array in zod schemas
export const COLORS = [
  'red',
  'yellow',
  'mint',
  'emerald',
  'sky',
  'lavender',
  'grape',
  'rose',
  'orange',
  'highlighter-yellow',
  'green',
  'teal',
  'blue',
  'purple',
  'pink',
  'gray',
  'tangerine',
  'lime',
  'sage',
  'cyan',
  'cobalt',
  'violet',
  'raspberry',
] as const

export type COLOR = (typeof COLORS)[number]
