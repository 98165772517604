import { createKey, defineApi } from '../../core'
import { type RouteTypes } from '../types'

export const queryKeys = {
  root: () => createKey('workspaces'),
  byId: (id: string) => createKey(queryKeys.root(), 'detail', id),
}

type GetWorkspaceSummary =
  RouteTypes<'V1WorkspacesController_getWorkspaceSummary'>
export const getWorkspaceSummary = defineApi<
  GetWorkspaceSummary['request'],
  GetWorkspaceSummary['response']
>().using({
  key: createKey('summary'),
  uri: 'workspaces/summary/get',
  // We can refetch the workspace statistics every five minutes
  queryOptions: {
    staleTime: 300_000,
  },
})
