import { type UserType } from '../user'

// Only used to be invited to workspaces - You are most likely looking for PMWorkspaceMemberType
export interface PMTeamMemberType {
  id: string
  teamId: string
  userId: string
  user: UserType
  role: PMTeamMemberRole
  createdTime: string
  allowOthersToAutoSchedule?: boolean
  // Optionally placed by frontend from constants
  color?: string
}

// Mapped to backend values
export enum PMTeamMemberRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  GUEST = 'GUEST',
}

// For display purposes
export enum UserFriendlyRole {
  ADMIN = 'Admin',
  MEMBER = 'Team Member',
  GUEST = 'External Guest',
}
