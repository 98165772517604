export function uniqueBy<T, TKey>(
  arr: T[],
  quantifier: (item: T) => TKey
): T[] {
  const uniqueItems = arr.reduce((acc, cur) => {
    const key = quantifier(cur)
    acc.set(key, cur)
    return acc
  }, new Map<TKey, T>())

  return Array.from(uniqueItems.values())
}
