import { defineMutation } from '../../core'
import { queryKeys as taskQueryKeys } from '../team-tasks'
import { type RouteTypes } from '../types'

type DeleteRecurringTask =
  RouteTypes<'RecurringTasksController_deleteRecurringTask'>
/**
 * @deprecated use deleteTask v2 api instead
 */
export const deleteRecurringTask = defineMutation<
  DeleteRecurringTask['request'] & { workspaceId: string },
  /* DeleteRecurringTask['response'] */ void
>().using({
  method: 'DELETE',
  uri: (args) => `/recurring_tasks/${args.id}`,
  invalidate: (args) => taskQueryKeys.byWorkspaceId(args.workspaceId),
  effects: [
    {
      on: 'mutate',
      action: 'remove',
      key: (args) => taskQueryKeys.byId(args.id),
    },
  ],
})
