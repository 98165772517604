import {
  type ClientRect,
  type CollisionDescriptor,
  type CollisionDetection,
} from '@dnd-kit/core'
import { type Coordinates } from '@dnd-kit/utilities'

function verticalDistanceBetween(p1: Coordinates, p2: Coordinates) {
  return Math.abs(p1.y - p2.y)
}

function sortCollisionsAsc(
  { data: { value: a } }: CollisionDescriptor,
  { data: { value: b } }: CollisionDescriptor
) {
  return a - b
}

/**
 * Returns the coordinates of the center of a given ClientRect
 */
function centerOfRectangle(
  rect: ClientRect,
  left = rect.left,
  top = rect.top
): Coordinates {
  return {
    x: left + rect.width * 0.5,
    y: top + rect.height * 0.5,
  }
}

/**
 * Returns the closest rectangles from an array of rectangles to the center of a given
 * rectangle. This only works for vertical lists.
 */

export const pastVerticalCenter: CollisionDetection = ({
  droppableRects,
  droppableContainers,
  active,
  pointerCoordinates,
}) => {
  if (!pointerCoordinates) {
    return []
  }
  const collisions: CollisionDescriptor[] = []
  const activeRect = droppableRects.get(active.id)

  if (!activeRect) return collisions

  /**
   * This is the rectangle we are currently dragging, it's position doesn't change while dragging
   * even though it visually moves.
   */
  const activeCenterRect = centerOfRectangle(activeRect)

  const distPointerToActive = verticalDistanceBetween(
    pointerCoordinates,
    activeCenterRect
  )

  for (const droppableContainer of droppableContainers) {
    const { id } = droppableContainer
    const rect = droppableRects.get(id)

    if (!rect) continue

    const centerDroppableRect = centerOfRectangle(rect)

    const distDroppableToActive = verticalDistanceBetween(
      centerDroppableRect,
      activeCenterRect
    )

    /**
     * If the distance from active to pointer is smaller than from active to droppable, we haven't crossed the center.
     */
    if (distPointerToActive < distDroppableToActive) {
      continue
    }

    collisions.push({
      id,
      data: {
        droppableContainer,
        /**
         * This will allow sorting of the rectangles, the smaller the distance
         * from the pointer to the droppable the higher it will sort
         */
        value: verticalDistanceBetween(pointerCoordinates, centerDroppableRect),
      },
    })
  }

  return collisions.sort(sortCollisionsAsc)
}
