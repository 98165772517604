import { twMerge } from 'tailwind-merge'

import { Modal, type ModalProps } from './modal'

export type ExpandableModalProps = ModalProps & {
  expanded?: boolean
}

export const ExpandableModal = (props: ExpandableModalProps) => {
  const { expanded, children, ...rest } = props

  return (
    <Modal
      {...rest}
      // @ts-expect-error className unexposed
      className={twMerge(
        'transition-motion',
        expanded ? 'w-screen h-screen' : 'max-w-[550px] max-h-[600px]'
      )}
    >
      {children}
    </Modal>
  )
}
