import { useMutation } from '@tanstack/react-query'

import { useMutationOptionsFactory } from './use-mutation-options-factory'

import {
  type ApiUseMutationOptions,
  type MutationApiDefinition,
} from '../../core/types'

export function createUseMutation<TApi extends MutationApiDefinition>(
  api: TApi
) {
  return (opts?: ApiUseMutationOptions<TApi>) => {
    const optionsOf = useMutationOptionsFactory(api)
    return useMutation(optionsOf(opts))
  }
}

export type UseMutationFn<TApi extends MutationApiDefinition> = ReturnType<
  typeof createUseMutation<TApi>
>

export type UseMutationArgs<THook extends UseMutationFn<any>> = Parameters<
  ReturnType<THook>['mutateAsync']
>[0]
