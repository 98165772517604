import { useClosure } from '@motion/react-core/hooks'

import { type MouseEvent, type ReactNode, useCallback, useState } from 'react'

import { type Point } from './context-menu.types'

import { Popover } from '../popover'

export type ContextMenuPopoverProps = {
  renderContent: ({ close }: { close: () => void }) => ReactNode
}

export type Options = {
  onOpen?: () => void
}

export const useContextMenu = (options: Options = {}) => {
  const { onOpen = () => {} } = options

  const onOpenClosure = useClosure(onOpen)

  const [point, setPoint] = useState<Point | null>(null)
  const handleContextMenu = useCallback(
    (event: MouseEvent) => {
      // Prevent the default context menu from showing up
      event.preventDefault()
      setPoint({
        x: event.clientX,
        y: event.clientY,
      })

      onOpenClosure()
    },
    [onOpenClosure]
  )

  const ContextMenuPopover = ({ renderContent }: ContextMenuPopoverProps) => {
    if (!point) return null

    const handleClose = () => {
      setPoint(null)
    }

    return (
      <Popover
        triggerRef={point}
        onClose={handleClose}
        open
        placement='bottom-start'
      >
        {renderContent({ close: handleClose })}
      </Popover>
    )
  }

  return { handleContextMenu, ContextMenuPopover }
}
