import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import { useQueryOptionsFactory } from './use-query-options-factory'
import { useClosure, useMemoDeep } from './utils'

import { type ApiTypes, type LooseApiDefinition } from '../../core'

type QueryFn<TApi extends LooseApiDefinition, TSelect> = (
  opts?: ApiTypes<TApi>['UseQueryOptions']
) => (
  args?: ApiTypes<TApi>['args'],
  opts?: ApiTypes<TApi>['UseQueryOptions']
) => Promise<TSelect>

export function createUseQueryFn<TApi extends LooseApiDefinition>(
  api: TApi,
  staticOptions?: Partial<ApiTypes<TApi>['UseQueryOptions']>
): QueryFn<TApi, ApiTypes<TApi>['data']>

export function createUseQueryFn<TApi extends LooseApiDefinition, TSelect>(
  api: TApi,
  staticOptions?: Omit<Partial<ApiTypes<TApi>['UseQueryOptions']>, 'select'> & {
    select(data: ApiTypes<TApi>['data']): TSelect
  }
): QueryFn<TApi, TSelect>
/**
 * Creates a function returns a promise with the result of calling the api
 * This does not have any intermediatary statuses
 *
 * Use case:
 * You have a query that you only want to execute as a promise.
 *
 * @param api the defined api
 * @param staticOptions initial set of options to pass
 * @returns a function that will call the api
 */
export function createUseQueryFn<TApi extends LooseApiDefinition>(
  api: TApi,
  staticOptions?: Partial<ApiTypes<TApi>['UseQueryOptions']>
) {
  type t = ApiTypes<TApi>

  return (hookOptions?: Partial<ApiTypes<TApi>['UseQueryOptions']>) => {
    const client = useQueryClient()

    const unstableQueryOptionsOf = useQueryOptionsFactory(api)
    const queryOptionsOf = useClosure(unstableQueryOptionsOf)

    const stableHookOptions = useMemoDeep(hookOptions)

    return useCallback(
      (
        args: t['args'],
        executionOptions?: t['UseQueryOptions']
      ): Promise<t['data']> => {
        const mergedOptions = {
          ...staticOptions,
          ...stableHookOptions,
          ...executionOptions,
        }

        const options = queryOptionsOf(args, mergedOptions)

        return client.fetchQuery(options)
      },
      [client, queryOptionsOf, stableHookOptions]
    )
  }
}

export type UseQueryFn<TApi extends LooseApiDefinition> = ReturnType<
  typeof createUseQueryFn<TApi>
>
