import { FilledChevronDownSolid } from '@motion/icons'
import { classed } from '@motion/theme'

import { type MouseEventHandler, type ReactNode, useState } from 'react'

import {
  type Group,
  type RenderGroupContext,
  type RenderItemContext,
} from './types'

import { Checkbox } from '../../../../forms/checkbox'
import { ConnectedSearchableListItem } from '../common'

export type GroupedCheckboxItemProps<T> = {
  group: Group<T>

  selected: T[]
  onSelected(items: T[]): void

  renderHeader(ctx: RenderGroupContext<T>): NonNullable<ReactNode>
  renderItem(ctx: RenderItemContext<T>): NonNullable<ReactNode>
}

const preventBubble: MouseEventHandler<HTMLElement> = (e: React.MouseEvent) => {
  e.preventDefault()
  e.stopPropagation()
}

export const GroupedCheckboxItem = <T,>(props: GroupedCheckboxItemProps<T>) => {
  const [expanded, setExpanded] = useState(false)

  const selected = props.group.items.filter((item) =>
    props.selected.some((selectedItem) => item === selectedItem)
  )
  const indeterminate =
    selected.length > 0 && selected.length !== props.group.items.length
  const allSelected = selected.length === props.group.items.length

  const hasChildren = props.group.items.length > 1

  return (
    <Container aria-expanded={expanded} expanded={expanded}>
      <Header
        value={props.group.key}
        onSelect={() => {
          const selectedItems = allSelected ? [] : props.group.items
          props.onSelected(selectedItems)
        }}
      >
        <CheckboxRow
          checked={indeterminate ? 'indeterminate' : allSelected}
          onChange={(checked) => {
            const selectedItems = checked ? props.group.items : []
            props.onSelected(selectedItems)
          }}
          label={props.renderHeader({ group: props.group, selected })}
        />

        {hasChildren && (
          <ExpandButton
            onClick={(e) => {
              preventBubble(e)
              setExpanded(!expanded)
            }}
          >
            <ExpandIcon width={12} height={12} expanded={expanded} />
          </ExpandButton>
        )}
      </Header>
      {hasChildren && (
        <Content visible={expanded} aria-hidden={expanded ? undefined : 'true'}>
          {props.group.items.map((item, idx) => {
            const key = `${props.group.key}_${idx}`
            return (
              <ConnectedSearchableListItem
                key={key}
                value={key}
                disabled={!expanded}
                onSelect={() => {
                  const isSelected = selected.includes(item)
                  const newSelection = !isSelected
                    ? [...selected, item]
                    : selected.filter((x) => x !== item)
                  props.onSelected(newSelection)
                }}
              >
                <CheckboxRow
                  leaf
                  checked={selected.includes(item)}
                  onChange={(checked) => {
                    const newSelection = checked
                      ? [...selected, item]
                      : selected.filter((x) => x !== item)
                    props.onSelected(newSelection)
                  }}
                  label={props.renderItem({ selected, item })}
                />
              </ConnectedSearchableListItem>
            )
          })}
        </Content>
      )}
    </Container>
  )
}

const Container = classed('div', {
  base: `
    text-sm font-sans
    motion-safe:transition-[grid-template-rows] 
    motion-safe:duration-[var(--animate-time,200ms)]
    grid gap-x-2
  `,
  variants: {
    expanded: {
      true: '[grid-template-rows:min-content_1fr]',
      false: '[grid-template-rows:min-content_0fr] overflow-hidden',
    },
  },
  dataAttributes: ['expanded'],
})
const ExpandButton = classed('button', {
  base: `
    w-4 h-4 grid items-center justify-center    
    hover:bg-semantic-neutral-bg-active-hover    
    text-button-neutral-outlined-icon-default
    rounded
  `,
})
const ExpandIcon = classed(FilledChevronDownSolid, {
  base: 'transition-transform',
  variants: {
    expanded: {
      true: 'rotate-180',
    },
  },
})

const Header = classed(ConnectedSearchableListItem, {
  base: `
    grid gap-1 grid-cols-[1fr_auto]
    text-semantic-neutral-text-default
    items-center
    rounded
    hover:bg-button-neutral-outlined-bg-hover
    has-[button:hover]:bg-dropdown-bg 
    pr-1
    cursor-pointer
  `,
})

const CheckboxRow = classed(Checkbox, {
  base: `
    pl-2 pr-1 py-1.5
  `,
  variants: {
    leaf: {
      true: 'pl-[32px] hover:bg-button-neutral-outlined-bg-hover rounded pr-2',
    },
  },
  defaultVariants: {
    leaf: false,
  },
})

const Content = classed('div', {
  base: 'overflow-hidden flex flex-col',
  variants: {
    visible: {
      true: 'motion-safe:animate-fadeIn',
      false: 'motion-safe:animate-fadeOut  pointer-events-none',
    },
  },
})
