const FOCUSABLE_SELECTOR =
  'a,frame,iframe,input:not([type=hidden]):not(:disabled),select:not(:disabled),textarea:not(:disabled),button:not([aria-disabled="true"]):not([tabindex="-1"]):not(:disabled),*[tabindex]'
const MENULISTITEM_SELECTOR = '[role="menuitem"],[role="listitem"]'

export function isFocusable(element: HTMLElement) {
  return element.matches(FOCUSABLE_SELECTOR)
}

export function focusFirstFocusableNode(
  element: HTMLElement,
  onlyDescendants = true
) {
  findFirstFocusableNode(element, onlyDescendants)?.focus()
}

export function findAllFocusableNodes(
  element: HTMLElement
): NodeListOf<HTMLElement> {
  return element.querySelectorAll(FOCUSABLE_SELECTOR)
}

export function findFirstFocusableNode(
  element: HTMLElement,
  onlyDescendants = true
): HTMLElement | null {
  if (!onlyDescendants && isFocusable(element)) {
    return element
  }

  return element.querySelector(FOCUSABLE_SELECTOR)
}

export function focusFirstParentFocusableNode(element: HTMLElement) {
  const focusable: HTMLElement | null =
    element.parentElement?.closest(FOCUSABLE_SELECTOR) ?? null

  if (focusable != null) {
    focusable.focus()
  }
}

export function getAllMenuListItems(parent: HTMLElement) {
  return parent.querySelectorAll(
    MENULISTITEM_SELECTOR
  ) as NodeListOf<HTMLElement>
}

export function focusNextMenuItem(
  parent: HTMLElement,
  currentFocused: HTMLElement,
  direction: 'next' | 'previous' = 'next'
) {
  const menuItems = getAllMenuListItems(parent)

  const currentIndex = getCurrentFocusedIndex(menuItems, currentFocused)

  if (currentIndex === -1) {
    menuItems[0]?.focus()
  } else {
    const i = direction === 'next' ? 1 : -1
    const next = (currentIndex + i + menuItems.length) % menuItems.length

    menuItems[next].focus()
  }
}

function getCurrentFocusedIndex(
  items: NodeListOf<HTMLElement>,
  currentFocused: HTMLElement
): number {
  let idx = 0

  for (const focusableChild of items) {
    if (focusableChild === currentFocused) {
      break
    }
    idx++
  }
  return idx === items.length ? -1 : idx
}
