import { recordAnalyticsEvent } from '@motion/web-common/analytics'
import { useExperiment } from '@motion/web-common/flags'

export const useEnterpriseLink = () => {
  let enterpriseLink = useExperiment('harry-enterprise-booking-link').payload
    ?.url

  const isInvalidLink = !enterpriseLink

  if (!enterpriseLink) {
    enterpriseLink = 'https://app.usemotion.com/meet/harry-qi/enterprise?d=30'
  }

  return (location: string) => {
    recordAnalyticsEvent('ENTERPRISE_CARD_CLICKED', {
      url: enterpriseLink,
      isInvalidLink,
      location,
    })
    window.open(enterpriseLink, '_blank')
  }
}
