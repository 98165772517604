import { classed } from '@motion/theme'

export const AvatarPill = classed('div', {
  base: `
    shrink-0 flex items-center justify-center
    rounded-full
    !text-primitives-global-white
    font-bold
    tracking-[-0.25px]
    bg-avatar-empty-bg
    overflow-hidden
    relative
    uppercase
    [&>[data-icon]]:text-avatar-empty-icon

  `,
  variants: {
    outlined: {
      true: 'outline outline-1 outline-semantic-neutral-bg-default',
    },
  },
})
