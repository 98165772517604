import { XSolid } from '@motion/icons'
import { classed } from '@motion/theme'

import { type ReactNode } from 'react'

import { type DataTestIdAttribute } from '../../types'
import { Button, type ButtonProps } from '../button'

type AlertDialogActionValue<T> =
  | {
      onClick?: (value: T) => void
      value: T
    }
  | {
      onClick?: never
      value?: never
    }

export type AlertDialogAction<T> = AlertDialogActionValue<T> &
  DataTestIdAttribute & {
    label: string
    variant?: ButtonProps['variant']
    sentiment?: ButtonProps['sentiment']
  }

export type AlertDialogProps<T> = {
  visible: boolean

  title: ReactNode
  description: ReactNode

  banner?: string

  actions: AlertDialogAction<T>[]

  dismissible?: boolean

  onClose?: (value?: T | undefined) => void

  actionBarOrientation?: 'vertical' | 'horizontal'
}

export const AlertDialog = <T,>(props: AlertDialogProps<T>) => {
  if (props.actions.length === 0 && !props.dismissible) {
    throw new Error(
      'The AlertDialog requires at least one action or be dismissible.'
    )
  }

  const closeDialog = (value: T | undefined) => {
    props.onClose?.(value)
  }

  if (!props.visible) {
    return null
  }

  return (
    <Alert>
      {props.banner && (
        <img src={props.banner} alt='banner' className='w-full h-[164px]' />
      )}
      <Contents>
        <Header>
          <div>{props.title}</div>
          {props.dismissible && (
            <CloseContainer>
              <Button
                sentiment='neutral'
                variant='muted'
                onClick={() => closeDialog(undefined)}
                iconOnly
              >
                <XSolid width={18} height={18} />
              </Button>
            </CloseContainer>
          )}
        </Header>
        <Description>{props.description}</Description>
      </Contents>
      <ActionBar orientation={props.actionBarOrientation ?? 'vertical'}>
        {props.actions.map((action) => {
          return (
            <Button
              data-testid={action['data-testid']}
              key={action.label}
              variant={action.variant}
              sentiment={action.sentiment}
              onClick={() => {
                action.onClick?.(action.value)
              }}
            >
              {action.label}
            </Button>
          )
        })}
      </ActionBar>
    </Alert>
  )
}

const Alert = classed('form', {
  base: `
    rounded-lg border border-dropdown-border
    w-[320px]
    bg-dropdown-bg
    flex flex-col shadow-lg
    text-semantic-neutral-text-default
    animate-fadeIn
    overflow-hidden
  `,
})

const Contents = classed('div', {
  base: `
    font-sans text-sm
    flex flex-col gap-2
    p-4
  `,
})

const Header = classed('div', {
  base: `
    font-sans font-semibold text-base
    grid grid-cols-[1fr_auto]
  `,
})

const CloseContainer = classed('div', {
  base: `
    align-top -mt-1 -mr-1
  `,
})

const Description = classed('p', {
  base: `text-semantic-neutral-text-subtle`,
})

const ActionBar = classed('div', {
  base: `flex gap-2 p-6 pt-0`,
  variants: {
    orientation: {
      vertical: `flex-col`,
      horizontal: `flex-row justify-end`,
    },
  },
})
