import { CheckOutline } from '@motion/icons'

import { SearchableListItem } from './common'
import { type SelectableListItemProps } from './types'

export const SelectableListItem = ({
  selected,
  children,
  ...etc
}: SelectableListItemProps) => {
  return (
    <SearchableListItem {...etc} role='menuitem' selected={selected}>
      <div className='relative flex w-full gap-2 items-center'>
        <span className='flex items-center grow truncate'>{children}</span>
        {selected && (
          <CheckOutline className='text-semantic-primary-icon-default w-4 h-4' />
        )}
      </div>
    </SearchableListItem>
  )
}
