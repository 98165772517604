import { type DateLike, parseDate } from '@motion/utils/dates'

export const calculateDurationInMinutes = (start: DateLike, end: DateLike) => {
  const startDate = parseDate(start)
  const endDate = parseDate(end)

  if (!startDate.isValid || !endDate.isValid) return null

  return Math.round(endDate.diff(startDate, 'minutes').minutes)
}
