/**
 * Auto Generated
 */
import { type ClientZod } from '../../utils/client-transform'
import type * as Server from '../../server/common/days'

// Generated Types

/**
 * Days of the week
 */
export type DaysOfWeekSchema = ClientZod<typeof Server.DaysOfWeekSchema>
/**
 * Days of the week
 */
export const DaysOfWeekSchema = [
  'MO',
  'TU',
  'WE',
  'TH',
  'FR',
  'SA',
  'SU',
] as const
