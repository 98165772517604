import { initialize as initializeApp } from './app'
import { initialize as initializeAuth } from './auth'
import { initialize as initializeFirestore } from './store'

export function initialize() {
  const app = initializeApp()
  const auth = initializeAuth(app)
  const store = initializeFirestore(app)

  return {
    app() {
      return app
    },
    auth() {
      return auth
    },
    store() {
      return store.store
    },
    firestore() {
      return store.builder()
    },
  }
}
