import { createContext } from 'react'

import {
  type InternalStateKey,
  type SharedStateApi,
  type StateKey,
} from './types'

import { createEvent } from '../hooks'

/* c8 ignore start */
const throwContextNotFound = () => {
  throw new Error(
    'The SharedStateContext.Provider was not found in the component tree.'
  )
}

const EMPTY_SHARED_STATE: SharedStateApi = {
  id: 'empty-root',
  keys() {
    return []
  },
  get<T>(key: StateKey<T>): T {
    return (key as InternalStateKey<T>).__defaultValue as T
  },
  hasOwn() {
    return false
  },
  has() {
    return false
  },
  set: throwContextNotFound,
  clear: throwContextNotFound,
  all() {
    return {}
  },
  event: createEvent(),
}
/* c8 ignore stop */

export const SharedStateContext =
  createContext<SharedStateApi>(EMPTY_SHARED_STATE)
