import { type TemplateProjectType } from '@motion/rpc/types'
import {
  type ProjectDefinitionSchema,
  type ProjectSchema,
} from '@motion/zod/client'

type FlowProject = ProjectSchema & {
  activeStageDefinitionId: NonNullable<ProjectSchema['activeStageDefinitionId']>
  projectDefinitionId: NonNullable<ProjectSchema['projectDefinitionId']>
}

export function isFlowProject(
  project: ProjectSchema | null | undefined
): project is FlowProject {
  return (
    project?.projectDefinitionId != null &&
    project.activeStageDefinitionId != null
  )
}

export function isFlowTemplate(
  template: TemplateProjectType | ProjectDefinitionSchema | null | undefined
): template is ProjectDefinitionSchema {
  return (
    template != null &&
    typeof template === 'object' &&
    'stages' in template &&
    Array.isArray(template.stages)
  )
}
