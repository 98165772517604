type KeyPart = number | string

export type Flatten<
  T extends KeyPart | readonly (KeyPart | readonly KeyPart[])[],
> = T extends KeyPart
  ? [T]
  : T extends readonly KeyPart[]
    ? [...T]
    : T extends [
          infer U extends KeyPart | readonly KeyPart[],
          ...infer V extends (KeyPart | readonly KeyPart[])[],
        ]
      ? [...Flatten<U>, ...Flatten<V>]
      : never

export function createKey<T extends readonly (KeyPart | readonly KeyPart[])[]>(
  ...parts: T
): Flatten<T> {
  return parts.flat() as Flatten<T>
}
