import React from 'react'

// Helpful to delay unmounting a component so that exit animations can finish

export const useDelayedUnmount = (isMounted: boolean, delay: number) => {
  const [shouldRender, setShouldRender] = React.useState(false)
  // Delay unmount
  React.useEffect(() => {
    if (isMounted) {
      setShouldRender(true)
    } else {
      const timeout = setTimeout(() => {
        setShouldRender(false)
      }, delay)
      return () => clearTimeout(timeout)
    }
  }, [isMounted, delay])
  return shouldRender
}
