import { SectionedList, type SectionedListProps } from './sectioned-list'
import { SelectableListItem } from './selectable-item'

import { type SearchableListSectionedSimpleProps } from '../types'

export type SectionedSelectContainerProps<T> = Pick<
  SearchableListSectionedSimpleProps<T>,
  | 'renderItem'
  | 'computeKey'
  | 'computeSelected'
  | 'computeDisabled'
  | 'onSelect'
  | 'filter'
  | 'computeSearchValue'
> &
  Omit<SectionedListProps<T>, 'renderItem'> & {
    activeValue: string | null
    setActiveValue(value: string | null): void

    clearSearch(): void
  }

export const SectionedSelectList = <T,>(
  props: SectionedSelectContainerProps<T>
) => {
  const {
    computeKey,
    computeSelected,
    computeDisabled,
    activeValue,
    setActiveValue,
    renderItem,
    onSelect,
    clearSearch,
    computeSearchValue = computeKey,

    ...otherSectionedListProps
  } = props

  return (
    <SectionedList
      computeKey={computeKey}
      computeSearchValue={computeSearchValue}
      computeSelected={computeSelected}
      {...otherSectionedListProps}
      renderItem={({ item }) => {
        const key = computeKey(item)
        const selected = computeSelected(item)

        return (
          <SelectableListItem
            key={key}
            // value needs to be unique, ideally we could read the key prop, but we can't so we need to "pass it" twice.
            value={key}
            active={activeValue === key}
            setActiveValue={setActiveValue}
            selected={selected}
            disabled={computeDisabled?.(item) ?? false}
            onSelect={() => {
              return onSelect(item, clearSearch)
            }}
          >
            {renderItem(item)}
          </SelectableListItem>
        )
      }}
    />
  )
}
