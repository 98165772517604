import { type SvgIcon } from '@motion/icons'

import { forwardRef } from 'react'

import { Button, type ButtonProps } from './button'

export type IconButtonProps = Omit<ButtonProps, 'children' | 'iconOnly'> & {
  icon: SvgIcon
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    const { icon: Icon, ...rest } = props

    return (
      <Button ref={ref} iconOnly {...rest}>
        <Icon />
      </Button>
    )
  }
)
IconButton.displayName = 'IconButton'
