import { type DurationLike } from 'luxon'

import { type DateLike, parseDate } from './parse-date'

type Options = {
  startingDay?: 'sunday' | 'monday'
  zone?: string
}

export const getWeekOf = (date: DateLike, opts?: Options) => {
  const { startingDay = 'sunday' } = opts ?? {}

  const offset: DurationLike = { day: startingDay === 'sunday' ? 1 : 0 }

  const baseDay = parseDate(date, opts?.zone).plus(offset)

  return {
    start: baseDay.startOf('week').minus(offset),
    end: baseDay.endOf('week').minus(offset),
  }
}
