import { type QueryClient } from '@tanstack/react-query'

import { type LoaderContext } from './types'

const LOADER_CONTEXT_KEY = ['__loader_ctx__']

export const setLoaderContext = (
  client: QueryClient,
  valueOrSetter: LoaderContext | ((prev: LoaderContext) => LoaderContext)
) => {
  const newValue =
    typeof valueOrSetter === 'function'
      ? valueOrSetter(getLoaderContext(client))
      : valueOrSetter

  client.setQueryData<LoaderContext>(LOADER_CONTEXT_KEY, newValue, {
    updatedAt: Date.now(),
  })

  client.setQueryDefaults(LOADER_CONTEXT_KEY, {
    staleTime: Infinity,
    cacheTime: Infinity,
    networkMode: 'always',
    queryFn: () => newValue,
  })
}

const DEFAULT_LOADER_CONTEXT: LoaderContext = Object.freeze({
  token: null,
  headers: {},
  baseUri: '',
})

export const getLoaderContext = (client: QueryClient): LoaderContext => {
  return (
    client.getQueryData<LoaderContext>(LOADER_CONTEXT_KEY) ??
    DEFAULT_LOADER_CONTEXT
  )
}

export const clearLoaderContext = (client: QueryClient) => {
  client.setQueryData(LOADER_CONTEXT_KEY, DEFAULT_LOADER_CONTEXT)
}
