import { useClosure } from '@motion/react-core/hooks'
import { filterAndRankMatches } from '@motion/ui-logic'
import { byValue, ordered } from '@motion/utils/array'

import { type MutableRefObject, useMemo, useState } from 'react'

import { SearchableListCheckboxItem } from './checkbox-item'
import { GrowOnlyDiv } from './grow-only-div'

import { type SearchableListFlatCheckboxProps } from '../types'
import { ITEM_HEIGHT, MAX_HEIGHT } from '../utils'

type CheckboxListProps<T> = Pick<
  SearchableListFlatCheckboxProps<T>,
  | 'renderItem'
  | 'computeKey'
  | 'computeSelected'
  | 'computeDisabled'
  | 'onSelect'
  | 'filter'
  | 'computeSearchValue'
> & {
  items: T[]

  activeValue: string | null
  setActiveValue(value: string | null): void

  search: string
  clearSearch(): void
  containerRef?: MutableRefObject<HTMLDivElement | null>
}

export const CheckboxList = <T,>(props: CheckboxListProps<T>) => {
  const {
    items: unSortedItems,
    computeKey,
    computeSelected,
    computeDisabled,
    activeValue,
    setActiveValue,
    renderItem,
    onSelect,
    clearSearch,
    filter,
    search,
    computeSearchValue = computeKey,
    containerRef,
  } = props

  const computeKeyClosure = useClosure(computeKey)

  // Keep the initial sort on mount, so checking/unchecking items while it's render doesn't affect the order
  const [initialSortedItemKeys] = useState(() =>
    [...unSortedItems]
      .sort(byValue(computeSelected, ordered([true, false])))
      .map((l) => computeKey(l))
  )

  const items = useMemo(
    () =>
      [...unSortedItems].sort(
        byValue(computeKeyClosure, ordered(initialSortedItemKeys))
      ),
    [unSortedItems, computeKeyClosure, initialSortedItemKeys]
  )

  const filteredItems = useMemo(() => {
    // Return original items if there's no search term
    if (!search) return items
    return filter
      ? filter(search, items)
      : filterAndRankMatches(search, items, computeSearchValue)
  }, [search, items, filter, computeSearchValue])

  const resultCount = filteredItems.length
  const hasResults = resultCount !== 0

  return (
    <GrowOnlyDiv
      ref={containerRef}
      calculateWhenChange={search}
      style={{
        height: Math.min(
          Math.max(0, ITEM_HEIGHT * resultCount + (hasResults ? 9 : 0)),
          MAX_HEIGHT
        ),
        transitionProperty: 'height',
        transitionDuration: '75ms',
      }}
      className={`scrollbar-none w-full scroll-py-1 max-w-sm overflow-y-auto overflow-x-hidden ${
        hasResults ? 'py-1' : ''
      }`}
    >
      {filteredItems.map((item) => {
        const key = computeKey(item)
        const selected = computeSelected(item)

        return (
          <SearchableListCheckboxItem
            key={key}
            // value needs to be unique, ideally we could read the key prop, but we can't so we need to "pass it" twice.
            value={key}
            active={activeValue === key}
            setActiveValue={setActiveValue}
            selected={selected}
            disabled={computeDisabled?.(item) ?? false}
            onSelect={() => {
              const selectedItems = items.filter((allItem) => {
                const itemKey = computeKey(allItem)
                if (selected && itemKey === key) {
                  return false
                }
                return computeSelected(allItem) || itemKey === key
              })

              return onSelect(selectedItems, clearSearch)
            }}
          >
            {renderItem(item)}
          </SearchableListCheckboxItem>
        )
      })}
    </GrowOnlyDiv>
  )
}
