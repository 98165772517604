import { CheckOutline } from '@motion/icons'
import { classed } from '@motion/theme'

import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export type RadioButtonProps = {
  label?: NonNullable<ReactNode>
  renderLabel?: () => ReactNode
  labelHidden?: boolean
  checked: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
  variant?: 'default' | 'checkmark'
  name?: string
}

export function RadioButton(props: RadioButtonProps) {
  const {
    disabled = false,
    checked,
    onChange,
    label,
    labelHidden = false,
    renderLabel,
    variant = 'default',
    name,
  } = props

  return (
    <RadioWrapper disabled={disabled}>
      <RadioInput
        type='radio'
        disabled={disabled}
        checked={checked}
        name={name}
        onChange={(e) => {
          onChange(e.currentTarget.checked)
        }}
      />
      <CheckContainer>
        {variant === 'default' ? (
          <span
            className={twMerge(
              'w-2 h-2 rounded-full',
              checked && !disabled && 'bg-form-icon-active',
              checked && disabled && 'bg-form-icon-disabled'
            )}
          />
        ) : (
          <CheckOutline />
        )}
      </CheckContainer>
      {renderLabel ? (
        renderLabel()
      ) : (
        <LabelText labelHidden={labelHidden}>{label}</LabelText>
      )}
    </RadioWrapper>
  )
}

export const RadioWrapper = classed('label', {
  base: `group inline-flex items-center gap-2 relative`,
  variants: {
    disabled: {
      true: `text-form-text-disabled cursor-not-allowed`,
      false: `text-form-text-default cursor-pointer`,
    },
  },
})

const RadioInput = classed('input', {
  base: `sr-only peer`,
})

const CheckContainer = classed('div', {
  base: `
    rounded-full flex items-center justify-center shrink-0
    w-4 h-4 border border-form-border-default bg-form-bg-default
    text-transparent peer-focus-visible:outline-none
    [&>[data-icon]]:w-2 [&>[data-icon]]:h-2

    group-hover:border-form-border-hover

    peer-checked:text-form-icon-active
    peer-checked:bg-form-bg-active-default

    peer-focus-visible:ring-[2px]
    peer-focus-visible:ring-form-border-focus
    peer-focus-visible:ring-offset-1
    peer-focus:border-form-border-focus

    peer-disabled:peer-checked:text-form-icon-disabled
    peer-disabled:peer-checked:border-form-bg-disabled
    peer-disabled:bg-form-bg-disabled
    peer-disabled:border-form-border-disabled
  `,
})

const LabelText = classed('span', {
  variants: {
    labelHidden: { true: 'sr-only' },
  },
})
