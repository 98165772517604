import { Compare, CompareFn } from '../array'

export function sortAndDedupArrayValues<T extends object>(
  obj: T,
  sortFn: CompareFn<any> = Compare.string
): T {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      // @ts-expect-error - we know this is an object
      obj[key] = sortAndDedupArrayValues(obj[key])
    }

    if (Array.isArray(obj[key])) {
      // @ts-expect-error - we know this is an array
      obj[key] = [...new Set(obj[key] as any[])]
        .map((item) => {
          if (typeof item === 'object') {
            return sortAndDedupArrayValues(item)
          }
          return item
        })
        .sort(sortFn)
    }
  }

  return obj
}
