import { useEffect, useRef } from 'react'

export type InputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'type' | 'value'
> & {
  /**
   * Event handler called when the search value changes.
   */
  onValueChange?: (search: string) => void
  search: string
}

export const SearchableListInput = ({
  onValueChange,
  search,
  ...etc
}: InputProps) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (ref.current && etc.placeholder?.length) {
      ref.current.setAttribute('size', String(etc.placeholder.length))
    }
  }, [etc.placeholder])

  return (
    <input
      ref={ref}
      tabIndex={1}
      className='text-field-text-default placeholder:text-field-text-placeholder h-8 w-full bg-transparent py-1 px-3 text-sm focus-visible:outline-none border-b border-dropdown-border'
      {...etc}
      autoComplete='off'
      autoCorrect='off'
      spellCheck={false}
      aria-autocomplete='list'
      role='combobox'
      value={search}
      onChange={(e) => {
        onValueChange?.(e.target.value)
      }}
    />
  )
}
