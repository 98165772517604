import {
  type MutationKey,
  type MutationOptions,
  type QueryFilters,
  type QueryKey,
} from '@tanstack/react-query'

import {
  type ApiDefinition,
  type Effect,
  type HttpMethod,
  type InitialData,
  type MutationApiDefinition,
  type QueryContext,
  type QueryOptions,
  type UriFn,
} from './types'

type ApiDefinitionArgs<
  TArgs,
  TResponse,
  TQueryKey extends QueryKey = QueryKey,
  TQueryFnData = TResponse,
> = {
  key: TQueryKey | ((args: TArgs) => TQueryKey)

  uri: UriFn<TArgs> | string

  method?: HttpMethod

  body?: (args: TArgs) => unknown
  transform?: (data: TQueryFnData) => TResponse

  enabled?(args: TArgs): boolean

  fetchOptions?: (opts: RequestInit) => RequestInit
  queryOptions?: Omit<
    QueryOptions<NoInfer<TQueryFnData>, Error, TResponse, TQueryKey>,
    'queryKey'
  >

  initialData?:
    | TResponse
    | ((args: TArgs, ctx: QueryContext) => InitialData<TResponse> | undefined)
}

export function defineApi<TArgs, TResponse>() {
  return {
    using<TQueryKey extends QueryKey = QueryKey, TFetchFnData = TResponse>(
      definition: ApiDefinitionArgs<TArgs, TResponse, TQueryKey, TFetchFnData>
    ): ApiDefinition<TArgs, TResponse, TQueryKey, TFetchFnData> {
      const { key: keyInit, body, method, ...rest } = definition

      const key = typeof keyInit === 'function' ? keyInit : () => keyInit

      // @ts-expect-error - properly typed in signature
      return {
        key,
        method: method ?? 'GET',
        body: normalizeBody(body),
        ...rest,
      }
    },
  }
}

let nextAutoMutationKeyId = 1

type MutationDefinitionArgs<
  TArgs,
  TResponse,
  TKey extends MutationKey = MutationKey,
> = {
  key?: TKey | ((args: TArgs) => TKey)

  uri: UriFn<TArgs> | string
  method?: HttpMethod
  body?: (args: TArgs) => unknown
  fetchOptions?: (opts: RequestInit) => RequestInit
  mutationOptions?: MutationOptions<TResponse, Error, TArgs>

  invalidate?:
    | QueryKey
    | QueryFilters
    | (QueryKey | QueryFilters)[]
    | ((args: TArgs) => QueryKey | QueryFilters | (QueryKey | QueryFilters)[])

  // Stores the results of the query into the cache based on the `key`
  effects?: Effect<TArgs, TResponse>[]
}

function createDefaultMutationKeyFn() {
  const id = nextAutoMutationKeyId++
  return () => [`auto-mutation-${id}`] as const
}

export function defineMutation<TArgs, TResponse>() {
  return {
    using<TKey extends MutationKey = [`auto-mutation-${number}`]>(
      definition: MutationDefinitionArgs<TArgs, TResponse, TKey>
    ): MutationApiDefinition<TArgs, TResponse> {
      const { key: keyInit, body, method, ...rest } = definition

      const key =
        typeof keyInit === 'function'
          ? keyInit
          : keyInit == null
            ? createDefaultMutationKeyFn()
            : () => keyInit

      return {
        key,
        method: method ?? 'POST',
        body: normalizeBody(body),
        ...rest,

        // @ts-expect-error - just on type
        $response: undefined,
        // @ts-expect-error - just on type
        $args: undefined,
      }
    },
  }
}

// TODO: Support FormData and Blob
function normalizeBody<TArgs>(
  formatter: ((args: TArgs) => unknown) | undefined
): (TArgs extends void ? () => string : (args: TArgs) => string) | undefined {
  if (formatter == null) return undefined
  // @ts-expect-error - properly typed in signature
  return (args): string => {
    const body = formatter(args)
    if (typeof body === 'string') {
      return body
    }
    return JSON.stringify(body)
  }
}
