const parseTagRE = /^([^_]+)_(\d{4})(\d{1,2})(\d{1,2})_(\d{4})_(.+)$/im

export function tagToSemVer(tag: string | undefined) {
  if (!tag) return
  const parts = parseTagRE.exec(tag)
  if (parts == null) return

  const [, , year, month, day, time] = parts

  const major = year
  const minor = [month, day].map((x) => x.padStart(2, '0')).join('')
  const patch = time.padStart(4, '0')

  return [major, minor, patch].join('.')
}
