import { DragOverlay, type Modifier } from '@dnd-kit/core'
import { getEventCoordinates } from '@dnd-kit/utilities'

import { DragOverlayContainer } from './drag-overlay-container'
import { type SortableItem } from './sortable-treeview.types'

import { Portal } from '../../portal'

type DragOverlayPortalProps = {
  activeItem: SortableItem | undefined
}

export const DragOverlayPortal = ({ activeItem }: DragOverlayPortalProps) => {
  return (
    <Portal>
      <DragOverlay
        dropAnimation={{
          duration: 0,
        }}
        modifiers={[snapLeftToCursor]}
      >
        {activeItem ? (
          <DragOverlayContainer>{activeItem.label}</DragOverlayContainer>
        ) : null}
      </DragOverlay>
    </Portal>
  )
}

/**
 * Position the overlay left side relative to the cursor.
 */
const snapLeftToCursor: Modifier = ({
  activatorEvent,
  draggingNodeRect,
  transform,
}) => {
  if (draggingNodeRect && activatorEvent) {
    const activatorCoordinates = getEventCoordinates(activatorEvent)

    if (!activatorCoordinates) {
      return transform
    }

    const offsetX = activatorCoordinates.x - draggingNodeRect.left
    const offsetY = activatorCoordinates.y - draggingNodeRect.top

    return {
      ...transform,
      x: transform.x + offsetX - 12,
      y: transform.y + offsetY - draggingNodeRect.height / 2,
    }
  }

  return transform
}
