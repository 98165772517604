/* c8 ignore start */
import { cloneDeep, omit } from '@motion/utils/core'
import { definedValueCount, keys } from '@motion/utils/object'
import { type ProjectSchema } from '@motion/zod/client'

import { excludeByCustomField } from './custom-fields'
import { exclude, excludeDate, excludeLabels, isComplete } from './helpers'
import { type DataFilters } from './types'
import { getCompletedFilter, normalizeToDataFilter } from './utils'

import { createNoneProject } from '../../none-entities'
import { type AppDataContext } from '../../types'
import { isNoneId } from '../../utils'
import { type EntityFilterState, type ProjectFilter } from '../state/types'

export function getMatchingProjects(
  ctx: AppDataContext,
  stateOrFilter: DataFilters | EntityFilterState,
  overrides?: Partial<ProjectFilter>
) {
  const filter = normalizeToDataFilter(stateOrFilter)
  const predicate = buildProjectFilterFrom(ctx, filter, overrides)

  const allProjects = [
    ...ctx.projects.all(),
    ...ctx.workspaces.all().map((w) => createNoneProject(w.id)),
  ]

  return allProjects.filter((project) => {
    return predicate(project)
  })
}

const SELECT_ALL = () => true

export function buildProjectFilterFrom(
  ctx: AppDataContext,
  stateOrFilter: DataFilters | EntityFilterState,
  overrides?: Partial<ProjectFilter>
) {
  const filters = normalizeToDataFilter(stateOrFilter)
  const projectFilters = filters.projects
  if (projectFilters == null) return SELECT_ALL

  return buildProjectFilter(ctx, applyOptions(filters, overrides))
}

function applyOptions(filter: DataFilters, overrides?: Partial<ProjectFilter>) {
  if (overrides == null || keys(overrides).length === 0) return filter

  return cloneDeep({
    ...filter,
    projects: { ...filter.projects, ...overrides },
  })
}

function buildProjectFilter(ctx: AppDataContext, filters: DataFilters) {
  return (project: ProjectSchema) => {
    return isNoneId(project.id)
      ? matchesNoneProject(ctx, filters, project)
      : matchesProject(ctx, filters, project)
  }
}

function matchesNoneProject(
  ctx: AppDataContext,
  filters: DataFilters,
  project: ProjectSchema
) {
  const projectFilters = filters.projects
  if (exclude(filters.workspaces.ids, project, 'workspaceId')) return false

  if (exclude(projectFilters.ids, project, 'id')) return false

  const otherFilterCount = definedValueCount(
    omit(projectFilters, 'ids', 'completed')
  )
  return otherFilterCount === 0
}

function matchesProject(
  ctx: AppDataContext,
  filters: DataFilters,
  project: ProjectSchema
) {
  const projectFilters = filters.projects

  const completedFilter = getCompletedFilter(filters.projects)

  if (completedFilter === 'exclude' && isComplete(ctx, project)) {
    return false
  }
  if (exclude(filters.workspaces.ids, project, 'workspaceId')) return false

  if (exclude(projectFilters.ids, project, 'id')) return false

  if (
    exclude(
      projectFilters.stageDefinitionIds,
      project,
      'activeStageDefinitionId'
    )
  ) {
    return false
  }
  if (
    exclude(projectFilters.projectDefinitionIds, project, 'projectDefinitionId')
  ) {
    return false
  }

  if (exclude(projectFilters.statusIds, project, 'statusId')) return false
  if (exclude(projectFilters.managerIds, project, 'managerId')) return false
  if (exclude(projectFilters.priorities, project, 'priorityLevel')) return false
  if (exclude(projectFilters.createdByUserIds, project, 'createdByUserId'))
    return false

  if (excludeDate(projectFilters.dueDate, project.dueDate)) return false
  if (excludeLabels(projectFilters.labelIds, project)) return false
  if (excludeDate(projectFilters.createdTime, project.createdTime)) return false
  if (excludeDate(projectFilters.updatedTime, project.updatedTime)) return false
  if (excludeDate(projectFilters.startDate, project.startDate)) return false

  if (excludeByCustomField(projectFilters, project)) return false

  return true
}
