import { parseDate } from '@motion/utils/dates'
import type { ProjectSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'

/**
 * Start of stage is start of project for first stage or previous stage's due date
 */
export function getFlowTaskStartDate(
  selectedStageIndex: number,
  project: ProjectSchema
): string {
  const today = DateTime.now().startOf('day').toISODate()

  if (selectedStageIndex === 0) {
    // if project start date is after or equal to today for the first stage, use project start date
    if (
      project.startDate != null &&
      parseDate(project.startDate) >= parseDate(today)
    ) {
      return project.startDate
    }

    return today
  }

  const previousStage = project.stages[selectedStageIndex - 1]

  if (
    previousStage?.dueDate != null &&
    parseDate(previousStage.dueDate) >= parseDate(today)
  ) {
    return previousStage.dueDate
  }

  return today
}
