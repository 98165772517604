import { DateTime, type Zone } from 'luxon'

/*
 * Get a luxon zone from a timezone string
 * Ex: America/New_York
 *
 * @param {string} timezone - timezone string
 */
export function getZoneFromTimezone(timezone?: string) {
  return DateTime.local({ zone: timezone }).zone
}

/*
 * Format a luxon zone to a string in the format of
 * {shortName} - {longName} (GMT{shortOffset})
 * Ex: EST - Eastern Standard Time (GMT-05:00)
 *
 * @param {Zone} timezone - luxon zone
 */
export function formatTimezoneToString(timezone: Zone) {
  // Ex: EST
  const shortName = timezone.offsetName(0, {
    format: 'short',
  })

  // Ex: Eastern Standard Time
  const longName = timezone.offsetName(0, {
    format: 'long',
  })

  // Ex: -04:00
  const shortOffset = timezone.formatOffset(0, 'short')

  return `${shortName} - ${longName} (GMT${shortOffset})`
}

/*
 * Compare two timezone strings to see if they are equal by checking their offsets
 *
 * @param {string} timezone1 - timezone string
 * @param {string} timezone2 - timezone string
 */
export function areTimezonesOffsetsEqual(
  timezone1?: string,
  timezone2?: string
) {
  if (!timezone1 || !timezone2) {
    return false
  }

  // Get the offsets for the current date
  const timezone1Offset = getZoneFromTimezone(timezone1).offset(0)
  const timezone2Offset = getZoneFromTimezone(timezone2).offset(0)
  // Compare the offsets
  return timezone1Offset === timezone2Offset
}
