import { classed } from '@motion/theme'

import { type ReactNode } from 'react'

import { Link } from '../../internal'

export type TabItem = {
  title: ReactNode
  value: string
  onAction?: () => void
  url?: string
}

export type TabListProps = {
  items: TabItem[]
  activeValue: string
  outerPadding?: 'none' | 'small' | 'medium' | 'large'
}

export const TabList = ({ items, activeValue, outerPadding }: TabListProps) => {
  return (
    <Wrapper outerPadding={outerPadding}>
      {items.map((x) => {
        if (x.url) {
          return (
            <TabLink
              active={x.value === activeValue}
              key={x.value}
              url={x.url}
              onClick={() => x.onAction?.()}
            >
              {x.title}
            </TabLink>
          )
        }

        return (
          <TabButton
            key={x.value}
            active={x.value === activeValue}
            onClick={() => x.onAction?.()}
          >
            {x.title}
          </TabButton>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = classed('div', {
  base: `
    flex gap-4
    border-b border-solid border-tab-border-default
  `,
  variants: {
    outerPadding: {
      none: '',
      small: 'px-3',
      medium: 'px-6',
      large: 'px-12',
    },
  },
  defaultVariants: {
    outerPadding: 'none',
  },
})

const TabButton = classed('button', {
  base: `
    text-sm font-semibold
    py-[14px]
    border-b-2 border-solid
    cursor-pointer
    my-[-1px]
  `,
  variants: {
    active: {
      true: `
        border-tab-border-selected
        text-tab-text-selected
        hover:text-tab-text-selected
      `,
      false: `
        border-transparent
        text-tab-text-default
        hover:text-tab-text-default
        hover:border-tab-border-hover
      `,
    },
  },
})

const TabLink = classed(Link, 'inline-block', TabButton)
