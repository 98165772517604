import {
  type QueryClient,
  useQueryClient,
  type WithRequired,
} from '@tanstack/react-query'
import { useMemo } from 'react'

import { dereferenceToken, type FetchContext } from '../../core'
import { createFetch } from '../../core/create-fetch'
import {
  type ApiTypes,
  type ApiUseQueryOptions,
  type CommonApiResponseDefinition,
  type InitialData,
  type LooseApiDefinition,
  type QueryContext,
} from '../../core/types'
import { useRpcContext } from '../context/rpc-provider'

export type QueryOptionsContext<TApi extends CommonApiResponseDefinition> =
  FetchContext<TApi> & {
    client: QueryClient
  }

export function createQueryOptions<TApi extends LooseApiDefinition>(
  api: TApi,
  ctx: QueryOptionsContext<TApi>
) {
  const call = createFetch(api, ctx)

  type t = ApiTypes<TApi>

  function getInitialData(
    initialData:
      | t['data']
      | ((args: t['args'], ctx: QueryContext) => t['data']),
    args: t['args'],
    context: QueryContext
  ): InitialData<t['data']> | undefined {
    if (!initialData) return
    if (typeof initialData === 'function') {
      // @ts-expect-error - this is fine
      return initialData(args, context)
    }
    return { value: initialData, updatedAt: Date.now() }
  }

  return (
    args: t['args'],
    opts?: t['UseQueryOptions']
  ): UseQueryOptionsWithQueryFn<TApi> => {
    const staticOptions = api.queryOptions ?? {}
    const localOptions = opts ?? {}

    const combinedOptions = { ...staticOptions, ...localOptions }

    const queryFn: t['UseQueryOptions']['queryFn'] = (ctx) => {
      return call(args, { signal: ctx.signal })
    }

    const initialData = getInitialData(api.initialData, args, {
      client: ctx.client,
    })

    // Calculate whether the query should be enabled by &&ing the various flags.
    // Undefined values should be treated as `true`
    const enabled = [
      'enabled' in combinedOptions ? Boolean(combinedOptions.enabled) : true,
      api.enabled ? api.enabled(args) : true,
    ].every(Boolean)

    return {
      queryKey: api.key(args),
      ...(staticOptions ?? {}),
      ...opts,
      ...(initialData
        ? {
            initialData: initialData.value,
            initialDataUpdatedAt: initialData.updatedAt,
          }
        : {}),
      queryFn,
      enabled,
    }
  }
}

export type UseQueryOptionsWithQueryFn<TApi> = WithRequired<
  ApiUseQueryOptions<TApi>,
  'queryFn' | 'queryKey'
>

export function useQueryOptionsFactory<TApi extends LooseApiDefinition>(
  api: TApi
) {
  const rpcContext = useRpcContext()
  const client = useQueryClient()

  return useMemo(
    () =>
      createQueryOptions(api, {
        token: () => dereferenceToken(rpcContext.token),
        baseUri: rpcContext.baseUri,
        headers: rpcContext.headers,
        executor: rpcContext.executor,
        client,
      }),
    [
      api,
      client,
      rpcContext.baseUri,
      rpcContext.executor,
      rpcContext.headers,
      rpcContext.token,
    ]
  )
}
