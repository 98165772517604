// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - typing provided separately
import raw from './lodash.custom'

/// <reference path="@types/lodash" />

// @ts-expect-error - just need the types
const typed = raw as typeof _

export const cloneDeep = typed.cloneDeep
export const isEqual = typed.isEqual
export const throttle = typed.throttle
export const debounce = typed.debounce
export const uniqueId = typed.uniqueId
export const memoize = typed.memoize
export const omit = typed.omit
export const pick = typed.pick
export const merge = typed.merge

/**
 * These types were pulled from '@types/lodash
 */

/**
 * Settings for Debounce
 */
export interface DebounceSettings {
  leading?: boolean | undefined
  maxWait?: number | undefined
  trailing?: boolean | undefined
}

export interface DebounceSettingsLeading extends DebounceSettings {
  leading: true
}

export interface DebouncedFunc<T extends (...args: any[]) => any> {
  (...args: Parameters<T>): ReturnType<T> | undefined
  cancel(): void
  flush(): ReturnType<T> | undefined
}
