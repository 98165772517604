import { type WithRequired } from '@motion/utils/types'

import { type PriorityLevelType } from './other'
import { type PMProjectLabelType } from './PMLabelType'
import { type PMTaskStatusType } from './PMTaskStatusType'

import { type UserType } from '../user'

export enum PMItemType {
  project,
  task,
  recurringTask,
}

// All fields are optional in order to support partially created items
// i.e. create new item via UI and display it right away before the backend response comes back and replaces the temporary model
export interface PMProjectType {
  id?: string
  name?: string
  description?: string | null
  sortPosition?: string
  dueDate?: string | null
  labels?: PMProjectLabelType[]
  managerId?: UserType['id'] | null
  manager?: UserType
  // Optionally placed by frontend
  itemType?: PMItemType.project
  createdTime?: string
  updatedTime?: string | null
  createdByUserId?: UserType['id']
  workspaceId?: string
  priorityLevel?: PriorityLevelType
  status?: PMTaskStatusType
  statusId?: PMTaskStatusType['id']
  rank?: string | null
  activeStageDefinitionId?: string | null
}

export type PMProjectTypeWithItemType = WithRequired<
  PMProjectType,
  'id' | 'name'
> & {
  itemType: PMItemType.project
}
