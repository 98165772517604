type FormatFn = (value: number | null | undefined) => string
const formatterCache = new Map<string, FormatFn>()

export type NumberInputFormat =
  | { type: 'integer'; grouped: boolean }
  | { type: 'decimal'; decimalPlaces: number; grouped: boolean }
  | {
      type: 'percent'
      decimalPlaces: number
      grouped: boolean
      base1?: boolean
    }
  | ((value: number | null | undefined) => string)

function isEmpty(value: number | null | undefined) {
  return value == null || Number.isNaN(value)
}

export const getFormatter = (opts: NumberInputFormat) => {
  if (typeof opts === 'function') return opts

  const key = JSON.stringify(opts)
  const found = formatterCache.get(key)
  if (found) return found
  const formatter = createFormatter(opts)
  formatterCache.set(key, formatter)
  return formatter
}

export function createFormatter(
  opts: NumberInputFormat
): (value: number | null | undefined) => string {
  if (typeof opts === 'function') return opts

  const decimalPlaces = opts.type === 'integer' ? 0 : opts.decimalPlaces
  const style = opts.type === 'percent' ? 'percent' : 'decimal'

  const formatter = new Intl.NumberFormat(undefined, {
    useGrouping: opts.grouped,
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: 0,
    style,
  })

  return (value) => {
    if (value == null || isEmpty(value)) return ''

    const normalizedValue =
      opts.type !== 'percent' || opts.base1 ? value : value / 100

    if (typeof value !== 'number') value = 0

    return formatter.format(normalizedValue)
  }
}
