/* c8 ignore start */
import { type IDBPDatabase, openDB, type StoreNames } from 'idb'

import { type MotionDBConfig, type SimpleIDBStore, type ValueOf } from './types'

import { type MotionDB } from '../types'

function createDatabase(name: string) {
  return openDB<MotionDBConfig>(name, 1, {
    upgrade(db, oldVersion, newVersion, tx) {
      if (oldVersion !== newVersion) {
        db.createObjectStore('state')
      }
    },
  })
}

const UNSET = Symbol('unset')
type D = IDBPDatabase<MotionDBConfig>

export const createDb = (name: string): MotionDB => {
  let db: D | typeof UNSET = UNSET
  let initialized = false

  function assertOpen(db: D | typeof UNSET): asserts db is D {
    if (db === UNSET) {
      throw new Error(' DB Not open')
    }
  }

  function createSimpleStore<TName extends StoreNames<MotionDBConfig>>(
    store: TName
  ): SimpleIDBStore<TName> {
    return {
      async clear() {
        assertOpen(db)
        return db.clear(store)
      },
      async get(key: string) {
        assertOpen(db)
        return db.get(store, key)
      },
      async getMany(keys: string[]) {
        assertOpen(db)

        const tx = db.transaction(store, 'readonly')
        const results = await Promise.all([
          ...keys.map((key) => tx.store.get(key)),
          tx.done,
        ])
        return results.slice(0, -1).reduce(
          (acc, cur, i) => {
            acc[keys[i]] = cur as ValueOf<TName> | undefined
            return acc
          },
          {} as Record<string, ValueOf<TName> | undefined>
        )
      },

      async set(key, value) {
        assertOpen(db)
        await db.put('state', value, key)
      },
    }
  }

  return {
    provider: 'indexedDB',
    async clearAll() {
      if (!initialized) return
      assertOpen(db)
      const names = ['state'] as const
      const tx = db.transaction(names, 'readwrite')
      await Promise.all(names.map((x) => tx.objectStore(x).clear()))
    },

    async open() {
      if (initialized) return
      db = await createDatabase('motion')
    },
    async close() {
      if (db === UNSET) return
      db.close()
      db = UNSET
    },

    get initialized() {
      return db !== UNSET
    },

    state: createSimpleStore('state'),
  } satisfies MotionDB
}
