import { useLayoutEffect, useRef } from 'react'

const animationStore = {
  get startTime() {
    // @ts-expect-error - custom private property
    return window._animationSync ?? 0
  },
  set startTime(value: number | null) {
    // @ts-expect-error - custom private property
    window._animationSync = value
  },
}

export const useSyncAnimation = <T extends Element = HTMLElement>(
  name: string
) => {
  const ref = useRef<T>(null)

  useLayoutEffect(() => {
    if (ref.current == null) return

    const animations = ref.current
      .getAnimations()
      .filter((animation) => (animation as CSSAnimation).animationName === name)
    const currentAnimation = animations.find(
      (anim) => (anim.effect as KeyframeEffect)?.target === ref.current
    )

    if (!currentAnimation) {
      return
    }

    if (currentAnimation.startTime == null) {
      currentAnimation.startTime = animationStore.startTime
    }
  }, [name])

  return ref
}
