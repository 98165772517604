/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  type MutationOptions,
  type QueryClient,
  type QueryFilters,
  type QueryKey,
  type UseQueryOptions,
} from '@tanstack/react-query'

export type HttpMethod = 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT'

export type QueryContext = {
  client: QueryClient
}

export type InitialData<T> = {
  value: T
  updatedAt: number
}

export type QueryOptions<
  TQueryFnData,
  TError,
  TResponse,
  TQueryKey extends QueryKey,
> = Omit<
  UseQueryOptions<TQueryFnData, TError, TResponse, TQueryKey>,
  'queryFn' | 'select' | 'queryKey'
>

export type UriParts = {
  pathname: string
  search: Record<string, boolean | number | string | null | undefined> | string
}
export type UriFn<T> = T extends void
  ? () => UriParts | string
  : (args: T) => UriParts | string

export type CommonApiDefinition<
  TArgs = any,
  TKey extends QueryKey = QueryKey,
> = {
  key: (args: TArgs) => TKey
  uri: UriFn<TArgs> | string
  method: HttpMethod
  body?: TArgs extends void ? () => string : (args: TArgs) => string
  fetchOptions?: (opts: RequestInit) => RequestInit
}

export type CommonApiResponseDefinition<
  TArgs = any,
  TResponse = any,
> = CommonApiDefinition<TArgs> & {
  transform?: TArgs extends void
    ? <TBody>(body: TBody) => TResponse
    : <TBody>(body: TBody, args: TArgs) => TResponse
}

export type ApiDefinition<
  TArgs,
  TResponse,
  TQueryKey extends QueryKey = QueryKey,
  TQueryFnData = TResponse,
> = CommonApiResponseDefinition<TArgs, TResponse> & {
  transform?: TArgs extends void
    ? TQueryFnData extends void
      ? () => TResponse
      : (data: TQueryFnData) => TResponse
    : (data: TQueryFnData, args: TArgs) => TResponse
  enabled?(args: TArgs): boolean
  queryOptions?: Omit<
    QueryOptions<TQueryFnData, Error, TResponse, TQueryKey>,
    'queryFn'
  >

  initialData?:
    | TResponse
    | ((args: TArgs, ctx: QueryContext) => InitialData<TResponse> | undefined)
}

export type LooseApiDefinition = {
  key: (args: any) => QueryKey
  uri: UriFn<any> | string
  method: HttpMethod
  body?: (args: any) => string
  transform?: (data: any, args: any) => any
  enabled?(args: any): boolean

  fetchOptions?: (opts: RequestInit) => RequestInit
  queryOptions?: QueryOptions<any, Error, any, any>
  mutationOptions?: MutationOptions<any, Error, any, any>

  initialData?:
    | any
    | ((args: any, ctx: QueryContext) => InitialData<any> | undefined)
}

export type MutationApiDefinition<
  TArgs = any,
  TResponse = any,
  TKey extends QueryKey = QueryKey,
> = CommonApiResponseDefinition<TArgs, TResponse> & {
  key: TKey | ((args: TArgs) => TKey)
  mutationOptions?: MutationOptions<TResponse, Error, TArgs>

  invalidate?:
    | QueryKey
    | QueryFilters
    | (QueryKey | QueryFilters)[]
    | ((args: TArgs) => QueryKey | QueryFilters | (QueryKey | QueryFilters)[])

  effects?: Effect<TArgs, TResponse>[]

  $response: TResponse
  $args: TArgs
}

export const SKIP_UPDATE = Symbol('skip-update')

export type Effect<TArgs, TData> =
  | SuccessEffect<TArgs, TData>
  | OptimisiticEffect<TArgs>

export type SuccessEffectUpdate<TArgs, TData> = {
  key(args: TArgs): QueryKey
  merge(value: TData, previousValue: any, args: TArgs): any | typeof SKIP_UPDATE
  action: 'update'
}

export type SuccessEffectRemove<TArgs> = {
  key(args: TArgs): QueryKey
  action: 'remove'
}

export type SuccessEffectInvalidate<TArgs> = {
  key(args: TArgs): QueryKey
  action: 'invalidate'
}

export type SuccessEffect<TArgs, TData> = { on: 'success' } & (
  | SuccessEffectUpdate<TArgs, TData>
  | SuccessEffectRemove<TArgs>
  | SuccessEffectInvalidate<TArgs>
)

export type OptimisiticEffect<TArgs> = { on: 'mutate' } & (
  | {
      key(args: TArgs): QueryKey
      merge(data: TArgs, previousValue: any): any | typeof SKIP_UPDATE
      action: 'update'
    }
  | {
      key(args: TArgs): QueryKey
      action: 'remove'
    }
)
