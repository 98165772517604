/**
 * Performs a shallow test to see if the value matches
 * @param value the object to test
 * @param match the values to test against
 * @returns {boolean} true if matches
 */
export const shallowMatch = <T extends object>(
  value: T,
  match: Partial<T>
): boolean => {
  const keys = Object.keys(match) as (keyof T)[]
  return keys.every((key) => match[key] === value[key])
}
