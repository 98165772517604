import { createContext, useContext } from 'react'

import { type ItemSelectors } from './use-item-selectors'

export type SelectionContextType = ItemSelectors & {
  active: string | null
  setActive(value: string | null): void
  search: string
}

export const SelectionContext =
  // @ts-expect-error - provider is required
  createContext<SelectionContextType>(null)

/* eslint react-refresh/only-export-components: ["warn"] */
export const useSelectionContext = () => useContext(SelectionContext)
