import { makeLog } from '@motion/web-common/logging'

import {
  type Breadcrumb,
  type BreadcrumbHint,
  breadcrumbsIntegration,
  type Event,
  type EventHint,
  globalHandlersIntegration,
  init,
  setExtra,
} from '@sentry/browser'
import { type BrowserClientOptions } from '@sentry/browser/types/client'

import { onNetworkStatusChanged } from '../env'

let lastErrors: string[] = []

const log = makeLog('sentry')

export type InitializeSentryOptions = {
  version: string
  onBeforeBreadcrumb?: (
    breadcrumb: Breadcrumb,
    hint?: BreadcrumbHint
  ) => Breadcrumb | null
  onBeforeSend?: (event: Event, hint: EventHint) => Event | null
  allowUrls?: BrowserClientOptions['allowUrls']
}

export { setUser as setSentryUser } from '@sentry/browser'

onNetworkStatusChanged((status) => {
  setExtra('network-status', status)
})

// Set up to only send to Sentry if in Prod, otherwise log to browser console
export const initializeSentry = (options: InitializeSentryOptions) => {
  const {
    onBeforeBreadcrumb = (crumb) => crumb,
    onBeforeSend = (event) => event,
    allowUrls,
  } = options

  init({
    beforeBreadcrumb(breadcrumb, hint) {
      try {
        if (!breadcrumb.category) {
          log('breadcrumb', breadcrumb)
        }
        if (!__SENTRY_ENABLED__) {
          return null
        }

        return onBeforeBreadcrumb(breadcrumb, hint)
      } catch (e) {
        log.warn(
          'An error was thrown while recording breadcrumb. Dropping breadcrumb',
          breadcrumb,
          e
        )
        return null
      }
    },
    beforeSend: (event, hint) => {
      try {
        if (!__SENTRY_ENABLED__) {
          log('disabled', {
            type: 'event',
            payload: event,
          })
          return null
        }

        const modified = onBeforeSend(event, hint)
        if (modified == null) return null

        if (
          modified.exception &&
          modified.exception.values &&
          modified.exception.values.length
        ) {
          const lastError = modified.exception.values[0].value

          if (lastError && !lastErrors.includes(lastError)) {
            lastErrors.unshift(lastError)
            lastErrors = lastErrors.slice(0, 5)
          } else {
            return null
          }
        }

        return modified
      } catch (e) {
        log.warn(
          'An error was thrown while sending event. Dropping event',
          event,
          e
        )
        return null
      }
    },
    // motion-web
    // https://inmotion.sentry.io/projects/motion-web
    dsn: 'https://820f833d4063013505ae8080eeac79f9@o348473.ingest.sentry.io/4506854753566720',
    environment: __SENTRY_ENVIRONMENT__,
    release: __SENTRY_RELEASE__,
    allowUrls,
    integrations: [
      breadcrumbsIntegration({
        console: false,
      }),
      globalHandlersIntegration({
        onerror: true,
        onunhandledrejection: false,
      }),
    ],
  })

  log('initialized', { version: options.version })
}
