import { XSolid } from '@motion/icons'

import { type ReactNode } from 'react'

import { Modal, type ModalProps } from './modal'
import { type ModalAction } from './types'

import { Button } from '../button'
import { ButtonGroup } from '../button-group'

type BlockingActionModalProps = {
  blocking: true
  onClose?: ModalProps['onClose']
}

type NonBlockingActionModalProps = {
  blocking?: boolean
  onClose: ModalProps['onClose']
}

type BaseActionModalProps = Omit<ModalProps, 'children'> & {
  actions: ModalAction[]
  title: ReactNode
  description?: ReactNode
  showCloseButton?: boolean
}

export type ActionModalProps = BaseActionModalProps &
  (BlockingActionModalProps | NonBlockingActionModalProps)

export function ActionModal(props: ActionModalProps) {
  const {
    visible,
    title,
    description,
    actions,
    onClose,
    blocking = false,
    ...rest
  } = props

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      {...rest}
      disableOverlayClick={blocking}
      disableEscapeKey={blocking}
    >
      <div className='p-6 flex flex-col gap-2 w-[450px]'>
        <div className='flex gap-2 items-start'>
          <div className='text-semantic-neutral-text-default font-semibold mr-auto'>
            {title}
          </div>
          {!blocking && (
            <Button
              iconOnly
              onClick={onClose}
              sentiment='neutral'
              size='small'
              variant='muted'
            >
              <XSolid />
            </Button>
          )}
        </div>

        {description && (
          <div className='text-semantic-neutral-text-subtle text-sm'>
            {description}
          </div>
        )}

        <div className='flex justify-end mt-2'>
          <ButtonGroup>
            {actions.map((action) => (
              <Button
                key={action.label}
                variant={action.variant}
                sentiment={action.sentiment}
                shortcut={action.shortcut}
                onClick={() => action.onAction()}
              >
                {action.label}
              </Button>
            ))}
          </ButtonGroup>
        </div>
      </div>
    </Modal>
  )
}
