import { AutoscheduleStarSolid } from '@motion/icons'
import { classed } from '@motion/theme'

import { forwardRef, type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  Control,
  Pill,
  StyledInput,
  StyledLabel,
  type ToggleProps,
} from './toggle'

export type AutoScheduleToggleProps = Omit<ToggleProps, 'children' | 'size'> & {
  children?: ReactNode
  labelHidden?: boolean
  size?: 'xsmall' | 'small' | 'normal'
  isUnvisitedStage?: boolean
}

export const AutoScheduleToggle = forwardRef<
  HTMLLabelElement,
  AutoScheduleToggleProps
>(function AutoScheduleToggle(props, ref) {
  const {
    children = 'Auto-schedule',
    labelHidden,
    size = 'normal',
    isUnvisitedStage,
    ...rest
  } = props

  return (
    <StyledLabel
      ref={ref}
      disabled={rest.disabled}
      onClick={(e) => {
        e.stopPropagation()
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault()
        }
      }}
      variant={isUnvisitedStage ? 'subtle' : 'autoSchedule'}
      size={size}
    >
      <StyledInput type='checkbox' {...rest} />
      <StyledControl>
        <StyledPill>
          <StyledStarIcon />
        </StyledPill>
      </StyledControl>
      <span className={twMerge(labelHidden && 'sr-only')}>{children}</span>
    </StyledLabel>
  )
})

const StyledControl = classed(Control, {
  base: `
    w-[22px]
    bg-autoschedule-toggle-bg-off-default
    group-hover/label:autoschedule-toggle-bg-off-hover
    peer-checked:bg-autoschedule-toggle-bg-on-default
    group-hover/label:peer-checked:bg-autoschedule-toggle-bg-on-default

    peer-checked:peer-disabled:bg-autoschedule-toggle-bg-on-default
    group-hover/label:peer-checked:peer-disabled:bg-autoschedule-toggle-bg-on-default
  `,
})

const StyledPill = classed(Pill, {
  base: `
    w-[14px] h-[14px]
    ml-px

    bg-autoschedule-toggle-circle-off

    peer-checked:group-[]:translate-x-1.5
    peer-checked:group-[]:bg-autoschedule-toggle-circle-on

    [&>[data-icon]]:transition-colors
    [&>[data-icon]]:text-autoschedule-toggle-icon-off-default
    group-hover/label:[&>[data-icon]]:text-autoschedule-toggle-icon-off-hover
    peer-checked:group-[]:[&>[data-icon]]:text-autoschedule-toggle-icon-on-default
    group-hover/label:peer-checked:group-[]:[&>[data-icon]]:text-autoschedule-toggle-icon-on-default
  `,
})

const StyledStarIcon = classed(AutoscheduleStarSolid, {
  base: 'w-[10px] h-[10px]',
})
