import { type MouseEvent, type ReactNode, useCallback } from 'react'

import { useContextMenu } from './use-context-menu'

type ContextMenuRenderFunctionProps = {
  handleContextMenu: (e: MouseEvent) => void
}

export type ContextMenuWrapperProps = {
  renderContextMenu: (close: () => void) => ReactNode
  onMenuOpen?: () => void
  children: ReactNode | ((props: ContextMenuRenderFunctionProps) => ReactNode)
}

export const ContextMenuWrapper = ({
  onMenuOpen,
  renderContextMenu,
  children,
}: ContextMenuWrapperProps) => {
  const { handleContextMenu: internalHandleContextMenu, ContextMenuPopover } =
    useContextMenu()

  const handleContextMenu = useCallback(
    (e: MouseEvent) => {
      internalHandleContextMenu(e)
      if (onMenuOpen) onMenuOpen()
    },
    [internalHandleContextMenu, onMenuOpen]
  )

  return (
    <>
      {typeof children === 'function'
        ? children({ handleContextMenu })
        : children}

      <ContextMenuPopover
        renderContent={({ close }) => renderContextMenu(close)}
      />
    </>
  )
}
