import {
  createContext,
  type ForwardRefExoticComponent,
  type HTMLProps,
  type RefAttributes,
  useContext,
} from 'react'

export type LinkLikeComponentProps = HTMLProps<HTMLAnchorElement> & {
  url: string
  external?: boolean
  children?: React.ReactNode
}
export type LinkLikeComponent = ForwardRefExoticComponent<
  LinkLikeComponentProps & RefAttributes<HTMLAnchorElement>
>

export const LinkContext = createContext<LinkLikeComponent | undefined>(
  undefined
)

/* eslint react-refresh/only-export-components: ["warn"] */
export function useLink() {
  return useContext(LinkContext)
}
