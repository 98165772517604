import { type VirtualizedSharedProps } from './types'
import { VirtualizedBase } from './virtualized-base'

type VirtualizedColumnsProps<T> = VirtualizedSharedProps<T> & {
  estimateWidth: (item: T) => number
}

export const VirtualizedColumns = <T,>(props: VirtualizedColumnsProps<T>) => {
  const { estimateWidth, ...rest } = props
  return <VirtualizedBase horizontal estimateSize={estimateWidth} {...rest} />
}
