/**
 * Removes Emojis from a given string
 * @param text the text to parse
 * @returns the string without emoji
 */
export function stripEmojis(text: string) {
  // \p{Extended_Pictographic}       - to select emojis
  // \uFE00-\uFE0F                   - emoji variation selector - https://en.wikipedia.org/wiki/Variation_Selectors_(Unicode_block)
  // \w200d                          - Zero width joiner - https://en.wikipedia.org/wiki/Zero-width_joiner
  //
  // Note, there is a `\p{Emoji}` pattern, however that also matches non-emoji characters such as '0123456789#*'

  // More information
  // https://stackoverflow.com/questions/70401560/what-is-the-difference-between-emoji-presentation-and-extended-pictographic

  return text
    .replace(/\p{Extended_Pictographic}|[\uFE00-\uFE0F]|\u200d/gmu, '')
    .replace(/\s+/g, ' ')
    .trim()
}
