import {
  replaceSystemVariableKeys,
  type SystemVariableReplacementValues,
  wrapVariableInDelimiters,
} from '@motion/shared/flows'
import type {
  ProjectSchema,
  StageDefinitionSchema,
  VariableDefinitionSchema,
  VariableInstanceSchema,
} from '@motion/zod/client'

export function isValidStageDefinitionId(
  stageDefinitionId: StageDefinitionSchema['id'] | null,
  project: ProjectSchema
) {
  return (
    stageDefinitionId != null &&
    stageDefinitionId === project.activeStageDefinitionId &&
    project.stages.some((s) => s.stageDefinitionId === stageDefinitionId)
  )
}

export function replaceTextVariables(
  text: string,
  variables: VariableDefinitionSchema[],
  variablesValues: Record<string, VariableInstanceSchema>,
  systemVariableValues: SystemVariableReplacementValues
) {
  let result = replaceProjectTextVariableKeys(text, variables, variablesValues)
  result = replaceSystemVariableKeys(result, systemVariableValues)
  return result
}

export function replaceProjectTextVariableKeys(
  textToReplace: string,
  variables: VariableDefinitionSchema[],
  variablesValues: Record<string, VariableInstanceSchema>
) {
  return variables.reduce((result, variable) => {
    if (variable.type === 'text') {
      const wrappedVariableName = wrapVariableInDelimiters(variable.key)

      return result.replaceAll(
        wrappedVariableName,
        variablesValues[variable.id]?.value ?? ''
      )
    }

    return result
  }, textToReplace)
}
